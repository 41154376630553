<template>
    <Teleport to="body">
        <ProgressModal
            v-if="isImporting"
            label="Importing questions"
            :message="importProgressMessage"
            :progress="importProgressPercent"
        />
    </Teleport>
    <div ref="editor" class="import-field__editor" />
    <div class='upload-block'>
        <Errors 
            class="upload-block__errors"
            v-if="validationMessages.length" 
            :errors="validationMessages" />
        <div 
            class="upload-block__file-name"
            :class="{ 'upload-block__error-file': validationMessages.length}">
            {{ fileName }}
        </div>
        <label class='upload-block__upload-button'>
            <span v-if="importedQuestions.length">Replace File</span>
            <span v-else>Choose File</span>
            <input
                class="upload-block__file-upload"
                type="file"
                @change="csvUploadFileChange"
            >
        </label>
    </div>
    <FormSection v-if="importedQuestions.length"
                 :column-grid="true"
                 class="import-field"
    >
        <div  class="import-field__import-preview">
            <div 
                class="import-field__import-list-label import-field__import-overview-label" 
                @click="showImportSection.ka = !showImportSection.ka"
            >
                Questions will be imported into {{ Object.values(importedKnowledgeAreas).length }} subjects
                <VIcon :icon="chevron" :class="{ collapse: !showImportSection.ka }" />
            </div>
            <div v-show="showImportSection.ka" class="import-field__import-overview">
                <table>
                    <tr>
                        <td>Name</td>
                        <td>New</td>
                        <td>Updating</td>
                        <td>Status</td>
                    </tr>
                    <tr
                        v-for="ka in Object.values(importedKnowledgeAreas)"
                        :key="ka.name"
                        :style="{ fontWeight: existingKnowledgeAreasLib.includes(ka.name) ? 400 : 600 }"
                    >
                        <td>{{ ka.name }}</td>
                        <td>{{ ka.questions.filter(q => !q.objectId).length }}</td>
                        <td>{{ ka.questions.filter(q => q.objectId).length }}</td>
                        <td>{{ existingKnowledgeAreasLib.includes(ka.name) ? 'Found' : 'NEW' }}</td>
                    </tr>
                </table>
            </div>
            <template v-if="activeImportedQuestions.length">
                <div 
                    class="import-field__import-list-label import-field__import-list-active-label"
                    @click="showImportSection.active = !showImportSection.active"
                >
                    {{ activeImportedQuestions.length }} Questions cannot be imported because 
                    they already exist in the CMS.
                    <VIcon :icon="chevron" :class="{ collapse: !showImportSection.active }" />
                </div>
                <ImportQuestionsList
                    v-show="showImportSection.active"
                    :questions="activeImportedQuestions"
                    class="import-field__import-list-active"
                />
            </template>
            <template v-if="inactiveImportedQuestions.length">
                <div 
                    class="import-field__import-list-label import-field__import-list-inactive-label"
                    @click="showImportSection.inactive = !showImportSection.inactive"
                >
                    {{ inactiveImportedQuestions.length }} Questions Pending Import
                    <VIcon :icon="chevron" :class="{ collapse: !showImportSection.inactive }" />
                </div>
                <ImportQuestionsList
                    v-show="showImportSection.inactive"
                    :questions="inactiveImportedQuestions"
                    class="import-field__import-list-inactive"
                />
            </template>
        </div>
    </FormSection>
    <div class="import-instructions">
        <PocketLink href="/files/import_example.csv" target="_blank">Download Sample CSV</PocketLink>
        <div class="import-instructions__text">
            Upload a CSV of your edited questions directly into the CMS. Questions imported here will show 
            up under Question Drafts and will be added to this job in the "completed" status. These
            questions can then be published in the same way that any question draft is published.
        </div>
    </div>
</template>

<script lang="ts">
import { parse as csvParse } from 'csv-parse/browser/esm'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import ImportQuestionsList from '@/components/Jobs/JobForm/ImportQuestionsList.vue'
import { FormGroup, FormSection, FormValidation } from '@/components/Forms/'
import type { ICSVImportRow, IMappedCSVRow, TMappedImportQuestion } from '@/store/files/types'
import { Component, Vue, Prop, Emit } from 'vue-facing-decorator'
import type { IKnowledgeArea } from '@/store/knowledgeAreaDrafts/types'
import type { CMS, Study } from '@pocketprep/types'
import ProgressModal from '@/components/ProgressModal.vue'
import InlineEditor from '@pocketprep/ckeditor5-build-inline'
import type { TEnhancedQuestion } from '@/store/questions/types'
import questionDraftsModule from '@/store/questionDrafts/module'
import { isProxy, toRaw } from 'vue'
import UIKit from '@pocketprep/ui-kit'

@Component({
    components: {
        FormGroup,
        ImportQuestionsList,
        FormSection,
        FormValidation,
        ProgressModal,
        VIcon: FontAwesomeIcon,
        PocketButton: UIKit.Button,
        PocketLink: UIKit.Link,
        Errors: UIKit.Errors,
    },
})
export default class ImportField extends Vue {
    @Prop() exam!: CMS.Class.ExamDraftJSON
    @Prop() examQuestions!: TEnhancedQuestion[]
    @Prop() knowledgeAreas!: (CMS.Class.KnowledgeAreaDraftJSON | IKnowledgeArea)[]

    importedQuestions: IMappedCSVRow[] = []
    allQDSerials: string[] = []
    chevron = faChevronUp
    showImportSection = {
        ka: true,
        active: false,
        inactive: false,
    }
    editorProxy: ReturnType<(typeof InlineEditor)['create']> | null = null

    validationMessages: string[] = []
    isImporting = false
    importProgressMessage = ''
    importProgressPercent = 0
    fileName = ''

    get editor () {
        if (isProxy(this.editorProxy)) {
            return toRaw(this.editorProxy)
        } else {
            return this.editorProxy
        }
    }

    // questions that can be imported because they are not already in the CMS
    get inactiveImportedQuestions () {
        return this.importedQuestions.filter(q => !this.allQDSerials.includes(q.serial))
    }

    // questions that cannot be imported because they are in the CMS
    get activeImportedQuestions () {
        return this.importedQuestions.filter(q => this.allQDSerials.includes(q.serial))
    }

    get mappedImportQuestions (): TMappedImportQuestion[] {
        const questionLib = this.examQuestions.reduce((acc, q) => {
            acc[q.serial || ''] = q

            return acc
        }, {} as { [serial: string]: TEnhancedQuestion })

        return this.inactiveImportedQuestions
            .map(q => {
                if (questionLib[q.serial]) {
                    const addedDate = questionLib[q.serial].addedDate
                    return {
                        ...q,
                        updatedAt: questionLib[q.serial].updatedAt,
                        createdAt: questionLib[q.serial].createdAt,
                        objectId: questionLib[q.serial].objectId,
                        appName: this.exam.nativeAppName,
                        compositeKey: this.exam.compositeKey,
                        passageImage: questionLib[q.serial].passageImage,
                        explanationImage: questionLib[q.serial].explanationImage,
                        addedDate: 'iso' in addedDate
                            ? new Date(addedDate.iso)
                            : addedDate,
                        isMockQuestion: false,
                        references: [ q.reference ],
                        answeredCorrectlyCount: questionLib[q.serial].answeredCorrectlyCount, 
                        answeredIncorrectlyCount: questionLib[q.serial].answeredIncorrectlyCount, 
                        percentCorrect: questionLib[q.serial].percentCorrect, 
                        choiceStats: questionLib[q.serial].choiceStats,
                    }
                }
                
                return {
                    ...q,
                    addedDate: new Date(),
                    appName: this.exam.nativeAppName,
                    status: q.isArchived ? 'Archived' : 'Complete',
                    compositeKey: this.exam.compositeKey,
                    classic: 0,
                    dirty: false,
                    isMockQuestion: false,
                    references: [ q.reference ],
                    answeredCorrectlyCount: 0, 
                    answeredIncorrectlyCount: 0, 
                    percentCorrect: 0, 
                    choiceStats: {},
                }
            })
    }

    get importedKnowledgeAreas () {
        if (this.mappedImportQuestions.length) {
            const initKALibObj: { [key: string]: 
                { name: string; questions: Partial<Study.Class.ExamDataJSON>[] } } = {},
                kaLib = this.mappedImportQuestions.reduce((acc, q) => {
                    if (q.subject) {
                        if (q.subject in acc) {
                            acc[q.subject].questions.push(q)
                        } else {
                            acc[q.subject] = { questions: [ q ], name: q.subject }
                        }
                    }

                    return acc
                }, initKALibObj)

            return kaLib
        }

        return {}
    }

    get existingKnowledgeAreasLib () {
        if (this.knowledgeAreas.length) {
            return this.knowledgeAreas.map(ka => ka.name)
        }

        return []
    }

    async mounted () {
        this.editorProxy = await InlineEditor.create(this.$refs.editor, {
            licenseKey: import.meta.env.VUE_APP_CKEDITOR_LICENSE,
            wproofreader: {
                serviceId: import.meta.env.VUE_APP_WPROOFREADER_KEY,
                srcUrl: 'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js',
            },
            initialData: '',
        })
    }

    lintContent (content: string) {
        if (!this.editor) {
            throw new Error('Editor is not running.')
        }

        this.editor.data.set(content)
        return this.editor.getData()
    }

    generateSerial () {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

        let result = ''
        for (let i = 0; i < 10; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length))
        }
        return result
    }

    @Emit('change')
    async csvUploadFileChange ($event: Event) {
        this.validationMessages = []

        const files = ($event.target as HTMLInputElement).files,
            file = files && files.length && files[0]

        if (!(file instanceof File)) {
            this.importedQuestions = []
            this.validationMessages.push('Invalid file. Selected file isn\'t recognized as a file.')
            return []
        }

        this.fileName = file.name
        
        if (file.type !== 'text/csv') {
            this.importedQuestions = []
            this.validationMessages.push('Invalid file. Only CSV files can be imported.')
            return []
        }

        this.isImporting = true
        this.importProgressMessage = 'Loading file'
        this.importProgressPercent = 0
        const fileReader = new FileReader()
        fileReader.readAsText(file)

        const fileContent = await new Promise<string | null | ArrayBuffer>((resolve, reject) => {
            fileReader.onload = () => {
                resolve(fileReader.result) 
            }
            fileReader.onerror = (e) => {
                return reject(e.type) 
            }
        })

        if (!(typeof fileContent === 'string')) {
            this.importedQuestions = []
            this.validationMessages.push('Import failed. We were unable to read file contents.')
            return []
        } else {
            const fileObj = await new Promise<ICSVImportRow[]>
            ((resolve, reject) => csvParse(fileContent, (err, output) => err ? reject(err) : resolve(output)))

            this.importProgressMessage = `0 / ${fileObj.length - 1} questions processed`
            this.importProgressPercent = 10

            for (let index = 0; index < fileObj.length; index++) {
                if (fileObj[index][0] === 'Serial') {
                    continue
                }

                this.importProgressMessage = `${index} / ${fileObj.length - 1} questions processed`
                this.importProgressPercent = 10 + (80 * (index / fileObj.length))

                await new Promise(resolve => setTimeout(resolve, 0))

                const createChoiceObj = (idx: number, text: string, isCorrect: boolean) => ({
                    text,
                    isCorrect,
                    id: `${isCorrect ? 'a' : 'd' }${index + 1}`,
                })

                const choices = [
                    ...[
                        this.lintContent(fileObj[index][6]),
                        this.lintContent(fileObj[index][7]),
                        this.lintContent(fileObj[index][8]),
                        this.lintContent(fileObj[index][9]),
                        this.lintContent(fileObj[index][10]),
                        this.lintContent(fileObj[index][11]),
                        this.lintContent(fileObj[index][12]),
                        this.lintContent(fileObj[index][13]),
                        this.lintContent(fileObj[index][14]),
                    ].map((val, idx) => createChoiceObj(idx, val, true)),
                    ...[
                        this.lintContent(fileObj[index][18]),
                        this.lintContent(fileObj[index][19]),
                        this.lintContent(fileObj[index][20]),
                        this.lintContent(fileObj[index][21]),
                        this.lintContent(fileObj[index][22]),
                        this.lintContent(fileObj[index][23]),
                        this.lintContent(fileObj[index][24]),
                        this.lintContent(fileObj[index][25]),
                        this.lintContent(fileObj[index][26]),
                    ].map((val, idx) => createChoiceObj(idx, val, false)),
                ].filter(c => c.text)

                this.importedQuestions.push({
                    serial: fileObj[index][0] || this.generateSerial(),
                    type: fileObj[index][1],
                    isArchived: !!(Number(fileObj[index][2])),
                    isFree: !!(Number(fileObj[index][3])),
                    subject: fileObj[index][4],
                    prompt: this.lintContent(fileObj[index][5]),
                    choices,
                    explanation: this.lintContent(fileObj[index][15]),
                    passage: this.lintContent(fileObj[index][16]),
                    reference: this.lintContent(fileObj[index][17]),
                    answeredCount: fileObj[index][27],
                    percentCorrect: fileObj[index][28],
                    dateAdded: fileObj[index][29],
                    isMockQuestion: fileObj[index][30],
                    subtopic: fileObj[index][31],
                    bloomTaxonomyLevel: fileObj[index][32],
                })
            }
            
            /* eslint-disable */
            if (!JSON.stringify(fileObj[0]).startsWith('["Serial","Type","Archived","Free","Subject","Question","Answer","Answer 2","Answer 3","Answer 4","Answer 5","Answer 6","Answer 7","Answer 8","Answer 9","Explanation","Passage","Reference","Distractor 1","Distractor 2","Distractor 3","Distractor 4","Distractor 5","Distractor 6","Distractor 7","Distractor 8","Distractor 9","Answered Count","Percent Correct","Date Added","Is Mock","Subtopic","Bloom\'s Taxonomy Level"')) {
                this.importedQuestions = []
                this.validationMessages.push('Invalid formatting. Compare your file and column titles to the sample CSV file below.')
                this.isImporting = false
                return []
            }
            /* eslint-enable */

            this.importProgressMessage = 'Processing imported question serials'
            this.importProgressPercent = 95

            this.allQDSerials = await questionDraftsModule.actions.fetchQuestionDraftSerials()
        }

        this.isImporting = false

        return this.mappedImportQuestions
    }
}
</script>

<style lang="scss" scoped>

.upload-block {
    margin-top: 53px;
    margin-bottom: 40px;
    padding: 12px 0;

    &__file-upload {
        display:none;
    }

    &__file-name {
        position: relative;
        bottom: 22px;
        color: $brand-black;
        font-weight: 600;
        line-height: 20px;
        font-size: 15px;
    }

    &__errors {
        position: relative;
        bottom: 34px;
        width: 430px;
    }

    &__error-file {
        color: $pepper;
    }

    &__upload-button {
        height: 39px;
        width: 118px;
        border: none;
        border-radius: 4px;
        padding: 10px 18px 9px 18px;
        background-color: $brand-blue;
        color: white;
        font-weight: 600;

        &:hover {
            background-color: mix($brand-blue, black, 90%);
        }

        &:active {
            background-color: mix($brand-blue, black, 80%);
        }
    }
}

.import-field {
    margin-bottom: 40px;
    
    &__editor {
    display: none;
    }

.import-field__import-overview {
    margin-bottom: 20px;

    table {
        border: 1px solid $night;

        tr {
            td {
                padding: 10px;
                border: 1px solid $night;
                border-left: 0;
                border-top: 0;
                vertical-align: top;
            }

            &:first-child {
                font-weight: 600;
                text-align: center;
                background: #fff;
                white-space: nowrap;
            }
        }
    }
}

.import-field__import-list-label {
    margin: 10px 0 20px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    user-select: none;

    &:hover {
        text-decoration: underline;
    }

    svg {
        margin-left: 5px;

        &.collapse {
            transform: rotate(180deg);
        }
    }
}

.import-field__import-list-active-label {
    color: $red;
}

.import-field__import-list-active {
    margin-bottom: 30px;
}

    .import-field__import-preview {
        width: 100%;
    }
}

.import-instructions {    
    &__text {
        font-weight: 500;
        line-height: 20px;
        font-size: 15px;
        height: 100px;
        width: 424px;
        color: $ash;
        margin-top: 14px;
    }
}
</style>