import type { TUploadImage } from './types'
import { Parse } from '@/store/ParseUtils'
import type { CMS } from '@pocketprep/types'

/**
 * Upload a file to Parse
 *
 * @param {file} file - file to upload to Parse
 * @param {string} fileName - name of file to upload
 * @param {string} directory - directory path (i.e., bucket) to upload file to
 *
 * @returns {Promise} resolves to string URL of uploaded file or false if image is too big
 */
const uploadImage = async ({ file, directory }: Parameters<TUploadImage>[0]): ReturnType<TUploadImage> => {
    if (file.size > 5000000) {
        alert('ERROR: Images cannot be larger than 5MB.')
        return false
    }

    if (file.name.includes('\\')) {
        alert('ERROR: Image names cannot contain backward slashes (\\).')
        return false
    }

    return new Promise<string | false>((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            if (typeof fileReader.result !== 'string') {
                throw 'Invalid file type.'
            }

            return Parse.Cloud.run<CMS.Cloud.uploadFile>(
                'uploadFile', 
                { 
                    file: fileReader.result, 
                    directory,
                    fileName: file.name,
                    type: file.type,
                }
            )
                .then(url => {
                    resolve(url)
                })
                .catch(() => {
                    alert('ERROR: Images cannot be larger than 5MB.')
                    resolve(false)
                })
        }
        fileReader.onerror = () => {
            return reject(false) 
        }
    })
}

export default {
    uploadImage,
}
