<template>
    <div class="references">
        <div class="references__form">
            <EditorField
                v-model="reference"
                size="large"
                class="references__field"
                :enable-comments="false"
                @editorCreated="storeEditor"
            />
            <div class="references__add-ref">
                <PocketButton
                    type="secondary"
                    :disabled="reference === ''"
                    @click="addReference"
                >
                    Add
                </PocketButton>
            </div>
        </div>

        <div class="references__list">
            <ul>
                <li
                    v-for="(reference, index) in references"
                    :key="index"
                >
                    <span @click="removeReference(index)">&times;</span>
                    <div v-html="reference" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Emit, Prop, Watch } from 'vue-facing-decorator'
import { FormGroup, TextareaField, EditorField } from '@/components/Forms/'
import InlineEditor from '@pocketprep/ckeditor5-build-inline'
import UIKit from '@pocketprep/ui-kit'
import { isProxy, toRaw } from 'vue'

@Component({
    components: {
        FormGroup,
        EditorField,
        TextareaField,
        PocketButton: UIKit.Button,
    },
})
export default class ReferencesList extends Vue {
    @Prop() readonly loadedReferences!: string[]

    references: string[] = []
    reference = ''
    lastRefreshTime = Date.now()
    editorProxy: ReturnType<(typeof InlineEditor)['create']> | null = null

    get editor () {
        if (isProxy(this.editorProxy)) {
            return toRaw(this.editorProxy)
        } else {
            return this.editorProxy
        }
    }

    mounted () {
        this.references = this.loadedReferences
    }

    storeEditor (editor: ReturnType<(typeof InlineEditor)['create']>) {
        this.editorProxy = editor
    }

    @Watch('loadedReferences')
    onLoadedReferencesChange (val: string[]) {
        this.references = val
    }

    addReference () {
        this.references.push(this.reference)
        this.reference = ''
        this.onReferencesChange()

        if (this.editor) {
            this.editor.setData('')
        }
    }

    removeReference (index: number) {
        this.references.splice(index, 1)
        this.onReferencesChange()
    }

    @Emit('change')
    onReferencesChange () {
        return this.references
    }
}
</script>

<style lang="scss" scoped>

.references {
    display: flex;
    justify-content: space-between;

    &__field {
        width: 100% !important;
        margin-bottom: 15px;
    }

    &__add-ref {
        :deep(.uikit-btn--secondary) {
            border-radius: 3px;
            border: solid 1px $brand-blue;
        }
    }

    &__form {
        flex: 1;

        button {
            display: block;
        }
    }

    &__list {
        flex: 1;
        padding-left: 30px;

        ul {
            li {
                margin-bottom: 16px;
                white-space: pre;
                display: flex;
                font-size: 14px;
                line-height: 18px;

                span {
                    cursor: pointer;
                    user-select: none;
                    margin-right: 15px;
                }

                :deep(p) {
                    margin: 0;
                }

                div {
                    white-space: normal;
                }
            }
        }
    }
}
</style>

