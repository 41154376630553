<template>
    <div class="forgot-password">
        <Loading v-if="isLoading" />
        <Modal v-if="showSuccessModal">
            <div class="forgot-password__modal-content">
                <h2 class="forgot-password__success-message">
                    Password reset successful!
                </h2>
                <span class="forgot-password__success-detail">
                    Check your email for additional steps.
                </span>
                <PocketButton
                    class="forgot-password__success-ok-btn"
                    @click="okClicked"
                >
                    Ok
                </PocketButton>
            </div>
        </Modal>
        <div 
            class="forgot-password__header" 
            :class="{'forgot-password__header--prod-env' : currentEnvironment === 'production'}"
        >
            <img
                class="forgot-password__logo"
                src="@/assets/img/pocket-prep-logo-gray.svg"
                alt="Pocket Prep"
            >
            <div class="forgot-password__app-name">
                CMS
            </div>
        </div>
        <div 
            class="forgot-password__form"
        >
            <PocketButton
                class="forgot-password__back-btn"
                type="icon"
                icon="arrow"
                @click="backClicked"
            />
            <div class="forgot-password__title">
                Forgot your password?
            </div>
            <div class="forgot-password__instructions">
                Enter the email associated with your account and we'll send an email to reset your password.
            </div>
            <div>
                <div class="forgot-password__form-label">
                    Email
                </div>
                <input
                    v-model="email"
                    class="forgot-password__email-input"
                    type="email"
                    autocomplete="email"
                >
            </div>
            <FormValidation class="forgot-password__form-validation" :messages="validationMessages" />
            <div class="forgot-password__options">
                <PocketButton
                    class="forgot-password__submit-btn"
                    @click="submitForgotPasswordForm"
                >
                    Submit
                </PocketButton>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import Loading from '@/components/Loading.vue'
import Modal from '@/components/Modal.vue'
import { FormValidation } from '@/components/Forms/'
import { usersModule } from '@/store/users/module'
import { environment } from '@/utils'
import UIKit from '@pocketprep/ui-kit'

@Component({
    components: {
        Loading,
        Modal,
        FormValidation,
        PocketButton: UIKit.Button,
        Icon: UIKit.Icon,
    },
})
export default class ForgotPassword extends Vue {
    email = ''
    isLoading = false
    showSuccessModal = false
    validationMessages: string[] = []

    get currentEnvironment () {
        return environment
    }

    async submitForgotPasswordForm () {
        const email = this.email
        if (email) {
            this.validationMessages = []
            this.isLoading = true
            
            const resetSuccess = await usersModule.actions.forgotPassword(email)
            
            if (!resetSuccess) {
                this.validationMessages.push('error/Unable to process request')
            } else {
                this.showSuccessModal = true
            }
            this.isLoading = false
        } else {
            this.validationMessages.push('error/Please enter an email address')
        }
    }

    okClicked () {
        this.$router.push({ name: 'login' })
    }

    backClicked () {
        this.$router.push({
            name: 'login',
        })
    }
}
</script>

<style lang="scss" scoped>

.forgot-password {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    &__modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px;
    }

    &__success-message {
        font-size: 24px;
        line-height: 46px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    &__success-detail {
        margin-bottom: 30px;
        font-size: 13px;
        line-height: 14px;
        color: #587183;
    }

    &__header {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        background-color: $brand-blue;
        color: white;
        min-height: 52px;
        width: 100%;
        z-index: -1;

        &--prod-env {
            background-color: $charcoal;
        }
    }

    &__logo {
        width: 30px;
        height: 30px;
        margin-left: 44px;
        color: #fff;
    }

    &__app-name {
        margin-left: 12px;
        margin-right: 8px;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        color: #fff;
        opacity: 0.8;
    }

    &__form {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        width: 660px;
        height: 500px;
        flex-shrink: 0;
        max-width: $mobile-width;
        padding: calc(#{$base} * 2);
        background-color: $manilla;
        border-radius: 16px;
        box-shadow: 0 6px 22px 0 rgba(31, 53, 69, 0.2);

        @include breakpoint(mobile) {
            flex: 1;
            margin: 0;
            padding: $base;
            height: 60%;
        }
    }

    &__back-btn {
        position: absolute;
        top: 0;
        left: 18px;
        height: 24px;
        margin-top: 15px;
        transform: rotate(180deg);

        :deep(.uikit-btn__content svg) {
            height: 24px;
            width: 18px;
        }
    }

    &__instructions {
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 30px;
        color: #475967;
        text-align: center;
        width: 455px;
    }

    &__options {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 24px;
    }

    &__title {
        font-size: 36px;
        line-height: 46px;
        font-weight: 700;
        margin-bottom: 30px;
    }

    &__form-label {
        font-size: 13px;
        line-height: 14px;
        margin: 0 0 6px 12px;
        color: #587183;
    }

    &__email-input {
        color: $brand-black;
        border: 1px solid rgba(136, 163, 182, 0.85);
        border-radius: 3px;
        padding: 9px 11px;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        height: 36px;
        width: 386px;
    }

    &__form-validation {
        height: 36px;
        width: 386px;
    }
}
</style>