import type { IExamDraftsModuleState } from './types'

export const getInitialExamDraftsState = (): IExamDraftsModuleState => {
    return {
        examDrafts: [],
    }
}

const moduleState = getInitialExamDraftsState()

export default moduleState
