<template>
    <div class="question-templates">
        <template v-if="knowledgeAreas.length">
            <div class="question-templates__title">
                Subject Counts
            </div>
            <div class="question-templates__head">
                <span>
                    Subject
                    <span v-if="showSubtopicsHeader">, Subtopic</span>
                </span>
                <span class="head__num-questions">
                    # of Questions
                </span>
            </div>
            <table 
                v-for="(subject, index) in questionTemplates" 
                :key="index">
                <tr class="question-templates__subject">
                    <OverflowTooltip class="question-templates__subject-overflow-tooltip">
                        {{ subject.name }}
                    </OverflowTooltip>
                    <PocketInput 
                        class="question-templates__subject-input"
                        field-type="number"
                        :modelValue="subject.count"
                        @update:modelValue="validateValue($event, subject)"
                        @focusout="autoFillSubtopicCounts(subject)"
                        :error="subtopicCountErrors[subject.name] && 
                            subtopicCountErrors[subject.name].length"
                    />
                    <Errors
                        v-if="subtopicCountErrors[subject.name] && subtopicCountErrors[subject.name].length"
                        class="question-templates__count-error"
                        :errors="subtopicCountErrors[subject.name]"
                    />
                </tr> 
                <tr class="question-templates__subtopic"
                    v-for="(subtopic, index) in subject.subtopics"
                    :key="index">
                    <OverflowTooltip class="question-templates__subtopic-overflow-tooltip">
                        {{ subtopic.name }}
                    </OverflowTooltip>
                    <PocketInput 
                        class="question-templates__subtopic-input"
                        field-type="number"
                        :modelValue="subtopic.count"
                        @update:modelValue="validateValue($event, subtopic)"
                        @focusout="validateSubtopicCounts(subject)"
                        :error="subtopicCountErrors[subject.name] &&
                            subtopicCountErrors[subject.name].length"
                    />
                </tr>
            </table>
        </template>
        <template v-else>
            <div
                style="font-size: 14px;"
            >
                Select an exam to add new questions
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Emit, Watch, Vue } from 'vue-facing-decorator'
import { FormGroup, TextField, TypeaheadField, SelectField } from '@/components/Forms/'
import type { IKnowledgeAreaPayload, IQuestionTemplatePayload } from '@/store/jobs/types'
import UIKit from '@pocketprep/ui-kit'

@Component({
    components: {
        TypeaheadField,
        FormGroup,
        TextField,
        SelectField,
        PocketButton: UIKit.Button,
        PocketInput: UIKit.Input,
        Errors: UIKit.Errors,
        OverflowTooltip: UIKit.OverflowTooltip,
    },
})
export default class QuestionTemplates extends Vue {
    @Prop() readonly knowledgeAreas!: IKnowledgeAreaPayload[]
    @Prop() readonly loadedQuestionTemplates!: IQuestionTemplatePayload[]
    @Prop() readonly showSubtopicsHeader!: boolean
    
    questionTemplates: IQuestionTemplatePayload[] = []
    questionTemplateSearch = ''
    questionTemplate = { text: '' }
    enabledSubtopicAutoFillLib: { [subjectName: string]: boolean } = {}
    subtopicCountErrors: { [subjectName: string]: string [] } = {}
    

    created () {
        this.questionTemplates = this.knowledgeAreas.map(ka => {
            const questionTemplate = this.loadedQuestionTemplates.find(qt => qt.name === ka.name)
            return {
                name: ka.name,
                count: questionTemplate ? questionTemplate.count : 0,
                subtopics: ka.subtopics ? ka.subtopics.map(subtopic => {
                    const subtopicTemplate = questionTemplate?.subtopics?.find(st => st.name === subtopic.name) 
                    return {
                        id: subtopic.id,
                        name: subtopic.name,
                        count: subtopicTemplate ? subtopicTemplate.count : 0,
                    }
                }) : [],
            }
        })

        this.enabledSubtopicAutoFillLib = this.questionTemplates.reduce((acc, subject) => 
            (acc[subject.name] = true, acc), 
        {} as { [subjectName: string]: boolean })
    }

    validateValue (
        val: number,
        target: IQuestionTemplatePayload | { name: string; count: string | number }
    ) {
        if (val < 0) {
            return
        }
        target.count = Math.round(val)
    }

    autoFillSubtopicCounts (subject: IQuestionTemplatePayload) {
        const subtopics = subject.subtopics
        if (!subtopics?.length) {
            return
        }
        const subjectCount = subject.count

        if (subtopics && subjectCount === 0){
            subtopics.forEach(subtopic => {
                subtopic.count = 0
            })
            this.subtopicCountErrors[subject.name] = []
            this.enabledSubtopicAutoFillLib[subject.name] = true
            return
        }
            
        if (subtopics && this.enabledSubtopicAutoFillLib[subject.name]) {
            const numOfSubtopics = subtopics.length
            let remainder = subjectCount % numOfSubtopics
            subtopics.forEach(subtopic => {
                subtopic.count = subjectCount / numOfSubtopics >> 0
                //distribute remainder as evenly as possible
                if (remainder) {
                    subtopic.count += 1
                    remainder -= 1
                }
            })
        }

        const totalSubtopicCount = subtopics.reduce((acc, st) => {
            return acc + st.count
        }, 0)

        this.compareAndValidateCounts(totalSubtopicCount, subjectCount, subject.name)
    }

    validateSubtopicCounts (
        subject: IQuestionTemplatePayload
    ) {
        //enable autofill back if user erased all subtopics
        if (!subject.subtopics?.find(st => st.count)) {
            this.enabledSubtopicAutoFillLib[subject.name] = true
            return
        }

        this.enabledSubtopicAutoFillLib[subject.name] = false

        const subjectCount = subject.count
        const totalSubtopicCount = subject.subtopics?.reduce((acc, st) => {
            return acc + st.count
        }, 0)

        if (subjectCount === 0 || subjectCount <  totalSubtopicCount) {
            subject.count = totalSubtopicCount
            this.subtopicCountErrors[subject.name] = []
            return
        }

        this.compareAndValidateCounts(totalSubtopicCount, subjectCount, subject.name)
    }

    compareAndValidateCounts (totalSubtopicCount: number, subjectCount: number, subjectName: string) {
        if (totalSubtopicCount !== subjectCount) {
            this.subtopicCountErrors[subjectName] = []
            this.subtopicCountErrors[subjectName].push(`Sum of subtopics = ${totalSubtopicCount}`)
        } else {
            this.subtopicCountErrors[subjectName] = []
        }
    }

    @Emit('change')
    emitChange () {
        return this.questionTemplates
    }

    @Emit('errorCountChange')
    emitErrorCountChange () {
        return this.subtopicCountErrors
    }

    @Watch('questionTemplates', { deep: true })
    onQuestionTemplatesChange () {
        this.emitChange()
    }

    @Watch('subtopicCountErrors', { deep: true })
    onSubtopicCountErrorsChange () {
        this.emitErrorCountChange()
    }
}
</script>

<style lang="scss" scoped>

.question-templates {

    &__head {
        margin-top: 30px;
        display: flex;
        margin-bottom: 6px;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: $pickled-bluewood;
        width: 596px;
        justify-content: space-between;

        .head__num-questions {
            width: 110px;
            text-align: center;
        }
    }

    &__title {
        margin-top: 44px;
        color: $brand-black;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
    }

    &__subject {
        position: relative;
        height: 36px;
        line-height: 20px;
        font-size: 15px;
        border-bottom: 1px solid $fog;
        margin-top: 4px;
        width: 596px;
        display: flex;
        justify-content: space-between;
        padding: 8px 0;
    }

    &__subtopic {
        height: 30px;
        font-size: 14px;
        line-height: 19px;
        padding: 3.5px 0 7.5px 20px;
        color: $pickled-bluewood;
        border-bottom: 1px solid $fog;
        margin-top: 4px;
        width: 596px;
        display: flex;
        justify-content: space-between;
    }

    &__subject-input {
        width: 110px;
        position: relative;
        bottom: 8px;

        :deep(.uikit-input__input) {
            text-align: right;
            padding-right: 4px;
        }   
        :deep(.uikit-input__input::-webkit-inner-spin-button) {
            margin-left: 3px;
        }
    }

    &__subtopic-input {
        width: 76px;
        position: relative;
        bottom: 3.5px;
        
        :deep(.uikit-input__input) {
            height: 30px;
            text-align: right;
            padding-right: 4px;
        }
        :deep(.uikit-input__input::-webkit-inner-spin-button) {
            margin-left: 3px;
        }
    }

    &__subject-overflow-tooltip {
        width: 470px
    }

    &__subtopic-overflow-tooltip {
        width: 470px;
    }

    input::-webkit-outer-spin-button, 
    input::-webkit-inner-spin-button { 
        margin-left: 3px; 
    } 

    &__count-error {
        position: absolute;
        left: 620px;
        bottom: -1px;
        width: 256px;
        height: 36px;
    }

    &__knowledge-area-label {
        width: 100%;
        max-width: 300px;
        margin-left: 8px;
        font-weight: 600;
        font-size: 14px;
    }

    &__count-label {
        width: 100%;
        max-width: 300px;
        margin-left: 10px;
        font-weight: 600;
        font-size: 14px;
    }

    &__knowledge-area-row {
        display: flex;
    }

    &__knowledge-area-select {
        width: 100%;
        max-width: 300px;
        margin-right: 12px;
    }

    &__count {
        width: 100%;
        max-width: 80px;
        margin-right: 8px;
    }

    &__remove-ka-btn {
        margin-top: 4px;

        :deep(.uikit-btn--secondary) {
            height: 38px;
            font-weight: 800;
            border-radius: 3px;
            border: solid 1px $brand-blue;
            padding: 8px 18px 10px 18px;
        }
    }

    &__add-ka {
        margin-top: 12px;

        :deep(.uikit-btn--secondary) {
            border-radius: 3px;
            border: solid 1px $brand-blue;
        }
    }

    &__add-all-kas {
        margin-top: 12px;

        :deep(.uikit-btn--secondary) {
            border-radius: 3px;
            border: solid 1px $brand-blue;
        }
    }
}
</style>