<template>
    <div class="bulk-action" ref="dropdownBtn">
        <PocketButton
            type="secondary"
            class="bulk-action__title"
            :class="{ 'bulk-action__title--disabled': disabled }"
            :disabled="disabled"
            @click="showActionDropdown = !showActionDropdown"
        >
            {{ bulkActionGroupTitle }}
            <Icon 
                type="accordionArrow" 
                class="bulk-action__arrow-icon"
                :class="{ 'bulk-action__arrow-icon--expanded': showActionDropdown }"
            />
        </PocketButton>
        <div
            v-if="showActionDropdown"
            ref="dropdown"
            class="bulk-action__dropdown"
            :class="{ 'bulk-action__dropdown--extra-wide': extraWidth }"
        >
            <div class="bulk-action__subtitle">
                {{ bulkActionGroupSubtitle }}
            </div>
            <div 
                v-for="action in actions"
                :key="action.label"
            >
                <div 
                    class="bulk-action__dropdown-item"
                    :class="{
                        'bulk-action__dropdown-item--header': action.subActions,
                        'bulk-action__dropdown-item--extra-margin': actions.some(item => item.subActions)
                    }"
                    @click="!action.subActions && emitBulkAction(action.value)"
                >
                    {{ action.label }}
                </div>
                <div 
                    v-for="subAction in action.subActions"
                    :key="subAction.value"
                    class="bulk-action__dropdown-item"
                    @click="emitBulkAction(subAction.value)"
                >
                    {{ subAction.label }}
                </div>
            </div>
        </div>
    </div>
    <div>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import type { ComponentPublicInstance } from 'vue'

@Component({
    components: {
        PocketButton: UIKit.Button,
        Icon: UIKit.Icon,
    },
})
export default class BulkAction extends Vue {
    @Prop() readonly bulkActionGroupTitle!: string
    @Prop() readonly bulkActionGroupSubtitle!: string
    @Prop() readonly actions!: { label: string; value: string; subActions?: { label: string; value: string }[] }[]
    @Prop() readonly extraWidth!: boolean
    @Prop() readonly disabled!: boolean

    showActionDropdown = false

    mounted () {
        document.addEventListener('click', this.clickListener)
    }

    clickListener (e: MouseEvent) {
        if (this.showActionDropdown) {
            const targetEl = e.target as HTMLElement
            const dropdown = this.$refs.dropdown as ComponentPublicInstance | undefined
            const dropdownEl = dropdown?.$el as HTMLElement | undefined
            const dropdownBtnEl = this.$refs.dropdownBtn as HTMLElement | undefined
            if (
                !dropdownBtnEl?.contains(targetEl)
                && !dropdownEl?.contains(targetEl) 
                && document.body.contains(targetEl)
            ) {
                this.showActionDropdown = false
            }
        }
    }

    @Emit('bulkActionSelected')
    emitBulkAction (bulkAction: string) {
        return bulkAction
    }
}
</script>

<style lang="scss" scoped>
.bulk-action{
    position: relative;
    margin-right: 12px;

    &__title {
        padding: 4px 6px;

        &:hover {
            background-color: $cream-puff;
        }

        &:disabled {
            pointer-events: none;
        }
    }

    &__arrow-icon {
        height: 6px;
        margin-bottom: 1px;

        &--expanded {
            transform: rotate(180deg);
        }
    }

    &__dropdown {
        position: absolute;
        border-radius: 4px;
        width: 160px;
        z-index: 1;
        left: -6px;
        top: 23px;
        box-sizing: border-box;
        background: $white;
        box-shadow: 0 6px 22px 0 rgba($brand-black, 0.1);
        max-height: 280px;
        overflow: auto;
        padding-bottom: 7px;

        &--extra-wide {
            width: 270px;
        }
    }

    &__subtitle {
        font-size: 12px;
        padding: 11px 12px 4px;
        line-height: 16px;
        font-weight: 600;
        color: $slate;
        letter-spacing: 1px;
        text-transform: uppercase;
        cursor: default;
    }

    &__dropdown-item {
        font-size: 15px;
        padding: 4px 12px;
        font-weight: 500;
        color: $brand-black;
        line-height: 20px;
        cursor: pointer;

        &:hover {
            background-color: $gray-background;
        }

        &--header {
            font-size: 12px;
            line-height: 16px;
            font-weight: 600;
            color: $slate;
            letter-spacing: 1px;
            text-transform: uppercase;

            &:hover {
                background-color: initial;
                cursor: default;
            }
        }

        &--extra-margin {
            margin-top: 11px;
        }
    }
}
</style>