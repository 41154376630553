<template>
    <PocketModalContainer class="export-progress">
        <PocketModal class="export-progress__content">
            <label>{{ label }}</label>
            <div class="export-progress__message">
                {{ message }}
            </div>
            <div class="export-progress__bar">
                <div class="export-progress__bar-fill" :style="`width: ${progress}%`" />
            </div>
        </PocketModal>
    </PocketModalContainer>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'

@Component({
    components: {
        PocketModalContainer: UIKit.ModalContainer,
        PocketModal: UIKit.Modal,
    },
})
export default class ProgressModal extends Vue {
    @Prop() readonly progress!: number
    @Prop() readonly message!: string
    @Prop() readonly label!: string
}
</script>

<style lang="scss" scoped>

.export-progress {
    position: fixed;

    &__content {
        border-radius: 12px;
        padding: 48px 40px;
        max-width: 100%;
        width: 374px;
        height: 184px;
        text-align: center;
    }

    label {
        display: block;
        font-size: 16px;
        color: $brand-black;
        line-height: 22px;
        font-weight: 600;
        margin-bottom: 6px;
    }

    &__bar {
        border: 1px solid $brand-blue;
        margin: auto;
        background: $sky-blue;
        height: 8px;
        border-radius: 30px;
        width: 274px;
        margin-top: 32px;
        overflow: hidden;

        .export-progress__bar-fill {
            background: $brand-blue;
            height: 30px;
        }
    }

    &__message {
        text-align: center;
        font-size: 15px;
        line-height: 20px;
        color: $ash;
    }

    :deep(.uikit-modal) {
        top: 40%;
    }
}
</style>