<template>
    <div class="list">
        <ListControls
            v-if="listOptions.listData.length || forceShowControls"
            :list-data="listOptions.listData"
            :fields="listOptions.listSchema"
            :default-sort="listOptions.defaultSort"
            :async="typeof listOptions.listData === 'function'"
            :show-case-sensitive-toggle="showCaseSensitiveToggle"
            :store-name="storeName"
            :list-is-loading="isLoading"
            @dataChanged="updateFilteredData"
        >
            <template #filterTypeSelectValue="{ item }">
                <slot name="filterTypeSelectValue" :item="item" />
            </template>
            <template #filterTypeDropdownListItem="{ item }">
                <slot name="filterTypeDropdownListItem" :item="item" />
            </template>
            <template #filterTextDropdownListItem="{ item, field }">
                <slot
                    name="filterTextDropdownListItem"
                    :item="item"
                    :field="field"
                />
            </template>
        </ListControls>
        <div
            ref="listResultsContainer"
            class="list__results-container"
        >
            <div v-if="(listOptions.listData.length || forceShowControls)" class="list__results-header">
                <div class="list__results-summary">
                    <div v-if="selectable && filteredData.length" class="list__results-select-all">
                        <Checkbox
                            class="list__result-checkbox"
                            :modelValue="isSelectAllChecked"
                            @update:modelValue="toggleSelectAll"
                        />
                    </div>
                    <span
                        v-if="!isLoading"
                        class="list__result-count"
                        :class="{'list__result-count--unselectable': !selectable}">
                        {{ resultCount }}
                        <slot 
                            name="additionalListUnitText" 
                            v-if="listOptions.listData.length === filteredData.length">
                        </slot>
                        {{ 
                            `${listUnit}${filteredData.length === 1 ? '' : 's'}` 
                        }}                         
                        {{
                            selectable
                                ? ` |&nbsp;&nbsp;${preSelectedItems.length} Selected`
                                : ''
                        }}
                    </span>
                    <span v-if="isLoading" class="list__loading">
                        Loading {{`${listUnit}${filteredData.length === 1 ? '' : 's'}`}}...
                    </span>
                </div>
                <div class="list__bulk-actions">
                    <slot name="listBulkActions"></slot>
                </div>
                <TablePagination
                    v-if="filteredData.length"
                    class="list__page-controls-top"
                    :perPage="pageSize"
                    :unit="`${listUnit}${filteredData.length === 1 ? '' : 's'}`"
                    :showFirstAndLastBtn="showLastPageBtn"
                    :currentPage="currentPageNum - 1"
                    :total="resultCount"
                    @changePage="handlePageChange"
                />
            </div>
            <div class="list__result-rows-container">
                <div v-if="isLoading" class="list__loading-overlay" />
                <Table
                    :show-header="false"
                    :columns="tableColumns"
                    :rows="tableRows"
                    :table-column-labels-styles="{
                        padding: '0',
                        backgroundColor: brandColors.grayBackground,
                        fontSize: '12px',
                        color: brandColors.brandBlack,
                    }"
                    :table-row-styles="{
                        padding: '0',
                        cursor: isClickable || selectable ? 'pointer' : 'auto',
                    }"
                    :default-sort="tableSort"
                    :sort-toggle-order="[ -1, 1 ]"
                    @sort="handleTableSort"
                    @rowClicked="itemClicked"
                    @rowEnter="rowEnter"
                    @rowLeave="rowLeave"
                >
                    <template #tableColumnLabelText="{ column }">
                        <slot name="tableColumnLabelText" :column="column" />
                    </template>
                    <template #tableCellValue="{ row, column }">
                        <slot
                            name="tableCellValue"
                            :row="row"
                            :column="column"
                        >
                            <template v-if="column.type === 'progressGroup'">
                                <div class="list__progress-bar-container">
                                    <div
                                        v-for="progress in row[column.propName]"
                                        :key="progress.label"
                                        class="list__progress-bar-fill"
                                        :class="{
                                            'list__progress-bar-fill--halftone': progress.color !== 'green',
                                            'list__progress-bar-fill--green': progress.color === 'green',
                                        }"
                                        :style="{
                                            'width': `${(progress.count / progress.total) * 100}%`,
                                        }"
                                    />
                                </div>
                            </template>
                            <template v-else-if="column.type === 'percent'">
                                {{ Math.round(Number(row[column.propName]) || 0) }}%
                            </template>
                            <template v-else-if="selectable && column.propName === tableColumns[0].propName">
                                <div class="list__checkbox-container">
                                    <Checkbox
                                        class="list__checkbox"
                                        :modelValue="isItemSelected(row)"
                                    />
                                    <div
                                        class="list__increased-checkbox-click-area"
                                        @click.stop.prevent="selectItem(row, !isItemSelected(row))"
                                    />
                                    <div class="list__checkbox-label">
                                        <OverflowTooltip>
                                            {{ stripHtmlTags(row[column.propName] || '--') }}
                                        </OverflowTooltip>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <OverflowTooltip>
                                    {{ stripHtmlTags(row[column.propName] || '--') }}
                                </OverflowTooltip>
                            </template>
                        </slot>
                    </template>
                    <template #tableRows>
                        <div v-if="filteredData.length === 0 && !isLoading" class="list__no-results">
                            <slot name="emptyState">
                                <EmptyState>
                                    <template #imageRow>
                                        <slot
                                            name="emptyStateImage"
                                            :activeFilterPropNames="activeFilterPropNames"
                                        >
                                            <img
                                                src="@/assets/img/brain-looking-down.png"
                                                alt=""
                                                class="list__empty-state-image"
                                            >
                                        </slot>
                                    </template>
                                    <template #emptyStateTitle>
                                        <slot
                                            name="emptyStateTitle"
                                            :searchAll="searchAll"
                                            :activeFilterPropNames="activeFilterPropNames"
                                        >
                                            <template>
                                                No results{{ searchAll ? ` for "${searchAll}"`: '' }}
                                            </template>
                                        </slot>
                                    </template>
                                    <template #emptyStateMessage >
                                        <slot
                                            name="emptyStateMessage"
                                            :searchAll="searchAll"
                                            :activeFilterPropNames="activeFilterPropNames"
                                        >
                                            There are no {{ listUnit.toLowerCase() }}    
                                            that match your filter and search criteria.
                                        </slot>
                                    </template>
                                </EmptyState>
                            </slot>
                        </div>
                    </template>
                </Table>
            </div>
            <div class="list__footer">
                <TablePagination
                    v-if="filteredData.length"
                    class="list__page-controls-bottom"
                    :perPage="pageSize"
                    :unit="`${listUnit}${filteredData.length === 1 ? '' : 's'}`"
                    :showFirstAndLastBtn="showLastPageBtn"
                    :currentPage="currentPageNum - 1"
                    :total="resultCount"
                    @changePage="handlePageChange"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit, Watch } from 'vue-facing-decorator'
import {
    ListControls,
} from '@/components/Lists'
import type {
    IListOptions,
    TListData,
    IListDataUpdatePayload,
} from '@/components/Lists'
import type {
    IListDataIcon,
    TSortDirection,
} from './types'
import * as R from 'ramda'
import listsModule from '@/store/lists/module'
import UIKit, { type ITableColumn, type ITableSortSettings, type TTableRow } from '@pocketprep/ui-kit'
import BrandColors from '@pocketprep/ui-kit/pocketprep-export.module.scss'

@Component({
    components: {
        ListControls,
        Table: UIKit.Table,
        OverflowTooltip: UIKit.OverflowTooltip,
        TablePagination: UIKit.TablePagination,
        EmptyState: UIKit.EmptyState,
        Checkbox: UIKit.Checkbox,
    },
})
export default class List extends Vue {
    @Prop() readonly listOptions!: IListOptions<TListData>
    @Prop({ default: false }) readonly showCaseSensitiveToggle!: boolean
    @Prop({ default: false }) readonly selectable!: boolean
    @Prop({ default: Array }) readonly preSelectedItems!: TListData[]
    @Prop({ default: 20 }) readonly pageSize!: number
    @Prop({ default: true }) readonly showLastPageBtn!: boolean
    @Prop({ default: true }) readonly isClickable!: boolean
    @Prop({ default: false }) readonly forceShowControls!: boolean
    @Prop({ default: '' }) readonly storeName!: string // If set, we save list's filters, sort, and pagination
    @Prop({ default: 'result' }) readonly listUnit!: string

    filteredData: TListData[] = []
    selectedItems: TListData[] = []
    currentPageStart = 0
    resultCount = 0
    sort = { by: '' as keyof TListData, direction: 'ASC' as TSortDirection }
    filters: IListDataUpdatePayload['filters'] = []
    searchAll: IListDataUpdatePayload['searchAll'] = ''
    caseSensitive: IListDataUpdatePayload['caseSensitive'] = false
    activeFilterPropNames: IListDataUpdatePayload['activeFilterPropNames'] = []
    isLoading = false
    rowHoverId: string | null = null
    brandColors = BrandColors

    get tableColumns (): ITableColumn[] {
        const schema = this.listOptions.listSchema
        const visibleFields = schema.filter(field =>
            'propName' in field
            && 'label' in field
            && (
                !field.options
                || !('isHidden' in field.options)
                || !field.options.isHidden
            )
        )
        return visibleFields.map((field) => ({
            propName: 'propName' in field ? field.propName as string : '',
            name: 'label' in field ? field.label : undefined,
            type: field.type,
            styles: {
                padding: '10px 0 10px 15px',
                margin: '0',
                ...field?.options?.fieldStyles,
                ...(field?.options?.width && { width: `${field.options.width}px` }),
                ...(field?.type === 'percent' 
                && !field?.options?.fieldStyles?.padding
                && !field?.options?.fieldStyles?.textAlign
                && { textAlign: 'right', paddingRight: '13px', paddingLeft: '2px' }),
            },
            labelStyles: {
                height: '28px',
                padding: '8px 0 7px 15px',
                ...(field?.type === 'percent' 
                && !field?.options?.labelStyles?.margin 
                && { marginLeft: '15px', marginRight: '-15px' }),
                ...(field.options && 'labelStyles' in field.options && field.options.labelStyles),
            },
            ...(field.options && 'sort' in field.options && field.options?.sort === false && { isSortDisabled: true }),
        }))
    }

    get tableRows (): TTableRow[] {
        const rows: TTableRow[] = this.currentPage.map(item => {
            const styles = this.processDataModifiers(item) || {}
            if (this.isClickable && item.objectId === this.rowHoverId) {
                styles.backgroundColor = `${BrandColors.grayBackground}`
            }
            return {
                id: item.objectId,
                styles,
                tabIndex: '0',
                ...item,
            }
        })
        return rows
    }

    get tableSort () {
        return {
            column: this.tableColumns.find(column => column.propName === this.sort.by),
            direction: this.sort.direction === 'ASC' ? 1 : -1,
        }
    }

    get sortOrder () {
        return this.sort.direction === 'ASC' ? [ 1, -1 ] : [ -1, 1 ]
    }

    get currentPageEnd () {
        return this.currentPageStart + this.pageSize
    }

    get totalPages () {
        return Math.ceil((this.resultCount) / this.pageSize)
    }

    get currentPageNum () {
        return Math.floor(this.currentPageStart / this.pageSize) + 1
    }

    get currentPage () {
        return this.filteredData.slice(this.currentPageStart, this.currentPageEnd)
    }

    get isAsync () {
        return typeof this.listOptions.listData === 'function'
    }

    get isPersistable () {
        return !!this.storeName
    }

    get storedListState () {
        if (!this.isPersistable) {
            return undefined
        }

        const isEqualListData = (a: TListData[], b: TListData[]) => {
            try {
                return JSON.stringify(a) === JSON.stringify(b)
            } catch (err) {
                return false
            }
        }

        const oldListState = listsModule.getters.getListState(this.storeName)
        // If the list data is out of sync, erase the stored list state
        if (
            typeof this.listOptions.listData !== 'function'
            && oldListState
            && oldListState.data
            && (
                this.listOptions.listData.length !== oldListState.data.length
                || !isEqualListData(this.listOptions.listData, oldListState.data)
            )
        ) {
            listsModule.actions.resetListState(this.storeName)
            return undefined
        } else {
            return oldListState
        }
    }

    get filteredIds () {
        return this.filteredData.map(fil => fil.objectId)
    }

    get selectedIds () {
        return this.selectedItems.map(sel => sel.objectId)
    }

    get isSelectAllChecked () {
        const selectedItems = this.filteredIds.filter(filteredId => this.selectedIds.includes(filteredId))
        if (!selectedItems.length) {
            return false
        } else if (selectedItems.length === this.filteredIds.length) {
            return true
        } else {
            return null
        }
    }

    get viewingJob () {
        return this.$router.currentRoute.value.params.jobId ? true : false
    }

    created () {
        this.selectedItems = this.preSelectedItems
        this.loadListState()

        const defaultSort = this.listOptions.defaultSort
        if (!this.sort.by && defaultSort) {
            this.sort.by = defaultSort.propName
            this.sort.direction = defaultSort.sortDir
            if (this.filteredData.length) {
                this.filteredData = this.sortData(this.filteredData)
            }
        }
    }

    stripHtmlTags (html: string) {
        const div = document.createElement('div')
        div.innerHTML = html
        return div.textContent || ''
    }

    loadListState () {
        if (this.isPersistable) {
            const oldListState = this.storedListState
            if (oldListState) {
                if (
                    !this.isAsync
                    && oldListState.currentPageStart !== undefined
                    && oldListState.currentPageStart >= this.resultCount
                ) {
                    this.currentPageStart = 0
                } else {
                    this.currentPageStart = oldListState.currentPageStart || 0
                }
                this.filters = oldListState.filters || this.filters
                this.sort = oldListState.sort || this.sort
                this.searchAll = oldListState.searchAll || this.searchAll
            }
        }
    }

    isItemSelected (item: TListData) {
        return this.selectedIds.includes(item.objectId)
    }

    handlePageChange (newPageIndex: number) {
        this.goToPage(newPageIndex + 1)
    }

    async goToPage (pageNum: number) {
        const newPageStart = (pageNum - 1) * this.pageSize
        const page = await this.fetchListData(pageNum - 1)
        if (page) {
            if (this.isAsync) {
                const resultsList = new Array(page.totalCount)
                resultsList.splice(newPageStart, page.results.length, ...page.results)
                this.filteredData = resultsList
            } else {
                this.filteredData.splice(newPageStart, page.results.length, ...page.results)
            }
            this.resultCount = page.totalCount
        }
        if (this.isPersistable) {
            const initialState = this.storedListState || {
                listName: this.storeName,
            }
            listsModule.actions.saveListState({
                ...initialState,
                currentPageStart: newPageStart,
            })
            this.loadListState()
        } else {
            this.currentPageStart = newPageStart
        }
    }

    processDataModifiers (data: TListData) {
        const listDataModifiers = this.listOptions.listDataModifiers,
            listDataModifications = listDataModifiers && listDataModifiers.map(modifier => ({ ...modifier(data) }))

        return listDataModifications && R.mergeAll(listDataModifications)
    }

    processDataIcons (data: TListData) {
        const listDataIcons = this.listOptions.listDataIcons
            && this.listOptions.listDataIcons.reduce<(IListDataIcon|false)[]>(
                (icons, iconFunc) => {
                    return iconFunc(data)
                        ? [ ...icons, iconFunc(data) ]
                        : icons
                }, []
            )

        return listDataIcons
    }

    async fetchListData (page: number) {
        const listData = this.listOptions.listData
        if (typeof listData === 'function') {
            const sortField = this.filters.find(field => 'propName' in field && field.propName === this.sort.by)
            this.isLoading = true
            const newListData = await listData({
                searchAll: this.searchAll,
                caseSensitive: this.caseSensitive,
                filters: this.filters,
                sort: {
                    by: sortField && sortField.propName || '',
                    direction: this.sort.direction,
                },
                pagination: {
                    perPage: this.pageSize,
                    page,
                },
            })
            this.isLoading = false
            return newListData
        }
    }

    async handleTableSort (sortSettings: ITableSortSettings) {
        if (!sortSettings.column?.propName) {
            return
        }
        this.sort.by = sortSettings.column.propName
        this.sort.direction = sortSettings.direction === 1 ? 'ASC' : 'DESC'

        if (this.isAsync) {
            await this.updateFilteredData({
                searchAll: this.searchAll,
                filters: this.filters,
                data: 'async',
                caseSensitive: this.caseSensitive,
                activeFilterPropNames: this.activeFilterPropNames,
            })
        } else {
            this.filteredData = this.sortData(this.filteredData)
            this.goToPage(1)
        }
    }

    async updateFilteredData ({
        searchAll,
        filters,
        data,
        caseSensitive,
        activeFilterPropNames,
    }: IListDataUpdatePayload) {
        this.searchAll = searchAll
        this.activeFilterPropNames = activeFilterPropNames

        if (!this.isPersistable || data === 'async') {
            if (!this.isPersistable) {
                this.currentPageStart = 0
            }
            this.caseSensitive = caseSensitive
            this.filters = filters
        }
        if (data === 'async' && this.isAsync) {
            const currentPage = await this.fetchListData(this.currentPageNum - 1)
            if (currentPage) {
                this.resultCount = currentPage.totalCount

                const isCurrentPageNumValid = this.currentPageNum <= this.totalPages
                if (!isCurrentPageNumValid) {
                    this.goToPage(1)
                } else {
                    // We want the array to be the full length so that pages can be filled in at arbitrary indices
                    const resultsList = new Array(currentPage.totalCount)
                    resultsList.splice(this.currentPageStart, currentPage.results.length, ...currentPage.results)
                    this.filteredData = resultsList
                }
            }
        } else if (data instanceof Array) {
            this.filteredData = this.sortData(data)
            if (this.isPersistable && this.resultCount > data.length) {
                const initialState = this.storedListState || {
                    listName: this.storeName,
                }
                listsModule.actions.saveListState({
                    ...initialState,
                    currentPageStart: 0,
                })
            }
            this.resultCount = data.length
        }
        if (this.isPersistable) {
            this.loadListState()
        }
    }

    sortData (data: TListData[]) {
        const schema = this.listOptions.listSchema
        return data.sort((a, b) => {
            const currentSortField = schema
                .find(field => 'propName' in field && field.propName === this.sort.by)

            if (currentSortField) {
                const propName = 'propName' in currentSortField && currentSortField.propName
                if (propName) {
                    const aValue =  a[propName]
                    const bValue = b[propName]
                    if (typeof aValue !== 'number' && !aValue) {
                        return 1
                    }
                    if (typeof bValue !== 'number' && !bValue) {
                        return -1
                    }
                    if (currentSortField.type === 'text') {
                        return aValue > bValue
                            ? this.sortOrder[0]
                            : this.sortOrder[1]
                    } else if (currentSortField.type === 'percent') {
                        const aNumVal = isNaN(aValue as unknown as number) ? -1000 : aValue
                        const bNumVal = isNaN(bValue as unknown as number) ? -1000 : bValue
                        return aNumVal > bNumVal
                            ? this.sortOrder[1]
                            : this.sortOrder[0]
                    } else if (
                        currentSortField.type === 'progress'
                        && typeof aValue === 'object'
                        && typeof bValue === 'object'
                        && !(aValue instanceof Array)
                        && !(bValue instanceof Array)
                    ) {
                        const aProgressPct = 'percent' in aValue
                            ? aValue.percent
                            : aValue.total !== 0
                                ? Math.min((aValue.count / aValue.total) * 100, 100)
                                : 0
                        const bProgressPct = 'percent' in bValue
                            ? bValue.percent
                            : bValue.total !== 0
                                ? Math.min((bValue.count / bValue.total) * 100, 100)
                                : 0
                        return aProgressPct > bProgressPct
                            ? this.sortOrder[0]
                            : this.sortOrder[1]
                    } else if (
                        currentSortField.type === 'progressGroup'
                        && aValue instanceof Array
                        && bValue instanceof Array
                    ) {
                        const aTotalProgressCount = aValue.reduce((acc, progress) => acc + progress.count, 0)
                        const bTotalProgressCount = bValue.reduce((acc, progress) => acc + progress.count, 0)
                        return aTotalProgressCount > bTotalProgressCount
                            ? this.sortOrder[1]
                            : this.sortOrder[0]
                    } else if (currentSortField.type === 'date') {
                        if (currentSortField.options?.reversedSort) {
                            if (typeof aValue !== 'string' || !aValue) {
                                return this.sortOrder[0]
                            }
                            if (typeof bValue !== 'string' || !bValue) {
                                return this.sortOrder[1]
                            }
                            return new Date(aValue) > new Date(bValue)
                                ? this.sortOrder[1]
                                : this.sortOrder[0]
                        } else {
                            if (typeof aValue !== 'string' || !aValue) {
                                return this.sortOrder[1]
                            }
                            if (typeof bValue !== 'string' || !bValue) {
                                return this.sortOrder[0]
                            }
                            return new Date(aValue) > new Date(bValue)
                                ? this.sortOrder[0]
                                : this.sortOrder[1]
                        }
                    }
                }
            }

            return 0
        })
    }

    rowEnter (row: TTableRow) {
        this.rowHoverId = String(row.id)
    }

    rowLeave (row: TTableRow) {
        if (this.rowHoverId === String(row.id)) {
            this.rowHoverId = null
        }
    }

    @Watch('preSelectedItems')
    updateSelectedItems () {
        this.selectedItems = this.preSelectedItems
    }

    @Emit('itemClicked')
    itemClicked (item: TListData) {
        return item
    }

    // for lists with selectable items
    @Emit('select')
    selectItem (item: TListData, selected: boolean) {
        if (typeof this.listOptions.listData !== 'function') {
            const isItemAlreadySelected = !!this.selectedIds.includes(item.objectId)

            if (selected && !isItemAlreadySelected) {
                this.selectedItems = [ ...this.selectedItems, item ]
            } else if (!selected && isItemAlreadySelected) {
                this.selectedItems = this.selectedItems.filter(sel => sel.objectId !== item.objectId)
            }
            return this.selectedItems
        }

        return false
    }

    @Emit('select')
    toggleSelectAll (val: boolean | null) {
        if (val) {
            const newlySelectedItems = this.filteredData.filter(filtered =>
                !this.selectedIds.includes(filtered.objectId)
            )
            this.selectedItems = [ ...this.selectedItems, ...newlySelectedItems ]
        } else {
            this.selectedItems = this.selectedItems.filter(sel => !this.filteredIds.includes(sel.objectId))
        }
        return this.selectedItems
    }
}
</script>

<style lang="scss" scoped>

.list {
    &__results-container {
        padding: 10px;
        overflow: hidden;
        outline: none;
    }

    &__results-header {
        height: 40px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    &__results-summary {
        display: flex;
        margin: 5px;
        align-items: center;
    }

    &__bulk-actions {
        flex-grow: 1;
    }

    &__results-select-all {
        text-align: center;

        input {
            zoom: 1.5;
            width: 14px;
            height: 14px;
        }
    }

    &__result-rows-container {
        position: relative;
    }

    &__loading-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.6);
        z-index: 2;
    }

    &__checkbox-container {
        position: relative;
    }

    &__checkbox {
        position: absolute;
        left: 0;
        min-width: 18px;
    }

    &__checkbox-label {
        padding-left: 28px;
        width: 100%;
    }

    &__increased-checkbox-click-area {
        width: 50px;
        position: absolute;
        top: -10px;
        left: -16px;
        bottom: -10px;
        background-color: transparent;
    }

    &__progress-bar-container {
        position: relative;
        margin-top: 7px;
        overflow: hidden;
        width: 168px;
        height: 8px;
        border-radius: 40px;
        border: 1px solid $scifi-takeout;
    }

    &__progress-bar-fill {
        position: absolute;
        height: 100%;

        &--halftone {
            background: url('@/assets/img/job-progress-pattern.svg'), rgba($jungle-green, 0.2);
            background-position: 0 2.5px;
            background-size: 6px;
        }

        &--green {
            background-color: $spectral-green;
        }
    }

    :deep(table.uikit-table__content) {
        border: 1px solid rgba($pewter, 0.85);
        border-radius: 4px;
        overflow: hidden;
    }

    :deep(thead.uikit-table__rows) {
        overflow: hidden;
    }

    :deep(.uikit-table__row:last-child) {
        border-bottom: none;
    }

    &__no-results {
        height: 270px;
        background-color: white;
        display: flex;
        justify-content: center;
        padding-top: 12px;
    }

    :deep(.uikit-empty-state) {
        padding-left: 0;
        padding-right: 0;
    }

    &__empty-state-image {
        height: 92px;
        margin-bottom: 22px;
    }

    &__footer {
        display: flex;
        margin: 11px 0;
        justify-content: flex-end;
    }

    &__result-checkbox {
        padding: 0;
        margin-left: 10.5px;
        margin-right: 15px;
    }

    &__result-count {
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
        color: $brand-black;
        margin-bottom: 7px;
        margin-left: 8px;

        &--unselectable {
            margin-left: 0;
            padding-left: 15px;
        }
    }

    &__loading {
        color: $brand-black;
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        padding-left: 15px;
        margin-bottom: 7px;
    }

    &__page-controls-top,
    &__page-controls-bottom {
        margin-right: 14px;
        margin-bottom: 3px;
    }
}
</style>