<template>
    <div class="sign-in">
        <div class="sign-in__header" :class="{'sign-in__header--prod-env' : currentEnvironment === 'production'}">
            <img
                class="sign-in__header-logo"
                src="@/assets/img/pocket-prep-logo-gray.svg"
                alt="Pocket Prep"
            >
            <div class="sign-in__app-name">
                CMS
            </div>
        </div>
        <Loading v-if="isLoading" />
        <div class="sign-in__form">
            <div class="sign-in__form-header">
                <img
                    class="sign-in__logo"
                    src="@/assets/img/pocket-prep-sign-in.svg"
                    alt="Pocket Prep Logo"
                >
                <img
                    class="sign-in__pocket-prep"
                    src="@/assets/img/pocket-prep.svg"
                    alt="Pocket Prep Logo"
                >
                <span class="sign-in__line-separator" />
                <div class="sign-in__description">
                    <div class="sign-in__cms">
                        CMS
                    </div>
                    Content Management System
                </div>
            </div>
            <div class="sign-in__title">
                Sign In
            </div>
            <div>
                <div class="sign-in__form-label">
                    Email
                </div>
                <input
                    v-model="email"
                    class="sign-in__email-input"
                    type="email"
                    autocomplete="email"
                    @keydown.enter="submitSignInForm"
                >
                <div 
                    class="sign-in__form-label"
                >
                    Password
                </div>
                <input
                    v-model="password"
                    class="sign-in__password-input"
                    type="password"
                    autocomplete="current-password"
                    @keydown.enter="submitSignInForm"
                >
                <div
                    v-if="error"
                    class="sign-in__error"
                >
                    {{ error }}
                </div>
            </div>
            <div class="sign-in__options">
                <PocketLink
                    class="sign-in__forgot-password"
                    @click="forgotPasswordClicked"
                >
                    Forgot Password?
                </PocketLink>
                <PocketButton
                    class="sign-in__submit-btn"
                    @click="submitSignInForm"
                >
                    Sign In
                </PocketButton>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import Loading from '@/components/Loading.vue'
import UIKit from '@pocketprep/ui-kit'
import { environment } from '@/utils'
import { usersModule } from '@/store/users/module'

@Component({
    components: {
        Loading,
        PocketButton: UIKit.Button,
        PocketLink: UIKit.Link,
    },
})
export default class SignIn extends Vue {
    email = ''
    password = ''
    error = ''
    isLoading = false

    get currentEnvironment () {
        return environment
    }

    async submitSignInForm () {
        if (this.email && this.password) {
            try {
                this.isLoading = true
                await usersModule.actions.logIn({
                    username: this.email,
                    password: this.password,
                })

                if (this.$route.query.redirect && typeof this.$route.query.redirect === 'string') {
                    this.$router.push({ path: this.$route.query.redirect })
                } else {
                    this.$router.push({ name: 'job-list' })
                }
            } catch (err) {
                this.error = 'Unable to sign in'
            }
            this.isLoading = false
        } else {
            this.error = 'Please complete all fields'
        }
    }

    forgotPasswordClicked () {
        this.$router.push({ name: 'forgot-password' })
    }
}
</script>

<style lang="scss" scoped>

.sign-in {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    &__header {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        background-color: $brand-blue;
        color: white;
        min-height: 52px;
        width: 100%;
        z-index: -1;

        &--prod-env {
            background-color: $charcoal;
        }
    }

    &__header-logo {
        width: 30px;
        height: 30px;
        margin-left: 44px;
        color: #fff;
    }

    &__app-name {
        margin-left: 12px;
        margin-right: 8px;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        color: #fff;
        opacity: 0.8;
    }

    &__form {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        width: 660px;
        height: 500px;
        flex-shrink: 0;
        max-width: $mobile-width;
        padding: calc(#{$base} * 2);
        background-color: $manilla;
        border-radius: 16px;
        box-shadow: 0 6px 22px 0 rgba(31, 53, 69, 0.2);

        @include breakpoint(mobile) {
            flex: 1;
            margin: 0;
            padding: $base;
            height: 60%;
        }
    }

    &__form-header {
        position: absolute;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        top: 0;
        left: 18px;
        margin-top: 6px;
        min-height: 52px;
        width: 100%;
    }

    &__pocket-prep {
        margin-top: 4px;
        margin-left: 6px;
    }

    &__line-separator {
        border: 1.02px solid $brand-black;
        border-radius: 3px;
        margin-top: 4px;
        margin-right: 8px;
        margin-left: 10px;
        height: 16px;
    }

    &__description {
        top: 0;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        color: $brand-black;
        margin-top: 3px;
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
    }

    &__cms {
        font-weight: 600;
        margin-right: 4px;
    }

    &__title {
        font-size: 36px;
        line-height: 46px;
        font-weight: 700;
        margin-bottom: 30px;
    }

    &__form-label {
        font-size: 13px;
        line-height: 14px;
        margin: 0 0 6px 12px;
        color: #587183;
    }

    &__email-input,
    &__password-input {
        color: $brand-black;
        border: 1px solid rgba(136, 163, 182, 0.85);
        border-radius: 3px;
        padding: 9px 11px;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        height: 36px;
        width: 386px;
        margin-bottom: 24px;
    }

    &__error {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(221, 0, 0, 0.5);
        color: $brand-black;
        position: relative;
        border-radius: 3px;
        background-color: #ffefef;
        margin-bottom: 24px;
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
        height: 36px;
        width: 386px;
    }

    &__options {
        display: flex;
        align-items: center;
    }

    &__forgot-password {
        margin-right: 25px;
    }
}
</style>