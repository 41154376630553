<template>
    <div :class="'form-section form-section--' + (groupOpen ? 'open' : 'closed') + (toggleable ? ' toggleable' : '')">
        <h4
            v-if="label"
            class="form-section__header"
            :style="{ borderBottom: groupOpen ? '1px solid #a8b7c7' : undefined }"
            @click="toggleGroup"
            v-html="label"
        />
        <Transition 
            name="slide"
            @enter="setElementHeight"
            @before-leave="setElementHeight"
            @after-leave="removeElementHeight"
            @after-enter="removeElementHeight"
        >
            <div
                v-show="groupOpen"
                :class="'form-section__contents' + (columnGrid ? ' column-grid' : '')"
            >
                <slot />
            </div>
        </Transition>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator'

@Component
export default class FormSection extends Vue {
    @Prop() readonly label?: string
    @Prop({ default: false }) readonly toggleable?: boolean
    @Prop({ default: false }) readonly columnGrid?: boolean
    @Prop({ default: false }) readonly groupDefaultOpen!: boolean

    groupOpen = true

    toggleGroup () {
        this.groupOpen = this.toggleable
            ? !this.groupOpen
            : true
    }

    setElementHeight (el: Element) {
        (el as HTMLElement).style.height = el.scrollHeight + 'px'
    }

    removeElementHeight (el: Element) {
        (el as HTMLElement).style.height = ''
    }

    created () {
        this.groupOpen = this.toggleable
            ? this.groupDefaultOpen
            : true
    }
}
</script>

<style lang="scss" scoped>

.form-section {
    border-radius: 6px;
    border: 1px solid $cloudy;
    margin-bottom: 20px;
    background-color: #fff;

    &__header {
        padding: 11px 16px;
        background-color: $beach-sand;
        border-radius: 6px 6px 0 0;
        position: relative;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: $brand-black;

        &:deep(span) {
            position: absolute;
            right: 45px;
            top: 14px;
            font-size: 15px;
        }
    }

    &__contents {
        width: 100%;
        padding: 0 16px;

        &.column-grid {
            padding: 0;
        }
    }

    &.toggleable {
        h4 {
            cursor: pointer;
            user-select: none;
            position: relative;

            &::after {
                position: absolute;
                right: 16px;
                top: 10px;
                margin-left: 5px;
                content: '\25BE';
                font-size: 14px;
                font-family: Arial, Helvetica, sans-serif;
                transition: all 0.3s ease;
                transform: rotate(180deg);
                vertical-align: bottom;
                line-height: 25px;
            }
        }
    }

    &--closed.toggleable {
        overflow: hidden;

        h4::after {
            transform: rotate(0deg);
        }
    }
}

.slide-leave-active {
    border-top: 1px solid #a8b7c7;
}

.slide-enter-active,
.slide-leave-active {
    transition: height 0.3s ease;
    overflow: hidden;
}

.slide-enter {
    height: 0;
}

.slide-leave-to {
    height: 0 !important;
}
</style>
