import type { 
    IUser,
    TRole,
    TDeleteUser,
    TEnableUser,
    TDisableUser,
    TUpdateUser,
    TCreateUser,
    TFetchUsers,
    TLogin,
    TValidateUser,
    TForgotPassword,
    TLogOut,
} from './types'
import { Parse } from '@/store/ParseUtils'
import type { CMS } from '@pocketprep/types'
import { usersModule } from '@/store/users/module'
import { resetState } from '@/store/reset'

const updateStoreUser = (userUpdate: IUser) => {
    usersModule.state.user = userUpdate

    const parseLocalStorageUserKey = `Parse/${import.meta.env.VUE_APP_PARSE_APP_ID}/currentUser`
    localStorage.removeItem(parseLocalStorageUserKey)
    localStorage.setItem(parseLocalStorageUserKey, JSON.stringify(userUpdate))

    const userIndex = usersModule.state.users.findIndex(user => user.objectId === userUpdate.objectId)

    if (userIndex !== -1) {
        usersModule.state.users[userIndex] = userUpdate
    } else {
        usersModule.state.users.push(userUpdate)
    }
}

/**
 * Format phone number into: (###) ###-####
 * 
 * @prop {string} phone - number to reformat
 * 
 * @return {string} formatted phone number
 */
const formatPhoneNumber = (phone: string | undefined): string | undefined => {
    const cleaned = ('' + phone).replace(/\D/g, '')
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return undefined
}

/**
 * Convert Parse.Object into IUser typed object for store
 *
 * @param {Parse.Object} user - user object returned from Parse
 * @param {TRole} role - string name of user's role
 *
 * @returns {IUser} formatted IUser typed object for store
 */
const parseUserToStoreUser = (parseUser: CMS.Class.User, role: TRole): IUser => {
    return {
        ...parseUser.toJSON(),
        role,
    }
}


/**
 * Forgot password
 * 
 * @param {string} email - email address submitted for forgot password request
 */
const forgotPassword = async (email: Parameters<TForgotPassword>[0]): ReturnType<TForgotPassword> => {
    try {
        await Parse.User.requestPasswordReset(email)
        
        return true
    } catch (e) {
        return false
    }
}

/**
 * Check that user has a valid Parse session
 * 
 * @return {Promise} resolves to boolean
 */
const validateUser = async (): ReturnType<TValidateUser> => {
    if (!usersModule.state.user) {
        return false
    }
    
    try {
        const currentUser = Parse.User.current()
        if (currentUser) {
            const asyncUserRequest = await new Parse.Query('_User')
                .equalTo('objectId', currentUser.id)
                .count()

            if (asyncUserRequest && currentUser.id === usersModule.state.user.objectId) {
                return true
            }
        }
    } catch (e) {
        return false
    }
    
    return false
}

/**
 * Log the user in using Parse
 * @param payload the necessary info to login the user
 */
const logIn = async ({ username, password }: Parameters<TLogin>[0]): ReturnType<TLogin> => {
    const parseUser = await Parse.User.logIn(username, password) as CMS.Class.User
    // Get the user's role
    const roleQuery = new Parse.Query(Parse.Role)
    roleQuery.equalTo('users', parseUser)

    let parseRole = undefined
    try {
        // check if user has a role
        parseRole = await roleQuery.first()
        if (!parseRole) {
            throw 'Error'
        }

        // check if user is disabled
        if (parseUser.get('isDisabled')) {
            throw 'disabled'
        }
    } catch (e) {
        await logOut()
        throw new Error('User is not assigned a valid Parse role')
    }
    const role: TRole = parseRole.get('name')
    const user: IUser = parseUserToStoreUser(parseUser, role)
    updateStoreUser(user)

    await fetchUsers()

    return user
}

/**
 * Log out the user using Parse
 */
const logOut = async (): ReturnType<TLogOut> => {
    await Parse.User.logOut()
    resetState()
}

/**
 * Fetch all the CMS users from Parse
 * For each user, we query their role and save that in the store
 */
const fetchUsers = async (): ReturnType<TFetchUsers> => {
    const [ adminUsers, editorUsers, writerUsers ] = await Promise.all(
        ([ 'Admin', 'Editor', 'Writer' ] as TRole[])
            .map(async roleName => {
                // Get the Parse.Role for the given role name
                const roleQuery = new Parse.Query(Parse.Role)
                roleQuery.equalTo('name', roleName)
                const parseRole = await roleQuery.first()
                // Get the users for the given Parse.Role
                const usersByRole = parseRole ? await parseRole.getUsers().query().findAll({ batchSize: 2000 }) : []
                // Convert the parse users to IUser objects
                return (usersByRole as CMS.Class.User[])
                    .map(parseUser => parseUserToStoreUser(parseUser, roleName))
            })
    )

    const users = [
        ...adminUsers,
        ...editorUsers,
        ...writerUsers,
    ]

    usersModule.state.users = users

    // Update the locally stored current user if out of date
    const currentUser = usersModule.getters.getCurrentUser()
    const fetchedCurrentUser = users.find(u => u.objectId === currentUser?.objectId)
    if (!currentUser?.role && fetchedCurrentUser?.role) {
        updateStoreUser(fetchedCurrentUser)
    }

    return users
}

/**
 * Create a new user using Parse.
 * Re-fetch the list of users to update the store.
 * @param payload all initial info to create a user
 */
const createUser = async ({
    username,
    password,
    firstName,
    lastName,
    phone,
    role,
    subscribedAdminEmails,
}: Parameters<TCreateUser>[0]): ReturnType<TCreateUser> => {
    const createdUser = await new Parse.User({
        username,
        email: username,
        password,
        firstName,
        lastName,
        phone: formatPhoneNumber(phone),
        subscribedAdminEmails,
    }).save()

    // Give the new user the proper role
    const roleQuery = new Parse.Query(Parse.Role)
    const parseRole = await roleQuery
        .equalTo('name', role)
        .first()
    if (parseRole) {
        parseRole
            .getUsers()
            .add(createdUser)
        parseRole.save()
    }

    // send email to new user
    await Parse.Cloud.run<CMS.Cloud.sendWelcomeEmail>('sendWelcomeEmail', {
        email: username,
        name: firstName + ' ' + lastName,
        password,
    })

    // Fetch the updated list of users
    await fetchUsers()

    return parseUserToStoreUser(createdUser, role)
}

/**
 * Update an existing user
 * Re-fetch the list of users to update the store
 * @param payload the user's attributes that can be updated
 */
const updateUser = async ({
    objectId,
    username,
    firstName,
    lastName,
    phone,
    role,
    password,
    subscribedAdminEmails,
}: Parameters<TUpdateUser>[0]): ReturnType<TUpdateUser> => {
    // Get original Parse.User
    const userQuery = new Parse.Query(Parse.User)
    userQuery.equalTo('objectId', objectId)
    const userToUpdate = await userQuery.first()
    
    // Get original store user (to quickly retrieve the original Role)
    const originalStoreUser = usersModule.state.users.find(user => user.objectId === objectId)
    if (objectId && userToUpdate && originalStoreUser) {
        userToUpdate.set('objectId', objectId)
        const formattedPhone = formatPhoneNumber(phone)

        // Only update the attributes that have been provided
        username
            && userToUpdate.set('username', username) && userToUpdate.set('email', username)
        firstName
            && userToUpdate.set('firstName', firstName)
        lastName
            && userToUpdate.set('lastName', lastName)
        phone
            && userToUpdate.set('phone', formattedPhone)
        password
            && userToUpdate.set('password', password)
        
        userToUpdate.set('subscribedAdminEmails', subscribedAdminEmails)

        if (username || firstName || lastName || phone || password) {
            await userToUpdate.save()
        }

        // Update the user's role if it was changed
        if (role && role !== originalStoreUser.role) {
            // Get the old Parse.Role
            const oldRoleQuery = new Parse.Query(Parse.Role)
            oldRoleQuery.equalTo('name', originalStoreUser.role)
            const oldParseRole = await oldRoleQuery.first()

            // Remove the user from the old role
            if (oldParseRole) {
                oldParseRole.getUsers().remove(userToUpdate)
                await oldParseRole.save()
            }

            // Get the new Parse.Role
            const newRoleQuery = new Parse.Query(Parse.Role)
            newRoleQuery.equalTo('name', role)
            const newParseRole = await newRoleQuery.first()

            // Add the user to the new role
            if (newParseRole) {
                newParseRole && newParseRole.getUsers().add(userToUpdate)
                await newParseRole.save()
            }
        }

        await fetchUsers()

        if (usersModule.state.user && userToUpdate.id === usersModule.state.user.objectId) {
            updateStoreUser({
                ...usersModule.state.user,
                firstName: userToUpdate.get('firstName'),
                lastName: userToUpdate.get('lastName'),
                username: userToUpdate.get('username'),
                phone: userToUpdate.get('phone'),
                role: role || 'Writer',
                subscribedAdminEmails: userToUpdate.get('subscribedAdminEmails'),
            })
        }

        return true
    }
}

/**
 * Disable a user using Parse.
 * Re-fetch the users to update the store.
 * @param payload the required info to disable the user
 */
const disableUser = async (userId: Parameters<TDisableUser>[0]): ReturnType<TDisableUser> => {
    await Parse.Cloud.run<CMS.Cloud.disableUser>('disableUser', { userId })

    // Re-fetch the users
    await fetchUsers()
}

/**
 * Enable a user using Parse.
 * Re-fetch the users to update the store.
 * @param payload the required info to enable the user
 */
const enableUser = async (userId: Parameters<TEnableUser>[0]): ReturnType<TEnableUser> => {
    // Enable the user
    const user = new Parse.User()
    user.set({
        objectId: userId,
        isDisabled: false,
    })

    const acl = new Parse.ACL(user)
    acl.setRoleReadAccess('Writer', true)
    acl.setRoleReadAccess('Admin', true)
    acl.setRoleWriteAccess('Admin', true)
    acl.setReadAccess(user, true)
    acl.setWriteAccess(user, true)
    user.setACL(acl)

    user.save()

    // Re-fetch the users
    await fetchUsers()
}

/**
 * Delete a user using Parse.
 * Re-fetch the users to update the store.
 * @param payload the required info to delete the user
 */
const deleteUser = async (userId: Parameters<TDeleteUser>[0]): ReturnType<TDeleteUser> => {
    // Delete the user
    const userToDelete = new Parse.User()
    userToDelete.set('objectId', userId)
    await userToDelete.destroy()

    // Re-fetch the users
    await fetchUsers()
}

export default {
    forgotPassword,
    validateUser,
    logIn,
    logOut,
    fetchUsers,
    createUser,
    updateUser,
    disableUser,
    enableUser,
    deleteUser,
}
