import type {
    TFetchMessages,
    TFetchMessage,
    TFetchMessagesByJobId,
    TCreateMessage,
    TReadJobMessages,
    TMappedMessage,
} from './types'
import { Parse, sessionToken, objPointer } from '@/store/ParseUtils'
import type { CMS } from '@pocketprep/types'

/**
 *  Fetch all messages that are currently in cms server 
 */
const fetchMessages = async (): ReturnType<TFetchMessages> => {
    const parseMessages = await new Parse.Query<CMS.Class.Message>('Message')
        .findAll({
            ...sessionToken(),
            batchSize: 2000,
        })

    return parseMessages.map(message => message.toJSON())
}

/**
 * Fetch message by ID
 * 
 * @param {string} messageID - ID of message to fetch
 * 
 * @returns {Promise<IMessage>}
 */
const fetchMessage = async (messageId: Parameters<TFetchMessage>[0]): ReturnType<TFetchMessage> => {
    const parseMessage = await new Parse.Query<CMS.Class.Message>('Message')
        .equalTo('objectId', messageId)
        .first()

    return (parseMessage && parseMessage.toJSON()) || undefined
}

/**
 * Fetch messages by job ID
 * 
 * @param {string} jobId - ID of job to fetch messages for
 *
 * @returns {Promise} resolves with IMessage[]
 */
const fetchMessagesByJobId = async (
    jobId: Parameters<TFetchMessagesByJobId>[0]): ReturnType<TFetchMessagesByJobId> => {
    const parseMessages = await new Parse.Query<CMS.Class.Message>('Message')
        .equalTo('job', objPointer(jobId)('Job'))
        .findAll({
            ...sessionToken(),
            batchSize: 2000,
        })

    return parseMessages
        .map(message => {
            const user = message.get('user')
            const userId = 'objectId' in user ? user.objectId : user.id

            const mappedMessage: TMappedMessage = {
                ...message.toJSON(),
                jobId,
                userId,
            }
            delete mappedMessage.job
            delete mappedMessage.user

            return mappedMessage
        })
}

const fetchUnreadMessagesByJobIds = async ({ jobIds, userId }: { jobIds: string[]; userId: string }) => {
    const parseMessages = await new Parse.Query<CMS.Class.Message>('Message')
        .containedIn('job', jobIds.map(jobId => objPointer(jobId)('Job')))
        .notEqualTo('readUsers', userId)
        .findAll({
            ...sessionToken(),
            batchSize: 2000,
        })
    return parseMessages.map(message => message.toJSON())
}

/**
 * Create new message
 *
 * @param {IMessage} params - message object
 *
 * @returns {Promise} resolves to IMessage of new message object
 */
const createMessage = async (
    {
        content,
        userId,
        jobId,
    }: Parameters<TCreateMessage>[0]): ReturnType<TCreateMessage> => {
    const newMessage = new Parse.Object('Message',
        {
            content,
            user: objPointer(userId)('_User'),
            job: objPointer(jobId)('Job'),
            readUsers: [ userId ],
        })

    const savedMessage = await newMessage.save(null, sessionToken())

    return savedMessage.toJSON()
}

/**
 * Mark message as read
 * 
 * @param {string} jobId - ID of job to update messages for
 * @param {string} userId - ID of user who read message
 */
const readJobMessages = async (
    { jobId, userId }: Parameters<TReadJobMessages>[0]): ReturnType<TReadJobMessages> => {
    const messages = await new Parse.Query('Message')
        .equalTo('job', objPointer(jobId)('Job'))
        .findAll({ batchSize: 2000 })

    const updatedMessages = messages.map(message => {
        message.set('readUsers', [
            ...message.get('readUsers') || [],
            userId,
        ])

        return message
    })

    try {
        Parse.Object.saveAll(updatedMessages)

        return true
    } catch (e) {
        return false
    }
    
}

export default {
    fetchMessages,
    fetchMessage,
    fetchMessagesByJobId,
    fetchUnreadMessagesByJobIds,
    createMessage,
    readJobMessages,
}
