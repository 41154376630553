import type { CMS } from '@pocketprep/types'
import type { IUser, IUsersModuleState } from './types'
import * as Sentry from '@sentry/browser'
import { Parse } from '@/store/ParseUtils'

export const getInitialUsersState = (): IUsersModuleState => {
    const parseUserFromLocalStorage = Parse.User.current<CMS.Class.User>()

    // update Sentry user context if signed in
    const user = parseUserFromLocalStorage?.toJSON() as IUser || null
    if (user) {
        Sentry.setUser({ 
            id: user.objectId,
            email: user.username,
        })
    }

    return {
        user,
        users: [],
    }
}

const moduleState = getInitialUsersState()

export default moduleState
