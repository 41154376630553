import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFlag } from '@fortawesome/free-solid-svg-icons'
import { Component, Vue, Watch } from 'vue-facing-decorator'
import InlineEditor from '@pocketprep/ckeditor5-build-inline'
import JobInfo from '@/components/Jobs/JobInfo.vue'
import QuestionDraftSidebar from '@/components/Sidebars/QuestionDraftSidebar.vue'
import QuestionDraftPreview from '@/components/QuestionDrafts/QuestionDraftPreview.vue'
import {
    TextareaField,
    EditorField,
    FormGroup,
    FormSection,
    FormValidation,
    SelectField,
    ImageField,
    SelectV2,
} from '@/components/Forms/'
import Loading from '@/components/Loading.vue'
import { objPointer } from '@/store/ParseUtils'
import * as R from 'ramda'
import type { CMS, Study } from '@pocketprep/types'
import type { TChoiceKey } from '@pocketprep/types/Study/Cloud'
import { activitiesModule } from '@/store/activities/module'
import { commentsModule } from '@/store/comments/module'
import type { IQuestionTemplate } from '@/store/jobs/types'
import type {
    IParseQuestionDraft,
    IQuestionDraft,
    TQuestionDraftJobStatus,
    TQuestionDraftStatus,
} from '@/store/questionDrafts/types'
import type { TQuestionType } from '@/store/types'
import type { IKnowledgeArea } from '@/store/knowledgeAreaDrafts/types'
import { usersModule } from '@/store/users/module'
import Highlight from '@/assets/img/jobForm/Highlight.vue'
import questionDraftsModule from '@/store/questionDrafts/module'
import questionsModule from '@/store/questions/module'
import examsModule from '@/store/exams/module'
import examDraftsModule from '@/store/examDrafts/module'
import kaDraftsModule from '@/store/knowledgeAreaDrafts/module'
import mockExamsModule from '@/store/mockExams/module'
import mockExamDraftsModule from '@/store/mockExamDrafts/module'
import jobsModule from '@/store/jobs/module'
import type { ICKEditorSuggestionPayload, ICKEditorThread } from '@/store/comments/types'
import filesModule from '@/store/files/module'
import TitleText from '@/components/TitleText.vue'
import ButtonFooter from '@/components/ButtonFooter.vue'
import UIKit from '@pocketprep/ui-kit'
import { bloomTaxonomyLevels, formatDate } from '@/utils'
import ChatButton from '@/components/Chat/ChatButton.vue'

interface IMockExamSetting { 
    id: string
    label: string
    value: boolean
}

@Component({
    components: {
        FormGroup,
        FormSection,
        Loading,
        FormValidation,
        SelectField,
        SelectV2,
        EditorField,
        TextareaField,
        QuestionDraftPreview,
        JobInfo,
        ImageField,
        Highlight,
        VIcon: FontAwesomeIcon,
        ButtonFooter,
        TitleText,
        QuestionDraftSidebar,
        Errors: UIKit.Errors,
        Modal: UIKit.Modal,
        ModalContainer: UIKit.ModalContainer,
        PocketButton: UIKit.Button,
        Icon: UIKit.Icon,
        Tooltip: UIKit.Tooltip,
        Toast: UIKit.Toast,
        ChatButton,
    },
})
export default class QuestionDraftForm extends Vue {
    validationMessages: string[] = []
    archivedStatus = ''
    isLoading = false
    originalIsReadOnly = true
    isReadOnly = true
    isQuestionModalOpen = false
    flag = faFlag
    submitAndContinue = false
    lastSave: Date | null = null
    userOS = window.navigator.platform
    showSavedNotificationMessage: string[] = []
    defaultMCQDistractors = 3
    minMCQDistractors = 2
    maxMCQDistractors = 6
    maxMCRChoices = 10
    jobId: string | null = null
    examDataId: string | null = null
    examDraftId: string | null = null
    examMetadataId: string | null = null
    jobName = ''
    job: CMS.Cloud.JobWithMetrics | null = null
    questionTemplates: IQuestionTemplate[] = []
    jobReferences: string[] | null = null
    jobQuestionDrafts: IParseQuestionDraft[] = []
    knowledgeAreaDraftId: string | null = null
    subtopicId = ''
    bloomTaxonomyLevel: Study.Class.BloomTaxonomyLevel | '' = ''
    questionDraftType: TQuestionType = 'Multiple Choice'
    prompt = ''
    jobStatus: TQuestionDraftJobStatus = 'Writer'
    originalJobStatus: TQuestionDraftJobStatus = 'Writer'
    draftStatus: TQuestionDraftStatus = 'active'
    passage = ''
    passageImageUrl: string | null = null
    passageImageFile: File | null = null
    passageImageAltText: string | null = null
    passageImageLongAltText: string | null = null
    explanation = ''
    explanationImageUrl: string | null = null
    explanationImageFile: File | null = null
    explanationImageAltText: string | null = null
    explanationImageLongAltText: string | null = null
    answers: string[] = new Array(1)
    distractors: string[] = new Array(1)
    references: { text: string; id: number }[] = []
    selectedReference = ''
    editors: ReturnType<(typeof InlineEditor)['create']>[] = []
    lastRefreshTime = Date.now()
    isFlagged = false
    originalIsFlagged = false
    isArchived = false
    isSpecial = false
    serial: string | null = null
    createdAt: string | null = null
    updatedAt: string | null = null
    lastUpdatedBy: string | null = null
    dateAdded: string | null = null
    subCategory: string | null = null
    knowledgeAreaDrafts: (CMS.Class.KnowledgeAreaDraftJSON | IKnowledgeArea)[] = []
    answeredCorrectlyCount = 0
    answeredIncorrectlyCount = 0
    choiceStats: Partial<Record<TChoiceKey,number>> = {}
    totalChoices = 0
    percentCorrect = 0
    isMockQuestion = false
    mockExamSettings: IMockExamSetting[] = []
    mockExams: (CMS.Class.MockExamDraftJSON | Study.Class.MockExamJSON)[] = []
    bloomTaxonomyLevels = bloomTaxonomyLevels
    bloomAIAssistLoading = false
    bloomAIAssistSuggestion: Study.Class.BloomTaxonomyLevel | 'Unknown' | null = null
    previousRouteName: string | null | undefined
    passageDisplaySettings = { 
        showPassage: false,
        showImage: false,
        showRemovePassageTooltip: false,
        showRemoveImageTooltip: false,
    }
    showExplanationImageTooltip = false
    showAddDistractorTooltip = false
    showAddAnswerTooltip = false
    distractorHoverIndex: number | null = null
    answerHoverIndex: number | null = null
    referenceHoverIndex: number | null = null
    toast: string | null = null
    toastSubtext: string | null = null
    retainToast = false
    modalError: { modalTitle: string; modalSubtext: string; firstLine?: string } | null = null
    errors: string[] = []
    subjectAndSubtopicErrors: string[] = []
    answerAndDistractorErrors: string[] = []
    latestSubmitAttemptParams: { 
        redirect?: boolean
        unflag?: boolean
        checkPlagiarism?: boolean
        createUnsavedDraft?: boolean
        clearUnsavedDraft?: boolean
        toast?: string
        toastSubtext?: string
    } = {}
    formatDate = formatDate
    isSerialUpdated = false
    questionDraftsFetched = false
    currentQueue: 'Writer' | 'Editor' | 'Completed' | undefined
    originalDraftData: string | null = null
    nextQuestionId: string | null = null
    endOfQueueModal = ''

    get currentQuestionId () {
        return this.$route.params.questionDraftId
    }

    get currentRouteName () {
        return this.$route.name
    }

    get questionTypes (): TQuestionType[] {
        if (this.job) {
            const aqt = this.job.allowedQuestionTypes
            const aqtNames = [
                aqt.mcq.allowed && 'Multiple Choice',
                aqt.mcr.allowed && 'Multiple Correct Response',
                aqt.tf.allowed && 'True/False',
            ].filter((aqtName): aqtName is TQuestionType => !!aqtName)

            // If the question's existing type is outside the job's allowed types, we should still display it
            return [ ...new Set([ ...aqtNames, this.questionDraftType ]) ]
        } else {
            return [
                'Multiple Choice',
                'Multiple Correct Response',
                'True/False',
            ]
        }
    }

    get formattedErrors () {
        if (this.errors.length > 1) {
            const errorredFields = [ 'Subject', 'Subtopic', 'Explanation', 'answer', 'prompt', 'distractor' ]
                .reduce((acc, curr) => {
                    if (this.errors.find(errorMessage => errorMessage.includes(curr))) {
                        acc.push(curr)
                    }
                    return acc
                }, [ 'The following fields are required to submit question:' ])
            const formattedFields = errorredFields.map(field => {
                if (field === 'answer') {
                    return 'At least 1 answer'
                } else if (field === 'prompt') {
                    return 'Question Prompt'
                } else if (field === 'distractor') {
                    return this.job?.allowedQuestionTypes.mcq.numDistractors
                        ? `${this.job?.allowedQuestionTypes.mcq.numDistractors
                        } distractors are required for each question in this job`
                        : 'At least 1 Distractor'
                } else {
                    return field
                }
            })
            return formattedFields
        } else {
            return this.errors
        }
    }

    get lastSavedTime () {
        return this.lastSave && new Intl.DateTimeFormat(undefined, {
            hour: 'numeric',
            minute: 'numeric',
        }).format(this.lastSave)
    }

    // gets list of all available knowledge areas from exam
    get formattedKnowledgeAreas () {
        // if no knowledge areas on component, return empty array
        if (!this.knowledgeAreaDrafts.length) {
            return []
        }

        const mappedKnowledgeAreas = this.knowledgeAreaDrafts
            .map(knowledgeAreaDraft => ({
                label: knowledgeAreaDraft.name + (knowledgeAreaDraft.isArchived ? ' (archived)' : ''),
                value: ('objectId' in knowledgeAreaDraft && knowledgeAreaDraft.objectId) || knowledgeAreaDraft.name,
                disabled: knowledgeAreaDraft.isArchived,
            }))
            .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase(), undefined, { numeric: true }))

        // If we have a job with questionTemplates, then filter to only include those knowledge areas
        const questionTemplates = this.job && this.job.questionTemplates
        if (questionTemplates && !this.examDataId) {
            return mappedKnowledgeAreas.filter(ka =>
                this.knowledgeAreaDraftId === ka.value  // Keep the KA even if not in job's questionTemplates
                || questionTemplates.find(qt => qt.knowledgeAreaDraftId === ka.value)
            )
        } else {
            return mappedKnowledgeAreas
        }
    }

    get subtopics () {
        return this.knowledgeAreaDrafts
            .find(kaDraft => 'objectId' in kaDraft && kaDraft.objectId === this.knowledgeAreaDraftId)?.subtopics
            ?.map(subtopic => ({
                label: subtopic.name,
                value: subtopic.id,
            })) || []
    }

    get filteredAnswers () {
        return this.answers.filter(ans => !!ans)
    }

    get filteredDistractors () {
        return this.distractors.filter(val => !!val)
    }

    get isDraftEdited () {
        const currentDraftData = {
            subject: this.knowledgeAreaDraftId,
            blooms: this.bloomTaxonomyLevel,
            subtopic: this.subtopicId,
            type: this.questionDraftType,
            status: this.draftStatus,
            cost: this.isSpecial,
            prompt: this.prompt,
            answers: this.answers,
            distractors: this.distractors,
            image: this.passageImageUrl,
            explanation: this.explanation,
            explanationImage: this.explanationImageUrl,
            references: this.references,
        }

        return this.originalDraftData !== JSON.stringify(currentDraftData)
    }

    get nextIncompleteSubject () {
        const subjectCurrentCounts : { [key: string]: { count: number; completed: number }} = {}
        this.questionTemplates.forEach(qt => {
            subjectCurrentCounts[qt.knowledgeAreaDraftId] = { count: qt.count, completed: 0 }
        })
        this.jobQuestionDrafts.forEach(jqd => {
            if (jqd.knowledgeAreaDraft) {
                subjectCurrentCounts[jqd.knowledgeAreaDraft.objectId].completed += 1
            }
        })

        return Object.keys(subjectCurrentCounts).find(subject =>
            subjectCurrentCounts[subject].completed < subjectCurrentCounts[subject].count)
    }

    nextIncompleteSubtopicInSubject (subjectId: string) {
        const subjectSubtopics = this.questionTemplates.find(qt =>
            qt.knowledgeAreaDraftId === subjectId)?.subtopics

        const subtopicsCurrentCounts = subjectSubtopics?.reduce(
            (acc: { [key: string]: { count: number; completed: number }}, curr) => {
                acc[curr.id] = { count: curr.count, completed: 0 }
                return acc
            }, {})

        this.jobQuestionDrafts.forEach(jqd => {
            if (jqd.subtopicId && subtopicsCurrentCounts && subtopicsCurrentCounts[jqd.subtopicId]) {
                subtopicsCurrentCounts[jqd.subtopicId].completed += 1
            }
        })

        return subtopicsCurrentCounts && Object.keys(subtopicsCurrentCounts).find(subtopic =>
            subtopicsCurrentCounts[subtopic].completed < subtopicsCurrentCounts[subtopic].count)
    }

    get duplicateAnswersAndDistractors () {
        const count: { [key:string]: number } = {}
        const duplicates = []
    
        this.distractors.forEach(item => {
            if (item) {
                count[item] = (count[item] || 0) + 1
            }
        })
        this.answers.forEach(item => {
            if (item) {
                count[item] = (count[item] || 0) + 1
            }
        })
    
        for (const key in count) {
            if (count[key] > 1) {
                duplicates.push(key)
            }
        }
    
        return duplicates.length ? [ 'All answers and distractors must be unique. ' ] : []
    }

    get currentUser () {
        return usersModule.getters.getCurrentUser()
    }

    get isAdmin () {
        return usersModule.getters.getIsAdmin()
    }

    get exams () {
        return examsModule.state.exams
    }

    get unsavedQuestionDraft () {
        return questionDraftsModule.state.unsavedQuestionDraft
    }

    get isWriterFinishedReviewing () {
        return this.job?.totalReviewQuestions && this.jobQuestionDrafts.filter(qd =>
            qd.jobStatus !== 'Writer').length >= this.job?.totalReviewQuestions
    }

    get didEditorApproveAll () {
        return this.jobQuestionDrafts.filter(qd => qd.jobStatus === 'Completed')
            .length >= (this.job?.totalNewQuestionTemplates || 0 + (this.job?.totalReviewQuestions || 0))
    }

    get isEditorFinished () {
        return this.jobQuestionDrafts.filter(qd => qd.jobStatus === 'Editor').length ? false : true
    }

    get numOfQuestionsLeftToWrite () {
        const numOfUnsubmittedQuestionsLeft = this.job?.totalNewQuestionTemplates
            && this.job?.totalNewQuestionTemplates - this.jobQuestionDrafts
                .filter(qd =>qd.jobStatus !== 'Writer').length
        return numOfUnsubmittedQuestionsLeft || 0
    }

    get numOfQuestionsLeftToReviewByWriter () {
        return this.jobQuestionDrafts.filter(qd => qd.jobStatus === 'Writer').length
    }

    get numOfApproved () {
        return this.jobQuestionDrafts.filter(qd => qd.jobStatus === 'Completed').length
    }

    async loadQuestionDraftForm () {
        this.editors = []
        const initialQueryParams = this.$route.query
        const unsavedQuestionDraft = this.unsavedQuestionDraft

        // if missing required parameter
        if (!initialQueryParams.job && !this.$route.params.questionDraftId && !initialQueryParams.question) {
            // if no job id or question id passed, there's an error => return to job list
            this.$router.push({ name: 'job-list' })
            throw new Error('No job ID, questionID, or questionDraft ID.')
        }

        // if editing a question, load question data
        if (
            this.$route.params.questionDraftId
            || typeof initialQueryParams.question === 'string'
            || unsavedQuestionDraft
        ) {
            this.isLoading = true

            // if questionDraftId passed, fetch question draft
            let questionDraft: IQuestionDraft
            if (typeof this.$route.params.questionDraftId === 'string') {
                // convert IParseQuestionDraft to IQuestionDraft
                const parseQuestionDraft = await questionDraftsModule.actions.fetchQuestionDraft(
                    this.$route.params.questionDraftId
                )
                if (!parseQuestionDraft) {
                    this.$router.push({
                        name: 'job-list',
                    })
                    return
                }
                questionDraft = {
                    ...parseQuestionDraft,
                    knowledgeAreaDraftId: parseQuestionDraft.knowledgeAreaDraft
                        && parseQuestionDraft.knowledgeAreaDraft.objectId,
                    jobId: parseQuestionDraft.job && parseQuestionDraft.job.objectId,
                    examDraftId: parseQuestionDraft.examDraft && parseQuestionDraft.examDraft.objectId,
                    lastUpdatedById: parseQuestionDraft.lastUpdatedBy && parseQuestionDraft.lastUpdatedBy.objectId,
                }

                const exam = parseQuestionDraft.examDraft &&
                    await this.fetchOrGetExamDraft(parseQuestionDraft.examDraft.objectId)
                this.examMetadataId = (exam && exam.examMetadataId) || null
                this.examDraftId = parseQuestionDraft.examDraft && parseQuestionDraft.examDraft.objectId || null
                this.knowledgeAreaDraftId = questionDraft.knowledgeAreaDraftId || null
                this.bloomTaxonomyLevel = questionDraft.bloomTaxonomyLevel || ''
                this.subtopicId = questionDraft.subtopicId || ''
            } else if (unsavedQuestionDraft && this.$route.name === 'question-draft-create') {
                questionDraft = unsavedQuestionDraft
                this.examDraftId = questionDraft.examDraftId || null
                this.knowledgeAreaDraftId = questionDraft.knowledgeAreaDraftId || this.nextIncompleteSubject || null
                this.bloomTaxonomyLevel = questionDraft.bloomTaxonomyLevel || ''
                this.subtopicId = questionDraft.subtopicId || ''
            } else {
                // otherwise, pull data from app server
                if (typeof initialQueryParams.question !== 'string') {
                    throw new Error('Invalid question ID.')
                }

                // if question draft already exists, redirect to edit page
                const fetchedQuestionDrafts = await questionDraftsModule.actions.fetchQuestionDrafts({
                    equalTo: {
                        examDataId: initialQueryParams.question,
                    },
                })
                if (fetchedQuestionDrafts.results.length > 0) {
                    const fetchedQuestionDraft = fetchedQuestionDrafts.results[0]
                    this.$router.push({
                        name: 'question-draft-edit',
                        params: { questionDraftId: fetchedQuestionDraft.objectId || '' },
                    })
                    return
                }

                // otherwise, fetch question from server
                const parseQuestion = await questionsModule.actions.fetchQuestion(initialQueryParams.question)

                // fetch live exam
                const exam = (await this.fetchOrGetExams())
                    .find(item => item.compositeKey === parseQuestion.compositeKey)

                if (!exam) {
                    throw new Error('Unable to find exam for question.')
                }

                // check whether an exam draft already exists for this exam
                const examDraft = await examDraftsModule.actions.fetchExamDraftByMetadataId(exam.objectId)
                this.examDraftId = examDraft && examDraft.objectId || null

                // if an exam draft does not exist, fetch the knowledge areas from app server
                if (!examDraft) {
                    this.knowledgeAreaDrafts = (await examsModule.actions.fetchSubjects({
                        examMetadataId: exam.objectId,
                    }))
                        .map(s => ({
                            name: s.name,
                            isArchived: s.isArchived,
                            examMetadataId: s.examMetadata.objectId,
                            subjectId: s.objectId,
                        }))
                    this.knowledgeAreaDraftId = (parseQuestion.subject as Study.Class.SubjectJSON).name
                } else if (this.examDraftId) {
                    this.knowledgeAreaDrafts = await kaDraftsModule.actions.fetchKADraftsByExamDraftId(this.examDraftId)

                    const knowledgeArea = this.knowledgeAreaDrafts
                        .find(item => item.name === (parseQuestion.subject as Study.Class.SubjectJSON).name)
                    this.knowledgeAreaDraftId = (
                        knowledgeArea &&
                        'objectId' in knowledgeArea &&
                        knowledgeArea.objectId
                    ) || null
                }

                // convert IParseQuestion to IQuestionDraft
                questionDraft = await questionDraftsModule.actions.convertPQToPQDraft({
                    question: parseQuestion,
                    examDraft,
                })

                // get mock exams
                this.mockExams = exam && await mockExamsModule.actions.fetchMockExams(exam.objectId) || []

                this.examMetadataId = (exam && exam.objectId) || null
            }

            // check whether question draft is part of a job and fetch job details if so
            this.jobId = questionDraft.jobId || null
            if (this.jobId) {
                await this.loadJobDetails(this.jobId)
            }

            // if draft status is locked, redirect
            if (this.draftStatus === 'locked') {
                alert('This question is locked and cannot be edited.')
                this.$router.go(-1)
                return
            }

            // calculate our total choice selections
            this.choiceStats = questionDraft.choiceStats || {}
            const choiceValues = Object.values(this.choiceStats) as number[]
            const totalChoices = choiceValues.reduce((sum, value) => value + sum, 0)
            this.totalChoices = totalChoices
        
            // fill in the rest of the draft properties
            this.examDataId = questionDraft.examDataId || null
            this.examDraftId = questionDraft.examDraftId || null
            this.jobStatus = questionDraft.jobStatus
            this.originalJobStatus = questionDraft.jobStatus
            this.draftStatus = questionDraft.draftStatus
            this.prompt = questionDraft.prompt || ''
            this.passage = questionDraft.passage || ''
            this.explanation = questionDraft.explanation
            || (this.questionDraftType !== 'Multiple Correct Response' ? 'Correct answer: ' : '')
            this.questionDraftType = questionDraft.type
            this.answers = this.questionDraftType === 'True/False'
                ? (questionDraft.answers && [ questionDraft.answers[0] ]) || []
                : (questionDraft.answers || [])
            this.distractors = questionDraft.distractors || []
            this.isArchived = questionDraft.isArchived
            if (questionDraft.images) {
                this.passageImageUrl = questionDraft.images.passage?.url || null
                this.passageImageAltText = questionDraft.images.passage?.altText || null
                this.passageImageLongAltText = questionDraft.images.passage?.longAltText || null
                this.explanationImageUrl = questionDraft.images.explanation?.url || null
                this.explanationImageAltText = questionDraft.images.explanation?.altText || null
                this.explanationImageLongAltText = questionDraft.images.explanation?.longAltText || null
            }
            this.references = questionDraft.references && questionDraft.references.length
                      && questionDraft.references
                          .map(reference => ({ text: reference, id: Math.round(Math.random() * 1e18) }))
                      || []
            this.isFlagged = questionDraft.isFlagged
            this.originalIsFlagged = questionDraft.isFlagged
            this.isSpecial = questionDraft.isSpecial
            this.serial = questionDraft.serial || null
            this.createdAt = questionDraft.createdAt || null
            this.updatedAt = questionDraft.updatedAt || null
            this.lastUpdatedBy = questionDraft.lastUpdatedById || null
            this.isSerialUpdated = true
            this.subCategory = questionDraft.subCategory || null
            this.dateAdded = questionDraft.dateAdded || null
            this.percentCorrect = questionDraft.percentCorrect || 0
            this.answeredCorrectlyCount = questionDraft.answeredCorrectlyCount || 0
            this.answeredIncorrectlyCount = questionDraft.answeredIncorrectlyCount || 0
            this.isMockQuestion = !!questionDraft.isMockQuestion
        }

        // load job details into form
        if (initialQueryParams.job) {
            this.jobId = this.queryParamToString(initialQueryParams.job)
            await this.loadJobDetails(this.jobId)
        }

        if (!this.jobQuestionDrafts.length) {
            await this.fetchJobQuestionDrafts()
            this.questionDraftsFetched = true
        }

        // check if user is allowed to work on job
        if (
            this.job &&
            this.currentUser?.objectId !== this.job.writerId &&
            this.currentUser?.objectId !== this.job.editorId &&
            this.currentUser?.role !== 'Admin'
        ) {
            this.$router.push({
                name: 'job-list',
            })
            return
        }


        // Load knowledge areas based on exam draft id
        if (this.examDraftId && !this.knowledgeAreaDrafts.length) {
            this.knowledgeAreaDrafts = await kaDraftsModule.actions.fetchKADraftsByExamDraftId(this.examDraftId)
        }

        const updatedQueryParams = this.$route.query

        // load knowledge area details into form
        if (updatedQueryParams.subtopic) {
            this.subtopicId = this.queryParamToString(updatedQueryParams.subtopic)
        }
        if (updatedQueryParams.ka) {
            if (!updatedQueryParams.subtopic){
                this.subtopicId = ''
            }
            this.knowledgeAreaDraftId = this.queryParamToString(updatedQueryParams.ka)
            this.submitAndContinue = true

            // check if already created enough questions in this KA
            if (this.currentUser?.role !== 'Editor' && this.$route.name === 'question-draft-create') {
                await this.checkKnowledgeAreaCompletionStatus()
            }
        } else if (!this.isFlagged && !this.examDataId && this.jobStatus === 'Writer') {
            // if editing a saved but not submitted question, enable submitAndContinue
            this.submitAndContinue = true

            // check if already created enough questions in this KA
            if (this.currentUser?.role !== 'Editor' && this.$route.name === 'question-draft-create') {
                await this.checkKnowledgeAreaCompletionStatus()
            }
        }

        // if we dont have examMetadataId, get it from the exam draft
        if (!this.examMetadataId && this.examDraftId) {
            const fetchedExamDraft = (await examDraftsModule.actions.fetchExamDraft(this.examDraftId))
            this.examMetadataId = fetchedExamDraft && fetchedExamDraft.examMetadataId || null
        }

        // load mock exams into form
        const examDraftId = this.examDraftId || this.job?.examDraftId
        this.mockExams = (examDraftId 
            ? await mockExamDraftsModule.actions.fetchMockExamDrafts({ examDraftId }) 
            : this.examMetadataId && await mockExamsModule.actions.fetchMockExams(this.examMetadataId)) || []
        const jobMockExamDraftId = this.job?.mockExamDraftId
        this.mockExamSettings = [
            {
                label: 'Standard',
                id: 'standard',
                value: !this.isMockQuestion && !this.job?.mockExamDraftId,
            },
            ...this.mockExams.map(me => (
                {
                    label: me.name, 
                    id: me.objectId, 
                    value: (!!this.isMockQuestion && !!me.questionSerials.find(s => s === this.serial))
                        || jobMockExamDraftId === me.objectId,
                }
            )),
        ]

        // Check whether current user is allowed to make changes to this question
        this.isReadOnly = true
        if (this.isAdmin) {
            this.isReadOnly = false
        } else if (
            (
                this.currentUser?.role === 'Writer'
                && this.job && this.jobStatus === 'Writer'
                && this.job.writerId && this.currentUser.objectId === this.job.writerId
            )
            || (
                this.currentUser?.role === 'Editor'
                && this.job && this.jobStatus === 'Editor'
                && this.job.editorId && this.currentUser.objectId === this.job.editorId
            )
        ) {
            this.isReadOnly = false
        }

        // listen for save command
        window.addEventListener('keydown', this.keyboardSaveListener, true)

        // if user is editor, default submitAndContinue true
        if (this.currentUser?.role === 'Editor') {
            this.submitAndContinue = true
        }

        if (this.currentUser?.role === 'Writer') {
            this.submitAndContinue = true
        }

        // show archived warning dialog and disable fields if archived and not an admin
        this.archivedStatus = ''
        if (this.isArchived) {
            this.archivedStatus = 'This question is archived.'
        }

        // If readonly status changed, refresh editors
        if (!this.isReadOnly) {
            this.lastRefreshTime = Date.now()
        }

        // if async fetch required, stop loading and refresh editors
        if (this.isLoading) {
            this.lastRefreshTime = Date.now()
            this.isLoading = false
        }
        if (updatedQueryParams.ka) {
            this.knowledgeAreaDraftId = updatedQueryParams.ka as string
        }
        if (
            this.$route.name === 'question-draft-create'
                && this.job 
                && !updatedQueryParams.ka
                && !unsavedQuestionDraft
        ) {
            this.knowledgeAreaDraftId = this.nextIncompleteSubject || this.formattedKnowledgeAreas[0].value

            if (this.subtopics && this.subtopics.length && !this.$route.query.subtopic) {
                this.subtopicId = this.nextIncompleteSubtopicInSubject(this.knowledgeAreaDraftId) || ''
            }
        }

        if (!this.explanation && this.questionDraftType !== 'Multiple Correct Response') {
            this.explanation = 'Correct answer: '
        }

        this.previousRouteName = String(this.$route.name)
        this.bloomAIAssistSuggestion = null
        this.isSerialUpdated = true
        this.currentQueue = this.jobStatus
        this.subjectAndSubtopicErrors = []
        this.errors = []
        this.answerAndDistractorErrors = []

        if (this.currentUser?.role !== 'Editor' && this.$route.name === 'question-draft-create') {
            await this.checkKnowledgeAreaCompletionStatus()
        }

        const originalDraftData = {
            subject: this.knowledgeAreaDraftId,
            blooms: this.bloomTaxonomyLevel,
            subtopic: this.subtopicId,
            type: this.questionDraftType,
            status: this.draftStatus,
            cost: this.isSpecial,
            prompt: this.prompt,
            answers: this.answers,
            distractors: this.distractors,
            image: this.passageImageUrl,
            explanation: this.explanation,
            explanationImage: this.explanationImageUrl,
            references: this.references,
        }

        this.originalDraftData = JSON.stringify(originalDraftData)
    }

    get jobs () {
        return jobsModule.state.jobs
    }

    async mounted () {
        await this.loadQuestionDraftForm()
    }

    toggleQuestionModal () {
        this.isQuestionModalOpen = !this.isQuestionModalOpen
    }

    storeEditor (editor: ReturnType<(typeof InlineEditor)['create']>) {
        this.editors.push(editor)
    }

    clearPromptImage () {
        this.passageImageFile = null
        this.passageImageUrl = null
        this.passageImageAltText = null
        this.passageImageLongAltText = null
        this.passageDisplaySettings.showImage = false
    }
    
    clearExplanationImage () {
        this.explanationImageUrl = null
        this.explanationImageFile = null
        this.explanationImageAltText = null
        this.explanationImageLongAltText = null
    }

    getUserName (userId: string) {
        const fullName = usersModule.getters.getDisplayName(userId)
        const firstName = fullName?.split(' ')[0]
        const shortLastName = fullName?.split(' ')[1].charAt(0)

        return `${firstName} ${shortLastName}`
    }

    async fetchJobQuestionDrafts () {
        try {
            this.jobQuestionDrafts = (await questionDraftsModule.actions.fetchQuestionDrafts({
                equalTo: {
                    job: objPointer((this.job as CMS.Cloud.JobWithMetrics).objectId)('Job'),
                },
                orderBy: 'updatedAt',
                order: 'descending',
            })).results
        } catch (err) {
            return err
        }
    }

    async fetchOrGetExams () {
        return this.exams.length
            ? this.exams
            : await examsModule.actions.fetchExams()
    }

    async fetchOrGetExamDraft (examDraftId: string) {
        return examDraftsModule.getters.getExamDraft(examDraftId)
            || examDraftsModule.actions.fetchExamDraft(examDraftId)
    }

    removeAnswer (editorIndex: number) {
        if (this.answers.length <= 1) {
            return
        }
        this.showAddAnswerTooltip = false
        this.lastRefreshTime = Date.now()
        return this.answers.splice(editorIndex, 1)
    }

    removeDistractor (editorIndex: number) {
        if (!(
            (this.questionDraftType === 'Multiple Choice'
                && this.distractors.length > this.minMCQDistractors)
            || (this.questionDraftType === 'Multiple Correct Response'
                && this.distractors.length > 1)
        )) {
            return
        }
        this.lastRefreshTime = Date.now()
        this.distractorHoverIndex = null
        return this.distractors.splice(editorIndex, 1)
    }

    // Only for MCR question types
    addAnswer () {
        this.showAddAnswerTooltip = false
        this.answerHoverIndex = null
        return this.answers.push('')
    }

    addDistractor () {
        this.showAddDistractorTooltip = false
        return this.distractors.push('')
    }

    stripHtmlTags (html: string) {
        const div = document.createElement('div')
        div.innerHTML = html
        return div.textContent || ''
    }

    getAnswerStatByIndex (index: number) {
        return Math.round((this.choiceStats[`a${index + 1}` as TChoiceKey] || 0) * 100 / this.totalChoices)
    }

    getDistractorStatByIndex (index: number) {
        return Math.round((this.choiceStats[`d${index + 1}` as TChoiceKey] || 0) * 100 / this.totalChoices)
    }

    queryParamToString (param: string | (string | null)[]): string {
        if (typeof param === 'object') {
            return param[0] || ''
        }

        return param
    }

    removeReference (index: number) {
        this.references.splice(index, 1)
        this.referenceHoverIndex = null
    }

    addReference (val: string) {
        this.selectedReference = val
        this.references.push({ text: val, id: Math.round(Math.random() * 1e18) })

        this.$nextTick(() => {
            this.selectedReference = ''
        })
    }

    trueFalseChanged (val: string) {
        val === '<p>True</p>'
            ? this.distractors = [ '<p>False</p>' ]
            : this.distractors = [ '<p>True</p>' ]
    }

    changeQuestionType () {
        if (this.questionDraftType === 'Multiple Correct Response') {
            this.maxMCRChoices = 10
            this.answers = new Array(1)
            this.distractors = new Array(1)
            this.appendMCRLanguage()
        } else if (this.questionDraftType === 'True/False') {
            this.answers = [ '<p>True</p>' ]
            this.distractors = [ '<p>False</p>' ]
            this.removeMCRLanguage()
        } else if (this.questionDraftType === 'Multiple Choice') {
            this.answers = new Array(1)
            this.distractors = new Array(this.defaultMCQDistractors)
            this.removeMCRLanguage()
        }
    }

    appendMCRLanguage () {
        if (!this.prompt.includes('Select all that apply')) {
            if (this.prompt === '') {
                this.prompt += '<p></p>'
            }
            this.prompt += '<p>Select all that apply.</p>'
            this.lastRefreshTime = Date.now()
        }
    }

    removeMCRLanguage () {
        if (this.prompt.includes('<p>Select all that apply.</p>')) {
            this.prompt = this.prompt.replace('<p>Select all that apply.</p>', '')
            this.lastRefreshTime = Date.now()
        }
    }

    /**
     * Form submit variations
     */
    toggleArchiveQuestion () {
        this.isArchived = !this.isArchived
        this.lastRefreshTime = Date.now()
        this.latestSubmitAttemptParams = { redirect: false }
        this.submitQuestionForm({ redirect: false })
    }
    flagQuestion () {
        this.jobStatus = 'Writer'
        this.isFlagged = true
        this.retainToast = true
        const toast = 'Question flagged back to writer'
        const toastSubtext = this.currentUser?.role === 'Editor'
            ? 'You’re on the next question to review.'
            : 'You’re on the next approved question.'
        this.originalIsReadOnly = this.isReadOnly
        this.latestSubmitAttemptParams = { toast, toastSubtext }
        this.submitQuestionForm({ redirect: true, toast, toastSubtext })
    }
    unflagQuestion () {
        this.isFlagged = false
        this.retainToast = true
        const toast = 'Question Unflagged'
        const toastSubtext = this.currentUser?.role === 'Editor' && this.jobStatus === 'Writer'
            ? 'It has been unflagged and is now ready for another Editor review and approval.'
            : ''
        this.currentUser?.role === 'Admin' ?  this.jobStatus = 'Writer' : this.jobStatus = 'Editor'
        this.originalIsReadOnly = this.isReadOnly
        this.latestSubmitAttemptParams = { unflag: true, toast, toastSubtext }
        this.submitQuestionForm({ unflag: true, toast, toastSubtext })
    }
    async recallQuestion () {
        if (this.jobStatus === 'Completed' && this.currentUser?.role === 'Editor') {
            this.jobStatus = 'Editor'
            this.originalJobStatus = 'Editor'
        } else if (this.jobStatus === 'Completed' && this.currentUser?.role === 'Writer') {
            this.jobStatus = 'Writer'
            this.originalJobStatus = 'Writer'
        } else if (this.jobStatus === 'Editor') {
            this.jobStatus = 'Writer'
            this.originalJobStatus = 'Writer'
            this.isFlagged = false
        }
        this.originalIsReadOnly = this.isReadOnly
        this.isReadOnly = false
        this.lastRefreshTime = Date.now()
        this.retainToast = true
        const toast = 'Question recalled'
        const toastSubtext = this.currentUser?.role === 'Writer'
            ? 'It has been pulled back from the Editor to the Writer and will need to be resubmitted.'
            : 'It has been pulled back from Approved questions and will need to be approved again.'
        this.latestSubmitAttemptParams = { clearUnsavedDraft: false, toast, toastSubtext }
        await this.submitQuestionForm({ redirect: true, clearUnsavedDraft: false, toast, toastSubtext, recall: true })
    }
    async approveQuestion () {
        // An admin can approve a flagged question
        // if it is flagged unflag it then mark it as approved
        this.isFlagged = false
        this.jobStatus = 'Completed'
        this.retainToast = true
        const toast = 'Question approved'
        const toastSubtext = this.currentUser?.role === 'Editor' ? 'You’re on the next question to review.' : ''
        this.originalIsReadOnly = this.isReadOnly
        this.latestSubmitAttemptParams = { toast, toastSubtext }
        await this.submitQuestionForm({ toast, toastSubtext })
    }
    async saveQuestion () {
        const redirect = this.$route.name === 'question-draft-create' && this.currentUser?.role !== 'Admin'
        const clearUnsavedDraft = this.$route.name === 'question-draft-create'
        // if user not admin, save the question to their jobStatus
        if (this.currentUser && this.currentUser.role !== 'Admin') {
            this.jobStatus = this.currentUser.role
        }
        this.retainToast = true
        const toast = 'Question Saved'
        const toastSubtext = this.currentUser?.role === 'Writer'
            ? 'You`re on the next question to write/review.'
            : ''
        this.originalIsReadOnly = this.isReadOnly
        this.latestSubmitAttemptParams = { redirect, clearUnsavedDraft, toast, toastSubtext }
        await this.submitQuestionForm({ redirect, clearUnsavedDraft, toast, toastSubtext })

    }
    async submitToEditor () {
        const clearUnsavedDraft = this.$route.name === 'question-draft-create'
        this.jobStatus = 'Editor'
        this.retainToast = true
        const toast = this.currentUser?.role === 'Writer' ? 'Question submitted to Editor' : 'Question sent to Editor'
        const toastSubtext = this.currentUser?.role === 'Writer'
            ? 'You’re on the next question to write.'
            : 'You’re on the next approved question.'
        this.originalIsReadOnly = this.isReadOnly
        this.latestSubmitAttemptParams = {
            checkPlagiarism: this.job?.runPlagiarismCheck, clearUnsavedDraft, toast, toastSubtext,
        }
        await this.submitQuestionForm({
            checkPlagiarism: this.job?.runPlagiarismCheck, clearUnsavedDraft, toast, toastSubtext,
        })
    }

    selectQuestion (id: string) {
        this.$router.push({
            name: 'question-draft-edit',
            params: { questionDraftId: String(id) },
        })
    }

    updateNextQuestion (id: string | boolean) {
        if (typeof id === 'string') {
            this.nextQuestionId = id
        } else {
            this.nextQuestionId = null
        }
    }

    kaNameForUnsavedDraft () {
        if (this.unsavedQuestionDraft && this.$route.name !== 'question-draft-create') {
            return this.formattedKnowledgeAreas
                .find(fka => fka.value === this.unsavedQuestionDraft?.knowledgeAreaDraftId)?.label
        } else if (!this.unsavedQuestionDraft && this.$route.name !== 'question-draft-create') {
            return undefined
        } else {
            return this.formattedKnowledgeAreas.find(fka => fka.value === this.knowledgeAreaDraftId)?.label
        }
    }

    unsavedDraftPrompt () {
        if (this.unsavedQuestionDraft && this.$route.name !== 'question-draft-create') {
            return this.unsavedQuestionDraft.prompt
        } else if (!this.unsavedQuestionDraft && this.$route.name !== 'question-draft-create') {
            return undefined
        } else {
            return this.prompt
        }
    }

    async deleteQuestion () {
        const confirmDialog = confirm(
            'Are you sure you want to delete this question? This action cannot be undone.'
        )
        if (confirmDialog && typeof this.$route.params.questionDraftId === 'string') {
            this.isLoading = true

            // set mock exam settings to standard to remove from all mock exams
            this.mockExamSettings.forEach((mes, i) => {
                this.mockExamSettings[i].value = mes.id === 'standard'
            })

            // remove question from any mock exam drafts
            if (this.serial) {
                await this.updateMockExamDrafts(this.serial)
            }

            // delete question record
            await questionDraftsModule.actions.deleteQuestionDraft(this.$route.params.questionDraftId)

            if (this.jobId) {
                await jobsModule.actions.fetchJob(this.jobId)
                await this.fetchJobQuestionDrafts()

                this.retainToast = true
                this.toast = 'Question Deleted'
                this.completeRedirect(true)
            } else {
                this.$router.push({
                    name: 'question-list',
                })
            }

            this.isLoading = false
        }
    }

    isFieldInErrorState (fieldName: string) {
        return (this.errors.find(errorMessage => errorMessage.includes(fieldName))
        || this.subjectAndSubtopicErrors.find(errorMessage => errorMessage.includes(fieldName.toLowerCase())))
            ? true : false
    }

    // validate question fields on submit
    validateQuestionFormFields (
        params: { knowledgeAreaDraftId: string | null }
    ): params is { examDraftId: string; knowledgeAreaDraftId: string } {
        // if submitting to editor or completing, do check
        if (!this.prompt) {
            this.errors.push('Question prompt is required to save/submit question.')
        }
        if (!this.explanation) {
            this.errors.push('Explanation is required to save/submit question.')
        }
        if (!this.filteredAnswers.length) {
            this.errors.push('At least one answer is required to save/submit question.')
        }
        if (!this.filteredDistractors.length) {
            this.errors.push('At least one distractor is required to save/submit question.')
        }
        if (!params.knowledgeAreaDraftId) {
            this.errors.push('Subject is required to save/submit question.')
        }
        if (this.subtopics.length && !this.subtopicId) {
            this.errors.push('Subtopic is required to save/submit question.')
        }
        if (this.duplicateAnswersAndDistractors.length) {
            this.answerAndDistractorErrors.push('All answers and distractors must be unique.')
        }

        // return false if validation errors
        if (this.errors.length || this.answerAndDistractorErrors.length) {
            this.isLoading = false
            return false
        }

        return true
    }

    async checkQuestionDraftExists () {
        const questionId = this.$route.query.question
        if (questionId && typeof questionId === 'string') {
            return (await questionDraftsModule.actions.fetchQuestionDrafts({
                equalTo: {
                    examDataId: questionId,
                },
            })).results[0]
        }
    }

    async updateMockExamDrafts (serial: string) {
        // update mock exams if question not part of a job OR if question is part of mock exam job
        const questionMockExamIds = this.mockExamSettings.filter(s => s.value).map(s => s.id)
        if (!this.jobId || this.job?.mockExamDraftId) {
            await Promise.all(this.mockExams.map(mockExam => {
                // check if serial (or objectId which will eventually be serial) is in mock exam
                const mockExamContainsQuestion = mockExam.questionSerials
                    .includes(serial)
                let hasMockExamChanged = false

                if (mockExamContainsQuestion && !questionMockExamIds.includes(mockExam.objectId)) {
                    mockExam.questionSerials = mockExam.questionSerials
                        .filter(s => s !== (serial))
                    hasMockExamChanged = true
                } else if (!mockExamContainsQuestion && questionMockExamIds.includes(mockExam.objectId)) {
                    mockExam.questionSerials.push(serial)
                    hasMockExamChanged = true
                }

                return hasMockExamChanged
                    ? mockExamDraftsModule.actions.updateMockExamDraft(mockExam)
                    : undefined
            }))
        }
    }

    resetComponentState () {
        this.originalIsReadOnly = true
        this.isReadOnly = true
        this.archivedStatus = ''
        this.isQuestionModalOpen = false
        this.flag = faFlag
        this.lastSave = null

        this.bloomTaxonomyLevel = ''
        this.questionDraftType = 'Multiple Choice'
        this.prompt = ''
        this.jobStatus = 'Writer'
        this.originalJobStatus = 'Writer'
        this.draftStatus = 'active'
        this.passage = ''
        this.passageImageUrl = null
        this.passageImageFile = null
        this.passageImageAltText = null
        this.passageImageLongAltText = null
        this.explanation = 'Correct answer: '
        this.explanationImageUrl = null
        this.explanationImageFile = null
        this.explanationImageAltText = null
        this.explanationImageLongAltText = null
        this.answers = new Array(1)
        this.distractors = new Array(1)
        this.references = []
        this.selectedReference = ''
        this.editors = []
        this.lastRefreshTime = Date.now()
        this.isFlagged = false
        this.originalIsFlagged = false
        this.isArchived = false
        this.isSpecial = false
        this.serial = null
        this.dateAdded = null
        this.subCategory = null
        this.knowledgeAreaDrafts = []
        this.answeredCorrectlyCount = 0
        this.answeredIncorrectlyCount = 0
        this.choiceStats = {}
        this.totalChoices = 0
        this.percentCorrect = 0
        this.isMockQuestion = false
        this.mockExamSettings = []
        this.mockExams = []
        this.bloomTaxonomyLevel = ''
        this.bloomAIAssistLoading = false
        this.bloomAIAssistSuggestion = null
    }

    async submitQuestionForm ({ 
        redirect = true,
        unflag = false,
        checkPlagiarism = false,
        createUnsavedDraft = false,
        clearUnsavedDraft = true,
        toast = '',
        toastSubtext = '',
        recall = false,
    }) {
        this.isLoading = true
        this.errors = []
        this.subjectAndSubtopicErrors = []
        this.answerAndDistractorErrors = []
        this.archivedStatus = ''

        // validate form fields with a custom type guard
        const validatedParams = { knowledgeAreaDraftId: this.knowledgeAreaDraftId }
        if (!createUnsavedDraft && !recall && !this.validateQuestionFormFields(validatedParams)) {
            this.isFlagged = this.originalIsFlagged
            this.jobStatus = this.originalJobStatus
            this.isLoading = false
            this.isReadOnly = this.originalIsReadOnly
            return false
        }

        // if question already has a question draft, cancel and redirect
        if (this.$route.query.question) {
            const questionDraft = await this.checkQuestionDraftExists()
            if (questionDraft) {
                this.completeRedirect()
                alert('Error: A draft already exists for this question. Redirecting you to question draft.')
                return false
            }
        }

        // format references for IQuestionDraft
        const referencesFiltered = this.references.filter(reference => reference.text !== ''),
            referencesArray = referencesFiltered.map(reference => reference.text)

        // get comments from editors
        const commentThreads = this.editors.map(editor => {
            const commentsRepo = editor.plugins.get('CommentsRepository')

            return commentsRepo.getCommentThreads({
                skipNotAttached: true,
                skipEmpty: true,
                toJSON: true,
            })
        }).flat()

        const suggestions = this.editors.map(editor => {
            const trackChangesRepo = editor.plugins.get('TrackChanges')
            return trackChangesRepo.getSuggestions()
        }).flat()

        // fetch exam draft
        const examDraft = (this.examDraftId && await this.fetchOrGetExamDraft(this.examDraftId))
            || await examDraftsModule.actions.cloneExamDraftFromExamId(this.examMetadataId || '')
        // if had to clone mock exam drafts, fetch the new records
        if (!this.examDraftId) {
            this.mockExams = await mockExamDraftsModule.actions.fetchMockExamDrafts({ examDraftId: examDraft.objectId })
            this.mockExamSettings = [
                {
                    label: 'Standard',
                    id: 'standard',
                    value: !this.isMockQuestion && !this.job?.mockExamDraftId,
                },
                ...this.mockExams.map(me => (
                    {
                        label: me.name, 
                        id: me.objectId, 
                        value: (!!this.isMockQuestion && !!me.questionSerials.find(s => s === this.serial))
                            || this.job?.mockExamDraftId === me.objectId,
                    }
                )),
            ]
        }
        this.examDraftId = examDraft.objectId

        if (!examDraft || !this.examDraftId) {
            this.modalError = {
                modalTitle: 'Missing Exam',
                modalSubtext: 'Please use the “Report CMS Bug” on the right of your screen to alert our dev team.',
            }
            return false
        }

        // convert form data to IQuestionDraft
        let questionParams: IQuestionDraft = {
            examDataId: this.examDataId || undefined,
            prompt: this.prompt,
            answers: this.answers,
            distractors: this.distractors,
            knowledgeAreaDraftId: validatedParams.knowledgeAreaDraftId || this.knowledgeAreaDraftId || '',
            references: referencesArray,
            explanation: this.explanation || undefined,
            passage: this.passage || undefined,
            type: this.questionDraftType,
            jobId: this.jobId || undefined,
            examDraftId: this.examDraftId,
            appName: examDraft.nativeAppName,
            isFlagged: this.isFlagged,
            jobStatus: this.jobId ? this.jobStatus : 'Completed',
            isArchived: this.isArchived,
            draftStatus: this.draftStatus,
            isSpecial: this.isSpecial,
            isClassic: false,
            hasSuggestions: !!(suggestions.length),
            hasComments: !!(commentThreads.length),
            serial: this.serial || undefined,
            dateAdded: this.dateAdded || undefined,
            subCategory: this.subCategory || undefined,
            images: {
                explanation: this.explanationImageUrl
                    ? {
                        url: this.explanationImageUrl,
                        altText: this.explanationImageAltText || '',
                        longAltText: this.explanationImageLongAltText || '',
                    }
                    : undefined,
                passage: this.passageImageUrl 
                    ? {
                        url: this.passageImageUrl,
                        altText: this.passageImageAltText || '',
                        longAltText: this.passageImageLongAltText || '',
                    }
                    : undefined,
            },
            answeredCorrectlyCount: this.answeredCorrectlyCount,
            answeredIncorrectlyCount: this.answeredIncorrectlyCount,
            choiceStats: this.choiceStats,
            percentCorrect: this.percentCorrect,
            isMockQuestion: !!this.mockExamSettings.some(s => s.value && s.id !== 'standard'),
            bloomTaxonomyLevel: this.bloomTaxonomyLevel || undefined,
            subtopicId: this.subtopicId || undefined,
            lastUpdatedById: this.currentUser?.objectId,
        }
        let questionDraft: CMS.Class.QuestionDraftJSON | undefined

        // create draft in local storage and exit submit function
        if (createUnsavedDraft && this.currentUser?.role === 'Writer') {
            const unsavedQuestionDraft = questionParams
            unsavedQuestionDraft.serial = undefined
            questionDraftsModule.state.unsavedQuestionDraft = unsavedQuestionDraft
            return
        }

        // clear local draft (default)
        if (clearUnsavedDraft) {
            questionDraftsModule.state.unsavedQuestionDraft = null
        }

        // if no job, set draft status inactive
        if (!this.jobId) {
            questionParams.draftStatus = 'inactive'
        }

        // if creating a new question, save it
        if (!this.$route.params.questionDraftId) {
            // if creating a question without a job, fetch required exam data
            if (this.$route.query.question) {
                questionParams = {
                    ...questionParams,
                    ...(await this.fetchQuestionExamData(examDraft.objectId)),
                }
            }

            // save new question draft
            questionDraft = await questionDraftsModule.actions.createQuestionDraft(questionParams)
        } else if (typeof this.$route.params.questionDraftId === 'string') {
            // if updating an existing question, save it
            questionDraft = await questionDraftsModule.actions.updateQuestionDraft({
                questionId: this.$route.params.questionDraftId,
                params: questionParams,
            })
        }

        // if question draft unable to create, fail
        if (!questionDraft || !questionDraft.objectId) {
            this.isLoading = false
            this.modalError = {
                firstLine: 'Unable to Create',
                modalTitle: 'Question Draft',
                modalSubtext: 'Please check your connection and try again. If you’re still getting this error, \
                use the “Report CMS Bug” on the right of your screen to alert our dev team.',
            }
            return false
        }

        // if we want to check it for plagiarism, do so
        if (questionDraft && checkPlagiarism) {
            questionDraftsModule.actions.checkQuestionPlagiarism(questionDraft.objectId)
        }

        // check if question serial is part of mock exams and update them if so
        if (questionDraft.serial) {
            await this.updateMockExamDrafts(questionDraft.serial)
        }

        // upload images
        if (this.explanationImageFile || this.passageImageFile) {
            const imagesObj = await this.uploadImages(questionDraft.serial || questionDraft.objectId)

            if (imagesObj) {
                questionParams.images = {
                    passage: imagesObj.passage
                        ? {
                            url: imagesObj.passage,
                            altText: this.passageImageAltText || '',
                            longAltText: this.passageImageLongAltText || '',
                        }
                        : undefined,
                    explanation: imagesObj.explanation
                        ? {
                            url: imagesObj.explanation,
                            altText: this.explanationImageAltText || '',
                            longAltText: this.explanationImageLongAltText || '',
                        }
                        : undefined,
                }

                await questionDraftsModule.actions.upsertQuestionDrafts([{
                    objectId: questionDraft.objectId,
                    images: questionParams.images,
                }])
            }
        }

        // update comments & suggestions
        await this.updateSuggestions(questionDraft.objectId, examDraft.objectId, commentThreads, suggestions)

        // log activity
        await activitiesModule.actions.createActivity({
            action: this.$route.params.questionDraftId
                ? this.isFlagged ? 'flag' : 'update'
                : 'create',
            subject: {
                type: 'Pointer',
                value: questionDraft.objectId,
                name: this.stripHtmlTags(this.prompt),
            },
            type: 'questionDraft',
            job: questionParams.jobId
                ? { __type: 'Pointer', className: 'Job', objectId: questionParams.jobId }
                : undefined,
        })

        // re-fetch job with new metrics IF question is part of a job
        if (questionParams.jobId) {
            await jobsModule.actions.fetchJob(questionParams.jobId)

            const updatedJob = jobsModule.getters.getJob(questionParams.jobId)

            // if flagging a question, check if need to alert admin
            if (updatedJob && questionParams.isFlagged && this.currentUser?.role === 'Editor') {
                jobsModule.actions.sendAdminFlagAlertEmail({
                    jobName: updatedJob.name,
                    jobId: updatedJob.objectId,
                    userId: this.currentUser.objectId,
                })
            }

            // check if job now complete and send admin and writer an email
            if (updatedJob &&
                ((updatedJob.totalCompletedQuestions || 0) /
                ((updatedJob.totalReviewQuestions || 0) + (updatedJob.totalNewQuestionTemplates || 0)))
                === 1
                && this.currentUser?.role !== 'Admin'
            ) {
                jobsModule.actions.sendAdminJobCompleteEmail(updatedJob.name)
                jobsModule.actions.sendWriterJobCompleteEmail(updatedJob.objectId)
            }
        }

        // fetch question drafts to pull in the new question
        await this.fetchJobQuestionDrafts()

        // redirect to next page
        if (redirect) {
            this.completeRedirect()
        } else {
            await this.loadQuestionDraftForm()
            this.isLoading = false
            this.lastSave = new Date()

            if (unflag && this.jobId) {
                this.$router.push({
                    name: 'job-view',
                    params: { jobId: this.jobId },
                })
                return
            }

            if (!this.$route.params.questionDraftId) {
                this.$router.push({
                    name: 'question-draft-edit',
                    params: { questionDraftId: questionDraft.objectId },
                    query: { saved: '' },
                })
            }

            setTimeout(() => {
                this.showSavedNotificationMessage = []
            }, 2000)
        }

        this.toast = toast
        this.toastSubtext = toastSubtext
    }

    async updateSuggestions (
        questionDraftId: string,
        examDraftId: string,
        commentThreads: ICKEditorThread[],
        suggestions: ICKEditorSuggestionPayload[]
    ) {
        // delete suggestions and threads
        await Promise.all([
            commentsModule.actions.deleteAllSuggestions(questionDraftId),
            commentsModule.actions.deleteAllThreads(questionDraftId),
        ])

        // save new suggestions and threads
        await Promise.all([
            commentsModule.actions.saveThreads({
                threads: commentThreads,
                questionDraftId: questionDraftId,
                examDraftId: examDraftId || '',
            }),
            commentsModule.actions.saveSuggestions({
                suggestions,
                questionDraftId: questionDraftId,
                examDraftId: examDraftId,
            }),
        ])
    }

    async fetchQuestionExamData (examDraftId: string) {
        // set the exam data ID
        const examDataId = this.$route.query.question as string

        // clone knowledge areas
        const knowledgeAreaDraftPromises =
            this.knowledgeAreaDrafts.every(item => !('objectId' in item))
                ? this.knowledgeAreaDrafts.map(
                    async item => await kaDraftsModule.actions.createKADraft({
                        name: item.name,
                        isArchived: item.isArchived,
                        examDraftId,
                    })
                )
                : this.knowledgeAreaDrafts

        const knowledgeAreaDrafts = await Promise.all(knowledgeAreaDraftPromises),
            knowledgeArea = (knowledgeAreaDrafts as CMS.Class.KnowledgeAreaDraftJSON[]).find(
                item =>
                    item.name === this.knowledgeAreaDraftId ||
                    item.objectId === this.knowledgeAreaDraftId
            )

        const knowledgeAreaDraftId = knowledgeArea && knowledgeArea.objectId

        return {
            examDataId,
            examDraftId,
            knowledgeAreaDraftId,
        }
    }

    async completeRedirect (deleting?:  boolean) {
        // if user is editor, take them to next question
        if (this.currentUser?.role === 'Editor') {
            const currentQuestionId = this.$route.params.questionDraftId
            const nextQuestion = this.nextQuestionId

            if (nextQuestion) {
                await this.$router.push({
                    name: 'question-draft-edit',
                    params: { questionDraftId: nextQuestion },
                    query: { ...this.$route.query, success: 'updated' },
                })
            } else {
                // if no next question - stay on the same one
                await this.$router.push({
                    name: 'question-draft-edit',
                    params: { questionDraftId: currentQuestionId },
                })
            }
            await this.loadQuestionDraftForm()
            if (this.didEditorApproveAll) {
                this.modalError = {
                    firstLine: 'All questions have been',
                    modalTitle: 'approved for this job!',
                    modalSubtext: 'Congrats! Thank you for your work. \
                    Be on the lookout for any questions sent back to the writer that will need your review.',
                }
            } else if (this.isEditorFinished) {
                if (this.job?.jobFunction === 'Create Questions') {
                    this.modalError = {
                        firstLine: 'You’ve reviewed all questions',
                        modalTitle: 'in the Editor queue',
                        modalSubtext: `The writer has ${this.numOfQuestionsLeftToWrite}
                        more question${this.numOfQuestionsLeftToWrite > 1 ? 's' : ''}
                        to write and submit to you.`,
                    }
                } else {
                    this.modalError = {
                        firstLine: 'You’ve reviewed all questions',
                        modalTitle: 'in the Editor queue',
                        modalSubtext: `The writer has ${this.numOfQuestionsLeftToReviewByWriter}
                        more question${this.numOfQuestionsLeftToReviewByWriter > 1 ? 's' : ''}
                        to review and submit to you.`,
                    }
                }
            }
        } else if (this.currentUser?.role === 'Writer') {
            // if not creating questions redirect to next in filter
            if (this.$route.name !== 'question-draft-create' && !this.isWriterFinishedReviewing) {
                const nextQuestion = this.nextQuestionId
                if (nextQuestion) {
                    this.resetComponentState()
                    await this.$router.push({
                        name: 'question-draft-edit',
                        params: { questionDraftId: nextQuestion },
                    })
                } else if (this.job?.jobFunction === 'Create Questions' && this.job?.totalNewQuestionTemplates
                && this.jobQuestionDrafts.length < this.job?.totalNewQuestionTemplates) {
                    // if filter is empty and still able to create questions - redirect to new draft
                    const jobId = this.jobId
                    this.resetComponentState()
                    await this.$router.push({
                        name: 'question-draft-create',
                        query: {
                            job: jobId,
                            startTime: String(Date.now()),
                        },
                    })
                }
            } else if (this.job?.jobFunction === 'Create Questions' && this.job?.totalNewQuestionTemplates
                && this.jobQuestionDrafts.length >= this.job?.totalNewQuestionTemplates) {
                await this.$router.push({
                    name: 'question-draft-edit',
                    params: { questionDraftId: this.jobQuestionDrafts[0].objectId },
                })
                await this.loadQuestionDraftForm()
                this.modalError = {
                    firstLine: `${this.jobQuestionDrafts.length} of ${this.job?.totalNewQuestionTemplates} Questions`,
                    modalTitle: 'have been written for this job!',
                    modalSubtext: 'Congrats! Thank you for your work. \
                    Be on the lookout for questions sent back to you to review.',
                }
                const numOfWrittenQuestionsToReview = this.jobQuestionDrafts
                    .filter(qd => qd.jobStatus === 'Writer').length
                if (numOfWrittenQuestionsToReview > 0) {
                    this.modalError.modalSubtext = `${numOfWrittenQuestionsToReview}
                    question${numOfWrittenQuestionsToReview > 1 ? 's' : ''}
                    need${numOfWrittenQuestionsToReview === 1 ? 's' : ''}
                    to be reviewed by you and submitted to the Editor.`
                }
                return
            } else if (this.job?.jobFunction !== 'Create Questions' && this.isWriterFinishedReviewing) {
                await this.$router.push({
                    name: 'question-draft-edit',
                    params: { questionDraftId: this.jobQuestionDrafts[0].objectId },
                })
                await this.loadQuestionDraftForm()
                this.modalError = {
                    firstLine: `${this.jobQuestionDrafts.length} of ${this.job?.totalReviewQuestions} Questions`,
                    modalTitle: 'have been reviewed for this job!',
                    modalSubtext: 'Congrats! Thank you for your work. \
                    Be on the lookout for questions sent back to you to review.',
                }
            } else {
                // if submit & continue and user is writer, take them to create another of same KA)
                const isSubjectCompleted = await this.checkKnowledgeAreaCompletionStatus()
                const nextSubjectId = isSubjectCompleted ? this.nextIncompleteSubject : this.knowledgeAreaDraftId
                const currentRoute = this.currentRouteName
                await this.$router.push({
                    name: 'question-draft-create',
                    query: {
                        job: this.jobId,
                        ka: nextSubjectId,
                        startTime: String(Date.now()),  // prevents duplicate router navigation
                    },
                })
                if (currentRoute === 'question-draft-create') {
                    this.resetComponentState()
                    await this.loadQuestionDraftForm()
                }
            }
        } else if (this.currentUser?.role === 'Admin') {
            const currentQuestionId = this.$route.params.questionDraftId
            const nextQuestion = this.nextQuestionId
            if (nextQuestion) {
                this.$router.push({
                    name: 'question-draft-edit',
                    params: { questionDraftId: nextQuestion },
                })
            } else {
                const nextAdminQuestion = await this.fetchNextAdminQuestion()
                if (nextAdminQuestion) {
                    this.$router.push({
                        name: 'question-draft-edit',
                        params: { questionDraftId: nextAdminQuestion.objectId },
                    })
                } else if (this.$route.name === 'question-draft-create') {
                    const isSubjectCompleted = await this.checkKnowledgeAreaCompletionStatus()
                    const nextSubjectId = isSubjectCompleted ? this.nextIncompleteSubject : this.knowledgeAreaDraftId
                    await this.$router.push({
                        name: 'question-draft-create',
                        query: {
                            job: this.jobId,
                            ka: nextSubjectId,
                            startTime: String(Date.now()),  // prevents duplicate router navigation
                        },
                    })
                    this.resetComponentState()
                    await this.loadQuestionDraftForm()
                } else {
                    if (deleting) {
                        // when deleting a question and there is no next question in the filter to go to,
                        // we will go back to job view for now since there is nothing else to display
                        this.$router.push({
                            name: 'job-view',
                            params: { jobId: this.jobId },
                        })
                    } else if (currentQuestionId) {
                        this.$router.push({
                            name: 'question-draft-edit',
                            params: { questionDraftId: currentQuestionId },
                        })
                    } else {
                        this.$router.push({
                            name: 'job-view',
                            params: { jobId: this.jobId },
                        })
                    }
                }
                if (!deleting) {
                    await this.loadQuestionDraftForm()
                }
                if (!this.numOfApproved) {
                    this.modalError = {
                        firstLine: '0 Approved questions',
                        modalTitle: 'for this job',
                        modalSubtext: 'Go back to Job View or change side panel filter to see Writer or Editor queues.',
                    }
                }
            }
        } else if (!this.jobId && !this.$route.params.questionDraftId) {
            // if question without job or draft ID, refresh page to redirect to questionDraft
            this.$router.go(0) // refreshes page
        } else if (!this.jobId && this.$route.params.questionDraftId) {
            // if question without job, don't go anywhere
            this.$router.push({
                name: 'question-draft-list',
            })
        } else if (this.jobId) {
            this.$router.push({
                name: 'job-view',
                params: { jobId: this.jobId },
            })
        } else {
            this.$router.push({
                name: 'job-list',
            })
        }

        this.isLoading = false
    }

    async uploadImages (serial: string) {
        // upload images if new images set
        let directory
        if (this.examDraftId) {
            const fetchedExamDraft = await this.fetchOrGetExamDraft(this.examDraftId)
            directory = fetchedExamDraft && fetchedExamDraft.compositeKey.toUpperCase()
        } else if (this.examMetadataId) {
            const exam = (await this.fetchOrGetExams()).find(item => item.objectId === this.examMetadataId)
            directory = exam && exam.compositeKey.toUpperCase()
        }
        directory = directory + '/images/' + serial

        // create array of image upload promises
        const imageUploadPromises = []
        imageUploadPromises.push(
            this.passageImageFile
                ? filesModule.actions.uploadImage({ file: this.passageImageFile, directory })
                : this.passageImageUrl || undefined
        )
        imageUploadPromises.push(
            this.explanationImageFile
                ? filesModule.actions.uploadImage({ file: this.explanationImageFile, directory })
                : this.explanationImageUrl || undefined
        )

        // await image uploads
        const imageUploads = await Promise.all(imageUploadPromises as Promise<string|false>[])

        // if image upload fails, return
        if (imageUploads.indexOf(false) !== -1) {
            return false
        }

        // add image URLs to question data
        return {
            passage: imageUploads[0] || undefined,
            explanation: imageUploads[1] || undefined,
        }
    }

    // fetches the next question in the current editor's queue
    async fetchNextEditorQuestion () {
        const questionDrafts = (await questionDraftsModule.actions.fetchQuestionDrafts({
            equalTo: {
                job: objPointer(this.job && this.job.objectId || '')('Job'),
                jobStatus: 'Editor',
            },
            orderBy: 'updatedAt',
            order: 'descending',
        }))
            .results

        const questionIndex = questionDrafts.findIndex(q => q.objectId === this.$route.params.questionDraftId),
            remainingQuestions = R.drop(questionIndex + 1, questionDrafts),
            nextQuestion = remainingQuestions.length
                ? remainingQuestions[0]
                : questionDrafts.filter(q => q.objectId !== this.$route.params.questionDraftId)[0]

        return nextQuestion
    }

    async fetchNextCompletedQuestion () {
        const questionDrafts = (await questionDraftsModule.actions.fetchQuestionDrafts({
            equalTo: {
                job: objPointer(this.job && this.job.objectId || '')('Job'),
                jobStatus: 'Completed',
            },
            orderBy: 'updatedAt',
            order: 'descending',
        }))
            .results

        const questionIndex = questionDrafts.findIndex(q => q.objectId === this.$route.params.questionDraftId),
            remainingQuestions = R.drop(questionIndex + 1, questionDrafts),
            nextQuestion = remainingQuestions.length
                ? remainingQuestions[0]
                : questionDrafts.filter(q => q.objectId !== this.$route.params.questionDraftId)[0]

        if (!nextQuestion) {
            return false
        }

        return nextQuestion
    }

    async fetchNextAdminQuestion () {
        const questionDrafts = (await questionDraftsModule.actions.fetchQuestionDrafts({
            equalTo: {
                job: objPointer(this.job && this.job.objectId || '')('Job'),
                jobStatus: this.currentQueue,
            },
            orderBy: 'updatedAt',
            order: 'descending',
        }))
            .results

        const questionIndex = questionDrafts.findIndex(q => q.objectId === this.$route.params.questionDraftId),
            remainingQuestions = R.drop(questionIndex + 1, questionDrafts),
            nextQuestion = remainingQuestions.length
                ? remainingQuestions[0]
                : questionDrafts.filter(q => q.objectId !== this.$route.params.questionDraftId)[0]

        if (!nextQuestion) {
            return false
        }

        return nextQuestion
    }

    async fetchOrGetJob (jobId: string) {
        return jobsModule.getters.getJob(jobId) || jobsModule.actions.fetchJob(jobId)
    }

    async loadJobDetails (jobId: string) {
        this.job = await this.fetchOrGetJob(jobId) || null

        if (this.job) {
            this.examDraftId = this.job.examDraftId
            this.jobName = this.job.name
            this.jobReferences = this.job.references && this.job.references.length
                ? this.job.references
                : this.jobReferences
            if (this.jobReferences?.length === 1) {
                this.references = []
                this.references.push({ text: this.jobReferences[0], id: Math.round(Math.random() * 1e18) })
            }
            this.questionTemplates = this.job.questionTemplates || []
            if (!this.questionDraftType) {
                this.questionDraftType = this.questionTypes[0]
            }
            if (this.questionDraftType === 'Multiple Choice') {
                this.defaultMCQDistractors = this.job.allowedQuestionTypes.mcq.numDistractors
                this.distractors = this.unsavedQuestionDraft?.distractors
                || new Array(Number(this.defaultMCQDistractors) || 0)
            } else if (this.prompt === '' && this.questionDraftType === 'Multiple Correct Response') {
                // If we're creating a new MCR question, add "Select all that apply." to the prompt
                this.prompt = '<p>&nbsp;</p><p>Select all that apply.</p>'
            }
        }
    }

    async changeKnowledgeArea (knowledgeAreaDraftId: string) {
        this.subjectAndSubtopicErrors = []

        this.knowledgeAreaDraftId = knowledgeAreaDraftId
        this.subtopicId = ''
        await this.checkKnowledgeAreaCompletionStatus()
    }

    async changeSubtopic (subtopicId: string) {
        this.subjectAndSubtopicErrors = []

        this.subtopicId = subtopicId
        await this.checkKnowledgeAreaCompletionStatus()
    }

    async clickedBloomAIAssist () {
        if (this.isReadOnly) {
            return
        }
        this.bloomAIAssistSuggestion = null
        this.bloomAIAssistLoading = true
        const draftId = this.$route.params.questionDraftId
        if (typeof draftId === 'string') {
            const aiSuggestion = await questionDraftsModule.actions.getBloomLevelForQuestion(draftId)
            const words = aiSuggestion.trim().split(/\s+/)
            this.bloomAIAssistSuggestion = words.length > 1 ? 'Unknown' : aiSuggestion
        } else {
            this.bloomAIAssistSuggestion = 'Unknown'
        }
        this.bloomAIAssistLoading = false
    }

    async checkKnowledgeAreaCompletionStatus () {
        let isCurrentSubjectCompleted = false
        if (this.job && this.job.questionTemplates && this.knowledgeAreaDraftId) {
            const kaQuestionDrafts = await questionDraftsModule.actions.fetchQuestionDrafts({
                    equalTo: {
                        knowledgeAreaDraft: {
                            __type: 'Pointer',
                            className: 'KnowledgeAreaDraft',
                            objectId: this.knowledgeAreaDraftId,
                        },
                        job: {
                            __type: 'Pointer',
                            className: 'Job',
                            objectId: this.job.objectId,
                        },
                    },
                    doesNotExist: [ 'examDataId' ],
                }),
                questionTemplate = this.job.questionTemplates
                    .find(qt => qt.knowledgeAreaDraftId === this.knowledgeAreaDraftId),
                knowledgeAreaDraft = this.knowledgeAreaDrafts
                    .find(ka => 'objectId' in ka && ka.objectId === this.knowledgeAreaDraftId),
                subtopicTemplate = questionTemplate?.subtopics?.find(template =>
                    template.id === this.subtopicId),
                numOfDraftsInSubtopic = kaQuestionDrafts.results
                    .filter(draft => draft.subtopicId === this.subtopicId).length

            if (questionTemplate && kaQuestionDrafts.totalCount >= questionTemplate.count && knowledgeAreaDraft) {
                this.subjectAndSubtopicErrors.push(kaQuestionDrafts.totalCount + ' out of ' +
                    questionTemplate.count + ' new questions have already been created for the subject: '
                    + knowledgeAreaDraft.name + '.')
                isCurrentSubjectCompleted = true
            }
            if (subtopicTemplate && numOfDraftsInSubtopic >= subtopicTemplate.count) {
                this.subjectAndSubtopicErrors.push(numOfDraftsInSubtopic + ' out of ' + subtopicTemplate.count +
                ' new questions have already been created for the subtopic: ' + subtopicTemplate.name)
            }

            return isCurrentSubjectCompleted
        }
    }

    beforeUnmount () {
        window.removeEventListener('keydown', this.keyboardSaveListener, true)
    }

    keyboardSaveListener (e: KeyboardEvent) {
        if ((e.metaKey || e.ctrlKey) && e.keyCode === 83) {
            e.preventDefault()
            !this.isLoading
                && this.saveQuestion()
        }
    }

    getCurrentMockExamSetting () {
        return this.mockExamSettings.find(mes => mes.value)
    }

    mockExamSettingsChanged (mockExamSetting: IMockExamSetting) {
        this.mockExamSettings.forEach((s, i) => {
            // ensure that you cannot have standard selected at the same time as any other mock exam
            if (s.id === mockExamSetting.id) {
                this.mockExamSettings[i].value = true
            } else {
                this.mockExamSettings[i].value = false
            }
        })
    }

    backToJobClicked () {
        if (this.job && this.job.objectId) {
            this.$router.push({
                name: 'job-view',
                params: {
                    jobId: this.job.objectId,
                },
            })
        } else {
            this.$router.push({
                name: 'question-list',
            }) 
        }
    }

    @Watch('questionDraftType')
    removeExplanationAutofill () {
        if (this.questionDraftType === 'Multiple Correct Response' && this.$route.name === 'question-draft-create') {
            this.explanation = ''
        } else if (!this.explanation) {
            this.explanation = 'Correct answer: '
        }
    }

    @Watch('currentQuestionId')
    async updateSelectedQuestion (serial: string) {
        this.isReadOnly = true
        if (!this.retainToast) {
            this.toast = null
            this.toastSubtext = null
        }
        if (this.$route.name !== 'question-draft-create') {
            this.serial = serial
            this.isSerialUpdated = true
        }
        if (this.$route.name === 'question-draft-edit') {
            await this.loadQuestionDraftForm()
        }
        if (this.$route.name === 'question-draft-create') {
            this.knowledgeAreaDraftId = null
            await this.loadQuestionDraftForm()
        }

        this.retainToast = false
    }

    @Watch('currentRouteName')
    saveUnsavedDraft (newRoute: string) {
        if (this.previousRouteName === 'question-draft-create' && this.currentUser?.role === 'Writer') {
            this.serial = null
            this.isSerialUpdated = true
            this.submitQuestionForm({ createUnsavedDraft: true })
        }

        this.previousRouteName = newRoute
    }
}
