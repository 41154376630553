<template>
    <div class="title-text">
        <slot>
            {{ text }}
        </slot>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component
export default class TitleText extends Vue {
    @Prop({ default: 'Title' }) text!: string
}
</script>

<style lang="scss" scoped>

.title-text {
    font-size: 48px;
    font-weight: 600;
}
</style>
