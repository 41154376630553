<template>
    <div class="select-container">
        <select
            v-model="selectValue"
            class="select-input"
            :class="{ 'select-centered': center, error }"
            :disabled="disabled"
            :autocomplete="autocomplete"
            @change="valueChange"
        >
            <option
                disabled
                value=""
            >
                {{ placeholder }}
            </option>
            <template v-for="(option, index) in data">
                <optgroup
                    v-if="typeof option === 'object' && 'group' in option && option.group"
                    :key="`${index}-group`"
                    :label="getOptionText(option)"
                >
                    <option
                        v-for="(item, itemKey) in option.items"
                        :key="itemKey"
                        :value="getOptionValue(item)"
                    >
                        {{ getOptionText(item) }}
                    </option>
                </optgroup>
                <option
                    v-else-if="typeof option !== 'object' || !('group' in option)"
                    :key="index"
                    :value="getOptionValue(option)"
                >
                    {{ getOptionText(option) }}
                </option>
            </template>
        </select>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit, Watch } from 'vue-facing-decorator'

type SelectFieldOption = {
    value: string
    label: string
}

type SelectFieldData = SelectFieldOption | {
    group: boolean
    label: string
    items: SelectFieldOption[]
} | string

@Component
export default class SelectField extends Vue {
    @Prop({ default: false }) center?: boolean
    @Prop() data!: SelectFieldData[]
    @Prop({ default: false }) disabled?: boolean
    @Prop({ default: false }) error?: boolean
    @Prop({ default: '' }) modelValue!: string
    @Prop({ default: '' }) autocomplete!: string
    @Prop({ default: 'Select an option' }) placeholder!: string

    selectValue = ''

    mounted () {
        this.selectValue = this.modelValue
    }

    getOptionValue (option: SelectFieldOption | string) {
        if (typeof option === 'object' && option.label) {
            return option.value
        }
        return option
    }

    getOptionText (option: SelectFieldOption | SelectFieldData | string) {
        if (typeof option === 'object' && 'label' in option) {
            return option.label
        }
        return option
    }

    @Watch('modelValue')
    onValueChange (val: string) {
        this.selectValue = val
    }

    @Emit('update:modelValue')
    valueChange () {
        return this.selectValue
    }
}
</script>

<style lang="scss" scoped>

.select-container {
    position: relative;
    border-radius: 3px;
    margin-top: 4px;
    max-width: 300px;
    width: 100%;
    background: #fff;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        content: '\25BE';
        font-size: 14px;
        line-height: 34px;
        width: 20px;
        font-family: Arial, Helvetica, sans-serif;
    }

    .select-input {
        border: 1px solid rgba(136, 163, 182, 0.85);
        padding: 7px;
        font-size: 14px;
        line-height: 22px;
        width: 100%;
        max-width: 300px;
        border-radius: 3px;
        color: $brand-black;
        padding-right: 20px;
        -webkit-appearance: none;
        position: relative;
        z-index: 1;
        background: transparent;
        margin: 0;

        &:focus {
            outline: none;
            border: 1px solid $brand-blue;
        }

        &.select-centered {
            text-align: center;
        }

        &:disabled {
            background-color: #ddd;
            border: 1px solid rgba($pewter, 0.5);
        }
    }
}
</style>
