<template>
    <div class="question">
        <Loading v-if="isLoading" />
        <FormValidation :messages="validationMessages" />
        <div v-if="!isLoading" class="question__header">
            <div
                v-if="exam"
                class="question__exam-name"
            >
                {{ exam.nativeAppName }}
            </div>
            <TitleText
                class="question__title-text"
            >
                Question
            </TitleText>
        </div>
        <ul v-if="question" class="question__general">
            <li class="question__info-item">
                <label>ID</label>
                <span>{{ question.objectId }}</span>
            </li>
            <li class="question__info-item">
                <label>Serial</label>
                <span>{{ question.serial }}</span>
            </li>
            <li class="question__info-item">
                <label>App Name</label>
                <span>{{ exam && exam.nativeAppName }}</span>
            </li>
            <li class="question__info-item">
                <label>Composite Key</label>
                <span>{{ question.compositeKey }}</span>
            </li>
            <li class="question__info-item">
                <label>Type</label>
                <span>{{ question.type }}</span>
            </li>
            <li class="question__info-item">
                <label>Subject</label>
                <span>{{ 'name' in question.subject && question.subject.name }}</span>
            </li>
            <li class="question__info-item">
                <label>Free</label>
                <span>{{ question.isFree ? "Yes" : "No" }}</span>
            </li>
            <li class="question__info-item">
                <label>Archived</label>
                <span>{{ question.isArchived ? "Yes" : "No" }}</span>
            </li>
            <li class="question__info-item">
                <label>Mock Question</label>
                <span>{{ question.isMockQuestion ? "Yes" : "No" }}</span>
            </li>
        </ul>
        <ul v-if="question" class="question__stats">
            <li class="question__info-item">
                <label>Date Added</label>
                <span>{{ question.addedDate.toLocaleString() }}</span>
            </li>
            <li class="question__info-item">
                <label>Created At</label>
                <span>{{ new Date(question.createdAt).toLocaleString() }}</span>
            </li>
            <li class="question__info-item">
                <label>Updated At</label>
                <span>{{ new Date(question.updatedAt).toLocaleString() }}</span>
            </li>
            <li v-if="percentCorrect" class="question__info-item">
                <label>Percent Correct</label>
                <span>{{ percentCorrect }}%</span>
            </li>
            <li v-if="answeredCount" class="question__info-item">
                <label>Times Answered</label>
                <span>
                    {{ answeredCount }}
                </span>
            </li>
        </ul>
        <ul v-if="question" class="question__info">
            <li class="question__info-item">
                <label>Prompt</label>
                <div v-html="question.prompt" />
            </li>
            <li v-if="question.passage" class="question__info-item">
                <label>Passage</label>
                <span>{{ question.passage }}</span>
            </li>
            <ul v-if="answers" class="question__info-item">
                <label> {{ answers.length > 1 ? "Answers" : "Answer" }} </label>
                <li
                    v-for="(answer, index) in answers"
                    :key="index"
                    class="question__answer"
                >
                    <div v-html="answer.text" />
                </li>
            </ul>
            <ul v-if="distractors" class="question__info-item">
                <label> {{ distractors.length? "Distractors" : "Distractor" }} </label>
                <li
                    v-for="(distractor, index) in distractors"
                    :key="index"
                    class="question__distractor"
                >
                    <div v-html="distractor.text" />
                </li>
            </ul>
            <li class="question__info-item">
                <label>Explanation</label>
                <span><div v-html="question.explanation" /></span>
            </li>
            <li v-if="question.references.length" class="question__info-item">
                <label>Reference{{ question.references.length > 1 ? 's' : '' }}</label>
                <span v-for="(reference, index) in question.references" :key="index">
                    <div v-html="reference" />
                </span>
            </li>
        </ul>
        <div v-else>
            No Question Found
        </div>
        <ButtonFooter v-if="question" class="question__button-footer">
            <template
                #rightSide 
            >
                <div class="question__right-side-buttons">
                    <PocketButton
                        class="question__edit-question-button"
                        type="secondary"
                        @click="editQuestionClicked"
                    >
                        Edit Question
                    </PocketButton>
                    <PocketButton
                        type="primary"
                        @click="createJobClicked"
                    >
                        Create Job
                    </PocketButton>
                </div>
            </template>
        </ButtonFooter>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import Loading from '@/components/Loading.vue'
import { FormValidation } from '@/components/Forms'
import type { Study } from '@pocketprep/types'
import type { TEnhancedQuestion } from '@/store/questions/types'
import examsModule from '@/store/exams/module'
import questionsModule from '@/store/questions/module'
import TitleText from '@/components/TitleText.vue'
import ButtonFooter from '@/components/ButtonFooter.vue'
import UIKit from '@pocketprep/ui-kit'

@Component({
    components: {
        Loading,
        FormValidation,
        TitleText,
        ButtonFooter,
        PocketButton: UIKit.Button,
    },
})
export default class QuestionView extends Vue {
    question: TEnhancedQuestion | null = null
    exam: Study.Class.ExamMetadataJSON | null = null
    isLoading = false
    validationMessages: string[] = []

    get answeredCount () {
        return (this.question?.answeredCorrectlyCount || 0)
            + (this.question?.answeredIncorrectlyCount || 0)
    }

    get percentCorrect () {
        if (!this.answeredCount) {
            return 0
        }

        const score = Math.round(((this.question?.answeredCorrectlyCount || 0) / this.answeredCount) * 100)

        return score
    }

    get answers () {
        return this.question?.choices.filter(c => c.isCorrect)
    }

    get distractors () {
        return this.question?.choices.filter(c => !c.isCorrect)
    }

    async fetchOrGetExam (examMetadataId: string) {
        return examsModule.getters.getExam(examMetadataId)
            || examsModule.actions.fetchExam(examMetadataId)
    }

    editQuestionClicked () {
        if (this.question && this.question.objectId) {
            this.$router.push({
                name: 'question-draft-create',
                query: { 'question': this.question && this.question.objectId },
            })
        }
    }

    createJobClicked () {
        if (this.question && this.question.objectId) {
            this.$router.push(
                {
                    name: 'job-create',
                    query: { 'question': this.question && this.question.objectId },
                }
            )
        }
    }

    async mounted () {
        const questionId = typeof this.$route.params.questionId === 'string'
            ? this.$route.params.questionId
            : this.$route.params.questionId[0]

        if (questionId) {
            this.isLoading = true
            const question = await questionsModule.actions.fetchQuestion(questionId)
            const compositeKey = question.compositeKey
            const currentVersion = compositeKey.split('/')[1]
            const mostRecentVersion = await examsModule.actions.fetchMostRecentExamVersion(compositeKey)
            this.exam = await this.fetchOrGetExam(question.examMetadata.objectId) || null

            // If this is not the most recent version of the exam, show a warning
            if (mostRecentVersion && currentVersion !== mostRecentVersion) {
                this.validationMessages
                    .push(
                        'warning/Warning: ' +
                        'This question is outdated. ' +
                        `${this.exam?.nativeAppName} has a newer version (${mostRecentVersion})`
                    )
            }

            this.isLoading = false
            this.question = question
        }
    }
}
</script>

<style lang="scss" scoped>

.question {
    padding: $base;
    margin-top: 52px;

    &__button-controls {
        position: fixed;
        z-index: 100;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 57px;
    }

    &__exam-name {
        margin-top: 22px;
        font-size: 16px;
        color: $slate;
        font-weight: 500;
    }

    &__title-text {
        margin-top: 16px;
        margin-bottom: 34px;
        font-size: 40px;
    }

    &__buttons {
        margin-bottom: 15px;

        button {
            margin-right: 15px;
        }
    }

    &__info-item {
        display: flex;
        flex-direction: column;
        padding: 10px;
        min-width: 150px;

        label {
            color: #666;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 5px;
        }
    }

    &__general,
    &__stats,
    &__info {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid $cloudy;
        border-radius: 4px;
        padding: 10px;
        margin-bottom: $base;
        background-color: white;

        &.question__general .question__info-item {
            width: 20%;
        }

        &.question__stats .question__info-item {
            width: 25%;
        }

        &.question__info .question__info-item {
            width: 100%;
        }
    }

    &__answer,
    &__distractor {
        border-top: 1px solid lighten($gray, 50%);
        border-right: 1px solid lighten($gray, 50%);
        border-left: 5px solid $gray;
        border-bottom: 1px solid $gray;
        border-radius: 4px;
        padding: 5px;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__right-side-buttons {
        position: absolute;
        right: 0;
        margin-right: 18px;
    }

    &__edit-question-button {
        margin-right: 8px;
    }
}
</style>
