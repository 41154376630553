<template>
    <div class="dashboard">
        <Loading v-if="isLoading" />
        <Nav />
        <Overlay class="dashboard__overlay"/>
        <div 
            v-if="!isLoading" 
            class="dashboard__content"
            :class="{ 'dashboard__content--sidebar-visible': isSidebarVisible() }">
            <div class="dashboard__routes">
                <RouterView />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import Nav from '@/components/Nav.vue'
import Loading from '@/components/Loading.vue'
import JobList from '@/components/Jobs/JobList.vue'
import * as Sentry from '@sentry/browser'
import { usersModule } from '@/store/users/module'
import Overlay from '@/components/Overlay.vue'
import { isSidebarVisible } from '@/router'

@Component({
    components: {
        Nav,
        JobList,
        Loading,
        Overlay,
    },
})
export default class Dashboard extends Vue {
    isLoading = true
    isSidebarVisible = isSidebarVisible

    get currentUser () {
        return usersModule.getters.getCurrentUser()
    }

    async mounted () {
        this.isLoading = true
        await usersModule.actions.fetchUsers()
        this.isLoading = false
        // update Sentry with user data
        const currentUser = this.currentUser
        if (currentUser) {
            Sentry.configureScope(scope => {
                scope.setUser({ 
                    id: currentUser.objectId,
                    email: currentUser.username,
                })
            })
        }
    }
}
</script>

<style lang="scss" scoped>

.dashboard {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;

    &__content {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        height: 100%;
        max-width: 1290px;
        margin: 0 auto;

        &--sidebar-visible {
            max-width: none;
            padding-left: 320px;
            display: block;
        }
    }
    
    &__routes {
        display: flex;
        flex: 1;
        min-width: 0;
        flex-direction: column;
        padding-bottom: 90px;
    }
}
</style>
