<template>
    <div class="import-questions-list">
        <table>
            <tr>
                <td />
                <td>Serial</td>
                <td>Type</td>
                <td>Subject</td>
                <td class="import-questions-list__wide-value">
                    Question
                </td>
                <td class="import-questions-list__wide-value">
                    Answer
                </td>
                <td class="import-questions-list__wide-value">
                    Answer 2
                </td>
                <td class="import-questions-list__wide-value">
                    Answer 3
                </td>
                <td class="import-questions-list__wide-value">
                    Answer 4
                </td>
                <td class="import-questions-list__wide-value">
                    Answer 5
                </td>
                <td class="import-questions-list__wide-value">
                    Answer 6
                </td>
                <td class="import-questions-list__wide-value">
                    Answer 7
                </td>
                <td class="import-questions-list__wide-value">
                    Answer 8
                </td>
                <td class="import-questions-list__wide-value">
                    Answer 9
                </td>
                <td class="import-questions-list__wide-value">
                    Distractor 1
                </td>
                <td class="import-questions-list__wide-value">
                    Distractor 2
                </td>
                <td class="import-questions-list__wide-value">
                    Distractor 3
                </td>
                <td class="import-questions-list__wide-value">
                    Distractor 4
                </td>
                <td class="import-questions-list__wide-value">
                    Distractor 5
                </td>
                <td class="import-questions-list__wide-value">
                    Distractor 6
                </td>
                <td class="import-questions-list__wide-value">
                    Distractor 7
                </td>
                <td class="import-questions-list__wide-value">
                    Distractor 8
                </td>
                <td class="import-questions-list__wide-value">
                    Distractor 9
                </td>
                <td class="import-questions-list__wide-value">
                    Passage
                </td>
                <td class="import-questions-list__wide-value">
                    Explanation
                </td>
                <td class="import-questions-list__wide-value">
                    Reference
                </td>
                <td>Is Archived</td>
                <td>Free</td>
                <td>Answered Count</td>
                <td>Percent Correct</td>
                <td>Date Added</td>
                <td>Is Mock</td>
                <td>Subtopic</td>
                <td>Bloom's Taxonomy Level</td>
            </tr>
            <tr
                v-for="(q, key) in processedQuestions"
                :key="q.serial || key"
                :class="{ expand: q === expandedQ }"
                @click="expandedQ === q ? expandedQ = null : expandedQ = q"
            >
                <td style="text-align: center;">
                    {{ key + 1 }}
                </td>
                <td><div>{{ q.serial || 'n/a' }}</div></td>
                <td style="white-space: nowrap;">
                    <div>{{ q.type }}</div>
                </td>
                <td style="white-space: nowrap;">
                    <div>{{ q.knowledgeArea }}</div>
                </td>
                <td><div v-html="q.question" /></td>
                <td><div v-html="q.answer" /></td>
                <td><div v-html="q.answer2" /></td>
                <td><div v-html="q.answer3" /></td>
                <td><div v-html="q.answer4" /></td>
                <td><div v-html="q.answer5" /></td>
                <td><div v-html="q.answer6" /></td>
                <td><div v-html="q.answer7" /></td>
                <td><div v-html="q.answer8" /></td>
                <td><div v-html="q.answer9" /></td>
                <td><div v-html="q.distractor1" /></td>
                <td><div v-html="q.distractor2" /></td>
                <td><div v-html="q.distractor3" /></td>
                <td><div v-html="q.distractor4" /></td>
                <td><div v-html="q.distractor5" /></td>
                <td><div v-html="q.distractor6" /></td>
                <td><div v-html="q.distractor7" /></td>
                <td><div v-html="q.distractor8" /></td>
                <td><div v-html="q.distractor9" /></td>
                <td><div v-html="q.passage" /></td>
                <td><div v-html="q.explanation" /></td>
                <td><div v-html="q.reference" /></td>
                <td><div>{{ q.isArchived ? 'Yes' : 'No' }}</div></td>
                <td><div>{{ q.isSpecial ? 'Yes' : 'No' }}</div></td>
                <td><div v-html="q.answeredCount" /></td>
                <td><div v-html="q.percentCorrect" /></td>
                <td><div v-html="q.dateAdded" /></td>
                <td><div v-html="q.isMockQuestion" /></td>
                <td><div v-html="q.subtopic" /></td>
                <td><div v-html="q.bloomTaxonomyLevel" /></td>
            </tr>
        </table>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import type { IMappedCSVRow } from '@/store/files/types'
import { stripCKEditorTags } from '@/utils'

@Component
export default class ImportQuestionsList extends Vue {
    @Prop() readonly questions!: IMappedCSVRow[]

    get processedQuestions () {
        return this.questions.map(examQuestion => {
            const answers = examQuestion.choices.filter(c => c.isCorrect).map(c => c.text)
            const distractors = examQuestion.choices.filter(c => !c.isCorrect).map(c => c.text)
            return {
                'serial': examQuestion['serial'],
                'type': examQuestion['type'],
                'isArchived': examQuestion['isArchived'],
                'isSpecial': examQuestion['isFree'],
                'knowledgeArea': examQuestion['subject'],
                'question': stripCKEditorTags(examQuestion['prompt'] || ''),
                'answer': stripCKEditorTags(answers[0] || ''),
                'answer2': stripCKEditorTags(answers[1] || ''),
                'answer3': stripCKEditorTags(answers[2] || ''),
                'answer4': stripCKEditorTags(answers[3] || ''),
                'answer5': stripCKEditorTags(answers[4] || ''),
                'answer6': stripCKEditorTags(answers[5] || ''),
                'answer7': stripCKEditorTags(answers[6] || ''),
                'answer8': stripCKEditorTags(answers[7] || ''),
                'answer9': stripCKEditorTags(answers[8] || ''),
                'explanation': stripCKEditorTags(examQuestion['explanation'] || ''),
                'passage': stripCKEditorTags(examQuestion['passage'] || ''),
                'reference': stripCKEditorTags(examQuestion['reference'] || ''),
                'distractor1': stripCKEditorTags(distractors[0] || ''),
                'distractor2': stripCKEditorTags(distractors[1] || ''),
                'distractor3': stripCKEditorTags(distractors[2] || ''),
                'distractor4': stripCKEditorTags(distractors[3] || ''),
                'distractor5': stripCKEditorTags(distractors[4] || ''),
                'distractor6': stripCKEditorTags(distractors[5] || ''),
                'distractor7': stripCKEditorTags(distractors[6] || ''),
                'distractor8': stripCKEditorTags(distractors[7] || ''),
                'distractor9': stripCKEditorTags(distractors[8] || ''),
                'answeredCount': examQuestion['answeredCount'],
                'percentCorrect': examQuestion['percentCorrect'],
                'dateAdded': examQuestion['dateAdded'],
                'isMockQuestion': examQuestion['isMockQuestion'],
                'subtopic': examQuestion['subtopic'] || '',
                'bloomTaxonomyLevel': examQuestion['bloomTaxonomyLevel'],
            }
        })
    }

    expandedQ: ImportQuestionsList['processedQuestions'][number] | null = null
}
</script>

<style lang="scss" scoped>

.import-questions-list {
    width: 100%;
    max-height: 500px;
    overflow: auto;
    border: 1px solid $night;

    table {
        tr {
            &:hover {
                cursor: pointer;
                background: $light-gray;
            }

            td {
                padding: 10px;
                border: 1px solid $night;
                border-left: 0;
                border-top: 0;
                vertical-align: top;

                div {
                    max-height: 70px;
                    max-width: 300px;
                    overflow: hidden;
                }

                &:last-child {
                    border-right: 0;
                }

                &.import-questions-list__wide-value {
                    min-width: 300px;
                }
            }

            &.expand {
                div {
                    max-height: none;
                    padding-bottom: 5px;
                }
            }

            &:first-child {
                font-weight: 600;
                text-align: center;
                background: #fff;
                white-space: nowrap;
            }

            &:last-child td {
                border-bottom: 0;
            }
        }
    }
}
</style>

