<template>
    <div class="chat-button">
        <Chat
            class="chat-button__chat-container-incomplete"
            :job-id="jobId"
            :is-visible="showChat"
        />
        <div class="chat-button__button-row">
            <PocketButton
                type="secondary"
                title="Chat"
                @click="toggleChat"
            >
                <Icon :type="showChat ? 'close' : 'chat'" />
                <div>{{!showChat ? 'Chat' : 'Close Chat'}}</div>
                <div v-if="hasUnreadMessages" class="chat-button__unread-message-dot" />
            </PocketButton>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { usersModule } from '@/store/users/module'
import jobsModule from '@/store/jobs/module'
import { chatModule } from '@/store/chat/module'
import Chat from '@/components/Chat/Chat.vue'

@Component({
    components: {
        PocketButton: UIKit.Button,
        Icon: UIKit.Icon,
        Chat,
    },
})
export default class ChatButton extends Vue {
    @Prop() readonly jobId?: string

    hasUnreadMessages = false
    showChat = false

    async mounted () {
        const currentUser = usersModule.state.user
        if (this.jobId && currentUser) {
            const jobs = jobsModule.state.jobs
            const jobsWithUnreadMessages = await chatModule.actions.fetchUnreadMessagesByJobIds({
                jobIds: jobs.map(job => job.objectId),
                userId: currentUser?.objectId,
            })
            this.hasUnreadMessages = jobsWithUnreadMessages.some(message => message.job.objectId === this.jobId)
        }
    }

    toggleChat () {
        this.hasUnreadMessages = false
        this.showChat = !this.showChat
    }

    @Watch('showChat')
    displayOverlay () {
        const overlayEl = document.getElementById('overlay')
        if (overlayEl) {
            this.showChat ? overlayEl.style.display = 'block' : overlayEl.style.display = 'none'
        }
    }
}
</script>

<style lang="scss" scoped>

.chat-button {
    &__chat-container-incomplete {
        position: fixed;
        left: calc(50%);
        max-width: calc(100% - 40px);
        bottom: 65px;
        z-index: 100;
    }

    &__button-row {
        line-height: 17px;
        font-weight: 600;

        svg {
            margin-top: -2px;
            margin-right: 7px;
            width: 26px;
            height: 24px;
        }

        :deep(.uikit-btn__content) {
            display: flex;
            align-items: center;
            margin-top: 1px;
        }
    }

    &__unread-message-dot {
        position: relative;
        width: 9px;
        height: 9px;
        margin-left: 4px;
        top: -6px;
        background-color: $brand-blue;
        border-radius: 100%;
    }
}
</style>
