<template>
    <div class="exams">
        <Loading v-if="isLoading" />
        <div class="exams__versions-control">
            Versions:
            <SegmentControl
                class="exams__segment-control"
                :default-option="versionsToShow === 'recent' ? 1 : 2"
                option1="Most Recent"
                option2="All Versions"
                @change="byVersionToShow"
            />
        </div>
        <div class="exams__options">
            <TitleText>
                Exams
            </TitleText>
        </div>
        <List 
            v-if="!isLoading"
            store-name="examsList"
            :list-options="listOptions"
            :force-show-controls="true"
            @itemClicked="examClicked"
        />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import Loading from '@/components/Loading.vue'
import { type IListOptions, List } from '@/components/Lists'
import type { Study } from '@pocketprep/types'
import { bundlesModule } from '@/store/bundles/module'
import examsModule from '@/store/exams/module'
import TitleText from '@/components/TitleText.vue'
import UIKit from '@pocketprep/ui-kit'

interface IMappedExam {
    objectId: string
    nativeAppName: string
    descriptiveName: string
    compositeKey: string
    bundle: string
    isFree: 'Yes' | 'No'
}

@Component({
    components: {
        List,
        Loading,
        TitleText,
        SegmentControl: UIKit.SegmentControl,
    },
})
export default class ExamList extends Vue {
    isLoading = true
    versionsToShow: 'recent' | 'all' = 'recent'
    examBundleMap: { [key: string]: string } = {}
    bundles: string[] = []

    get exams () {
        return examsModule.state.exams
    }

    get listOptions (): IListOptions<IMappedExam> {
        // Sort by composite key
        const mostRecentExams = this.exams
            .sort((a, b) => -a.compositeKey.localeCompare(b.compositeKey, undefined, { numeric: true }))
            .filter((exam, index, self) => 
                // Only keep the first exam of each directory (which will be the most recent due to previous sort)
                index === self.findIndex(selfExam => 
                    selfExam.compositeKey.split('/')[0] === exam.compositeKey.split('/')[0]
                )
            )

        const examsToList = this.versionsToShow === 'recent' ? mostRecentExams : this.exams
        const examsWithBundles = examsToList.map(e => ({ ...e, bundle: this.examBundleMap[e.objectId] || 'No Bundle' }))

        return {
            listData: examsWithBundles
                .map(({ objectId, nativeAppName, descriptiveName, compositeKey, bundle, isFree }) => ({
                    objectId,
                    nativeAppName: nativeAppName || '',
                    descriptiveName: descriptiveName || '',
                    compositeKey,
                    bundle,
                    isFree: isFree ? 'Yes' : 'No',
                })),
            listSchema: [
                {
                    propName: 'nativeAppName',
                    label: 'Native App Name',
                    type: 'text',
                    options: {
                        style: 'title',
                        width: 200,
                        group: 0,
                    },
                },
                {
                    propName: 'compositeKey',
                    label: 'Composite Key',
                    type: 'text',
                    options: {
                        width: 150,
                        group: 0,
                    },
                },
                {
                    propName: 'descriptiveName',
                    label: 'Descriptive Name',
                    type: 'text',
                    options: {
                        flex: true,
                        minWidth: 250,
                        group: 1,
                    },
                },
                {
                    propName: 'bundle',
                    label: 'Bundle',
                    type: 'text',
                    options: {
                        flex: true,
                        minWidth: 150,
                        group: 0,
                        
                    },
                    data: [ 'No Bundle', ...this.bundles ],
                },
                {
                    propName: 'isFree',
                    label: 'Is Free',
                    type: 'text',
                    data: [ 'Yes', 'No' ],
                    options: {
                        isHidden: true,
                    },
                },
            ],
        }
    }

    examClicked (exam: Study.Class.ExamMetadataJSON) {
        this.$router.push({
            name: 'exam-view',
            params: {
                examId: exam.objectId,
            },
        })
    }

    byVersionToShow (type: 1 | 2) {
        this.versionsToShow = type === 1 ? 'recent' : 'all'
    }

    async mounted () {
        this.isLoading = true
        
        const [ bundles ] = await Promise.all([
            bundlesModule.actions.fetchBundles(),
            examsModule.actions.fetchExams(),
        ])
        this.bundles = bundles.sort((a, b) => a.name.localeCompare(b.name)).map(b => b.name)
        this.examBundleMap = bundles.reduce((bAcc, b) => {
            const examLib = b.exams.reduce((eAcc, e) => {
                return {
                    [e.objectId]: b.name,
                    ...eAcc,
                }
            }, {})

            return { ...bAcc, ...examLib }
        }, {} as { [key: string]: string })

        this.isLoading = false
    }
}
</script>

<style lang="scss" scoped>

.exams {
    flex-grow: 1;
    padding: $base;
    padding-bottom: 0;
    margin-top: 52px;

    &__versions-control {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 12px;
        line-height: 16px;
        padding-right: 10px;
    }

    &__segment-control {
        margin-left: 10px;
    }

    &__options {
        display: flex;
        align-items: center;
        padding-left: 10px;
        margin-top: 24px;
        margin-bottom: 34px;
    }
}
</style>
