<template>
    <div class="exam-draft-list">
        <Loading v-if="isLoading" />
        <div
            class="exam-draft-list__title"
        >
            <TitleText>
                Exam Drafts
            </TitleText>
            <PocketButton
                class="exam-draft-list__create-draft-btn"
                @click="createExamDraftClicked"
            >
                Create Exam Draft
            </PocketButton>
        </div>
        <List
            v-if="!isLoading"
            store-name="examDraftsList"
            :list-options="listOptions"
            :force-show-controls="true"
            @itemClicked="examDraftClicked"
        />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import Loading from '@/components/Loading.vue'
import { List, type IListOptions } from '@/components/Lists'
import type { CMS } from '@pocketprep/types'
import examDraftsModule from '@/store/examDrafts/module'
import TitleText from '@/components/TitleText.vue'
import UIKit from '@pocketprep/ui-kit'

interface IMappedExamDraft {
    objectId: string
    nativeAppName: string
    compositeKey: string
    isFree: 'Yes' | 'No'
    descriptiveName: string
}

@Component({
    components: {
        List,
        Loading,
        TitleText,
        PocketButton: UIKit.Button,
    },
})
export default class ExamDraftList extends Vue {
    isLoading = true
    
    get mappedExamDrafts (): IMappedExamDraft[] {
        return examDraftsModule.state.examDrafts.map(examDraft => {
            return {
                ...examDraft,
                isFree: examDraft.isFree ? 'Yes' : 'No',
            }
        })
    }

    get listOptions (): IListOptions<IMappedExamDraft> {
        return {
            listData: this.mappedExamDrafts,
            listSchema: [{
                propName: 'nativeAppName',
                label: 'Native App Name',
                type: 'text',
                options: {
                    style: 'title',
                    group: 0,
                    minWidth: 200,
                },
            }, {
                propName: 'compositeKey',
                label: 'Composite Key',
                type: 'text',
                options: {
                    group: 0,
                    minWidth: 150,
                },
            }, {
                propName: 'descriptiveName',
                label: 'Descriptive Name',
                type: 'text',
                options: {
                    group: 1,
                    minWidth: 200,
                },
            }, {
                propName: 'isFree',
                label: 'Is Free',
                type: 'text',
                data: [ 'Yes', 'No' ],
                options: {
                    isHidden: true,
                },
            }],
        }
    }

    examDraftClicked (examDraft: CMS.Class.ExamDraftJSON) {
        this.$router.push({
            name: 'exam-draft-edit',
            params: {
                examDraftId: examDraft.objectId,
            },
        })
    }

    createExamDraftClicked () {
        this.$router.push({
            name: 'exam-draft-create',
        })
    }

    async mounted () {
        this.isLoading = true
        await examDraftsModule.actions.fetchExamDrafts()
        this.isLoading = false
    }
}
</script>

<style lang="scss" scoped>

.exam-draft-list {
    flex-grow: 1;
    padding: $base;
    padding-bottom: 0;
    margin-top: 52px;

    &__title {
        display: flex;
        align-items: center;
        padding-left: 10px;
        margin-top: 46px;
        margin-bottom: 34px;
    }

    &__create-draft-btn {
        margin-left: auto;
        margin-right: 10px;
        margin-bottom: 4px;
    }
}
</style>