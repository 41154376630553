<template>
    <div class="filter-dropdown" ref="dropdown">
        <div class="filter-dropdown__filter-label">FILTER BY</div>
        <Radio
            :data="filterOptions"
            v-model="radioValue"
            @update:modelValue="filterChanged"
        >
        </Radio>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'

interface IItem {
    value: string
    label: string
    header?: string
}

@Component({
    components: {
        Radio: UIKit.Radio,
    },
})
export default class FilterDropdown extends Vue {
    @Prop() readonly filterOptions!: IItem[]
    @Prop() readonly selectedFilter!: { label: string; value: string }

    radioValue: { label: string; value: string } | null = null

    clickListener (e: MouseEvent) {
        const targetEl = e.target as HTMLElement
        const dropdownEl = this.$refs.dropdown as HTMLElement
        if (!dropdownEl?.contains(targetEl) && document.body.contains(targetEl) 
        && !targetEl?.closest('.question-sidebar__questions-preview-header')) {
            this.emitClose()
        }
    }

    keydownListener (e: KeyboardEvent) {
        if (e.code === 'Escape') {
            e.stopPropagation()
            this.emitClose()
        }
    }

    mounted () {
        this.radioValue = this.selectedFilter
        document.addEventListener('keydown', this.keydownListener)
        document.addEventListener('click', this.clickListener)
    }

    beforeUnmount () {
        document.removeEventListener('keydown', this.keydownListener)
        document.removeEventListener('click', this.clickListener)
    }

    @Emit('close')
    emitClose () {
        return true
    }

    @Emit('filterChanged')
    filterChanged () {
        return this.radioValue
    }
}

</script>

<style lang="scss" scoped>
.filter-dropdown {
    width: 240px;
    position: absolute;
    background-color: $white;
    z-index: 100;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 6px 22px 0 rgba($brand-black, 0.1);
    box-sizing: border-box;
    font-weight: 500;

    &__filter-label {
        font-size: 12px;
        color: $slate;
        line-height: 16px;
        letter-spacing: 1px;
        font-weight: 600;
        margin-bottom: 16px;
    }

    :deep(.uikit-radio__list) {
        padding-left: 6px;
    }
}
</style>
