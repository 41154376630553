<template>
    <div class="question-draft-preview">
        <Teleport to="#app">
            <ModalContainer
                key="questionDraftPreview"
                @close="closePreview"
            >
                <template #modal>
                    <div id="questionDraftPreview" class="question-draft-preview__modal-div">
                        <Modal class="question-draft-preview__modal">
                            <div class="question-draft-preview__modal-header">
                                <div class="question-draft-preview__modal-title">
                                    <span>Question Preview</span>
                                    This is how the question will look to students in our web tool.
                                    Order of answers and distractors will be randomized.
                                </div>
                                <div class="question-draft-preview__buttons">
                                    <PocketButton
                                        class="question-draft-preview__reset-question-btn"
                                        type="primary"
                                        @click="reviewMode = !reviewMode"
                                    >
                                        {{ reviewMode ? 'Reset Question' : 'Show Answer' }}
                                    </PocketButton>
                                    <PocketButton
                                        class="question-draft-preview__view-as-text-btn"
                                        type="primary"
                                        @click="openTextPreview()"
                                    >
                                        View As Text
                                    </PocketButton>
                                </div>
                                <Icon
                                    class="question-draft-preview__close-icon"
                                    type="close"
                                    title="Close"
                                    @click="closePreview"
                                />
                            </div>
                            <div
                                ref="question-draft-preview__text-mode"
                                class="question-draft-preview__text-mode"
                            >
                                <strong>Question</strong>
                                <span v-html="qDraftFormData.prompt" />
                                <template v-if="qDraftFormData.passage">
                                    <strong>Passage</strong>
                                    <span v-html="qDraftFormData.passage" />
                                </template>
                                <span
                                    v-for="(answer, index) in qDraftFormData.answers"
                                    :key="`answer-${index}`"
                                >
                                    <strong>Answer {{ index + 1 }}</strong>
                                    <span v-html="answer" />
                                </span>
                                <span
                                    v-for="(distractor, index) in qDraftFormData.distractors"
                                    :key="`distractor-${index}`"
                                >
                                    <strong>Distractor {{ index + 1 }}</strong>
                                    <span v-html="distractor" />
                                </span>
                                <strong>Explanation</strong>
                                <span v-html="qDraftFormData.explanation" />
                                <template v-if="qDraftFormData.references && qDraftFormData.references.length">
                                    <strong>References</strong>
                                    <span
                                        v-for="(reference) in qDraftFormData.references"
                                        :key="reference.id"
                                    >
                                        <span v-html="reference.text" />
                                    </span>
                                </template>
                            </div>
                            <div>
                                <Question
                                    class="question-draft-preview__question"
                                    :key="question.serial"
                                    :question="question"
                                    :show-check-answer="true"
                                    :container-el="questionDraftPreviewEl"
                                    :review-mode="reviewMode"
                                    :show-next-question="false"
                                    @checkAnswer="reviewMode = !reviewMode"
                                />
                            </div>
                        </Modal>
                    </div>
                </template>
            </ModalContainer>
        </Teleport>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Emit } from 'vue-facing-decorator'
import type QuestionDraftForm from './QuestionDraftForm'
import UIKit from '@pocketprep/ui-kit'

interface IReference {
    text: string
    id: number
}

@Component({
    components: {
        Question: UIKit.Question,
        ModalContainer: UIKit.ModalContainer,
        Modal: UIKit.Modal,
        PocketButton: UIKit.Button,
        Icon: UIKit.Icon,
    },
})
export default class QuestionDraftPreview extends Vue {
    reviewMode = false
    questionDraftPreviewEl: HTMLElement | null = null

    get qDraftFormData () {
        return this.$parent?.$data as QuestionDraftForm
    }

    get questionDraftChoices () {
        return [ ...this.qDraftFormData.answers, ...this.qDraftFormData.distractors ].map((choice, idex) => {
            const isCorrect = this.qDraftFormData.answers.includes(choice)
            return {
                text: choice,
                isCorrect: this.qDraftFormData.answers.includes(choice),
                id: `${isCorrect ? 'a' : 'd' }${idex + 1}`,
            }
        })
    }

    get explanationImage () {
        return this.qDraftFormData.explanationImageUrl ? 
            {
                url: this.qDraftFormData.explanationImageUrl || '',
                altText: this.qDraftFormData.explanationImageAltText || '',
                longAltText: this.qDraftFormData.explanationImageLongAltText || '',
            } : ''
    }

    get passageImage () {
        return this.qDraftFormData.passageImageUrl ?
            {
                url: this.qDraftFormData.passageImageUrl,
                altText: this.qDraftFormData.passageImageAltText || '',
                longAltText: this.qDraftFormData.passageImageLongAltText || '',
            } : ''
    }

    get question () {
        return {
            prompt: this.qDraftFormData.prompt,
            choices: this.questionDraftChoices,
            passage: this.qDraftFormData.passage,
            passageImage: this.passageImage,
            explanation:  this.qDraftFormData.explanation,
            explanationImage: this.explanationImage,
            references: this.formatReferencesArray(this.qDraftFormData.references),
            serial: this.qDraftFormData.serial || '',
            type: this.qDraftFormData.questionDraftType,
            isArchived: this.qDraftFormData.isArchived,
            isFree: this.qDraftFormData.isSpecial,
        }
    }

    mounted () {
        this.questionDraftPreviewEl = document.getElementById('questionDraftPreview') || null
    }

    formatReferencesArray (references: IReference[]) {
        return references.map(ref => ref.text)
    }

    openTextPreview () {
        const popup = window.open('', `q_${new Date().getTime()}`, 'width=600, height=500'),
            content = (this.$refs['question-draft-preview__text-mode'] as HTMLDivElement).innerHTML
        popup
            && popup.document.write(`<html>${ content }</html>`)
    }

    @Emit('close')
    closePreview () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.question-draft-preview {
    &__modal-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
        position: fixed;
        z-index: 200;
        margin: auto;
        top: 0;
        right: 0;
        bottom: 0;
        width: 1312px;
        height: 777px;
        left: 0;
        border-radius: 12px;
    }

    &__modal {
        background-color: $gray-background;
        height: 100%;
        width: 100%;
        overflow: scroll;

        :deep(.uikit-modal__close) {
            font-size: 14px;
        }
    }

    &__modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $white;
        border-bottom: 1px solid $fog;
        height: 54px;
        width: 100%;
    }

    &__modal-title {
        color: $slate;
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;

        span {
            color: $brand-black;
            font-weight: 600;
            padding-left: 20px;
            margin-right: 14px;
        }
    }

    &__text-mode {
        display: none;
    }

    &__buttons {
        position: relative;
        margin-left: 50px;
    }

    &__view-as-text-btn,
    &__reset-question-btn {
        height: 32px;
        padding: 7px 12px 6px 12px;
        margin-left: 12px;

        :deep(.uikit-btn__content) {
            font-size: 14px;
        }
    }

    &__close-icon {
        color: $brand-blue;
        width: 34px;
        height: 34px;
        margin-right: 10px;
        cursor: pointer;
    }

    &__question {
        :deep(.uikit-question-context) {
            display: none;
        }

        :deep(.uikit-question__main) {
            overflow: hidden;
        }

        :deep(.uikit-question__main),
        :deep(.uikit-question-passage-and-image) {
            padding-top: 70px;
        }

        :deep(.uikit-question__right-side--explanation) {
            padding-top: 27px;
        }

        :deep(.uikit-question-explanation),
        :deep(.uikit-question-explanation--tablet-landscape) {
            max-width: 566px;
            height: 100%;
            min-height: 695px;
        }

        :deep(.uikit-question__right-side),
        :deep(.uikit-question__right-side--tablet-landscape) {
            width: 46%;
        }
    }
}
</style>

