<template>
    <div
        class="user-form"
    >
        <FormValidation :messages="validationMessages" />
        <Loading v-if="isLoading" />
        <div
            v-if="pageHeader === 'Edit User'"
            class="user-form__edit-user"
        >
            {{ isUserDisabled ? 'Disabled' : `${firstName} ${lastName}` }}
        </div>
        <TitleText 
            class="user-form__title-text"
            :class="{'user-form__title-text--edit-user': pageHeader === 'Edit User'}"
        >
            {{ isUserDisabled ? `${firstName} ${lastName}` : `${pageHeader}` }}
        </TitleText>
        <FormSection>
            <FormGroup label="First Name*">
                <TextField v-model="firstName" />
            </FormGroup>
            <FormGroup label="Last Name*">
                <TextField v-model="lastName" />
            </FormGroup>
            <FormGroup label="Email*">
                <TextField
                    v-model="email"
                    field-type="email"
                />
            </FormGroup>
            <FormGroup label="Phone Number">
                <TextField
                    v-model="phone"
                />
            </FormGroup>
            <FormGroup v-if="role === 'Admin'" label="Subscribe to Admin Emails?">
                <SelectField
                    v-model="subscribedAdminEmails"
                    :data="['Yes', 'No']"
                />
            </FormGroup>
            <FormGroup label="Role*">
                <SelectField
                    v-model="role"
                    :data="roles"
                    :disabled="isCurrentUser"
                />
            </FormGroup>
        </FormSection>
        <FormSection v-if="$route.name !== 'user-create'">
            <FormGroup label="New Password">
                <TextField
                    v-model="password"
                    field-type="password"
                />
            </FormGroup>
            <FormGroup label="Re-enter Password">
                <TextField
                    v-model="password2"
                    field-type="password"
                />
            </FormGroup>
        </FormSection>
        <div
            v-if="error"
            class="user-form__error"
        >
            {{ error }}
        </div>
        <ButtonFooter v-if="currentUser">
            <template
                v-if="pageHeader === 'Edit User'
                    && currentUser.role === 'Admin'
                    && userObjectId !== currentUser.objectId
                    && !isUserDisabled
                "
                #leftSide
            >
                <div class="user-form__left-side-buttons">
                    <PocketButton
                        type="primary-red"
                        @click="disableUserClicked"
                    >
                        Disable User
                    </PocketButton>
                </div>
            </template>
            <template
                #rightSide 
            >
                <div class="user-form__right-side-buttons">
                    <PocketButton
                        type="secondary"
                        class="user-form__close-button"
                        @click="cancelClicked"
                    >
                        {{ isUserDisabled ? 'Close' : 'Cancel' }}
                    </PocketButton>
                    <PocketButton
                        v-if="!isUserDisabled"
                        type="primary"
                        @click="submitUserForm"
                    >
                        {{ pageHeader === 'Add User' ? 'Add User' : 'Save Changes' }}
                    </PocketButton>
                    <PocketButton
                        v-if="isUserDisabled && currentUser.role === 'Admin' "
                        type="primary"
                        @click="enableUserClicked"
                    >
                        Enable User
                    </PocketButton>
                </div>
            </template>
        </ButtonFooter>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import Loading from '@/components/Loading.vue'
import { FormValidation, FormGroup, FormSection, TextField, SelectField } from '@/components/Forms/'
import { activitiesModule } from '@/store/activities/module'
import type { IUser, TRole } from '@/store/users/types'
import { usersModule } from '@/store/users/module'
import TitleText from '@/components/TitleText.vue'
import ButtonFooter from '@/components/ButtonFooter.vue'
import UIKit from '@pocketprep/ui-kit'

@Component({
    components: {
        FormGroup,
        FormValidation,
        FormSection,
        TextField,
        SelectField,
        Loading,
        TitleText,
        ButtonFooter,
        PocketButton: UIKit.Button,
    },
})
export default class UserForm extends Vue {
    roles = [ 'Writer', 'Editor', 'Admin' ]
    firstName = ''
    lastName = ''
    email = ''
    phone = ''
    subscribedAdminEmails = 'No'
    role: TRole = 'Writer'
    error = ''
    password = ''
    password2 = ''
    isLoading = false
    isCurrentUser = false
    validationMessages: string[] = []
    pageHeader = ''
    userObjectId = ''
    isUserDisabled = false
    editUser: IUser | null = null

    get currentUser () {
        return usersModule.getters.getCurrentUser()
    }

    get users () {
        return usersModule.state.users
    }

    mounted () {
        if (this.$route.name !== 'user-create') {
            this.isLoading = true
            const userId = typeof this.$route.params.userId === 'string'
                ? this.$route.params.userId
                : this.currentUser?.objectId
            const userFromId = this.users.find((user) => user.objectId === userId)
            if (userId && userFromId) {
                this.firstName = userFromId.firstName
                this.lastName = userFromId.lastName
                this.email = userFromId.username
                this.phone = userFromId.phone || ''
                this.role = userFromId.role
                this.subscribedAdminEmails = userFromId.subscribedAdminEmails ? 'Yes' : 'No'
                this.userObjectId = userId
                this.isUserDisabled = userFromId.isDisabled ? true : false
                this.editUser = userFromId
            }
            if (userId === this.currentUser?.objectId) {
                this.isCurrentUser = true
            }
            this.pageHeader = this.$route.name === 'settings' ? 'Account Settings' : 'Edit User'
            this.isLoading = false
        }

        if (this.$route.name === 'user-create') {
            this.isLoading = true
            this.pageHeader = 'Add User'
            this.isLoading = false
        }

        this.isLoading = false
    }

    async submitUserForm () {
        this.isLoading = true
        this.validationMessages = []
        if (
            !this.firstName ||
            !this.lastName ||
            !this.email ||
            !this.role
        ) {
            this.validationMessages.push('error/requiredField')
        }
        if (this.password !== this.password2) {
            this.validationMessages.push('error/Passwords do not match')
        }

        if (!this.validationMessages.length) {
            const userData: {
                username: string
                firstName: string
                lastName: string
                role: TRole
                phone: string
                password?: string
                subscribedAdminEmails: boolean
            } = {
                username: this.email,
                firstName: this.firstName,
                lastName: this.lastName,
                role: this.role,
                phone: this.phone,
                subscribedAdminEmails: this.subscribedAdminEmails === 'Yes' ? true : false,
            }

            if (this.password !== '') {
                userData.password = this.password
            }

            // Create or Update based on whether the user already exists (i.e. has an id)
            let userId = this.$route.name === 'settings'
                ? this.currentUser?.objectId
                : typeof this.$route.params.userId === 'string'
                    ? this.$route.params.userId
                    : null
            if (userId) {
                await usersModule.actions.updateUser({
                    ...userData,
                    objectId: userId,
                })

                await activitiesModule.actions.createActivity({
                    action: 'update',
                    subject: {
                        type: 'Pointer',
                        value: userId,
                        name: this.firstName + ' ' + this.lastName,
                    },
                    type: 'user',
                })

                this.validationMessages.push('success/Account successfully updated')
            } else {
                const newUser = await usersModule.actions.createUser({
                    ...userData,
                    password: [ ...Array(12) ].map(() => Math.random().toString(36)[2]).join(''),
                })
                userId = newUser.objectId

                await activitiesModule.actions.createActivity({
                    action: 'create',
                    subject: {
                        type: 'Pointer',
                        value: userId,
                        name: this.firstName + ' ' + this.lastName,
                    },
                    type: 'user',
                })
            }

            if (this.currentUser?.role === 'Admin') {
                this.$router.push({
                    name: 'user-view',
                    params: {
                        userId,
                    },
                })
            }
        }

        this.isLoading = false
    }

    cancelClicked () {
        if (this.$route.name === 'settings') {
            this.$router.push({
                name: 'job-list',
            })   
        } else {
            this.$router.push({
                name: 'user-list',
            })
        }
    }

    async disableUserClicked () {
        if (this.editUser) {
            this.isLoading = true
            await usersModule.actions.disableUser(this.editUser.objectId)
            this.isLoading = false
            this.$router.push({ name: 'user-list' })
        }
    }

    async enableUserClicked () {
        if (this.editUser) {
            this.isLoading = true
            await usersModule.actions.enableUser(this.editUser.objectId)
            this.isLoading = false
            this.$router.push({ name: 'user-list' })
        }
    }

}
</script>

<style lang="scss" scoped>

.user-form {
    padding: $base;
    flex: 1;
    margin-top: 52px;

    &__error {
        color: red;
    }

    &__edit-user {
        margin-top: 22px;
        font-size: 16px;
        color: $slate;
        font-weight: 500;
    }

    &__title-text {
        margin-top: 52px;
        margin-bottom: 34px;
        font-size: 40px;

        &--edit-user {
            margin-top: 16px;
        }
    }

    &__left-side-buttons {
        margin-left: 18px;
    }

    &__right-side-buttons {
        position: absolute;
        right: 0;
        margin-right: 18px;
    }

    &__close-button {
        margin-right: 8px;
    }
}
</style>