<template>
    <textarea
        :type="fieldType"
        :value="modelValue"
        :class="
            `textarea-input
            ${center?' textarea-centered':''}
            ${size?' textarea-' + size:''}`"
        :disabled="disabled"
        :style="height && { height: height + 'px' }"
        @input="valueChange"
        @keydown="keydownEvent"
        @keyup="keyupEvent"
    />
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from 'vue-facing-decorator'

@Component
export default class TextareaField extends Vue {
    @Prop({ default: '' }) readonly fieldType?: string
    @Prop({ default: false }) readonly center?: boolean
    @Prop({ default: '' }) readonly size?: string
    @Prop() readonly height?: string
    @Prop({ default: '' }) readonly modelValue!: string
    @Prop({ default: false }) readonly disabled?: boolean

    @Emit('update:modelValue')
    valueChange ($event: Event) {
        return ($event.target as HTMLInputElement).value
    }

    @Emit('keyup')
    keyupEvent ($event: KeyboardEvent) {
        return $event
    }

    @Emit('keydown')
    keydownEvent ($event: KeyboardEvent) {
        return $event
    }
}
</script>

<style lang="scss" scoped>

.textarea-input {
    @include inputStyles;

    height: 120px;

    &.textarea-centered {
        text-align: center;
    }

    &.textarea-large {
        max-width: 500px;
    }
}
</style>
