<template>
    <div class="user">
        <Loading v-if="isLoading" />
        <div v-if="user" class="user__id">
            <div class="user__user-id-label">
                User ID:
            </div>
            <div class="user__user-object-id">
                {{ user.objectId }}
            </div>
        </div>
        <TitleText
            v-if="user"
            class="user__title-text"
        >
            {{ user.firstName }} {{ user.lastName }}
        </TitleText>
        <div v-if="user" class="user__info">
            <div class="user__info-item">
                <label>First Name</label>
                <span>
                    {{ user.firstName }}
                </span>
            </div>
            <div class="user__info-item">
                <label>Last Name</label>
                <span>
                    {{ user.lastName }}
                </span>
            </div>
            <div class="user__info-item">
                <label>Email</label>
                <span>
                    {{ user.username }}
                </span>
            </div>
            <div class="user__info-item">
                <label>Phone Number</label>
                <span>
                    {{ user.phone }}
                </span>
            </div>
            <div class="user__info-item">
                <label>Role</label>
                <span>
                    {{ user.role }}
                </span>
            </div>
        </div>
        <ActivityList
            v-if="user"
            :activity-query="{ 
                userId: user.objectId
            }"
        />
        <ButtonFooter v-if="user" class="user__button-footer">
            <template
                #rightSide 
            >
                <div class="user__right-side-buttons">
                    <PocketButton
                        class="user__close-button"
                        type="secondary"
                        @click="closeClicked"
                    >
                        Close
                    </PocketButton>
                    <PocketButton
                        type="primary"
                        @click="editUserClicked"
                    >
                        Edit User
                    </PocketButton>
                </div>
            </template>
        </ButtonFooter>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import ActivityList from '@/components/ActivityList.vue'
import Loading from '@/components/Loading.vue'
import type { IUser } from '@/store/users/types'
import { usersModule } from '@/store/users/module'
import ButtonFooter from '@/components/ButtonFooter.vue'
import TitleText from '@/components/TitleText.vue'
import UIKit from '@pocketprep/ui-kit'

@Component({
    components: {
        Loading,
        ActivityList,
        ButtonFooter,
        TitleText,
        PocketButton: UIKit.Button,
    },
})
export default class User extends Vue {
    user: IUser | null = null
    isLoading = false

    get users () {
        return usersModule.state.users
    }

    created () {
        this.isLoading = true
        const userId = this.$route.params.userId
        const currentUser = this.users.find((user) => user.objectId === userId)
        if (currentUser) {
            this.user = currentUser
        }
        this.isLoading = false
    }

    closeClicked () {
        this.$router.push({
            name: 'user-list',
        })
    }

    editUserClicked () {
        if (this.user && this.user.objectId) {
            this.$router.push({
                name: 'user-edit',
                params: {
                    userId: this.user.objectId,
                },
            })
        }
    }
}
</script>

<style lang="scss" scoped>

.user {
    padding: $base;
    margin-top: 52px;

    &__button-controls {
        position: fixed;
        z-index: 100;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 57px;
    }

    &__id {
        margin-bottom: 13px;
        margin-top: 22px;
        display: flex;
        color: $slate;
        font-size: 16px;
    }

    &__user-id-label {
        margin-right: 10px;
    }

    &__title-text {
        margin-top: 16px;
        margin-bottom: 34px;
        font-size: 40px;
    }

    &__info {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid $cloudy;
        border-radius: 4px;
        padding: 10px;
        margin-bottom: $base;
        background-color: white;
    }

    &__info-item {
        display: flex;
        flex-direction: column;
        padding: 10px;
        flex: 1;
        min-width: 225px;

        @include breakpoint(desktop) {
            min-width: 300px;
            max-width: 300px;
        }

        label {
            color: #666;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 5px;
        }

        span {
            word-break: break-all;
        }
    }

    &__right-side-buttons {
        position: absolute;
        right: 0;
        margin-right: 18px;
    }

    &__close-button {
        margin-right: 8px;
    }
}
</style>
