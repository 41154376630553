import navigationModule from '@/store/navigation/module'

export const updateMenuVisible = (menuVisible: boolean) => {
    navigationModule.state.menuVisible = menuVisible
    localStorage.setItem('menuVisible', String(menuVisible))
}

export default {
    updateMenuVisible,
}
