<template>
    <input
        class="number-input"
        type="number"
        :value="modelValue"
        :min="min"
        :max="max"
        @input="valueChange"
    >
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from 'vue-facing-decorator'

@Component
export default class NumberField extends Vue {
    @Prop() readonly modelValue!: number // Using 'modelValue' allows parent to use v-model
    @Prop() readonly min!: number
    @Prop() readonly max!: number

    // Using 'update:modelValue' allows parent to use v-model
    @Emit('update:modelValue')
    valueChange ($event: Event) {
        const target = $event.target as HTMLInputElement
        return target.value ? Number(target.value) : 0
    }
}
</script>

<style lang="scss" scoped>

.number-input {
    @include inputStyles;

    height: 38px;
}
</style>
