<template>
    <!-- eslint-disable -->
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3209_124824)">
        <path d="M19.25 3.5C20.6997 3.5 21.875 4.67525 21.875 6.125V16.1875C21.875 18.3621 20.1121 20.125 17.9375 20.125H7C5.55025 20.125 4.375 18.9497 4.375 17.5V16.625H6.125V17.5C6.125 17.9487 6.46279 18.3186 6.89796 18.3691L7 18.375H17.9375C19.0973 18.375 20.0463 17.4724 20.1203 16.3313L20.125 16.1875V6.125C20.125 5.67627 19.7872 5.30643 19.352 5.25589L19.25 5.25H18.375V3.5H19.25Z" fill="#DFE3E7" fill-opacity="0.6"/>
        <path d="M15.75 0.875C17.1997 0.875 18.375 2.05025 18.375 3.5V14C18.375 15.4497 17.1997 16.625 15.75 16.625H3.5C2.05025 16.625 0.875 15.4497 0.875 14V3.5C0.875 2.05025 2.05025 0.875 3.5 0.875H15.75ZM15.75 2.625H3.5C3.05127 2.625 2.68143 2.96279 2.63089 3.39796L2.625 3.5V14C2.625 14.4487 2.96279 14.8186 3.39796 14.8691L3.5 14.875H15.75C16.1987 14.875 16.5686 14.5372 16.6191 14.102L16.625 14V3.5C16.625 3.05127 16.2872 2.68143 15.852 2.63089L15.75 2.625ZM9.5185 6.1425C9.93325 6.1425 10.2745 6.23044 10.5422 6.40631C10.81 6.58219 11.0213 6.80925 11.1762 7.0875C11.3311 7.36575 11.4387 7.6755 11.4991 8.01675C11.5594 8.358 11.5896 8.694 11.5896 9.02475C11.5896 9.3555 11.5594 9.6915 11.4991 10.0327C11.4387 10.374 11.3311 10.6838 11.1762 10.962C11.0213 11.2402 10.81 11.4673 10.5422 11.6432C10.2745 11.8191 9.93325 11.907 9.5185 11.907C9.10375 11.907 8.7625 11.8191 8.49475 11.6432C8.227 11.4673 8.01569 11.2402 7.86081 10.962C7.70594 10.6838 7.59831 10.374 7.53794 10.0327C7.47756 9.6915 7.44738 9.3555 7.44738 9.02475C7.44738 8.694 7.47756 8.358 7.53794 8.01675C7.59831 7.6755 7.70594 7.36575 7.86081 7.0875C8.01569 6.80925 8.227 6.58219 8.49475 6.40631C8.7625 6.23044 9.10375 6.1425 9.5185 6.1425ZM9.5185 7.22925C9.30325 7.22925 9.13656 7.29488 9.01844 7.42613C8.90031 7.55737 8.81238 7.7175 8.75463 7.9065C8.69688 8.0955 8.66144 8.29238 8.64831 8.49713C8.63519 8.70188 8.62862 8.87775 8.62862 9.02475C8.62862 9.17175 8.63519 9.34762 8.64831 9.55237C8.66144 9.75712 8.69688 9.954 8.75463 10.143C8.81238 10.332 8.90031 10.4921 9.01844 10.6234C9.13656 10.7546 9.30325 10.8203 9.5185 10.8203C9.7285 10.8203 9.89387 10.7546 10.0146 10.6234C10.1354 10.4921 10.2246 10.332 10.2824 10.143C10.3401 9.954 10.3756 9.75712 10.3887 9.55237C10.4018 9.34762 10.4084 9.17175 10.4084 9.02475C10.4084 8.87775 10.4018 8.70188 10.3887 8.49713C10.3756 8.29238 10.3401 8.0955 10.2824 7.9065C10.2246 7.7175 10.1354 7.55737 10.0146 7.42613C9.89387 7.29488 9.7285 7.22925 9.5185 7.22925Z" fill="#DFE3E7"/>
        </g>
        <defs>
            <clipPath id="clip0_3209_124824">
                <rect width="21.875" height="21" fill="white"/>
            </clipPath>
        </defs>
    </svg>
    <!-- eslint-enable -->
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'

@Component
export default class ZeroQuestionsIcon extends Vue {

}
</script>