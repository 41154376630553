import type {
    TGetJob,
} from './types'
import jobsModule from './module'

const getJob = (jobId: Parameters<TGetJob>[0]): ReturnType<TGetJob> => {
    return jobsModule.state.jobs.find(job => job.objectId === jobId)
}

export default {
    getJob,
}
