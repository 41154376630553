<template>
    <div class="questions">
        <div v-if="error" class="questions__error">
            {{ error }}
        </div>
        <div
            class="questions__title"
        >
            <TitleText>
                Questions
            </TitleText>
        </div>
        <List
            store-name="questionsList"
            :list-options="listOptions"
            :show-last-page-btn="true"
            :force-show-controls="true"
            :show-case-sensitive-toggle="true"
            list-unit="Question"
            @itemClicked="questionClicked"
        >
            <template #filterTypeDropdownListItem="{ item }">
                <template v-if="item.label === 'Serial'">
                    <div class="questions__filter-type-dropdown-item-container">
                        <div class="questions__filter-type-dropdown-section-title">
                            TABLE COLUMN
                        </div>
                        <div class="questions__filter-type-dropdown-item">
                            {{ item.label }}
                        </div>
                    </div>
                </template>
                <template v-if="item.label === 'Bloom\'s'">
                    <div class="questions__filter-type-dropdown-item-container">
                        <div class="questions__filter-type-dropdown-section-title">
                            QUESTION FIELDS
                        </div>
                        <div class="questions__filter-type-dropdown-item">
                            {{ item.label }}
                        </div>
                    </div>
                </template>
                <template v-if="item.label === 'Free question'">
                    <div class="questions__filter-type-dropdown-item-container">
                        <div class="questions__filter-type-dropdown-section-title">
                            T/F FILTERS
                        </div>
                        <div class="questions__filter-type-dropdown-item">
                            {{ item.label }}
                        </div>
                    </div>
                </template>
            </template>
            <template #tableColumnLabelText="{ column }">
                <template v-if="column.propName === 'type'">Type</template>
                <template v-else-if="column.propName === 'prompt'">Question Prompt</template>
                <template v-else-if="column.propName === 'compositeKey'">Composite Key</template>
            </template>
        </List>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { List } from '@/components/Lists'
import type { IListOptions, IFilterSortParams, IFilterSortReturn, IProcessedTextField } from '@/components/Lists'
import type { Study } from '@pocketprep/types'
import type { TSearchQParams } from '@/store/questions/types'
import questionsModule from '@/store/questions/module'
import TitleText from '@/components/TitleText.vue'
import UIKit from '@pocketprep/ui-kit'
import { bloomTaxonomyLevels, formatDate } from '@/utils'
import examsModule from '@/store/exams/module'

interface IMappedQuestion {
    objectId: string
    serial: string
    compositeKey: string
    subject: string
    type: 'MCQ' | 'MCR' | 'TF'
    prompt: string      // Mapped from "question"
    explanation: string
    passage: string
    references: string
    isFree: 'Yes' | 'No'
    isArchived: 'Yes' | 'No'
    explanationImage: 'Yes' | 'No'
    passageImage: 'Yes' | 'No'
    isMockQuestion: 'Yes' | 'No'
    addedDate: string
    updatedAt: string
    subtopic: string
    bloomTaxonomyLevel: Study.Class.BloomTaxonomyLevel
    bank: string
    exam: string
}

type TEqualToFilter = IProcessedTextField<IMappedQuestion>

@Component({
    components: {
        List,
        TitleText,
        PocketButton: UIKit.Button,
        ToggleSwitch: UIKit.ToggleSwitch,
    },
})
export default class QuestionList extends Vue {
    exams: Study.Class.ExamMetadataJSON[] = []
    error = ''

    async mounted () {
        this.exams = await examsModule.actions.fetchExams()
    }

    createContainsFilter (
        filterSortParams: IFilterSortParams<IMappedQuestion>
    ): NonNullable<TSearchQParams['contains']> {
        return filterSortParams.filters
            .reduce((acc: NonNullable<TSearchQParams['contains']>, { type, filter, propName }) => {
                const accUpdate = acc
                if (filter && type === 'text' && typeof filter.state === 'string' && filter.state && propName) {
                    // Need to set only valid IParseQuestion props
                    if (
                        propName !== 'isFree'
                        && propName !== 'isArchived'
                        && propName !== 'explanationImage'
                        && propName !== 'passageImage'
                        && propName !== 'isMockQuestion'
                        && propName !== 'subtopic'
                        && propName !== 'bank'
                        && propName !== 'exam'
                    ) {
                        accUpdate[propName] = filter.state
                    }
                }
                return accUpdate
            }, {})
    }

    createOrderByProp (
        filterSortParams: IFilterSortParams<IMappedQuestion>
    ): TSearchQParams['orderBy'] {
        const sortBy = filterSortParams.sort.by
        if (sortBy === 'subtopic') {
            return 'subtopicId'
        } else if (sortBy === 'exam') {
            return 'compositeKey'
        } else if (sortBy === 'bank') {
            return
        } else {
            return sortBy
        }
    }

    createEqualToFilter (
        filterSortParams: IFilterSortParams<IMappedQuestion>
    ) {
        return filterSortParams.filters
            .filter((filter): filter is TEqualToFilter => 'data' in filter)
            .reduce((acc: NonNullable<TSearchQParams['equalTo']>, { type, filter, propName, data }) => {
                const accUpdate = acc
                if (
                    filter &&
                    type === 'text' &&
                    typeof filter.state === 'string' &&
                    filter.state &&
                    propName &&
                    data &&
                    data.length
                ) {
                    if (propName === 'type') {
                        accUpdate[propName] = filter.state
                    } else if (
                        propName === 'isMockQuestion'
                        || propName === 'isArchived'
                        || propName === 'isFree'
                    ) {
                        accUpdate[propName] = filter.state === 'Yes' ? true : false
                    }
                }
                return accUpdate
            }, {})
    }

    createExistsFilter (
        filterSortParams: IFilterSortParams<IMappedQuestion>
    ): NonNullable<TSearchQParams['exists']> {
        return filterSortParams.filters
            .reduce((acc: NonNullable<TSearchQParams['exists']>, { filter, propName }) => {
                if (
                    filter 
                    && filter.state
                    && (
                        propName === 'explanationImage' 
                        || propName === 'passageImage'
                    )
                ) {
                    acc.push(propName)
                }
                return acc
            }, [])
    }

    async getMappedQuestions (
        filterSortParams: IFilterSortParams<IMappedQuestion>
    ): Promise<IFilterSortReturn<IMappedQuestion>> {
        const searchParams: TSearchQParams = {
            perPage: filterSortParams.pagination.perPage,
            page: filterSortParams.pagination.page,
            orderBy: this.createOrderByProp(filterSortParams),
            order: filterSortParams.sort.direction === 'DESC' ? 'descending' : 'ascending',
            contains: this.createContainsFilter(filterSortParams),
            equalTo: this.createEqualToFilter(filterSortParams),
            searchAll: {
                keys: [
                    'subject',
                    'type',
                    'prompt',
                    'isArchived',
                    'references',
                    'passage',
                    'explanation',
                    'isMockQuestion',
                ],
                value: filterSortParams.searchAll,
            },
            caseSensitive: filterSortParams.caseSensitive,
            exists: this.createExistsFilter(filterSortParams),
        }

        const questionDraftsResponse = await questionsModule.actions.fetchQuestions(searchParams)

        if (!questionDraftsResponse.results) {
            this.error = 'Unable to fetch questions'
            return {
                results: [],
                totalCount: 0,
            }
        }

        const mappedQuestions: IFilterSortReturn<IMappedQuestion> = {
            results: questionDraftsResponse.results
                .map(({
                    objectId,
                    serial,
                    prompt,
                    subject,
                    type,
                    isArchived,
                    compositeKey,
                    isFree,
                    explanation,
                    references,
                    passage,
                    passageImage,
                    explanationImage,
                    isMockQuestion,
                    addedDate,
                    updatedAt,
                    bloomTaxonomyLevel,
                    subtopicId,
                    examMetadata,
                }): IMappedQuestion => {
                    if (!serial) {
                        throw new Error(`Question ${objectId} has no serial`)
                    }
                    const subjectJSON = subject as Study.Class.SubjectJSON
                    const subtopicObj = subtopicId && subjectJSON?.subtopics?.find(sub => sub.id === subtopicId)

                    return {
                        objectId,
                        serial,
                        exam: examsModule.getters.getExamName(examMetadata.objectId) || '',
                        subject: subjectJSON.name,
                        explanation: explanation || '',
                        references: references.join(', ') || '',
                        passage: passage || '',
                        type: type === 'Multiple Choice' ? 'MCQ'
                            : type === 'Multiple Correct Response' ? 'MCR' : 'TF',
                        prompt,
                        compositeKey,
                        isArchived: isArchived ? 'Yes' : 'No',
                        isFree: isFree ? 'Yes' : 'No',
                        explanationImage: explanationImage ? 'Yes' : 'No',
                        passageImage: passageImage ? 'Yes' : 'No',
                        isMockQuestion: isMockQuestion ? 'Yes' : 'No',
                        addedDate: 'iso' in addedDate ? formatDate(addedDate.iso) : formatDate(addedDate),
                        updatedAt: formatDate(updatedAt),
                        subtopic: subtopicObj ? subtopicObj.name : '',
                        bloomTaxonomyLevel: bloomTaxonomyLevel || 'None',
                        bank: isMockQuestion ? 'ME' : 'SBQ',
                    }
                }),
            totalCount: questionDraftsResponse.totalCount,
        }
        return mappedQuestions
    }

    get listOptions (): IListOptions<IMappedQuestion> {
        return {
            listData: this.getMappedQuestions,
            listSchema: [
                {
                    propName: 'serial',
                    label: 'Serial',
                    type: 'text',
                    options: {
                        width: 120,
                        group: 0,
                    },
                },
                {
                    propName: 'exam',
                    label: 'Exam',
                    type: 'text',
                    options: {
                        width: 128,
                        group: 1,
                        sort: false,
                        filter: false,
                    },
                },
                {
                    propName: 'subject',
                    label: 'Subject',
                    type: 'text',
                    options: {
                        width: 230,
                        group: 0,
                        sort: false,
                    },
                },
                {
                    propName: 'subtopic',
                    label: 'Subtopic',
                    type: 'text',
                    options: {
                        width: 124,
                        group: 0,
                        sort: false,
                        filter: false,
                    },
                },
                {
                    propName: 'type',
                    label: 'Question type',
                    type: 'text',
                    options: {
                        width: 60,
                        group: 0,
                    },
                    data: [ 'Multiple Choice', 'Multiple Correct Response', 'True/False' ],
                },
                {
                    propName: 'prompt',
                    label: 'Question prompt',
                    type: 'text',
                    options: {
                        style: 'overflow-ellipsis',
                        group: 1,
                        width: 365,
                    },
                },
                {
                    propName: 'bank',
                    label: 'Bank',
                    type: 'text',
                    options: {
                        width: 61,
                        filter: false,
                        group: 0,
                        sort: false,
                    },
                },
                {
                    propName: 'compositeKey',
                    label: 'Composite key',
                    type: 'text',
                    options: {
                        width: 140,
                        group: 0,
                    },
                },
                {
                    propName: 'bloomTaxonomyLevel',
                    label: 'Bloom\'s',
                    type: 'text',
                    data: bloomTaxonomyLevels,
                    options: {
                        isHidden: true,
                        group: 0,
                    },
                },
                {
                    propName: 'passage',
                    label: 'Passage',
                    type: 'text',
                    options: {
                        isHidden: true,
                    },
                },
                {
                    propName: 'explanation',
                    label: 'Explanation',
                    type: 'text',
                    options: {
                        isHidden: true,
                    },
                },
                {
                    propName: 'references',
                    label: 'References',
                    type: 'text',
                    options: {
                        isHidden: true,
                    },
                },
                {
                    propName: 'isFree',
                    label: 'Free question',
                    type: 'text',
                    options: {
                        isHidden: true,
                    },
                    prePopulateSelectValue: 'Yes',
                    data: [ 'Yes', 'No' ],
                },
                {
                    propName: 'isMockQuestion',
                    label: 'Mock exam question',
                    type: 'text',
                    options: {
                        isHidden: true,
                    },
                    prePopulateSelectValue: 'Yes',
                    data: [ 'Yes', 'No' ],
                },
                {
                    propName: 'isArchived',
                    label: 'Archived question',
                    type: 'text',
                    options: {
                        filter: 'No',
                        isHidden: true,
                        defaultValue: 'No',
                    },
                    prePopulateSelectValue: 'Yes',
                    doNotDisplayPillValue: 'No',
                    data: [ 'Yes', 'No' ],
                },
                {
                    propName: 'passageImage',
                    label: 'Has question image',
                    type: 'text',
                    options: {
                        isHidden: true,
                    },
                    data: [ 'Yes' ],
                    prePopulateSelectValue: 'Yes',
                },
                {
                    propName: 'explanationImage',
                    label: 'Has explanation image',
                    type: 'text',
                    options: {
                        isHidden: true,
                    },
                    data: [ 'Yes' ],
                    prePopulateSelectValue: 'Yes',
                },
                {
                    propName: 'addedDate',
                    label: 'Date Added',
                    type: 'date',
                    options: {
                        isHidden: true,
                        filter: false,
                    },
                },
                {
                    propName: 'updatedAt',
                    label: 'Last Updated',
                    type: 'date',
                    options: {
                        isHidden: true,
                        filter: false,
                    },
                },
            ],
            defaultSort: {
                propName: 'compositeKey',
                sortDir: 'ASC',
            },
            listDataModifiers: [
                data => data.isArchived === 'Yes' && { opacity: '0.5' },
            ],
            listDataIcons: [
                data => data.isFree === 'Yes' && {
                    iconName: 'gift',
                    label: 'Special',
                    styles: {
                        color: 'white',
                        backgroundColor: 'darkgreen',
                        fontSize: '15px',
                    },
                },
                data => data.isMockQuestion === 'Yes' && {
                    iconName: 'fileAlt',
                    label: 'Mock Question',
                    styles: {
                        color: '#fff',
                        backgroundColor: '#609b03',
                        fontSize: '15px',
                    },
                },
            ],
        }
    }

    questionClicked (question: IMappedQuestion) {
        this.$router.push({
            name: 'question-view',
            params: {
                questionId: question.objectId,
            },
        })
    }
}
</script>

<style lang="scss" scoped>

.questions {
    padding: $base;
    padding-bottom: 0;
    flex-grow: 1;
    margin-top: 52px;

    &__title {
        display: flex;
        align-items: center;
        padding-left: 10px;
        margin-top: 46px;
        margin-bottom: 14px;
    }

    &__error {
        margin: $base 0;
        color: red;
    }

    &__filter-type-dropdown-item-container {
        position: relative;
    }

    :deep(.uikit-select__item[data-value="Serial"]),
    :deep(.uikit-select__item[data-value="Bloom's"]),
    :deep(.uikit-select__item[data-value="Free question"]) {
        margin-top: 31px;
    }

    &__filter-type-dropdown-section-title {
        position: absolute;
        top: -24px;
        color: $slate;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
    }
}
</style>