import type { ICommentsModuleState } from './types'

export const getInitialCommentsState = (): ICommentsModuleState => {
    return {
        comments: [],
    }
}

const moduleState = getInitialCommentsState()

export default moduleState
