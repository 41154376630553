import type { ISearchParams } from '@/store/types'
import type {
    TFetchQuestionsByExam,
    TFetchQuestionsByExamAndKA,
    TFetchQuestion,
    TFetchQuestions,
    TFetchGlobalQuestionMetrics,
} from './types'
import { Parse, objPointer } from '@/store/ParseUtils'
import type { CMS, Study } from '@pocketprep/types'
import examsModule from '@/store/exams/module'

/**
 * Retrieve questions from app server by examMetadataID and knowledge area name
 *
 * @param payload the examMetadataId to retrieve questions for
 *
 * @return { Promise<IQuestion[]> } resolves with IParseQuestion[] when query completes
 */
const fetchQuestionsByExamAndKA = async (
    { examMetadataId, subjectId }: Parameters<TFetchQuestionsByExamAndKA>[0]
): ReturnType<TFetchQuestionsByExamAndKA> => {
    const exam = examsModule.getters.getExam(examMetadataId)
        || await examsModule.actions.fetchExam(examMetadataId)

    if (!exam) {
        throw new Error(`fetchQuestionsByExamAndKA: No exam with id ${examMetadataId}`)
    }

    return (await Parse.Cloud.run<CMS.Cloud.searchQuestionsV2>(
        'searchQuestions-v2',
        { equalTo: {
            compositeKey: exam.compositeKey,
            subject: objPointer(subjectId)('Subject'),
        } }
    )).results
}

/**
 * Retrieve questions from app server
 *
 * @param payload the examMetadataId to retrieve questions for
 *
 * @return { Promise<IQuestion[]> } resolves with IParseQuestion[] when query completes
 */
const fetchQuestionsByExam = async (
    { examMetadataId, searchConfig }: Parameters<TFetchQuestionsByExam>[0]
): ReturnType<TFetchQuestionsByExam> => {
    const exam = examsModule.getters.getExam(examMetadataId)
        || await examsModule.actions.fetchExam(examMetadataId)

    if (!exam) {
        throw new Error(`fetchQuestionsByExam: No exam with id ${examMetadataId}`)
    }

    const searchParams: ISearchParams<Study.Class.QuestionJSON> = {
        perPage: 10000,
        ...searchConfig,
        equalTo: { ...searchConfig?.equalTo, compositeKey: exam.compositeKey },
    }

    return (await Parse.Cloud.run<CMS.Cloud.searchQuestionsV2>(
        'searchQuestions-v2',
        searchParams
    )).results
}

/**
 * Retrieve a single Question (ExamData) from app server based on its objectId and return IQuestionDraft
 *
 * @param context
 * @param objectId the question's objectId
 *
 * @returns {IQuestionDraft}
 */
const fetchQuestion = async (objectId: Parameters<TFetchQuestion>[0]): ReturnType<TFetchQuestion> => {
    // search for question by object id
    const questionResults =
        (await Parse.Cloud.run<CMS.Cloud.searchQuestionsV2>(
            'searchQuestions-v2',
            { equalTo: { objectId } }
        )).results

    if (questionResults.length !== 1) {
        throw new Error('Question search error.')
    }

    return questionResults[0]
}

/**
 *
 * @param context
 * @param searchParams the search params to filter and sort the results
 *
 * @return { Promise<IQuestionDraft[]> }
 */
const fetchQuestions = async (searchParams: Parameters<TFetchQuestions>[0]): ReturnType<TFetchQuestions> => {
    const searchResults = await Parse.Cloud.run<CMS.Cloud.searchQuestionsV2>(
        'searchQuestions-v2',
        searchParams
    )

    return {
        totalCount: searchResults.totalCount,
        results: searchResults.results,
    }
}

/**
 * Fetch global question metrics by question serials
 *
 */
const fetchGlobalQuestionMetrics = async (
    serials: Parameters<TFetchGlobalQuestionMetrics>[0]
): ReturnType<TFetchGlobalQuestionMetrics> => {
    return Parse.Cloud.run<CMS.Cloud.fetchGlobalQuestionMetrics>(
        'fetchGlobalQuestionMetrics',
        { serials }
    )
}

export default {
    fetchQuestionsByExamAndKA,
    fetchQuestionsByExam,
    fetchQuestion,
    fetchQuestions,
    fetchGlobalQuestionMetrics,
}
