<template>
    <div class="typeahead-field">
        <label
            v-if="label"
            class="typeahead-field__label"
        >{{ label }}</label>
        <input
            ref="searchField"
            :value="search"
            type="text"
            :placeholder="placeholder"
            class="typeahead-field__text-input"
            @input="valueChange"
            @blur="showResults = false"
            @focus="showResults = true"
        >
        <div
            v-show="showResults"
            class="typeahead-field__results"
        >
            <div
                v-for="(result, index) in filteredData" 
                :key="index" 
                class="typeahead-field__result" 
                @mousedown="clickResult(result)"
            >
                {{ result.text }}
            </div>
            <div
                v-if="!filteredData.length"
                class="typeahead-field__result typeahead-field__noresults"
            >
                No matches found
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit, Watch } from 'vue-facing-decorator'

@Component
export default class TypeaheadField extends Vue {
    @Prop() data?: [ { objectId?: string; text: string } ]
    @Prop() modelValue!: string | undefined
    @Prop() placeholder?: string
    @Prop() autoClear?: boolean
    @Prop() label?: string

    showResults = false
    filterTimeout: ReturnType<typeof setTimeout> | undefined = undefined
    search = ''
    
    get filteredData () {
        if (!this.data) {
            return []
        }

        return this.data.filter(
            result => 
                result.text.toLowerCase()
                    .includes(this.search.toLowerCase())
        )
    }

    mounted () {
        this.search = this.modelValue || ''
    }

    clickResult (result: { objectId?: string; text: string }) {
        this.search = result.text
        this.emitInput()
        this.emitResult(result)
        this.showResults = false

        if (this.autoClear) {
            this.search = ''
        }
    }
    
    valueChange ($event: Event) {
        if (this.filterTimeout) {
            clearTimeout(this.filterTimeout)
        }

        this.filterTimeout = setTimeout(() => {
            this.search = ($event.target as HTMLInputElement).value
            this.emitInput()
            this.emitResult()
        }, 100)
    }

    @Watch('modelValue')
    onPropValueChange (newVal: string) {
        this.search = newVal
    }

    @Emit('update:modelValue')
    emitInput () {
        return this.search
    }

    @Emit('change')
    emitResult (clickedResult?: { objectId?: string; text: string }) {
        const result = clickedResult ||
            (this.filteredData.length === 1 
            && this.filteredData[0].text.toLowerCase() === this.search.toLowerCase()
                ? this.filteredData[0]
                : { text: this.search })

        return result
    }
}
</script>

<style lang="scss" scoped>

.typeahead-field {
    position: relative;

    &__label {
        font-size: 13px;
        line-height: 14px;
        margin: 0 0 6px 12px;
        color: $slate-01;
        display: block;
    }

    &__text-input {
        @include inputStyles;

        border: 1px solid rgba(136, 163, 182, 0.85);
    }

    &__results {
        width: 100%;
        max-width: 300px;
        z-index: 2;
        position: absolute;
        background: #fff;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        border-radius: 0 3px 3px 0;
        top: 40px;
        left: 0;
        max-height: 200px;
        overflow: scroll;
    }

        &__result {
            padding: 5px 5px;
            font-size: 14px;

            &:hover {
                background: #ccc;
                cursor: pointer;
                user-select: none;

                &.typeahead-field__noresults {
                    cursor: default;
                    background: inherit;
                }
            }
        }
    }
</style>
