<template>
    <div class="list-controls">
        <div v-if="showCaseSensitiveToggle" class="list-controls__case-sensitive">
            Case Sensitive
            <ToggleSwitch
                v-model="caseSensitive"
                class="list-controls__toggle"
                size="small"
                aria-label="Case Sensitive"
            />
        </div>
        <div class="list-controls__options">
            <div class="list-controls__search-controls">
                <PocketSelect
                    class="list-controls__search-type"
                    label="Filter Type"
                    v-model="searchType"
                    :data="searchTypeOptions"
                >
                    <template #selectValue="{ item }">
                        <slot name="filterTypeSelectValue" :item="item" />
                    </template>
                    <template #dropdownListItem="{ item }">
                        <slot name="filterTypeDropdownListItem" :item="item" />
                    </template>
                </PocketSelect>
                <PocketInput
                    v-if="searchType && searchType.value === 'All'"
                    class="list-controls__filter-all-text-input"
                    label="Search"
                    v-model="searchAll"
                    @keydown.enter="submitForm"
                />
                <template v-for="field in processedFields">
                    <template v-if="field.filter && !isLoading">
                        <template
                            v-if="field.type === 'text'
                                && searchType
                                && searchType.value === field.label
                                && !field.filterValueWhenActive
                            "
                        >
                            <PocketInput
                                v-if="!field.data || !field.data.length"
                                class="list-controls__filter-text-input"
                                :key="`${field.label}-input`"
                                :label="field.label"
                                v-model="field.filter.state"
                                @keydown.enter="submitForm"
                            />
                            <PocketSelect
                                v-else
                                class="list-controls__filter-text-select"
                                :key="`${field.label}-select`"
                                :label="field.label"
                                :typeahead="field.typeahead"
                                :modelValue="{
                                    value: field.filter.state,
                                    label: field.filter.state,
                                }"
                                @update:modelValue="updateSelectFieldFilterState(field, $event)"
                                :data="field.data"
                            >
                                <template #dropdownListItem="{ item }">
                                    <slot
                                        name="filterTextDropdownListItem"
                                        :item="item"
                                        :field="field"
                                    >
                                        <template v-if="item.sectionTitle">
                                            <div class="list-controls__filter-text-dropdown-item-container">
                                                <div class="list-controls__filter-text-dropdown-section-title">
                                                    {{ item.sectionTitle.toUpperCase() }}
                                                </div>
                                                <div class="list-controls__filter-text-dropdown-item">
                                                    {{ item.label }}
                                                </div>
                                            </div>
                                        </template>
                                    </slot>
                                </template>
                            </PocketSelect>
                        </template>
                        <template v-else-if="field.type === 'progress' || field.type === 'percent'">
                            <div
                                v-if="searchType && searchType.value === field.label"
                                :key="field.label"
                                class="list-controls__number-range"
                            >
                                <PocketInput
                                    v-if="field.filter.initialState"
                                    class="list-controls__number-range-min"
                                    label="Min %"
                                    v-model="field.filter.state.min"
                                    field-type="number"
                                />
                                <PocketInput
                                    v-if="field.filter.initialState"
                                    class="list-controls__number-range-max"
                                    label="Max %"
                                    v-model="field.filter.state.max"
                                    field-type="number"
                                />
                            </div>
                        </template>
                        <template v-else-if="field.type === 'date'">
                            <div
                                v-if="searchType && searchType.value === field.label"
                                :key="field.label"
                                class="list-controls__due-date-range"
                            >
                                <Calendar
                                    class="list-controls__start-date"
                                    label="Range Start"
                                    :key="field.filter.state.start"
                                    :modelValue="new Date(field.filter.state.start)"
                                    @update:modelValue="updateDateFieldFilterState(field, 'start', $event)"
                                />
                                <Calendar
                                    class="list-controls__end-date"
                                    label="Range End"
                                    :key="field.filter.state.end"
                                    :modelValue="new Date(field.filter.state.end)"
                                    @update:modelValue="updateDateFieldFilterState(field, 'end', $event)"
                                />
                            </div>
                        </template>
                    </template>
                </template>
                <div class="list-controls__apply-container">
                    <PocketButton
                        v-if="async"
                        class="list-controls__apply-btn"
                        type="primary"
                        :disabled="!dirty"
                        @click="submitForm"
                    >
                        Apply
                    </PocketButton>
                </div>
                <div 
                    v-if="areFilterChipsVisible" 
                    class="list-controls__filter-chips-container"
                >
                    <div
                        v-if="areFilterChipsVisible"
                        class="list-controls__filter-chips-label"
                    >
                        Filters:
                    </div>
                    <div class="list-controls__filter-chips">
                        <Pill
                            v-if="searchAll"
                            show-close="true"
                            @click="searchAll = ''"
                        >
                            Search: {{ searchAll }}
                        </Pill>
                        <template v-for="field in processedFields">
                            <template v-if="field.filter">
                                <Pill
                                    v-if="activeFilterPropNames.includes(field.propName)"
                                    :key="field.label"
                                    show-close="true"
                                    @click="filterTagClicked(field)"
                                >
                                    <template v-if="field.type === 'text'">
                                        <template v-if="field.filterValueWhenActive">
                                            {{ field.label }}
                                        </template>
                                        <template v-else>
                                            {{ `${field.label}: ${field.filter.state}` }}
                                        </template>
                                    </template>
                                    <template v-else-if="field.type === 'progress' || field.type === 'percent'">
                                        {{ `${field.label}: ${field.filter.state.min } - ${field.filter.state.max} %` }}
                                    </template>
                                    <template v-else-if="field.type === 'date'">
                                        {{ `${field.label}: ${field.filter.state.start} - ${field.filter.state.end}` }}
                                    </template>
                                </Pill>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch, Emit } from 'vue-facing-decorator'
import { FormGroup, NumberField } from '@/components/Forms'
import type {
    TField,
    TProcessedField,
    ITextField,
    IProgressField,
    IDateField,
    TListData,
    IListDataUpdatePayload,
    ISelectData,
    IProcessedTextField,
} from '@/components/Lists'
import Loading from '@/components/Loading.vue'
import type {
    IFilterSortParams,
    IFilterSortReturn,
    IProcessedDateField,
    IProcessedPercentField,
    IProcessedProgressBarField,
    IProcessedWhitespaceField,
} from './types'
import listsModule from '@/store/lists/module'
import UIKit from '@pocketprep/ui-kit'
import { formatDate } from '@/utils'

@Component({
    components: {
        FormGroup,
        NumberField,
        Loading,
        ToggleSwitch: UIKit.ToggleSwitch,
        PocketButton: UIKit.Button,
        PocketSelect: UIKit.Select,
        PocketInput: UIKit.Input,
        Calendar: UIKit.Calendar,
        Pill: UIKit.Pill,
    },
})
export default class ListControls extends Vue {
    @Prop() readonly listData!: TListData[] | ((
        filterSort: IFilterSortParams<TListData>
    ) => Promise<IFilterSortReturn<TListData>>)
    @Prop() readonly fields!: TField<TListData>[]
    @Prop() readonly async!: boolean
    @Prop({ default: false }) readonly showCaseSensitiveToggle!: boolean
    @Prop({ default: '' }) readonly storeName!: string
    @Prop({ default: false }) readonly listIsLoading!: boolean

    searchType: ListControls['searchTypeOptions'][number] | null = null
    searchAll = ''
    caseSensitive = false
    processedFields: TProcessedField[] = []
    dirty = false
    isLoading = false

    get searchTypeOptions () {
        const filterableFieldLabels = this.processedFields
            .filter((field): field is Exclude<TProcessedField, IProcessedWhitespaceField> =>
                !!(field.filter && 'label' in field && field.label)
            )
            .map(field => ({
                value: field.label,
                label: field.label,
            }))
        return [
            { value: 'All', label: 'All' },
            ...filterableFieldLabels,
        ]
    }

    get isPersistable () {
        return this.storeName
    }

    get storedListState () {
        return this.isPersistable && listsModule.getters.getListState(this.storeName)
    }

    // Does not include the searchAll filter
    get activeFilters () {
        return this.processedFields.filter((field): field is (
            IProcessedTextField | IProcessedProgressBarField | IProcessedPercentField | IProcessedDateField
        ) => {
            return (
                field.type === 'text'
                && field.filter
                && field.filter.state !== ''
                && (
                    !field.filterValueWhenActive
                    || field.filterValueWhenActive === field.filter.state
                ) && 
                field.doNotDisplayPillValue !== field.filter.state
            ) || (
                (
                    field.type === 'progress'
                    || field.type === 'percent'
                )
                && field.filter
                && (
                    field.filter.state.min !== field.filter.initialState.min
                    || field.filter.state.max !== field.filter.initialState.max
                )
            ) || (
                field.type === 'date'
                && field.filter
                && (
                    field.filter.state.start !== field.filter.initialState.start
                    || field.filter.state.end !== field.filter.initialState.end
                )
            )
        })
    }

    get activeFilterPropNames () {
        return this.activeFilters.map(field => field.propName)
    }

    get areFilterChipsVisible () {
        return this.searchAll || this.activeFilters.length
    }

    created () {
        this.searchType = this.searchTypeOptions[0]
        this.processedFields = this.getProcessedFields()

        const defaultSearchAll = this.storedListState && this.storedListState.searchAll
        if (defaultSearchAll) {
            this.searchAll = defaultSearchAll
        }

        this.$nextTick(() => {
            this.submitForm()
        })
    }

    getProcessedFields (): TProcessedField[] {
        return this.fields.map(field => {
            const storedField = this.isPersistable
                && this.storedListState
                && this.storedListState.filters
                && this.storedListState.filters
                    .find(filter => 'propName' in field && filter.propName === String(field.propName))

            if (field.type === 'text') {
                const defaultTextFilter = this.getDefaultTextFilter(field.options)
                const defaultStoredFilterState = storedField
                    && storedField.type === 'text'
                    && storedField.filter
                    && storedField.filter.state
                return {
                    propName: String(field.propName),
                    label: field.label,
                    type: field.type,
                    exactMatch: !!field.exactMatch,
                    typeahead: !!field.options?.typeahead,
                    filterValueWhenActive: field.options?.filterValueWhenActive,
                    defaultValue: field.options?.defaultValue,
                    filter: defaultTextFilter && defaultStoredFilterState
                        ? {
                            state: defaultStoredFilterState,
                            initialState: defaultTextFilter.initialState,
                        }
                        : defaultTextFilter,
                    sort: this.getDefaultSort(field.options),
                    data: field.data?.map(fieldData => typeof fieldData === 'string'
                        ? {
                            value: fieldData,
                            label: fieldData,
                        }
                        : fieldData
                    ),
                    prePopulateSelectValue: field.prePopulateSelectValue,
                    doNotDisplayPillValue: field.doNotDisplayPillValue,
                }
            } else if (field.type === 'progress') {
                const defaultProgressFilter = this.getDefaultNumberFilter(field.options)
                const defaultStoredFilterState = storedField
                    && storedField.type === 'progress'
                    && storedField.filter
                    && storedField.filter.state
                return {
                    propName: String(field.propName),
                    label: field.label,
                    type: field.type,
                    filter: defaultProgressFilter && defaultStoredFilterState
                        ? {
                            state: defaultStoredFilterState,
                            initialState: defaultProgressFilter.initialState,
                        }
                        : defaultProgressFilter,
                    sort: this.getDefaultSort(field.options),
                }
            } else if (field.type === 'progressGroup') {
                return {
                    propName: String(field.propName),
                    label: field.label,
                    type: field.type,
                    filter: false as const,
                    sort: false as const,
                }
            } else if (field.type === 'date') {
                const defaultDateFilter = this.getDefaultDateFilter(field)
                const defaultStoredFilterState = storedField
                    && storedField.type === 'date'
                    && storedField.filter
                    && storedField.filter.state
                return {
                    propName: String(field.propName),
                    label: field.label,
                    type: field.type,
                    filter: defaultDateFilter && defaultStoredFilterState
                        ? {
                            state: defaultStoredFilterState,
                            initialState: defaultDateFilter.initialState,
                        }
                        : defaultDateFilter,
                    sort: this.getDefaultSort(field.options),
                    data: field.data && [{ label: '-- All --', 'value': '' }, ...field.data ],
                }
            } else if (field.type === 'whitespace') {
                return {
                    propName: undefined,
                    label: undefined,
                    type: field.type,
                    filter: false as const,
                    sort: false as const,
                }
            } else if (field.type === 'percent') {
                const defaultPercentFilter = this.getDefaultNumberFilter(field.options)
                const defaultStoredFilterState = storedField
                    && storedField.type === 'percent'
                    && storedField.filter
                    && storedField.filter.state
                return {
                    propName: String(field.propName),
                    label: field.label,
                    type: field.type,
                    filter: defaultPercentFilter && defaultStoredFilterState
                        ? {
                            state: defaultStoredFilterState,
                            initialState: defaultPercentFilter.initialState,
                        }
                        : defaultPercentFilter,
                    sort: this.getDefaultSort(field.options),
                }
            } else {
                throw new Error('getProcessedFields: Unknown field type')
            }
        })
    }

    getDefaultTextFilter (fieldOptions: ITextField<Record<string, unknown>>['options']) {
        if (fieldOptions && typeof fieldOptions.filter === 'string') {
            return {
                state: String(fieldOptions.filter),
                initialState: String(fieldOptions.filter),
            }
        } else if (fieldOptions && fieldOptions.filter === false) {
            return false as const
        } else {
            return {
                state: '',
                initialState: '',
            }
        }
    }

    getDefaultNumberFilter (fieldOptions: IProgressField<Record<string, unknown>>['options']) {
        if (fieldOptions && typeof fieldOptions.filter === 'object') {
            return {
                state: { ...fieldOptions.filter },
                initialState: { ...fieldOptions.filter },
            }
        } else if (fieldOptions && fieldOptions.filter === false) {
            return false as const
        } else {
            const defaultRange = {
                min: 0,
                max: 100,
            }
            return {
                state: { ...defaultRange },
                initialState: { ...defaultRange },
            }
        }
    }

    getDefaultDateFilter (field: IDateField<TListData>) {
        const fieldOptions = field.options
        if (fieldOptions && typeof fieldOptions.filter === 'object') {
            return {
                state: { ...fieldOptions.filter },
                initialState: { ...fieldOptions.filter },
            }
        } else if (fieldOptions && fieldOptions.filter === false) {
            return false as const
        } else {
            const fieldDateRange = this.getDateRange(field)
            const defaultDates = {
                start: formatDate(fieldDateRange.start),
                end: formatDate(fieldDateRange.end),
            }

            return {
                state: { ...defaultDates },
                initialState: { ...defaultDates },
            }
        }
    }

    getDateRange (dateSchema: IDateField<TListData>) {
        const listData = this.listData
        if (typeof listData === 'function') {
            return {
                start: new Date(),
                end: new Date(),
            }
        }
        const datePropName = dateSchema.propName
        const dateValue = listData[0] && listData[0][datePropName]
        const initialDate = typeof dateValue === 'string' && !isNaN(new Date(dateValue).getTime())
            ? new Date(dateValue)
            : new Date()

        return listData.reduce((dueDateRange, item) => {
            const newDateRange = dueDateRange
            const itemValue = item[datePropName]
            if (typeof itemValue === 'string' && (new Date(itemValue) < dueDateRange.start)) {
                newDateRange.start = new Date(itemValue)
            }
            if (typeof itemValue === 'string' && (new Date(itemValue) > dueDateRange.end)) {
                newDateRange.end = new Date(itemValue)
            }
            return newDateRange
        }, {
            start: initialDate,
            end: initialDate,
        })
    }

    getDefaultSort (fieldOptions: this['fields'][number]['options']) {
        return typeof fieldOptions === 'object' && 'sort' in fieldOptions && fieldOptions.sort === false ? false : true
    }

    updateSelectFieldFilterState (field: IProcessedTextField, selectedValue?: ISelectData) {
        if (!selectedValue?.value) {
            this.filterTagClicked(field)
            return
        } else if (field.filter) {
            field.filter.state = selectedValue.value
        }
    }

    updateDateFieldFilterState (field: IProcessedDateField, startOrEnd: 'start' | 'end', dateVal: Date | null) {
        if (field.filter) {
            field.filter.state[startOrEnd] = dateVal ? dateVal.toLocaleDateString() : ''
        }
    }

    defaultFilterSortData (listData: TListData[]) {
        return listData.filter(item => {
            // Filter all text fields by the searchAll string
            const filterAllCheck = this.processedFields.some(field => {
                const itemValue = field.propName && item[field.propName]
                return typeof itemValue === 'string'
                    && itemValue.toLowerCase().includes(this.searchAll.toLowerCase())
            })

            // Filter each field by its own filter state
            const filterFieldCheck = this.processedFields.every(field => {
                const itemValue = field.propName && item[field.propName]
                if (field.filter) {
                    if (
                        field.type === 'text'
                        && typeof field.filter.state === 'string'
                        && typeof itemValue === 'string'
                        && !field.exactMatch
                    ) {
                        // Filter text fields by presence of the filter substring
                        return itemValue.toLowerCase().includes(field.filter.state.toLowerCase())
                    } else if (
                        field.type === 'text'
                        && typeof field.filter.state === 'string'
                        && typeof itemValue === 'string'
                        && field.exactMatch
                    ) {
                        return !field.filter.state || itemValue.toLowerCase() === field.filter.state.toLowerCase()
                    } else if (
                        field.type === 'text'
                        && typeof itemValue === 'number'
                    ){
                        // Don't filter by a number shown as text
                        return true
                    } else if (
                        field.type === 'progress'
                        && typeof field.filter.state === 'object'
                        && 'min' in field.filter.state
                        && typeof itemValue === 'object'
                        && !(itemValue instanceof Array)
                    ) {
                        const progressPct = 'percent' in itemValue
                            ? itemValue.percent
                            : itemValue.total !== 0
                                ? Math.min((itemValue.count / itemValue.total) * 100, 100)
                                : 0
                        // Filter numbers by being within the filter range
                        return progressPct >= field.filter.state.min
                            && progressPct <= field.filter.state.max
                    } else if (
                        field.type === 'date'
                        && typeof field.filter.state === 'object'
                        && 'start' in field.filter.state
                        && typeof itemValue === 'string'
                    ) {
                        if (
                            !itemValue && (
                                field.filter.state.start !== field.filter.initialState.start
                                || field.filter.state.end !== field.filter.initialState.end
                            )
                        ) {
                            // Don't show data without dates if the date filter range has been modified
                            return false
                        }
                        // Filter dates by being within the filter range
                        return isNaN(new Date(itemValue).getTime()) ||
                            (new Date(itemValue) >= new Date(field.filter.state.start)
                            && new Date(itemValue) <= new Date(field.filter.state.end))
                    } else if (
                        field.type === 'percent'
                        && typeof field.filter.state === 'object'
                        && 'min' in field.filter.state
                    ) {
                        if (
                            Number.isNaN(Number(itemValue)) && 
                            typeof itemValue === 'string' && 
                            field.filter.state.min === 0
                        ) {
                            return true
                        }

                        // Filter numbers by being within the filter range
                        return Number(itemValue) >= field.filter.state.min
                            && Number(itemValue) <= field.filter.state.max
                    } else {
                        throw new Error('defaultFilterSortData: Unknown field type')
                    }
                } else {
                    return true
                }
            })
            return filterAllCheck && filterFieldCheck
        })
    }

    getDataUpdatePayload (): IListDataUpdatePayload {
        return {
            searchAll: this.searchAll,
            caseSensitive: this.caseSensitive,
            filters: this.processedFields,
            activeFilterPropNames: this.activeFilterPropNames,
            data: this.async || typeof this.listData === 'function'
                ? 'async'
                : this.defaultFilterSortData(this.listData),
        }
    }

    submitForm () {
        if (this.async) {
            this.dataChanged(this.getDataUpdatePayload())
        }
    }

    filterTagClicked (field: TProcessedField<TListData>) {
        const filter = field.filter
        if (filter) {
            if (typeof filter.initialState === 'string') {
                if (
                    field.type === 'text' && 
                    (field.filterValueWhenActive || field.defaultValue) && 
                    filter.state !== filter.initialState
                ) {
                    filter.state = filter.initialState
                } else {
                    filter.state = ''
                }

                // For text fields with typeahead select inputs, clearing the field value triggers the menu to open
                // This is a workaround to reset the select component with a closed dropdown menu
                this.isLoading = true
                this.$nextTick(() => {
                    this.isLoading = false
                })
            } else {
                filter.state = { ...filter.initialState }
            }
        }

        if (this.async) {
            this.submitForm()
        }
    }

    @Watch('processedFields', { deep: true })
    processedFieldsChanged () {
        if (this.async) {
            this.dirty = true
        } else {
            const updatePayload = this.getDataUpdatePayload()
            this.dataChanged(updatePayload)
        }
    }

    @Watch('searchType')
    searchTypeChanged () {
        const searchTypeValue = this.searchType?.value
        const currentSearchField = this.processedFields.find(field => field.label === searchTypeValue)
        if (currentSearchField && currentSearchField.type === 'text' && currentSearchField.filter) {
            if (currentSearchField.filterValueWhenActive) {
                currentSearchField.filter.state = currentSearchField.filterValueWhenActive
                this.searchType = this.searchTypeOptions[0]
            }

            if (currentSearchField.prePopulateSelectValue) {
                currentSearchField.filter.state = currentSearchField.prePopulateSelectValue
                const prePopulateSelectValue = { 
                    value: currentSearchField.prePopulateSelectValue, 
                    label: currentSearchField.prePopulateSelectValue,
                }
                this.updateSelectFieldFilterState(currentSearchField, prePopulateSelectValue)
            }
        }
    }

    @Watch('searchAll')
    searchAllChanged () {
        if (this.async) {
            this.dirty = true
        } else {
            const updatePayload = this.getDataUpdatePayload()
            this.dataChanged(updatePayload)
        }
    }

    @Watch('caseSensitive')
    caseSensitiveChanged () {
        if (this.async) {
            if (
                this.searchAll
                || this.processedFields.find(field => field.type === 'text' && field.filter && field.filter.state)
            ) {
                this.dirty = true
            }
        } else {
            const updatePayload = this.getDataUpdatePayload()
            this.dataChanged(updatePayload)
        }
    }


    @Watch('listData', { deep: true })
    listDataChanged () {
        this.dataChanged(this.getDataUpdatePayload())
    }

    @Emit('dataChanged')
    dataChanged (updatePayload: IListDataUpdatePayload) {
        this.dirty = false
        return updatePayload
    }
}
</script>

<style lang="scss" scoped>
.list-controls {
    &__case-sensitive {
        display: flex;
        align-items: center;
        font-size: 12px;
        justify-content: flex-end;
        position: relative;
        top: -110px;
        margin-right: 10px;
    }

    &__toggle {
        margin-left: 8px;
    }

    &__options {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-left: 10px;
        margin-bottom: 10px;
    }

    &__search-controls {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 5px;
    }

    &__search-type {
        width: 230px;
        margin-right: 12px;
    }

    &__filter-all-text-input {
        width: 320px;
    }

    &__filter-text-input,
    &__filter-text-select {
        width: 320px;
    }

    :deep(.uikit-select__item:has(.list-controls__filter-text-dropdown-item-container)) {
        margin-top: 31px;

        &:first-of-type {
            margin-top: 24px;
        }
    }

    &__filter-text-dropdown-item-container {
        position: relative;
    }

    &__filter-text-dropdown-section-title {
        position: absolute;
        top: -24px;
        color: $slate;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
    }

    &__number-range {
        display: flex;
        flex-wrap: wrap;
    }

    &__number-range-min {
        margin-right: 12px;
        width: 155px;
    }

    &__number-range-max {
        width: 155px;
    }

    &__due-date-range {
        display: flex;
        flex-wrap: wrap;
    }

    &__start-date {
        margin-right: 12px;
        width: 200px;
    }

    &__end-date {
        width: 200px;
    }

    &__apply-container {
        display: flex;
    }

    &__apply-btn {
        margin-left: 12px;
        margin-right: 16px;
        margin-top: 21px;
        padding: 7px 20px;
        height: 36px;
        font-size: 14px;
    }

    &__filter-chips-container {
        margin-left: 34px;
    }

    &__filter-chips-label {
        color: $pickled-bluewood;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 11px;
    }

    &__filter-chips {
        display: flex;
        flex-wrap: wrap;
        max-width: 522px;
    }

    :deep(.uikit-select__list) {
        max-height: 275px;
    }

    :deep(.uikit-pill),
    :deep(.uikit-pill__label) {
        max-width: 100%;
    }
}

.form-group {
    width: 200px;
    padding: 0;
    margin: 0;
}

.field-label {
    line-height: 14px !important;   // Overwriting child component's line-height
}

</style>