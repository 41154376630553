<template>
    <FormSection
        class="activity"
        :label="`Activity (${activityListOptions.listData.length})`"
        :toggleable="true"
    >
        <List
            v-if="activities.length"
            class="activity__list"
            :list-options="activityListOptions"
        />
    </FormSection>
</template>


<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator'
import { List, type IListOptions, type TSchema, type IDefaultSort } from '@/components/Lists'
import { FormSection } from '@/components/Forms'
import { activitiesModule } from '@/store/activities/module'
import type { IActivityPayload } from '@/store/activities/types'
import type { CMS } from '@pocketprep/types'
import { formatDate } from '@/utils'

interface IMappedActivity {
    objectId: string
    createdAt: string
    action: string
    user: string
    type: string
    subjectName: string
}

@Component({
    components: {
        List,
        FormSection,
    },
})
export default class ActivityList extends Vue {
    @Prop() readonly activityQuery!: IActivityPayload

    activities: CMS.Class.ActivityJSON[] = []

    async fetchActivities () {
        return await activitiesModule.actions.searchActivities(this.activityQuery)
    }
    
    get activityListOptions (): IListOptions<IMappedActivity> {
        const listData: IMappedActivity[] = this.activities.map(activity => ({
            ...activity,
            user: activity.user
                && 'firstName' in activity.user
                && (activity.user.firstName + ' ' + activity.user.lastName)
                || '',
            createdAt: activity.createdAt && formatDate(activity.createdAt) || '',
            type: activity.type || '',
            subjectName: activity.subject.name || '',
        })) || []
        const listSchema: TSchema<IMappedActivity> = [
            {
                propName: 'subjectName',
                label: 'Subject',
                type: 'text',
                options: {
                    style: 'overflow-ellipsis',
                    width: 400,
                    group: 0,
                },
            },
            {
                propName: 'type',
                label: 'Type',
                type: 'text',
                options: {
                    width: 125,
                    group: 0,
                },
            },
            {
                propName: 'action',
                label: 'Action',
                type: 'text',
                options: {
                    width: 100,
                    group: 0,
                },
            },
            {
                propName: 'user',
                label: 'User',
                type: 'text',
                options: {
                    width: 125,
                    group: 0,
                },
            },
            {
                propName: 'createdAt',
                label: 'Date',
                type: 'date',
                options: {
                    width: 100,
                    group: 0,
                },
            },
        ]

        const defaultSort: IDefaultSort<IMappedActivity> = {
            propName: 'createdAt',
            sortDir: 'DESC',
        }

        return {
            listData,
            listSchema,
            defaultSort,
        }
    }

    async created () {
        this.activities = await this.fetchActivities()
    }
}
</script>

<style lang="scss" scoped>
.activity {
    &__list {
        padding: 10px;
    }
}
</style>
