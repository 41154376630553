<template>
    <div
        class="bundle-form"
    >
        <div v-if="!isLoading">
            <div
                v-if="name && bundleId"
                class="bundle-form__bundle-name"
            >
                {{ name }}
            </div>
            <TitleText

                class="bundle-form__title-text"
                :class="{'bundle-form__title-text--create-bundle' : !bundleId}"
            >
                {{ bundleId ? 'Edit Bundle' : 'Create Bundle' }}
            </TitleText>
        </div>
        <FormValidation :messages="validationMessages" />
        <Loading v-if="isLoading" />
        <FormSection label="Bundle Settings">
            <FormGroup label="Name *">
                <TextField v-model="name" />
            </FormGroup>
        </FormSection>
        <FormSection
            label="iOS Settings"
            :column-grid="true"
            :toggleable="true"
        >
            <FormGroup label="Current Version" subtext="e.g., 0.1">
                <TextField v-model="ios.currentVersion" />
            </FormGroup>
            <FormGroup label="Minimum Version" subtext="e.g., 0.1">
                <TextField v-model="ios.minVersion" />
            </FormGroup>
            <FormGroup label="App Store ID" subtext="e.g., com.pocketprep.appname">
                <TextField v-model="ios.appStoreId" />
            </FormGroup>
            <FormGroup label="App Store URI" subtext="e.g., https://apps.apple.com/us/app/pocket-prep/id587680193">
                <TextField v-model="ios.appStoreURI" />
            </FormGroup>
        </FormSection>
        <FormSection
            label="Android Settings"
            :column-grid="true"
            :toggleable="true"
        >
            <FormGroup label="Current Version" subtext="e.g., 0.1">
                <TextField v-model="android.currentVersion" />
            </FormGroup>
            <FormGroup label="Minimum Version" subtext="e.g., 0.1">
                <TextField v-model="android.minVersion" />
            </FormGroup>
            <FormGroup label="App Store ID" subtext="e.g., com.pocketprep.appname">
                <TextField v-model="android.appStoreId" />
            </FormGroup>
            <FormGroup label="App Store URI" subtext="e.g., https://play.google.com/store?id=com.pocketprep.acsm">
                <TextField v-model="android.appStoreURI" />
            </FormGroup>
        </FormSection>
        <FormSection :label="`Connected Exams (${selectedExamIds.length})`" :toggleable="true">
            <List 
                v-if="!isLoading"
                class="bundle-form__connected-exams"
                store-name="connectedExamsList"
                :list-options="listOptions"
                :selectable="true"
                :pre-selected-items="selectedListData"
                @select="selectExam"
            />
        </FormSection>
        <FormSection label="Stripe Plans" :toggleable="true">
            <template v-if="!bundlePlans || !bundlePlans.length">
                <div class="bundle-form__no-stipe-plan">
                    There are no Stripe plans currently associated with this bundle.
                </div>
            </template>
            <template v-else>
                <ul class="bundle-form__plan-list">
                    <li
                        v-for="(plan, index) in bundlePlans"
                        :key="index"
                        class="bundle-form__plan"
                    >
                        <div>
                            <label>Plan Name</label>
                            <span>{{ plan.nickname }}</span>
                        </div>
                        <div>
                            <label>Cost</label>
                            <span>${{ ((plan.amount || 0) / 100).toFixed(2) }}</span>
                        </div>
                        <div>
                            <PocketLink
                                :href="`https://dashboard.stripe.com/${ 
                                    stripeEnv === 'sandbox' ? 'test/' : '' 
                                }plans/${plan.id}`"
                                target="_blank"
                            >
                                Open in Stripe
                            </PocketLink>
                        </div>
                    </li>
                </ul>
            </template>
        </FormSection>
        <ButtonFooter v-if="!isLoading && isAdmin">
            <template
                #leftSide 
            >
                <div v-if="!isDisabled && bundleId" class="bundle-form__left-side-buttons">
                    <PocketButton
                        type="primary-red"
                        @click="toggleBundleStatus"
                    >
                        Disable Bundle
                    </PocketButton>
                </div>
            </template>
            <template
                #rightSide 
            >
                <div class="bundle-form__right-side-buttons">
                    <PocketButton
                        class="bundle-form__cancel-button"
                        type="secondary"
                        @click="cancelClicked"
                    >
                        Cancel
                    </PocketButton>
                    <PocketButton
                        v-if="isDisabled && bundleId"
                        type="primary"
                        @click="toggleBundleStatus"
                    >
                        Enable Bundle
                    </PocketButton>
                    <PocketButton
                        v-else
                        type="primary"
                        @click="submitBundleForm"
                    >
                        {{ bundleId ? 'Save Changes' : 'Create Bundle' }}
                    </PocketButton>
                </div>
            </template>
        </ButtonFooter>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import Loading from '@/components/Loading.vue'
import { type IListOptions, List } from '@/components/Lists'
import { FormValidation, FormGroup, FormSection, TextField, SelectField } from '@/components/Forms/'
import type { Study } from '@pocketprep/types'
import type { Parse } from '@/store/ParseUtils'
import { activitiesModule } from '@/store/activities/module'
import { bundlesModule } from '@/store/bundles/module'
import examsModule from '@/store/exams/module'
import TitleText from '@/components/TitleText.vue'
import ButtonFooter from '@/components/ButtonFooter.vue'
import UIKit from '@pocketprep/ui-kit'
import { usersModule } from '@/store/users/module'

interface IListExam {
    objectId: string
    nativeAppName: string
    descriptiveName: string
    compositeKey: string
    selected: string
}

@Component({
    components: {
        FormGroup,
        FormValidation,
        FormSection,
        TextField,
        SelectField,
        Loading,
        List,
        ButtonFooter,
        TitleText,
        PocketButton: UIKit.Button,
        PocketLink: UIKit.Link,
    },
})
export default class BundleForm extends Vue {
    name = ''
    code = ''
    appId = ''
    stripeEnv: 'sandbox' | 'prod' = 'prod'
    stripePlans: ReturnType<Study.Cloud.fetchStripePlans> | null = null
    ios = {
        currentVersion: '0.1',
        minVersion: '0.1',
        appStoreId: '',
        appStoreURI: '',
    }
    android = {
        currentVersion: '0.1',
        minVersion: '0.1',
        appStoreId: '',
        appStoreURI: '',
    }
    bundleExams: Parse.Pointer[] = []
    exams: Study.Class.ExamMetadataJSON[] = []
    bundles: Study.Class.BundleJSON[] = []
    bundleId: string | null = null
    isLoading = false
    selectedExamIds: string[] = []
    isDisabled = true

    validationMessages: string[] = []

    get bundlePlans () {
        return this.stripePlans
            && this.stripePlans.filter(p => this.bundleId && p.id.startsWith(`${this.bundleId}_`))
    }

    get selectedListData () {
        return typeof this.listOptions.listData !== 'function'
             && this.listOptions.listData.filter(data => data.selected === 'Yes')
    }

    get listExams (): IListExam[] {
        return this.exams.map(exam => ({
            objectId: exam.objectId,
            nativeAppName: exam.nativeAppName || '',
            compositeKey: exam.compositeKey || '',
            descriptiveName: exam.descriptiveName || '',
            selected: this.selectedExamIds.find(selectedExamId => selectedExamId === exam.objectId)
                ? 'Yes'
                : 'No',
        }))
    }

    get listOptions (): IListOptions<IListExam> {
        return {
            listData: this.listExams,
            listSchema: [
                {
                    propName: 'nativeAppName',
                    label: 'Native App Name',
                    type: 'text',
                    options: {
                        style: 'title',
                        width: 200,
                        group: 0,
                    },
                },
                {
                    propName: 'compositeKey',
                    label: 'Composite Key',
                    type: 'text',
                    options: {
                        width: 150,
                        group: 0,
                    },
                },
                {
                    propName: 'descriptiveName',
                    label: 'Descriptive Name',
                    type: 'text',
                    options: {
                        flex: true,
                        minWidth: 250,
                        group: 1,
                    },
                },
                {
                    propName: 'selected',
                    label: 'Selected',
                    type: 'text',
                    options: {
                        isHidden: true,
                    },
                    data: [ 'Yes', 'No' ],
                },
            ],
            defaultSort: {
                sortDir: 'DESC',
                propName: 'selected',
            },
        }
    }

    get isAdmin () {
        return usersModule.getters.getIsAdmin()
    }

    async mounted () {
        this.isLoading = true
        const [
            bundles,
            stripePlans,
            exams,
            bucketName,
        ] = await Promise.all([
            bundlesModule.actions.fetchBundles(),
            bundlesModule.actions.fetchStripePlans(),
            examsModule.actions.fetchExams(),
            examsModule.actions.fetchS3BucketName(),
        ])
        this.bundles = bundles
        this.stripePlans = stripePlans 
        this.exams = exams
        this.stripeEnv = bucketName === 'ppappcontent-4.0' ? 'prod' : 'sandbox'

        const bundle = this.bundles.find(item => item.objectId === this.$route.params.bundleId)
        if (bundle && bundle.objectId) {
            this.bundleId = bundle.objectId
            this.name = bundle.name
            this.isDisabled = bundle.isDisabled
            this.bundleExams = (bundle.exams || []) as Parse.Pointer[]
            this.ios = bundle.ios && {
                currentVersion: bundle.ios.currentVersion || '',
                minVersion: bundle.ios.minVersion || '',
                appStoreId: bundle.ios.appStoreId || '',
                appStoreURI: bundle.ios.appStoreURI || '',
            } || this.ios
            this.android = bundle.android && {
                currentVersion: bundle.android.currentVersion || '',
                minVersion: bundle.android.minVersion || '',
                appStoreId: bundle.android.appStoreId || '',
                appStoreURI: bundle.android.appStoreURI || '',
            } || this.android
            this.selectedExamIds = this.bundleExams.map(item => item.objectId || '')
        }

        this.isLoading = false
    }

    selectExam (selectedData: IListExam[]) {
        this.$nextTick(() => {
            this.selectedExamIds = selectedData
                .map(data => data.objectId || '')
        })
    }

    async toggleBundleStatus () {
        this.validationMessages = []
        this.isLoading = true

        if (!confirm(`Are you sure you want to ${
            this.isDisabled ? 'enable' : 'disable'
        } this bundle? Updates take effect immediately.`)) {
            this.isLoading = false
            return
        }

        const bundle = this.bundles.find(item => item.objectId === this.$route.params.bundleId)

        bundle && await bundlesModule.actions.updateBundle(
            {
                ...bundle,
                isDisabled: !this.isDisabled,
                exams: bundle.exams.map(e => ({
                    objectId: e.objectId,
                    __type: 'Pointer',
                    className: 'ExamMetadata' as const,
                })),
            }
        )
        this.isDisabled = !this.isDisabled

        this.isLoading = false
    }

    async submitBundleForm () {
        this.validationMessages = []
        this.isLoading = true

        if (!confirm('Are you sure you want to save this bundle? Updates take effect immediately.')) {
            this.isLoading = false
            return
        }

        if (!this.name) {
            this.validationMessages.push('error/Name field is required.')
        }
        if (this.bundles.find(item => item.name === this.name && item.objectId !== this.bundleId)) {
            this.validationMessages.push('error/A bundle with this name already exists.')
        }

        if (!this.validationMessages.length) {
            const bundlePayload = {
                    name: this.name,
                    exams: this.selectedExamIds.map(id => ({
                        __type: 'Pointer' as const,
                        className: 'ExamMetadata' as const,
                        objectId: id,
                    })),
                    ios: this.ios,
                    android: this.android,
                },
                bundleId = this.bundleId
                    ? await bundlesModule.actions.updateBundle(
                        {
                            ...bundlePayload,
                            objectId: this.bundleId,
                        }
                    )
                    : await bundlesModule.actions.createBundle(bundlePayload)

            if (!bundleId) {
                this.validationMessages.push('error/Unable to save bundle.')
                this.isLoading = false
                return
            }

            await activitiesModule.actions.createActivity({
                action: this.bundleId ? 'update' : 'create',
                subject: {
                    type: 'Pointer',
                    value: bundleId || '',
                    name: this.name,
                },
                type: 'bundle',
            })

            this.$router.push({
                name: 'bundle-list',
            })
        }

        this.isLoading = false
    }

    cancelClicked () {
        this.$router.push({
            name: 'bundle-list',
        })
    }
}
</script>

<style lang="scss" scoped>

.bundle-form {
    padding: $base;
    flex: 1;
    margin-top: 52px;

    &__bundle-name {
        margin-top: 22px;
        font-size: 16px;
        color: $slate;
        font-weight: 500;
    }

    &__title-text {
        margin-top: 16px;
        margin-bottom: 34px;
        font-size: 40px;

        &--create-bundle {
            margin-top: 52px;
        }
    }

    &__setting {
        margin-left: 15px;
    }

    &__error {
        color: red;
    }

    &__connected-exams {
        padding-top: 16px;
    }

    &__no-stipe-plan {
        padding-top: 16px;
        padding-bottom: 16px;
        font-size: 16px;
    }

    &__plan-list {
        margin: 0;
        padding: 0;
    }

    &__plan {
        margin: 10px;
        padding: 10px;
        background-color: white;
        border: 1px solid $cloudy;
        border-radius: 4px;

        div {
            display: flex;
            flex-direction: column;
            padding: 10px;
            flex: 1;
            min-width: 200px;
            margin-right: 10px;

            label {
                color: #666;
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 5px;
            }

            span {
                word-wrap: break-word;
            }
        }
    }

    &__left-side-buttons {
        position: absolute;
        margin-left: 18px;
    }

    &__right-side-buttons {
        position: absolute;
        right: 0;
        margin-right: 18px;
    }

    &__cancel-button {
        margin-right: 8px;
    }
}
</style>