import type {
    TFetchExams,
    TFetchExam,
    TFetchManifest,
    TExportExamToS3,
    TUpdateOldParseData,
    TDeleteOldParseData,
    TExportExamToParse,
    TCleanUpExport,
    TCheckCompositeKeyExists,
    TExportImages,
    TDeleteExam,
    TFetchS3BucketName,
    TFetchMostRecentExamVersion,
    TUpdateBundlesForExamMajor,
    TExportQuestions,
    TQuestionQualityResetV2,
    TFetchSubjects,
} from './types'
import { Parse } from '@/store/ParseUtils'
import type { CMS } from '@pocketprep/types'
import examsModule from '@/store/exams/module'

/**
 * Fetch all exams from app server and stores them in store
 *
 * @returns {Promise} resolves with IExam[]
 */
const fetchExams = async (): ReturnType<TFetchExams> => {
    const parseExams = await Parse.Cloud.run<CMS.Cloud.fetchExams>('fetchExams')
    const exams = parseExams.map(exam => exam.toJSON())

    examsModule.state.exams = exams

    return exams
}

/**
 * Fetch exam from app server by ID
 *
 * @returns {Promise} resolves with IExam[]
 */
const fetchExam = async (examId: Parameters<TFetchExam>[0]): ReturnType<TFetchExam> => {
    const parseExam = await Parse.Cloud.run<CMS.Cloud.fetchExam>('fetchExam', { examId })
    return parseExam && parseExam.toJSON()
}

/**
 * Fetch all subjects for given exam
 *
 * @returns {Promise} resolves with IExam[]
 */

const fetchSubjects = async (params: Parameters<TFetchSubjects>[0]): ReturnType<TFetchSubjects> => {
    const parseSubjects = 
        await Parse.Cloud.run<TFetchSubjects>('fetchSubjects', { examMetadataId: params.examMetadataId })
    return parseSubjects
}

/**
 *
 */
const fetchMostRecentExamVersion = async (
    compositeKey: Parameters<TFetchMostRecentExamVersion>[0]
): ReturnType<TFetchMostRecentExamVersion> => {
    const manifest = await fetchManifest(compositeKey)
    if (manifest && manifest.versions instanceof Array) {
        const sortedVersions = manifest.versions
            .sort((a, b) => -a.version.localeCompare(b.version, undefined, { numeric: true }))
        const mostRecentExamVersion = sortedVersions[0].version
        return mostRecentExamVersion
    }
}

/**
 * Fetch the current manifest for an exam's composite key
 */
const fetchManifest = async (compositeKey: Parameters<TFetchManifest>[0]): ReturnType<TFetchManifest> => {
    return Parse.Cloud.run<CMS.Cloud.fetchManifest>('fetchManifest', { compositeKey })
}

const fetchS3BucketName = async (): ReturnType<TFetchS3BucketName> => {
    return Parse.Cloud.run<CMS.Cloud.fetchS3BucketName>('fetchS3BucketName')
}

/**
 * Export a new exam version to S3
 */
const exportExamToS3 = async (exportPayload: Parameters<TExportExamToS3>[0]): ReturnType<TExportExamToS3> => {
    return Parse.Cloud.run<CMS.Cloud.exportExamToS3>('exportExamToS3', exportPayload)
}

/**
 * Update old data in Parse in preparation for new data (marks everything as dirty)
 *
 * @return void
 */
const updateOldParseData = async (
    updatePayload: Parameters<TUpdateOldParseData>[0]): ReturnType<TUpdateOldParseData> => {
    await Parse.Cloud.run<CMS.Cloud.updateOldParseData>('updateOldParseData', updatePayload)
}

/**
 * Deletes all old data in Parse
 *
 * @return new examMetadata objectId from app server
 */
const deleteOldParseData = async (
    deletePayload: Parameters<TDeleteOldParseData>[0]): ReturnType<TDeleteOldParseData> => {
    await Parse.Cloud.run<CMS.Cloud.deleteOldParseData>('deleteOldParseData', deletePayload)
}

/**
 * Export a new exam version to Parse
 *
 * @return new examMetadata objectId from app server
 */
const exportExamToParse = async (
    exportPayload: Parameters<TExportExamToParse>[0]): ReturnType<TExportExamToParse> => {
    return Parse.Cloud.run<CMS.Cloud.exportExamToParse>('exportExamToParse', exportPayload)
}

/**
 * Export questions and subjects to app server
 *
 * @return void
 */
const exportQuestionsToParse = async (
    exportPayload: Parameters<TExportQuestions>[0]): ReturnType<TExportQuestions> => {
    return Parse.Cloud.run<TExportQuestions>('exportQuestions', exportPayload)
}

const questionQualityResetV2 = async (
    resetPayload: Parameters<TQuestionQualityResetV2>[0]): ReturnType<TQuestionQualityResetV2> => {
    return Parse.Cloud.run<TQuestionQualityResetV2>('questionQualityReset-v2', resetPayload)
}

/**
 * Update relevant bundles with new exam ID
 */
const updateBundlesForExamMajor = async (
    updatePayload: Parameters<TUpdateBundlesForExamMajor>[0]): ReturnType<TUpdateBundlesForExamMajor> => {
    return Parse.Cloud.run<CMS.Cloud.updateBundlesForExamMajor>('updateBundlesForExamMajor', updatePayload)
}

/**
 * Clean up data in CMS after export
 */
const cleanUpExport = async (cleanUpPayload: Parameters<TCleanUpExport>[0]): ReturnType<TCleanUpExport> => {
    return Parse.Cloud.run<CMS.Cloud.cleanUpExport>('cleanUpExport', cleanUpPayload)
}

/**
 * Check whether exam version already exists by looking for composite key
 */
const checkCompositeKeyExists = async (
    compositeKey: Parameters<TCheckCompositeKeyExists>[0]): ReturnType<TCheckCompositeKeyExists> => {
    return Parse.Cloud.run<CMS.Cloud.checkCompositeKeyExists>(
        'checkCompositeKeyExists',
        { compositeKey }
    )
}

/**
 * Export a new exam version images to S3
 */
const exportImages = async (exportPayload: Parameters<TExportImages>[0]): ReturnType<TExportImages> => {
    return Parse.Cloud.run<CMS.Cloud.exportImages>('exportImages', exportPayload)
}

/**
 * Delete an exam and all related data from classes: exam, examMetadata, examData, and Manifest
 *
 * @param {string} examId - ID of exam to delete
 */
const deleteExam = async (examId: Parameters<TDeleteExam>[0]): ReturnType<TDeleteExam> => {
    return Parse.Cloud.run<CMS.Cloud.deleteExam>('deleteExam', { examId })
}

export default {
    fetchExams,
    fetchExam,
    fetchSubjects,
    fetchMostRecentExamVersion,
    fetchManifest,
    fetchS3BucketName,
    exportExamToS3,
    updateOldParseData,
    deleteOldParseData,
    exportExamToParse,
    exportQuestionsToParse,
    questionQualityResetV2,
    updateBundlesForExamMajor,
    cleanUpExport,
    checkCompositeKeyExists,
    exportImages,
    deleteExam,
}
