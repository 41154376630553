import type { IQuestionDraftsModuleState } from './types'

export const getInitialQuestionDraftsState = (): IQuestionDraftsModuleState => ({
    questionDraftExamIds: [],
    unsavedQuestionDraft: null,
})

const moduleState = getInitialQuestionDraftsState()

export default moduleState
