<template>
    <Modal>
        <div class="loading">
            Loading...
        </div>
    </Modal>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import Modal from '@/components/Modal.vue'

@Component({
    components: {
        Modal,
    },
})
export default class Loading extends Vue {}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.loading {
    padding: calc(#{$base} * 2);
}
</style>
