<template>
    <div class="job-loading">
        <div class="job-loading__title-text">{{ title }}</div>
        <div class="job-loading__content">
            <div class="job-loading__progress-bar">
                <div class="job-loading__progress-bar-fill" :style="`width: ${progress}%`" />
            </div>
            <div class="job-loading__message">{{ message }}</div>
            <div class="job-loading__subtext">{{ subtext }}</div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component
export default class JobLoading extends Vue {
    @Prop() readonly title!: string
    @Prop() readonly progress!: number
    @Prop() readonly message!: string
    @Prop() readonly subtext!: string
}
</script>

<style lang="scss" scoped>

.job-loading {
    position: fixed;
    width: 110vw;
    height: 110vh;
    left: -5vw;
    background-color: $floral-white;
    z-index:99;
    display: flex;
    align-items: center;
    justify-content: center;

    &__title-text {
        font-size: 40px;
        line-height: 36px;
        font-weight: 600;
        position: absolute;
        top: 131px;
        width: 1230px;
    }

    &__content {
        position: absolute;
        top: 234px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__progress-bar {
        padding: 0;
        width: 1230px;
        border: 1px solid $brand-blue;
        background: $sky-blue;
        height: 8px;
        border-radius: 30px;
        overflow: hidden;

        .job-loading__progress-bar-fill {
            background: $brand-blue;
            height: 30px;
        }
    }

    &__message {
        margin-top: 28px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
    }

    &__subtext {
        font-size: 15px;
        line-height: 20px;
        color: $ash;
        margin-top: 8px;
    }
}
</style>
