import type {
    TSearchActivities,
    TCreateActivity,
} from './types'
import { Parse, sessionToken } from '@/store/ParseUtils'
import type { CMS } from '@pocketprep/types'

/**
 * Fetch all activities by subject pointer or user id
 *
 * @returns {Promise} resolves with IActivity[]
 */
const searchActivities = async (
    { subject, userId }: Parameters<TSearchActivities>[0]): ReturnType<TSearchActivities> => {
    // fetch activities
    const activityQuery = new Parse.Query('Activity')
        .include([
            'user.firstName',
            'user.lastName',
        ])

    userId &&
        activityQuery.equalTo('user', { __type: 'Pointer', className: '_User', objectId: userId })

    subject &&
        activityQuery.equalTo('subject.type', subject.type)
    subject && 
        activityQuery.equalTo('subject.value', subject.value)

    const activities = ((await activityQuery.findAll({ batchSize: 2000 })) as CMS.Class.Activity[])
        .map(activity => activity.toJSON())

    return activities
}

/**
 * Create new activity
 *
 * @param {IActivity} params - exam draft object
 *
 * @returns {Promise} resolves to IActivity of new activity object
 */
const createActivity = async (
    params: Parameters<TCreateActivity>[0]): ReturnType<TCreateActivity> => {
    const currentUser = Parse.User.current<CMS.Class.User>()

    if (!currentUser) {
        throw 'No current user found.'
    }

    const newActivity = new Parse.Object('Activity',
        {
            ...params,
            user: { __type: 'Pointer', className: '_User', objectId: currentUser.id },
        })

    const savedActivity = await newActivity.save(null, sessionToken())

    return savedActivity.toJSON()
}

export default {
    searchActivities,
    createActivity,
}
