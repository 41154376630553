import type { 
    TFetchBundles,
    TCreateBundle,
    TUpdateBundle,
    TFetchStripePlans,
} from './types'
import { Parse } from '@/store/ParseUtils'
import type { CMS, Study } from '@pocketprep/types'

/**
 * Fetch all stripe plans from app server
 */
const fetchStripePlans = (): ReturnType<TFetchStripePlans> => {
    return Parse.Cloud.run<Study.Cloud.fetchStripePlans>('fetchStripePlans')
}

/**
 * Fetch all bundles from app server
 */
const fetchBundles = async (): ReturnType<TFetchBundles> => {
    const bundles = await Parse.Cloud.run<CMS.Cloud.fetchBundles>('fetchBundles')

    return bundles.map(bundle => bundle.toJSON())
}

/**
 * Create bundle on app server
 * 
 * @returns {Promise<string>} returns ID of created bundle
 */
const createBundle = async (params: Parameters<TCreateBundle>[0]): ReturnType<TCreateBundle> => {
    return await Parse.Cloud.run<CMS.Cloud.createBundle>('createBundle', {
        ...params,
        isDisabled: true,
    })
}

/**
 * Update bundle on app server
 * 
 * @returns {Promise<string>} returns ID of updated bundle
 */
const updateBundle = async (params: Parameters<TUpdateBundle>[0]): ReturnType<TUpdateBundle> => {
    return await Parse.Cloud.run<CMS.Cloud.updateBundle>('updateBundle', params)
}

export default {
    fetchStripePlans,
    fetchBundles,
    createBundle,
    updateBundle,
}
