<template>
    <div 
        v-if="user"
        v-click-outside="clickOutsideDropdown" 
        class="user-dropdown"
    >
        <div
            class="user-dropdown__toggle"
            :class="{
                'user-dropdown__toggle--prod-env': currentEnvironment === 'production'
            }"
            @click="showMenu = !showMenu"
        >
            <div class="user-dropdown__initial">
                {{ user.firstName[0] }}
            </div>
            <div class="user-dropdown__name">
                {{ user.firstName }}
            </div>
        </div>
        <div
            v-if="showMenu"
            class="user-dropdown__menu"
        >
            <ul>
                <li
                    role="img"
                    :aria-label="user.firstName"
                    class="user-dropdown__item"
                >
                    <div class="user-dropdown__version">
                        {{ version }}
                    </div>
                    <div aria-hidden="true" class="user-dropdown__top">
                        <div class="user-dropdown__menu-initial">
                            <span>{{ user.firstName[0] }}</span>
                        </div>
                        <div
                            v-if="user.firstName || user.lastName" 
                            class="user-dropdown__menu-name"
                        >
                            {{ user.firstName }} {{ user.lastName }}
                        </div>
                    </div>
                </li>
                <li
                    ref="user-dropdown-item-2"
                    class="user-dropdown__item user-dropdown__role-item"
                    :aria-label="user.role"
                >
                    <div
                        class="user-dropdown__role"
                    >
                        {{ user.role }}
                    </div>
                    <div
                        class="user-dropdown__email"
                    >
                        {{ user.username }}
                    </div>
                </li>
                <li
                    ref="user-dropdown-item-3"
                    tabindex="0"
                    class="user-dropdown__item user-dropdown__account-settings"
                    role="link"
                    @click="accountSettingsClicked"
                    @keydown.enter="accountSettingsClicked"
                >
                    Account Settings
                </li>
                <li
                    ref="user-dropdown-item-4"
                    tabindex="0"
                    class="user-dropdown__item user-dropdown__sign-out"
                    role="link"
                    @click="signOutClicked"
                    @keydown.enter="signOutClicked"
                    @mousedown.prevent
                >
                    Sign Out
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import Loading from '@/components/Loading.vue'
import { usersModule } from '@/store/users/module'
import { environment } from '@/utils'

@Component({
    components: {
        Loading,
    },
})
export default class UserDropdown extends Vue {
    version = import.meta.env.VUE_APP_VERSION
    showMenu = false

    get currentEnvironment () {
        return environment
    }

    clickOutsideDropdown () {
        this.showMenu = false
    }

    get user () {
        return usersModule.getters.getCurrentUser()
    }

    get firstName () {
        return this.user?.firstName || ''
    }

    get lastName () {
        return this.user?.lastName || ''
    }

    accountSettingsClicked () {
        this.$router.push({
            name: 'settings',
        })
        this.showMenu = false
    }

    async signOutClicked () {
        this.showMenu = false
        await usersModule.actions.logOut()
        this.$router.push({ name: 'login' })
    }
}
</script>

<style lang="scss" scoped>

.user-dropdown {
    &__toggle {
        display: flex;
        align-items: center;
        margin-right: 40px;
        cursor: pointer;
        padding: 5px 8px 5px 6px;
        user-select: none;
        border-radius: 6px;
        border: 1px solid transparent;

        &:hover {
            background-color: rgba(white, 0.15);
        }

        &:focus {
            background: $brand-black;
            border: 1px solid $banana-bread;
            outline: none;
        }

        &--prod-env {
            &:hover {
                background-color: $brand-black;
            }
        }
    }

    &__version {
        width: 35px;
        background-color: $pearl;
        border-radius: 3px;
        padding: 0px 3px;
        position: absolute;
        right: 10px;
        top: 10px;
        color: $slate;
        line-height: 16px;
        font-size: 12px;
        font-weight: 600;
    }

    &__initial {
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0.3px;
        text-align: center;
        background-color: $buttermilk;
        color: #6e4700;
        border-radius: 26px;
        width: 26px;
        height: 26px;
        overflow: hidden;
        transition: background-color 0.3s;
        user-select: none;
        margin-right: 8px;
        text-transform: uppercase;
    }

    &__name {
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
    }

    &__menu {
        position: fixed;
        top: 52px;
        right: 15px;
        background: $white;
        box-shadow: 0 6px 22px 0 rgba($brand-black, 0.1);
        color: $night;
        border-radius: 0 0 8px 8px;
        width: 220px;
        z-index: 10;
    }

    &__item {
        margin: 0;
        border-bottom: 1px solid $gray-divider;
        font-size: 14px;
        line-height: 17px;
        padding: 0 15px;
        min-height: 40px;
        display: flex;
        align-items: center;

        &:last-child {
            border-bottom: none;
            border-radius: 0 0 8px 8px;
        }
    }

    &__top {
        margin: 0 auto;
    }

    &__menu-initial {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        color: $flat-brown;
        margin: 23px auto 12px;
        background: linear-gradient(216.65deg, $beach-sand 0%, $cream-puff 100%);
        line-height: 1px;
        font-size: 24px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
    }

    &__menu-name {
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 18px;
        color: #6d4705;

        &--force-break {
            word-break: break-all;
        }
    }

    &__role-item {
        display: block;
        margin-top: 10px;
        border-bottom: none;
    }

    &__role {
        font-size: 14px;
        font-weight: 600;
    }

    &__email {
        font-size: 13px;
    }

    &__account-settings {
        cursor: pointer;
        outline: none;
        position: relative;
        color: $brand-blue;
        border-top: none;
        font-weight: 600;

        &:focus::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            border: 1px solid $brand-blue;
            border-radius: 6px;
        }
    }

    &__sign-out {
        cursor: pointer;
        outline: none;
        position: relative;

        &:hover {
            background-color: $gray-background;
        }

        &:focus::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            border: 1px solid $brand-blue;
            border-radius: 6px;
        }
    }
}
</style>
