<template>
    <div class="jobs">
        <div class="jobs__error">
            {{ error }}
        </div>
        <div
            class="jobs__title"
        >
            <TitleText>
                Jobs
            </TitleText>
            <PocketButton
                v-if="isAdmin"
                class="jobs__create-job-btn"
                @click="createJobClicked"
            >
                Create Job
            </PocketButton>
        </div>
        <List
            v-if="listOptions && !isLoading"
            store-name="jobsList"
            list-unit="Job"
            :list-options="listOptions"
            :force-show-controls="true"
            @itemClicked="jobClicked"
        >
            <template #tableColumnLabelText="{ column }">
                <template v-if="[ 'editorName', 'editorProgress' ].includes(column.propName)">
                    <div class="jobs__column-label-with-key">
                        <div class="jobs__editor-key-icon" /> {{ column.name }}
                    </div>
                </template>
                <template v-else-if="[ 'writerName', 'writerProgress' ].includes(column.propName)">
                    <div class="jobs__column-label-with-key">
                        <div class="jobs__writer-key-icon" /> {{ column.name }}
                    </div>
                </template>
                <template v-else-if="column.propName === 'hasUnreadMessages'">
                    <Tooltip
                        v-if="showChatColumnLabelTooltip"
                        class="jobs__chat-icon-tooltip"
                        theme="leftalign"
                    >
                        Unread messages in job chat
                    </Tooltip>
                    <Icon
                        class="jobs__chat-icon"
                        :type="'chat'"
                        @mouseenter="showChatColumnLabelTooltip = true"
                        @mouseleave="showChatColumnLabelTooltip = false"
                    />
                </template>
            </template>
            <template #tableCellValue="{ row, column }">
                <template v-if="['writerName', 'editorName', 'leadName'].includes(column.propName)">
                    <OverflowTooltip>
                        {{ formatNameLastInitial(row[column.propName]) || '--' }}
                    </OverflowTooltip>
                </template>
                <template v-if="column.propName === 'hasUnreadMessages'">
                    <div v-if="row.hasUnreadMessages === 'Yes'" class="jobs__unread-message-dot" />
                    <div v-else />
                </template>
            </template>
            <template #emptyStateMessage="{ searchAll }">
                <template v-if="searchAll">
                    All column fields except for progress percents and unread chats are searchable.
                </template>
            </template>
        </List>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator'
import { List, type IListOptions, type TSchema, type IProgressGroupItem } from '@/components/Lists'
import type { CMS } from '@pocketprep/types'
import { usersModule } from '@/store/users/module'
import jobsModule from '@/store/jobs/module'
import TitleText from '@/components/TitleText.vue'
import UIKit from '@pocketprep/ui-kit'
import { formatDate } from '@/utils'
import examDraftsModule from '@/store/examDrafts/module'
import { chatModule } from '@/store/chat/module'
import mockExamDraftsModule from '@/store/mockExamDrafts/module'
import sidebarModule from '@/store/sidebar/module'

interface IMappedJob {
    objectId: string
    nativeAppName: string
    jobType: string
    leadName?: string
    editorName?: string
    writerName?: string
    editorProgress?: number
    writerProgress?: number
    progressGroups?: IProgressGroupItem[]
    dueDate?: string
    hasUnreadMessages: string
    questionBank: string
}

@Component({
    components: {
        List,
        TitleText,
        PocketButton: UIKit.Button,
        Icon: UIKit.Icon,
        Tooltip: UIKit.Tooltip,
        OverflowTooltip: UIKit.OverflowTooltip,
    },
})
export default class JobList extends Vue {
    @Prop() readonly parentJobs?: CMS.Cloud.JobWithMetrics[]
    @Prop() readonly examId?: string
    @Prop() readonly examDraftId?: string

    listOptions: IListOptions<IMappedJob> | null = null
    isLoading = true
    error: string | null = null
    unreadMessages: CMS.Class.MessageJSON[] = []
    showChatColumnLabelTooltip = false

    get isAdmin () {
        return usersModule.getters.getIsAdmin()
    }

    get currentUser () {
        return usersModule.getters.getCurrentUser()
    }

    get users () {
        return usersModule.state.users
    }

    get jobs () {
        return jobsModule.state.jobs
    }

    get examDrafts () {
        return examDraftsModule.state.examDrafts
    }

    get examDraftsById () {
        return this.examDrafts.reduce((acc, examDraft) => {
            acc[examDraft.objectId] = examDraft
            return acc
        }, {} as { [objectId: string]: CMS.Class.ExamDraftJSON })
    }

    get mockExamDraftAcronymsAndNamesById () {
        const allMockExamDrafts = Object.values(mockExamDraftsModule.state.mockExamDrafts).flat()
        return allMockExamDrafts.reduce((acc, mockExamDraft) => {
            acc[mockExamDraft.objectId] = { name: mockExamDraft.name || '', acronym: mockExamDraft.acronym || '' }
            return acc
        }, {} as {[ mockExamDraftId: string ]: { name: string; acronym: string }})
    }

    get jobExamNames () {
        const examNameSet = new Set<string>()
        this.jobs.forEach(job => {
            const jobExamDraft = this.examDraftsById[job.examDraftId]
            examNameSet.add(jobExamDraft.nativeAppName)
        })
        return [ ...examNameSet ].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    }

    get jobIdsWithUnreadMessages () {
        return this.unreadMessages.map(message => message.job.objectId)
    }

    formatNameLastInitial (name?: string) {
        if (!name) {
            return ''
        }
        const firstName = name.split(' ')[0] || ''
        const lastName = name.split(' ')[1] || ''
        return `${firstName} ${lastName.slice(0, 1)}`
    }

    formatMockExamName (name: string) {
        // formatting example "Mock Exam III" to "MEIII"
        const words = name.split(' ')
        const lastThreeWords = words.slice(-3)
        const result = `${lastThreeWords[0][0]}${lastThreeWords[1][0]}${lastThreeWords[2]}`

        return result
    }
    
    createJobClicked () {
        this.$router.push({
            name: 'job-create',
            query: this.examId
                ? { examId: this.examId }
                : this.examDraftId
                    ? { examDraftId: this.examDraftId }
                    : undefined,
        })
    }

    jobClicked (job: IMappedJob) {
        this.$router.push({
            name: 'job-view',
            params: { 
                jobId: job.objectId,
            },
        })
    }

    mapJobsToListOptions (jobs: CMS.Cloud.JobWithMetrics[]): IListOptions<IMappedJob> {
        const listData: IMappedJob[] = (this.parentJobs || jobs)
            .filter(({ editorId, writerId, isCompleted }) =>  
                this.isAdmin || 
                (
                    [ editorId, writerId ].includes(this.currentUser?.objectId) &&
                    !isCompleted
                )
            ).map(({
                type,
                objectId, 
                editorId, 
                writerId,
                leadId,
                dueDate,
                editorPercent,
                writerPercent,
                examDraftId,
                mockExamDraftId,
            }) => {
                const jobExamDraft = this.examDraftsById[examDraftId]
                const mockExam = mockExamDraftId && this.mockExamDraftAcronymsAndNamesById[mockExamDraftId]
                const mappedListData: IMappedJob = {
                    objectId,
                    nativeAppName: jobExamDraft.nativeAppName,
                    questionBank: mockExam
                        ? mockExam.acronym
                            ? mockExam.acronym
                            : this.formatMockExamName(mockExam.name)
                        : 'SQB',
                    jobType: type || '',
                    writerName: writerId && usersModule.getters.getDisplayName(writerId) || '',
                    editorName: editorId && usersModule.getters.getDisplayName(editorId) || '',
                    leadName: leadId && usersModule.getters.getDisplayName(leadId) || '',
                    dueDate: dueDate && formatDate(dueDate),
                    hasUnreadMessages: this.jobIdsWithUnreadMessages.includes(objectId) ? 'Yes' : 'No',
                    editorProgress: editorPercent || 0,
                    writerProgress: writerPercent || 0,
                    progressGroups: [
                        {
                            count: writerPercent || 0,
                            total: 100,
                            label: 'Writer',
                            color: 'blue' as const,
                        },
                        {
                            count: editorPercent || 0,
                            total: 100,
                            label: this.isAdmin ? 'Completed' : 'Editor',
                            color: 'green' as const,
                        },
                    ],
                }

                return mappedListData
            })

        const currentUser = this.currentUser
        const listSchema: TSchema<IMappedJob> = [
            {
                propName: 'nativeAppName',
                label: 'Exam',
                type: 'text',
                options: {
                    width: 200,
                    typeahead: true,
                },
                data: this.jobExamNames,
            },
            {
                propName: 'jobType',
                label: 'Job Type',
                type: 'text',
                options: {
                    width: 200,
                },
            },
            {
                propName: 'questionBank',
                label: 'Bank',
                type: 'text',
                options: {
                    width: 60,
                },
            },
            {
                propName: 'leadName',
                label: 'Lead',
                type: 'text',
                options: {
                    width: 100,
                    filter: !this.parentJobs && this.isAdmin && currentUser?.firstName && currentUser?.lastName
                        ? `${currentUser?.firstName} ${currentUser?.lastName}`.trim()
                        : '',
                },
                data: this.users
                    .filter(user => user.role === 'Admin')
                    .map(user => user.firstName + ' ' + user.lastName)
                    .sort(),
            },
            {
                propName: 'editorName',
                label: 'Editor',
                type: 'text',
                options: {
                    width: 100,
                },
                data: this.users
                    .filter(user => user.role !== 'Writer')
                    .map(user => user.firstName + ' ' + user.lastName)
                    .sort(),
            },
            {
                propName: 'writerName',
                label: 'Writer',
                type: 'text',
                options: {
                    width: 100,
                },
                data: this.users
                    .filter(user => user.role !== 'Editor')
                    .map(user => user.firstName + ' ' + user.lastName)
                    .sort(),
            },
            {
                propName: 'editorProgress',
                label: '%',
                type: 'percent',
                options: {
                    width: 65,
                    filter: false,
                },
            },
            {
                propName: 'writerProgress',
                label: '%',
                type: 'percent',
                options: {
                    width: 65,
                    filter: false,
                },
            },
            {
                propName: 'progressGroups',
                label: 'Progress',
                type: 'progressGroup',
                options: {
                    width: 185,
                },
            },
            {
                propName: 'dueDate',
                label: 'Due Date',
                type: 'date',
                options: {
                    width: 106,
                },
            },
            {
                propName: 'hasUnreadMessages',
                label: 'Unread messages in job chat',
                type: 'text',
                options: {
                    width: 45,
                    labelStyles: {
                        padding: '5px 0 7px 15px',
                    },
                    sort: false,
                    filter: false,
                },
                data: [ 'Yes', 'No' ],
            },
        ]

        return {
            listData,
            listSchema,
            defaultSort: {
                propName: 'dueDate',
                sortDir: 'ASC',
            },
        }
    }

    async mounted () {
        this.isLoading = true
        try {
            await Promise.all([
                jobsModule.actions.fetchJobs(),
                examDraftsModule.actions.fetchExamDrafts(),
            ])
            const mockJobExamDraftIds = this.jobs.filter(job => job.mockExamDraftId).map(job => job.examDraftId)
            await Promise.all(mockJobExamDraftIds.map(jobExamDraftId =>
                mockExamDraftsModule.actions.fetchMockExamDrafts({ examDraftId: jobExamDraftId })
            ))
        } catch (err) {
            this.error = 'Unable to Load Jobs'
        }
        
        if (this.jobs.length && this.currentUser) {
            this.unreadMessages = await chatModule.actions.fetchUnreadMessagesByJobIds({
                jobIds: this.jobs.map(job => job.objectId),
                userId: this.currentUser?.objectId,
            })
        }
        this.listOptions = this.mapJobsToListOptions(this.jobs)
        this.isLoading = false

        sidebarModule.state.lastSelectedJobStatus = null
    }
}
</script>

<style lang="scss" scoped>

.jobs {
    flex: 1;
    padding: $base;
    padding-bottom: 0;
    margin-top: 52px;

    &__error {
        color: red;
    }

    &__title {
        display: flex;
        align-items: center;
        padding-left: 10px;
        margin-top: 46px;
        margin-bottom: 34px;
    }

    &__create-job-btn {
        margin-left: auto;
        margin-right: 10px;
        margin-bottom: 7px;
    }

    &__column-label-with-key {
        display: inline-flex;
        align-items: center;
    }

    &__editor-key-icon {
        width: 11px;
        height: 11px;
        margin-right: 5px;
        margin-bottom: 1px;
        border-radius: 2px;
        background-color: $spectral-green;
    }

    &__writer-key-icon {
        width: 11px;
        height: 11px;
        margin-right: 5px;
        margin-bottom: 1px;
        border-radius: 2px;
        border: 1px solid $scifi-takeout;
        background: url('@/assets/img/job-progress-pattern.svg'), rgba($jungle-green, 0.2);
        background-position: 1px 0.5px;
    }

    &__chat-icon-tooltip {
        position: relative;
        top: -32px;
        right: -14px;
    }

    &__chat-icon {
        width: 21px;
        height: 19px;
        color: $brand-blue;
    }

    &__unread-message-dot {
        width: 9px;
        height: 9px;
        margin: 6px 2px 0 9px;
        background-color: $brand-blue;
        border-radius: 100%;
    }
}
</style>