<template>
    <div class="med">
        <div class="med__settings">
            <PocketButton
                v-if="mockExamDraftChanged"
                class="med__save-btn"
                :disabled="isLoading"
                @click="saveMockExamDraft"
            >
                Save
            </PocketButton>
            <div class="column-grid">
                <div class="med__name-container">
                    <FormGroup label="Name">
                        <TextField
                            class="med__name"
                            v-model="mockExamName"
                        />
                    </FormGroup>
                    <FormGroup label="Acronym" class="med__acronym">
                        <TextField
                            v-model="acronym"
                        />
                    </FormGroup>
                </div>
                <FormGroup label="Duration (in minutes)">
                    <TextField
                        v-model="durationMinutes"
                    />
                </FormGroup>
                <FormGroup label="Enabled">
                    <SelectField 
                        v-model="enabled" 
                        :data="[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]"
                    />
                </FormGroup>
            </div>
            <FormGroup label="Description" class="med__description">
                <EditorField
                    v-model="description"
                    size="large"
                    class="med__editor"
                    :enable-comments="false"
                    :editor-context="mockExamDraft ? mockExamDraft.objectId : 'newMockExamDraft'"
                    :editor-refresh="lastRefreshTime"
                />
            </FormGroup>
            <FormSection
                class="med__questions"
                :label="`Question Drafts (${questionDraftListOptions.listData.length}) <span>${
                    mockExamDraft && mockExamDraft.questionSerials.length || 0} total questions</span>`"
                :toggleable="true"
            >
                <List
                    class="med__questions-list"
                    :list-options="questionDraftListOptions"
                    @itemClicked="questionDraftClicked"
                />
            </FormSection>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'
import {
    TextField,
    FormValidation,
    FormGroup,
    SelectField,
    EditorField,
    FormSection,
} from '@/components/Forms'
import type { CMS } from '@pocketprep/types'
import { type IListOptions, List } from '@/components/Lists'
import type { TQuestionType } from '@/store/types'
import questionDraftsModule from '@/store/questionDrafts/module'
import mockExamDraftsModule from '@/store/mockExamDrafts/module'
import UIKit from '@pocketprep/ui-kit'

interface IMappedQuestion {
    objectId: string
    serial: string
    prompt: string
    type: TQuestionType
    draftType: 'New' | 'Update'
    isArchived: 'Yes' | 'No'
    dateAdded?: string
    jobStatus?: string
}

@Component({
    components: {
        FormSection,
        TextField,
        SelectField,
        FormGroup,
        FormValidation,
        EditorField,
        List,
        PocketButton: UIKit.Button,
    },
})
export default class MockExamDraftForm extends Vue {
    @Prop() mockExamDraft?: CMS.Class.MockExamDraftJSON

    isLoading = false
    mockExamName = ''
    acronym = ''
    durationMinutes = ''
    enabled = false
    description = ''
    lastRefreshTime = Date.now()
    mappedQuestionDrafts: IMappedQuestion[] = []

    get mockExamDraftChanged () {
        return this.mockExamDraft?.name !== this.mockExamName
            || this.mockExamDraft?.durationSeconds !== Number(this.durationMinutes) * 60
            || this.mockExamDraft?.enabled !== this.enabled
            || this.mockExamDraft?.description !== this.description
            || this.acronym && this.mockExamDraft?.acronym !== this.acronym
    }

    get questionDraftListOptions (): IListOptions<IMappedQuestion> {
        return {
            listData: this.mappedQuestionDrafts,
            listSchema: [
                {
                    propName: 'type',
                    label: 'Question Type',
                    type: 'text',
                    options: {
                        minWidth: 150,
                        group: 0,
                    },
                },
                {
                    propName: 'draftType',
                    label: 'Draft Type',
                    type: 'text',
                    data: [ 'New', 'Update' ],
                    options: {
                        isHidden: true,
                        group: 0,
                    },
                },
                {
                    propName: 'isArchived',
                    label: 'Archived',
                    type: 'text',
                    data: [ 'Yes', 'No' ],
                    options: {
                        isHidden: true,
                        group: 0,
                    },
                },
                {
                    propName: 'prompt',
                    label: 'Question',
                    type: 'text',
                    options: {
                        minWidth: 250,
                        style: 'overflow-ellipsis',
                        group: 1,
                    },
                },
            ],
            defaultSort: {
                propName: 'draftType',
                sortDir: 'ASC',
            },
            listDataIcons: [
                data =>
                    data.isArchived === 'Yes'
                        ? {
                            iconName: 'archive',
                            label: 'Archived',
                            styles: { color: '#fff', backgroundColor: '#475964' },
                        }
                        : false,
            ],
            listDataModifiers: [
                data => data.draftType === 'New' && { backgroundColor: 'rgb(220, 235, 220)' },
                data => data.isArchived === 'Yes' && { opacity: '0.5' },
            ],
        }
    }

    async mounted () {
        if (!this.mockExamDraft) {
            return
        }

        this.updateComponentMockExamDraft()

        const questionDrafts = (await questionDraftsModule.actions.fetchQuestionDrafts({
            containedIn: {
                serial: this.mockExamDraft.questionSerials,
            },
        })).results
        this.mappedQuestionDrafts = questionDrafts.map(questionDraft => {
            return {
                ...questionDraft,
                serial: questionDraft.serial || questionDraft.objectId || '',
                isArchived: questionDraft.isArchived ? 'Yes' : 'No',
                draftType: questionDraft.examDataId ? 'Update' : 'New',
                prompt: questionDraft.prompt || '',
            }
        })
    }

    updateComponentMockExamDraft () {
        this.mockExamName = this.mockExamDraft?.name || ''
        this.durationMinutes = String(Math.floor((this.mockExamDraft?.durationSeconds || 0) / 60))
        this.enabled = this.mockExamDraft?.enabled || false
        this.description = this.mockExamDraft?.description || ''
        this.acronym = this.mockExamDraft?.acronym || ''
    }

    async saveMockExamDraft () {
        this.isLoading = true
        const durationSeconds = Number(this.durationMinutes) * 60
        const examDraftId = typeof this.$route.params.examDraftId === 'string'
            ? this.$route.params.examDraftId
            : this.$route.params.examDraftId[0]

        if (this.mockExamDraft) {
            await mockExamDraftsModule.actions.updateMockExamDraft({
                ...this.mockExamDraft,
                name: this.mockExamName,
                durationSeconds,
                enabled: this.enabled,
                description: this.description,
                acronym: this.acronym,
            })
        } else {
            await mockExamDraftsModule.actions.createMockExamDrafts({
                examDraftId,
                payload: [{
                    name: this.mockExamName,
                    durationSeconds,
                    enabled: this.enabled,
                    description: this.description,
                    acronym: this.acronym,
                    questionSerials: [],
                }],
            })
        }
        await mockExamDraftsModule.actions.fetchMockExamDrafts({
            examDraftId,
            forceFetch: true,
        })

        this.isLoading = false
        this.updateComponentMockExamDraft()
        this.emitSaved()
    }

    questionDraftClicked (questionDraft: IMappedQuestion) {
        this.$router.push({
            name: 'question-draft-edit',
            params: {
                questionDraftId: String(questionDraft.objectId),
            },
        })
    }

    @Emit('saved')
    emitSaved () {
        return true
    }
}
</script>

<style lang="scss" scoped>

.med {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid $cloudy;
    border-radius: 4px;
    padding: 10px;
    margin-top: $base;
    margin-bottom: $base;
    background-color: white;
    position: relative;

    &__settings {
        width: 100%;
    }

    &__name-container {
        display: flex;
    }

    &__name {
        width: 300px;
    }

    &__acronym {
        width: 116px;
        margin-left: 16px;
    }

    &__description {
        margin-left: 16px;
        width: 100%;
    }

    &__editor {
        @include inputStyles;

        padding: 0;
        width: calc(100% - 32px);
        max-width: 100%;
    }

    &__questions {
        max-width: calc(100% - 32px);
        margin-left: 16px;
    }

    &__questions-list {
        padding-top: 20px;
    }

    &__save-btn {
        position: absolute;
        right: 26px;
        top: 26px;
        z-index: 1;
    }
}
</style>