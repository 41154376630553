<template>
    <div id="overlay" class="overlay">
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

@Component
export default class Overlay extends Vue {
}
</script>

<style lang="scss" scoped>
.overlay {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($brand-black, .15);
    z-index: 100;
}
</style>