import { reactive } from 'vue'
import state from './state'
import actions from './actions'

const questionsModule = {
    state: reactive(state),
    actions,
}

export default questionsModule
