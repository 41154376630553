<template>
    <div
        v-if="messages && messages.length"
        class="form-validation"
    >
        <ul>
            <li
                v-for="(message, index) in messagesFormatted"
                :key="index"
                :class="message.type"
            >
                {{ message.text }}
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import * as R from 'ramda'
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator'

interface IMessageCode {
    code?: string
    text?: string
}
interface IMessageCodes {
    error: IMessageCode[]
    warning: IMessageCode[]
    success: IMessageCode[]
}

@Component
export default class FormValidation extends Vue {
    @Prop({ type: Array }) readonly messages?: []

    messageCodes: IMessageCodes = {
        error: [
            { code: 'emailFormat', text: 'Invalid email address.' },
            { code: 'requiredField', text: 'Complete all required fields.' },
            { text: 'Form validation error.' },
        ],
        warning: [],
        success: [
            { code: 'default', text: 'Form submitted successfully.' },
        ],
    }

    @Watch('messages', { deep: true })
    messagesChanged () {
        if (this.messages && this.messages.length) {
            window.scrollTo(0,0)
        }
    }

    get messagesFormatted () {
        return R.uniq(this.messages || []).map((message: string) => {
            const [ type, code ] = message.split('/') as [ keyof IMessageCodes, string ]
            const messageObj = R.find<IMessageCode>(
                m => m.code === code, this.messageCodes[type]
            )

            return messageObj
                ? { ...messageObj, type }
                : { text: code, type }
        })
    }
}
</script>
<style lang="scss" scoped>

.form-validation {
    margin: 10px 0;

    ul {
        list-style: none;

        li {
            font-size: 14px;
            padding: 10px;
            margin-bottom: 5px;
            color: $brand-black;
            border-radius: 6px;

            &:last-child {
                margin-bottom: 0;
            }

            &.error {
                border: 1px solid rgba(221, 0, 0, 0.6);
                background-color: $barely-red;
            }

            &.warning {
                border: 1px solid $butterscotch;
                background-color: white;
            }

            &.success {
                border: 1px solid rgba(4, 152, 117, 0.6);
                background-color: $barely-green;
            }
        }
    }
}
</style>
