<template>
    <div class="exam-draft-form">
        <Loading v-if="isLoading" />
        <FormValidation :messages="validationMessages" />
        <div class="exam-draft-form__header">
            <div
                v-if="examVersionName"
                class="exam-draft-form__exam-name"
            >
                {{ examVersionName }}
            </div>
            <TitleText
                class="exam-draft-form__title-text"
                :class="{
                    'exam-draft-form__title-text--create': pageTitle === 'Create Exam Draft' && !examVersionName
                }"
            >
                {{ pageTitle }}
            </TitleText>
        </div>
        <FormSection
            v-if="examDraft"
            label="Exam Settings"
            class="exam-draft-form__settings"
            :column-grid="true"
        >
            <FormGroup 
                label="Exam Name *" 
                subtext="Example: ACT"
            >
                <TextField v-model="examDraft.nativeAppName" @update:modelValue="hasUnsavedChanges" />
            </FormGroup>
            <FormGroup
                label="Exam Version Name *"
                subtext="Use exam code, if available. Otherwise use exam name month and year: CASP+ November 2022"
            >
                <TextField v-model="examVersionName" @update:modelValue="hasUnsavedChanges" />
            </FormGroup>
            <FormGroup
                label="Middle Length Descriptive Name *"
                subtext="Match website's exam search"
            >
                <TextField v-model="examDraft.descriptiveName" @update:modelValue="hasUnsavedChanges" />
            </FormGroup>
            <FormGroup
                label="Exam Version Information *"
                :subtext="'Example: CompTIA® launched CAS-003 on April 2, 2018 '
                    + 'and will retire this version on April 5, 2022'"
            >
                <TextareaField
                    v-model="examVersionInfo"
                    @update:modelValue="hasUnsavedChanges"
                />
            </FormGroup>
            <FormGroup
                label="App ID *"
                subtext="Example: com.pocketprep.test"
            >
                <TextField
                    v-model="examDraft.appId"
                    :disabled="!!(examDraft.examMetadataId)"
                    @update:modelValue="hasUnsavedChanges"
                />
            </FormGroup>
            <FormGroup
                label="New Exam Version Alert Message *"
                :subtext="'The CompTIA® has released a new version of CASP+. If you\’re preparing for this '
                    + 'new version, we have new prep content available (updated questions, explanations, and '
                    + 'references).'"
            >
                <TextareaField
                    v-model="examVersionAlertMessage"
                    @update:modelValue="hasUnsavedChanges"
                />
            </FormGroup>
            <FormGroup
                label="Hide References"
                :subtext="exam && originalTextMessage(
                    examDraft.hideReferences ? 'Yes' : 'No',
                    exam.hideReferences ? 'Yes' : 'No'
                )"
            >
                <SelectField 
                    v-model="examDraft.hideReferences" 
                    :data="[{ value: false, label: 'No' }, { value: true, label: 'Yes' }]" 
                    @update:modelValue="hasUnsavedChanges"
                />
            </FormGroup>
            <FormGroup 
                label="Is Free"
                :subtext="exam && originalTextMessage(
                    examDraft.isFree ? 'Yes' : 'No',
                    exam.isFree ? 'Yes' : 'No'
                )"
            >
                <SelectField 
                    v-model="examDraft.isFree" 
                    :data="[{ value: false, label: 'No' }, { value: true, label: 'Yes' }]" 
                    @update:modelValue="hasUnsavedChanges"
                />
            </FormGroup>
        </FormSection>
        <FormSection
            v-if="examDraft && (examDraftKADrafts instanceof Array)"
            :label="`Subjects (${examDraftKADrafts.length})`"
            :toggleable="true"
            :group-default-open="!examDraft.examMetadataId"
        >
            <div 
                v-if="examDraftKADrafts" 
                :class="`exam-draft-form__knowledge-areas`"
            >
                <template
                    v-for="(knowledgeAreaDiff, index) in examDraftKADrafts"
                    :key="index"
                >
                    <div
                        :class="
                            `exam-draft-form__knowledge-area
                            ${knowledgeAreaDiff.knowledgeAreaDraft.isArchived ? ' isArchived':''}`
                        "
                    >
                        <FormGroup 
                            :subtext="
                                examDraft && (examDraft.objectId || examDraft.examMetadataId)
                                    ? originalTextMessage(
                                        knowledgeAreaDiff.knowledgeAreaDraft.name, 
                                        knowledgeAreaDiff.originalKADraft.name,
                                    )
                                    : ''
                            "
                        >
                            <TextField 
                                v-model="knowledgeAreaDiff.knowledgeAreaDraft.name" 
                                :disabled="knowledgeAreaDiff.knowledgeAreaDraft.isArchived" 
                            />
                        </FormGroup>
                        <FormGroup v-if="examDraft && (examDraft.objectId || examDraft.examMetadataId)">
                            <PocketButton
                                v-if="!knowledgeAreaDiff.knowledgeAreaDraft.isArchived
                                    && knowledgeAreaDiff.originalKADraft.name !== ''"
                                type="tertiary-red"
                                @click="submitUpdateKnowledgeArea(index, 'archive')"
                            >
                                Archive
                            </PocketButton>
                            <PocketButton
                                v-if="!knowledgeAreaDiff.knowledgeAreaDraft.isArchived"
                                class="exam-draft-form__add-subtopics-btn"
                                type="secondary"
                                :disabled="knowledgeAreaDiff.knowledgeAreaDraft.subtopics
                                    && knowledgeAreaDiff.knowledgeAreaDraft.subtopics.length
                                "
                                @click="addKnowledgeAreaSubtopic({ kaIndex: index })"
                            >
                                Add Subtopics
                            </PocketButton>
                            <PocketButton
                                v-if="
                                    (knowledgeAreaDiff.originalKADraft.name 
                                        !== knowledgeAreaDiff.knowledgeAreaDraft.name)
                                        || haveKASubtopicsChanged({ kaIndex: index })
                                "
                                @click="submitUpdateKnowledgeArea(index)"
                            >
                                Save
                            </PocketButton>
                        </FormGroup>
                    </div>
                    <template
                        v-if="knowledgeAreaDiff.knowledgeAreaDraft.subtopics
                            && knowledgeAreaDiff.knowledgeAreaDraft.subtopics.length
                            && !knowledgeAreaDiff.knowledgeAreaDraft.isArchived"
                    >
                        <div class="exam-draft-form__ka-subtopics">
                            <div class="exam-draft-form__subtopics-label">
                                Subtopics
                            </div>
                            <div
                                class="exam-draft-form__subtopic"
                                v-for="(subtopic, subtopicIndex) in knowledgeAreaDiff.knowledgeAreaDraft.subtopics"
                                :key="subtopic.id"
                            >
                                <TextField
                                    class="exam-draft-form__subtopic-input"
                                    v-model="subtopic.name"
                                    :disabled="knowledgeAreaDiff.knowledgeAreaDraft.isArchived" 
                                />
                                <div 
                                    class="exam-draft-form__subtopic-remove" 
                                    @click="removeSubtopic({ kaIndex: index, subtopicIndex: subtopicIndex })"
                                    @mouseover="showRemoveSubtopicTooltip = `${index}_${subtopic.id}`"
                                    @mouseout="showRemoveSubtopicTooltip = null"
                                >
                                    <Tooltip v-if="showRemoveSubtopicTooltip === `${index}_${subtopic.id}`">
                                        Remove Subtopic
                                    </Tooltip>
                                    <Icon
                                        class="exam-draft-form__remove-subtopic-icon"
                                        type="close"
                                    />
                                </div>
                                <div 
                                    class="exam-draft-form__subtopic-add" 
                                    @click="addKnowledgeAreaSubtopic({
                                        kaIndex: index,
                                        subtopicIndex: subtopicIndex + 1
                                    })"
                                    @mouseover="showAddSubtopicTooltip = `${index}_${subtopic.id}`"
                                    @mouseout="showAddSubtopicTooltip = null"
                                >
                                    <Tooltip v-if="showAddSubtopicTooltip === `${index}_${subtopic.id}`">
                                        Add Subtopic
                                    </Tooltip>
                                    <Icon 
                                        class="exam-draft-form__add-subtopic-icon"
                                        type="addCircle"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </div>
            <div v-else class="exam-draft-form__no-knowledge-areas">
                No Subjects
            </div>
            <PocketButton
                v-if="
                    !examDraftKADrafts.length 
                        ||  (
                            examDraftKADrafts.length
                            && (examDraftKADrafts[examDraftKADrafts.length-1].originalKADraft.name !== '')
                        )
                        || (
                            examDraft
                            && !examDraft.objectId
                        ) 
                "
                class="exam-draft-form__add-ka-btn"
                @click="addKnowledgeArea"
            >
                Add Subject
            </PocketButton>
        </FormSection>
        <FormSection
            v-if="examDraft && (examDraft.objectId || examDraft.examMetadataId)"
            class="exam__jobs"
            :label="`Jobs (${examJobs.length})`"
            :toggleable="true"
        >
            <JobList
                v-if="examJobs && examDraft"
                :exam-id="examDraft.examMetadataId"
                :exam-draft-id="examDraft.objectId"
                :parent-jobs="examJobs"
            />
        </FormSection>
        <FormSection
            v-if="examDraft && examDraft.objectId"
            :label="`Question Drafts (${mappedQuestionDrafts.length})`"
            :toggleable="true"
            :group-default-open="true"
        >
            <div v-if="examDraftQuestionStats" class="exam-draft-form__stats">
                <div class="exam-draft-form__stat">
                    <label>Special</label>
                    <span>
                        <span>{{ examDraftQuestionStats.originalSpecialCount }}</span>
                        <VIcon :icon="icons.arrowRight" />
                        <span>{{ examDraftQuestionStats.newSpecialCount }}</span>
                    </span>
                </div>
                <div class="exam-draft-form__stat">
                    <label>Total</label>
                    <span>
                        <span>
                            {{ examDraftQuestionStats.originalTotalCount - examDraftQuestionStats.originalMockCount }}
                        </span>
                        <VIcon :icon="icons.arrowRight" />
                        <span>
                            {{ examDraftQuestionStats.newTotalCount - examDraftQuestionStats.newMockCount }}
                        </span>
                    </span>
                </div>
                <div class="exam-draft-form__stat">
                    <label>Mock</label>
                    <span>
                        <span>{{ examDraftQuestionStats.originalMockCount }}</span>
                        <VIcon :icon="icons.arrowRight" />
                        <span>{{ examDraftQuestionStats.newMockCount }}</span>
                    </span>
                </div>
            </div>
            <div v-else-if="error" class="exam-draft-form__stats-error">
                Unable to load Exam Draft question tier info
            </div>
            <div v-if="selectedQuestionDrafts.length" class="exam-draft-form__question-draft-actions">
                <label>with selected questions:</label>
                <div class="exam-draft-form__question-draft-action-select">
                    <SelectField
                        v-model="bulkQuestionAction"
                        :data="bulkQuestionOptions"
                        placeholder="Select an action"
                        @update:modelValue="bulkEditCheckedQuestions($event)"
                    />
                </div>
            </div>
            <List
                v-if="!isLoading"
                store-name="qDraftsList"
                class="exam-draft-form__question-draft-list"
                :list-options="questionDraftListOptions"
                :selectable="true"
                :pre-selected-items="selectedQuestionDrafts"
                @itemClicked="questionDraftClicked"
                @select="selectedQuestionDrafts = $event"
            />
        </FormSection>
        <FormSection
            v-if="examDraft && examDraft.objectId"
            :label="`Mock Exams (${mockExamDrafts && mockExamDrafts.length || 0})`"
            :toggleable="true"
            :group-default-open="true"
        >
            <PocketButton
                v-if="!showNewMockExamDraftForm"
                class="exam-draft-form__new-mock-exam"
                @click="showNewMockExamDraftForm = true"
            >
                New Mock Exam
            </PocketButton>
            <MockExamDraftForm v-if="showNewMockExamDraftForm" @saved="showNewMockExamDraftForm = false" />
            <template v-if="mockExamDrafts && mockExamDrafts.length">
                <MockExamDraftForm
                    v-for="mockExamDraft in mockExamDrafts"
                    :key="mockExamDraft.objectId"
                    :mock-exam-draft="mockExamDraft"
                />
            </template>
        </FormSection>
        <ActivityList
            v-if="examDraft && examDraft.objectId"
            :activity-query="{ 
                subject: {
                    type: 'Pointer', 
                    value: examDraft.objectId
                }
            }"
        />
        <ButtonFooter
            v-if="(isAdmin && examDraft && !examDraft.objectId && !examDraft.examMetadataId) 
                || (examDraft && isAdmin)"
        >
            <template
                #rightSide 
            >
                <div class="exam-draft-form__right-side-buttons">
                    <PocketButton
                        class="exam-draft-form__cancel-button"
                        type="secondary"
                        @click="cancelExamDraftForm"
                    >
                        Cancel
                    </PocketButton>
                    <PocketButton
                        type="primary"
                        :disabled="!examDraft.nativeAppName || !examVersionName || !examDraft.descriptiveName
                            || !examVersionInfo || !examDraft.appId || !examVersionAlertMessage"
                        @click="submitExamForm"
                    >
                        Create Exam Draft
                    </PocketButton>
                </div>
            </template>
        </ButtonFooter>
        <ButtonFooter v-if="isAdmin && examDraft && examDraft.objectId">
            <template
                #leftSide 
            >
                <div class="exam-draft-form__left-side-buttons">
                    <PocketButton
                        v-if="examDraft && examDraft.objectId"
                        type="primary-red"
                        @click="submitDeleteExamDraft"
                    >
                        Delete Exam Draft
                    </PocketButton>
                </div>
            </template>
            <template
                #rightSide 
            >
                <div class="exam-draft-form__right-side-buttons">
                    <PocketButton
                        class="exam-draft-form__cancel-button"
                        type="secondary"
                        @click="cancelExamDraftForm"
                    >
                        Close
                    </PocketButton>
                    <PocketButton
                        v-if="examDraft.objectId || examDraft.examMetadataId"
                        type="primary"
                        class="exam-draft-form__save-changes"
                        :disabled="!examDraftSettingsChanged || !examDraft.nativeAppName || 
                            !examVersionName || !examDraft.descriptiveName
                            || !examVersionInfo || !examDraft.appId || !examVersionAlertMessage"
                        @click="submitExamForm"
                    >
                        Save Changes
                    </PocketButton>
                    <span class="exam-draft-form__line-separator" />
                    <PocketButton
                        v-if="examDraft && examDraft.objectId"
                        type="primary"
                        @click="prepareExport"
                    >
                        Prepare Export
                    </PocketButton>
                </div>
            </template>
        </ButtonFooter>
    </div>
</template>

<script lang="ts" src="@/components/ExamDrafts/ExamDraftForm.ts"></script>

<style lang="scss" scoped>

.exam-draft-form {
    padding: $base;
    width: 100%;
    margin-top: 52px;

    &__exam-name {
        margin-top: 22px;
        font-size: 16px;
        color: $slate;
        font-weight: 500;
    }

    &__title-text {
        margin-top: 16px;
        margin-bottom: 34px;
        font-size: 40px;

        &--create {
            margin-top: 50px;
        }
    }

    &__error {
        color: red;
        margin-bottom: $base;
    }

    &__settings {
        .exam-draft-form__initial-version {
            display: flex;
            max-width: 300px;
        }

        .exam-draft-form__save-btn {
            margin-left: 16px;
            margin-bottom: 16px;
        }
    }

    &__select-ka {
        padding: 30px;
        max-width: 450px;

        p {
            margin: 0 0 15px;
        }

        select {
            max-width: 100%;
            margin-bottom: 10px;
        }
    }

    &__knowledge-area {
        display: flex;

        &.isArchived {
            input {
                background: #ccc;
            }
        }

        .form-group {
            flex: 1;
            max-width: 340px;
            padding-right: 15px;
        }

        button {
            margin: 4px 15px 4px 0;
        }
    }

    &__add-subtopics-btn {
        width: 140px;
    }

    &__ka-subtopics {
        display: flex;
        flex-direction: column;
        margin-top: -8px;
        margin-left: 24px;
        font-size: 14px;
    }

    &__subtopics-label {
        padding-left: 18px;
    }

    &__subtopic {
        display: flex;
        align-items: center;
    }

    &__subtopic-input {
        margin-left: 18px;
    }

    &__subtopic-remove,
    &__subtopic-add {
        position: relative;
        margin-left: 11px;
        height: 20px;

        svg {
            cursor: pointer;
            color: $brand-blue;
        }

        &:hover svg {
            color: $brand-black;
        }

        .uikit-tooltip {
            position: absolute;
            top: -34px;
            left: 10px;
        }
    }

    &__no-knowledge-areas {
        margin: $base 0;
    }

    &__add-ka-btn {
        margin: $base 0;
    }

    &__options {
        button {
            margin-bottom: $base;
        }
    }

    &__stats {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid $night;
        border-radius: 4px;
        padding: 10px;
        margin-top: $base;
        margin-bottom: $base;
        background-color: white;
    }

    &__stat {
        display: flex;
        flex-direction: column;
        padding: 10px;
        margin-right: 100px;

        label {
            color: #666;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 5px;

            svg {
                margin: 0 5px;
                color: green;
            }
        }

        > span {
            display: flex;
            height: 100%;
            align-items: center;
            word-wrap: break-word;

            svg {
                margin: 0 5px;
                color: green;
            }

            span:last-of-type {
                color: green;
                font-weight: bold;
            }

            button {
                margin-left: $base;
                padding: 10px;
                font-size: 12px;
            }
        }
    }

    &__question-draft-list {
        margin-top: $base;
    }

    &__stats-error {
        color: red;
        margin: 10px 0;
    }

    &__new-mock-exam {
        margin: 20px;
    }

    &__left-side-buttons {
        margin-left: 18px;
    }

    &__right-side-buttons {
        position: absolute;
        right: 0;
        margin-right: 18px;
    }

    &__line-separator {
        border: 1px solid $cloudy;
        border-radius: 3px;
        margin-right: 18px;
        margin-left: 18px;
    }

    &__cancel-button {
        margin-right: 8px;
    }
}
</style>