import type {
    TSaveListState,
    TResetListState,
} from './types'
import listsModule from '@/store/lists/module'

/**
 * Save a list's state in the store
 */
const saveListState = async (listState: Parameters<TSaveListState>[0]): ReturnType<TSaveListState> => {
    const listStatesToUpdate = [ ...listsModule.state.listStates ]
    const stateToUpdate = listStatesToUpdate.find(oldState => oldState.listName === listState.listName)
    if (stateToUpdate) {
        stateToUpdate.currentPageStart = listState.currentPageStart
        stateToUpdate.sort = listState.sort
        stateToUpdate.filters = listState.filters
        stateToUpdate.searchAll = listState.searchAll
        stateToUpdate.data = listState.data
    } else {
        listStatesToUpdate.push(listState)
    }
    listsModule.state.listStates = [ ...listStatesToUpdate ]
}

/**
 * Reset all the list states
 */
const resetListState = async (listName: Parameters<TResetListState>[0]) => {
    const currentListStates = [ ...listsModule.state.listStates ]
    if (listName) {
        const listStateIndex = currentListStates.findIndex(listState => listState.listName === listName)
        listsModule.state.listStates = [
            ...currentListStates.slice(0, listStateIndex),
            ...currentListStates.slice(listStateIndex + 1, currentListStates.length),
        ]
    } else {
        listsModule.state.listStates = []
    }
}

export default {
    saveListState,
    resetListState,
}
