<template>
    <div class="button-footer">
        <slot name="leftSide" />
        <slot name="center" />
        <slot name="rightSide" />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

@Component

export default class ButtonFooter extends Vue {

}
</script>

<style lang="scss" scoped>

.button-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 57px;
}

</style>
