import type { TGetDisplayName, TGetUser } from './types'
import { usersModule } from '@/store/users/module'

const getIsAdmin = (): boolean => {
    const user = usersModule.state.user
    return !!(user && user.role === 'Admin')
}

const getDisplayName = (userId: Parameters<TGetDisplayName>[0]): ReturnType<TGetDisplayName> => {
    const user = usersModule.state.users.find(item => item.objectId === userId)

    return user && `${user.firstName} ${user.lastName}`
}

const getUser = (userId: Parameters<TGetUser>[0]): ReturnType<TGetUser> => {
    return usersModule.state.users.find(item => item.objectId === userId)
}

const getCurrentUser = () => {
    return usersModule.state.user
}

export default {
    getIsAdmin,
    getDisplayName,
    getUser,
    getCurrentUser,
}
