import type { 
    TGetExamDraft,
    TGetExamDraftByMetadataId, 
} from './types'
import examDraftsModule from '@/store/examDrafts/module'

const getExamDraft = (examDraftId: Parameters<TGetExamDraft>[0]): ReturnType<TGetExamDraft> => {
    return examDraftsModule.state.examDrafts.find(examDraft => examDraft.objectId === examDraftId)
}

/**
 * Return the examDraft object with the given app ID
 */
const getExamDraftByMetadataId = (
    examMetadataId: Parameters<TGetExamDraftByMetadataId>[0]
): ReturnType<TGetExamDraftByMetadataId> => {
    return examDraftsModule.state.examDrafts.find(examDraft => examDraft.examMetadataId === examMetadataId)
}

export default {
    getExamDraft,
    getExamDraftByMetadataId,
}
