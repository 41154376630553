<template>
    <Datepicker
        :modelValue="dateValue"
        input-class="date-input"
        input-format="MM/dd/yyyy"
        :clearable="clearable"
        @update:modelValue="valueChange"
    />
</template>

<script lang="ts">
import { formatDate } from '@/utils'
import { Component, Vue, Emit, Prop } from 'vue-facing-decorator'
import Datepicker from 'vue3-datepicker'

@Component({
    components: {
        Datepicker,
    },
})
export default class DateField extends Vue {
    @Prop({ default: false }) clearable!: boolean
    @Prop modelValue!: string | Date

    get dateValue () {
        return this.modelValue ? new Date(this.modelValue) : null
    }

    formatDate = formatDate

    @Emit('update:modelValue')
    valueChange (date: Date | null) {
        return date ? formatDate(date.toString()) : null
    }
}
</script>

<style lang="scss" scoped>
:deep([input-class="date-input"]) {
    @include inputStyles;

    border: 1px solid rgba(136, 163, 182, 0.85);
}
</style>
