<template>
    <input
        class="text-field"
        :type="fieldType"
        :value="modelValue"
        :class="`text-input${center?' text-centered':''}`"
        :disabled="disabled"
        :maxlength="maxlength"
        @input="valueChange"
    >
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from 'vue-facing-decorator'

@Component
export default class TextField extends Vue {
    @Prop({ default: '' }) readonly fieldType?: string
    @Prop({ default: '524288' }) readonly maxlength?: string // that's the default input field length
    @Prop({ default: false }) readonly center?: boolean
    @Prop({ default: '', type: [ Number, String ] }) readonly modelValue!: string
    @Prop({ default: false }) readonly disabled?: boolean

    @Emit('update:modelValue')
    valueChange ($event: Event) {
        return ($event.target as HTMLInputElement).value
    }
}
</script>

<style lang="scss" scoped>

.text-field {
    &:focus {
        outline: none;
        border: 1px solid $brand-blue;
    }
}

.text-input {
    @include inputStyles;

    border: 1px solid rgba(136, 163, 182, 0.85);
    height: 38px;

    &.text-centered {
        text-align: center;
    }
}
</style>
