<template>
    <div
        :class="`message-container${
            sender && currentUser && sender.objectId === currentUser.objectId
                ? ' message-container--from-me'
                : ''
        }${previewLink ? 'message-container--has-preview' : ''}`"
    >
        <div class="message">
            <div v-if="sender" class="message__icon">
                {{ createInitials(sender) }}
            </div>
            <div class="message__content" v-html="fancyContent" />
        </div>
        <div
            v-if="previewLink"
            class="message-container__preview-link"
            v-html="previewLink"
        />
        <div v-if="date" class="message-container__timestamp">
            {{ formatDateTime(date) }}
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator'
import type { IUser } from '@/store/users/types'
import { usersModule } from '@/store/users/module'
import { nl2br } from '@/utils'

@Component
export default class Message extends Vue {
    @Prop() readonly content!: string
    @Prop() readonly date?: string
    @Prop() readonly sender?: IUser

    get currentUser () {
        return usersModule.getters.getCurrentUser()
    }

    get previewLink () {
        const linkRegex = new RegExp(/((?:(?:https?:\/\/)|(?:www\.))[\S]+)/gi),
            links = this.content.match(linkRegex),
            previewLink = links && links.length && links[0],
            linkExtension = previewLink && 
                previewLink.split('.')[previewLink.split('.').length - 1].toLowerCase()

        if (!links || !previewLink || !linkExtension) {
            return false
        }

        if ([ 'jpg', 'jpeg', 'gif', 'png', 'bmp' ].indexOf(linkExtension) !== -1) {
            return `<img src="${previewLink}" alt="image preview" />`
        }

        return false
    }

    get fancyContent () {
        let formattedContent = this.content

        const tmp = document.createElement('div')
        tmp.innerHTML = formattedContent

        formattedContent = tmp.textContent || tmp.innerText || ''
    
        const linkRegex = new RegExp(/((?:(?:https?:\/\/)|(?:www\.))[\S]+)/gi)

        return nl2br(formattedContent.replace(linkRegex, match => {
            return `<a href="${match}" target="_blank">${match}</a>`
        }))
    }

    createInitials (user: IUser) {
        const name = user.firstName + ' ' + user.lastName
        return name.split(' ').map(word => word.substr(0,1)).join('')
    }

    formatDateTime (date: string) {
        const monthNames = [ 'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December',
        ]

        const dateObj = new Date(date)

        return monthNames[dateObj.getMonth()] + ' ' + 
            dateObj.getDate() + ', ' + 
            dateObj.getFullYear() + ' at ' + 
            (dateObj.getHours() > 12 ? dateObj.getHours() - 12 : dateObj.getHours()) + ':' + 
            (dateObj.getMinutes() < 10 ? '0' : '') + dateObj.getMinutes() +
            (dateObj.getHours() >= 12 ? 'pm' : 'am')
    }
}
</script>

<style lang="scss" scoped>

.message-container {
    margin-bottom: 30px;
    position: relative;

    &:last-child,
    &:last-child .preview-link {
        margin-bottom: 0;
    }

    &__preview-link {
        margin: 5px 0;
        max-width: 100%;
        text-align: right;
        overflow: hidden;

        :deep(img) {
            height: 170px;
            display: block;
        }
    }

    &__timestamp {
        position: absolute;
        left: 45px;
        bottom: -20px;
        font-size: 12px;
        line-height: 20px;
        opacity: 0.6;
    }

    &--from-me {
        .message-container__preview-link {
            text-align: left;
        }

        .message-container__timestamp {
            left: auto;
            right: 45px;
        }

        .message {
            flex-flow: row-reverse;

            &__content {
                background-color: darken($light-gray, 10%);
            }
        }
    }

    .message {
        display: flex;

        &__icon {
            width: 30px;
            height: 30px;
            background: $slate;
            color: $white;
            border-radius: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 13px;
            cursor: default;
            user-select: none;
        }

        &__content {
            max-width: 60%;
            border-radius: 5px;
            background-color: $light-gray;
            padding: 10px 10px;
            line-height: 22px;
            overflow-wrap: break-word;
            margin: 0 15px;
        }
    }
}
</style>
