<template>
    <div class="modal">
        <div class="modal__info">
            <div
                v-if="showClose"
                class="modal__close"
                @click="closeModal"
            >
                &times;
            </div>
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from 'vue-facing-decorator'

@Component
export default class Modal extends Vue {
    @Prop({ default: false }) readonly showClose!: boolean

    @Emit('close')
    closeModal () {
        return true
    }
}
</script>

<style lang="scss" scoped>

.modal {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 110vw;
    height: 110vh;
    top: -5vh;
    left: -5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;

    @include breakpoint(900px) {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }

    .modal__info {
        position: relative;
        max-width: calc(100vw - 80px);
        max-height: calc(100vh - 80px);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        border: 1px solid black;
        overflow: auto;
        min-width: 0;

        @include breakpoint(900px) {
            max-width: 100vw;
            max-height: 100vh;
        }

        @include breakpoint(600px) {
            width: 100%;
            height: 100%;
        }

        .modal__close {
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 0;
            padding: 10px;
            font-size: 40px;
            line-height: 24px;
            font-family: Arial, Helvetica, sans-serif;
            user-select: none;
            color: $dark-gray;
            font-weight: 400;
            z-index: 10;
        }
    }
}
</style>