<template>
    <ModalContainer
        :style="{ position: 'fixed' }"
        v-if="modalError && !isLoading"
        @close="modalError = null; latestSubmitAttemptParams = {}"
    >
        <Modal class="question-draft-form__modal-error">
            <div>
                <Icon
                    type="close"
                    class="question-draft-form__modal-close-btn"
                    @click="modalError = null; latestSubmitAttemptParams = {}"
                />
            </div>
            <div class="question-draft-form__modal-text-container">
                <div class="question-draft-form__modal-title">
                    <div v-if="modalError.firstLine">
                        {{ modalError.firstLine }}
                    </div>
                    <div>
                        {{ modalError.modalTitle }}
                    </div>
                </div>
                <div class="question-draft-form__modal-subtext">
                    {{ modalError.modalSubtext }}
                </div>
            </div>
            <div
                v-if="modalError.modalTitle === 'Question Draft'
                    || modalError.modalTitle === 'Missing Exam'"
                class="question-draft-form__modal-buttons"
            >
                <PocketButton
                    :type="modalError.modalTitle === 'Missing Exam' ? 'primary' :'secondary'"
                    @click="modalError = null; latestSubmitAttemptParams = {}"
                >
                    Close
                </PocketButton>
                <PocketButton
                    v-if="modalError.modalTitle === 'Question Draft'"
                    type="primary"
                    @click="submitQuestionForm(latestSubmitAttemptParams)"
                >
                    Retry
                </PocketButton>
            </div>
            <div
                v-else
                class="question-draft-form__modal-buttons"
            >
                <PocketButton
                    type="primary"
                    @click="$router.push({
                        name: 'job-view',
                        params: { 
                            jobId: jobId || job?.objectId,
                        },
                    })"
                >
                    Go to Job View
                </PocketButton>
            </div>
        </Modal>
    </ModalContainer>
    <QuestionDraftSidebar
        v-if="job && isSerialUpdated && questionDraftsFetched"
        :job="job"
        :questionDrafts="jobQuestionDrafts"
        :kaDrafts="knowledgeAreaDrafts"
        :userRole="currentUser?.role"
        :currentQuestionSerial="serial"
        :unsavedQuestionDraftSubject="kaNameForUnsavedDraft()"
        :unsavedQuestionDraftPrompt="unsavedDraftPrompt()"
        :currentSubjectId="knowledgeAreaDraftId"
        :currentSubtopicId="subtopicId"
        :showCreateQuestionsOnLoad="(jobStatus !== 'Editor' && jobStatus !== 'Completed')
            || currentUser?.role === 'Writer'"
        @questionClicked="selectQuestion"
        @nextQuestion="updateNextQuestion"
    >
    </QuestionDraftSidebar>
    <div
        v-if="!isLoading"
        class="question-draft-form"
        :class="{ 'question-nojob-create': !job }"
    >
        <Toast
            v-if="toast"
            :right="true"
            class="question-draft-form__toast"
            @close="toast = null"
        >
            {{ toast }}
            <template #icon>
                <template v-if="toast === 'Question recalled' || toast === 'Question sent back to Editor'">
                    <Icon type="arrow" class="question-draft-form__toast-icon--arrow" />
                </template>
                <template v-else-if="toast === 'Question flagged back to writer'">
                    <Icon type="flagFeedback" class="question-draft-form__toast-icon--filled-flag" />
                </template>
                <template v-else-if="toast === 'Question Unflagged'">
                    <Icon type="flagContent" class="question-draft-form__toast-icon--unfilled-flag" />
                </template>
                <template v-else-if="toast === 'Question Deleted'">
                    <Icon type="close" class="question-draft-form__toast-icon--delete" />
                </template>
            </template>
            <template #subtext v-if="toastSubtext">
                {{ toastSubtext }}
            </template>
        </Toast>
        <div v-if="!isLoading && job && jobName && jobStatus" class="question-draft-form__clarifying-text">
            <PocketButton
                v-if="job && jobName"
                class="question-draft-form__back"
                type="secondary"
                @click="backToJobClicked"
            >
                {{ jobName }}
            </PocketButton>
            <span class="question-draft-form__job-status-separator" />
            <div v-if="jobStatus" class="question-draft-form__job-status">
                Status: {{ jobStatus === 'Completed' ? 'Approved' : jobStatus }}
            </div>
        </div>

        <div class="question-draft-form__progress">
            <TitleText
                class="question-draft-form__title-text"
                :class="{'question-draft-form__title-text--edit': !jobName}"
            >
                Question Draft
            </TitleText>
            <JobInfo 
                v-if="job && !isLoading"
                class="question-draft-form__progress-bar" 
                :job="job" 
            />
        </div>
        <Loading v-if="isLoading" />
        <div v-if="archivedStatus" class="question-draft-form__archive-status-banner">
            {{ archivedStatus }}
        </div>
        <Errors
            v-if="errors.length"
            :errors="formattedErrors"
            class="question-draft-form__errors"
        />
        <Errors
            v-if="subjectAndSubtopicErrors.length"
            :errors="subjectAndSubtopicErrors"
            class="question-draft-form__subject-subtopic-errors"
        />
        <div class="question-draft-form__section-header">
            <Highlight />
            Question Metadata
        </div>
        <div class="question-draft-form__metadata-container" v-if="currentUser?.role === 'Admin'">
            <div class="question-draft-form__metadata-row">
                <div class="question-draft-form__metadata-name">Question Serial</div>
                <div class="question-draft-form__metadata">{{ serial || '--' }}</div>
            </div>
            <div class="question-draft-form__metadata-row">
                <div class="question-draft-form__metadata-name">Serial Created</div>
                <div class="question-draft-form__metadata">{{ dateAdded ? formatDate(dateAdded) : '--' }}</div>
            </div>
            <div class="question-draft-form__metadata-row">
                <div class="question-draft-form__metadata-name">Last Updated</div>
                <div class="question-draft-form__metadata">{{ updatedAt ? formatDate(updatedAt) : '--' }}</div>
            </div>
            <div class="question-draft-form__metadata-row">
                <div class="question-draft-form__metadata-name">Last Updated By</div>
                <div class="question-draft-form__metadata">{{ lastUpdatedBy ? getUserName(lastUpdatedBy) : '--' }}</div>
            </div>
        </div>
        <div class="question-draft-form__question-metadata">
            <div class="question-draft-form__question-metadata-left">
                <SelectV2
                    label="Subject"
                    class="question-draft-form__metadata-select"
                    :class="{ 'question-draft-form__metadata-select--error': isFieldInErrorState('Subject') }"
                    :data="formattedKnowledgeAreas"
                    :modelValue="knowledgeAreaDraftId"
                    :disabled="isReadOnly"
                    @update:modelValue="changeKnowledgeArea"
                />
                <SelectV2
                    v-if="subtopics && subtopics.length"
                    label="Subtopic"
                    class="question-draft-form__metadata-select"
                    :class="{ 'question-draft-form__metadata-select--error': isFieldInErrorState('Subtopic') }"
                    :modelValue="subtopicId"
                    :data="subtopics"
                    :disabled="isReadOnly || !subtopics || !subtopics.length"
                    @update:modelValue="changeSubtopic"
                />
                <div class="question-draft-form__bloom-select">
                    <div
                        class="question-draft-form__ai-assist-btn"
                        :class="{'question-draft-form__ai-assist-btn--disabled': isReadOnly}"
                        @click="clickedBloomAIAssist"
                    >
                        <template v-if="!bloomAIAssistLoading && !bloomAIAssistSuggestion">
                            AI Assist <Icon class="question-draft-form__ai-assist-icon" type="lightbulb" />
                        </template>
                        <template v-else-if="bloomAIAssistSuggestion">
                            <div class="question-draft-form__ai-assist-btn--suggestion">
                                {{ bloomAIAssistSuggestion === 'Unknown' || !bloomAIAssistSuggestion
                                    ? 'No AI suggestion'
                                    : `AI suggests ${bloomAIAssistSuggestion}` }}
                            </div>
                        </template>
                        <template v-else>
                            <Icon class="question-draft-form__ai-assist-loading-icon" type="loading2" />
                        </template>
                    </div>
                    <SelectV2
                        label="Bloom's Taxonomy Level"
                        class="question-draft-form__metadata-select"
                        v-model="bloomTaxonomyLevel"
                        :stringData="bloomTaxonomyLevels"
                        :disabled="isReadOnly"
                    />
                </div>
                <SelectV2
                    label="Question Type"
                    class="question-draft-form__metadata-select"
                    v-model="questionDraftType"
                    :stringData="questionTypes"
                    :disabled="isReadOnly"
                    @update:modelValue="changeQuestionType"
                >
                    <template
                        v-for="slotName in ['selectValue', 'dropdownItem']"
                        #[slotName]="{ selectValue, item }"
                        :key="slotName"
                    >
                        <div
                            v-if="[selectValue, item].includes('Multiple Choice')"
                            class="question-draft-form__question-type-dropdown-item"
                        >
                            <div class="question-draft-form__question-type-dropdown-item-label">
                                MCQ
                            </div>
                            <div>
                                Multiple Choice
                            </div>
                        </div>
                        <div
                            v-else-if="[selectValue, item].includes('Multiple Correct Response')"
                            class="question-draft-form__question-type-dropdown-item"
                        >
                            <div class="question-draft-form__question-type-dropdown-item-label">
                                MCR
                            </div>
                            <div>
                                Multiple Correct Response
                            </div>
                        </div>
                        <div
                            v-else-if="[selectValue, item].includes('True/False')"
                            class="question-draft-form__question-type-dropdown-item"
                        >
                            <div class="question-draft-form__question-type-dropdown-item-label">
                                T/F
                            </div>
                            <div>
                                True or False
                            </div>
                        </div>
                    </template>
                </SelectV2>
            </div>
            <div
                class="question-draft-form__question-metadata-right"
                v-if="currentUser && currentUser.role === 'Admin'"
            >
                <SelectV2
                    v-model="isArchived"
                    class="question-draft-form__metadata-select"
                    label="Question Status"
                    :data="[{ label: 'Active', value: false }, { label: 'Archived', value: true }]"
                />
                <SelectV2
                    v-if="mockExamSettings.length"
                    class="question-draft-form__metadata-select"
                    label="Question Bank"
                    :disabled="job"
                    :data="mockExamSettings"
                    :useObjectModel="true"
                    :modelValue="getCurrentMockExamSetting()"
                    @update:modelValue="mockExamSettingsChanged"
                />
                <SelectV2
                    v-model="isSpecial"
                    class="question-draft-form__metadata-select"
                    label="Cost"
                    :data="[{ label: 'Premium Question', value: false }, { label: 'Free Question', value: true }]"
                />

            </div>
        </div>
        <div class="question-draft-form__section-header">
            <Highlight />
            Question, Answers, Explanation
        </div>
        <div class="question-draft-form__question-section">
            <div class="question-draft-form__prompt-image-passage">
                <div
                    class="question-draft-form__editor-field-label"
                    :class="{ 'question-draft-form__editor-field-label--error': isFieldInErrorState('prompt') }"
                >
                    Question Prompt
                </div>
                <EditorField
                    v-model="prompt"
                    size="large"
                    class="question-draft-form__prompt-editor"
                    :class="{ 'question-draft-form__prompt-editor--error': isFieldInErrorState('prompt') }"
                    :editor-refresh="lastRefreshTime"
                    :editor-context="examDraftId"
                    :is-read-only="isReadOnly"
                    @editorCreated="storeEditor"
                />
                <div
                    v-if="!passageDisplaySettings.showPassage && !passageDisplaySettings.showImage
                        && !passage && !passageImageUrl"
                    class="question-draft-form__add-passage-image-btn"
                    @click="passageDisplaySettings.showPassage = true; passageDisplaySettings.showImage=true"
                >
                    Add Passage/Image
                </div>
                <div
                    v-if="passageDisplaySettings.showPassage || passage"
                    class="question-draft-form__prompt-passage"
                >
                    <div class="question-draft-form__editor-field-label">Prompt Passage</div>
                    <EditorField
                        v-model="passage"
                        class="question-draft-form__prompt-passage-editor"
                        :editor-refresh="lastRefreshTime"
                        :editor-context="examDraftId"
                        :is-read-only="isReadOnly"
                        @editorCreated="storeEditor"
                    />
                    <div
                        class="question-draft-form__passage-close-btn"
                        @mouseenter="passageDisplaySettings.showRemovePassageTooltip = true"
                        @mouseleave="passageDisplaySettings.showRemovePassageTooltip = false"
                        @click="passageDisplaySettings.showRemovePassageTooltip = false;
                                passage = ''; passageDisplaySettings.showPassage = false"
                    >
                        <Tooltip v-if="passageDisplaySettings.showRemovePassageTooltip">
                            Remove Prompt Passage
                        </Tooltip>
                        <Icon type="close" class="question-draft-form__passage-close-icon"/>
                    </div>
                </div>
                <div v-else-if="!passageDisplaySettings.showPassage && !passage
                    && (passageDisplaySettings.showImage || passageImageUrl || passageImageFile)">
                    <div
                        class="question-draft-form__add-passage-btn"
                        @click="passageDisplaySettings.showPassage = true"
                    >
                        Add Passage
                    </div>
                </div>
                <div
                    v-if="passageDisplaySettings.showImage || passageImageUrl"
                    class="question-draft-form__prompt-image"
                >
                    <ImageField
                        v-model="passageImageFile"
                        class="question-draft-form__passage-image-field"
                        :image="passageImageUrl"
                        :disabled="isReadOnly"
                        @remove="clearPromptImage"
                        tooltip="Remove Prompt Image"
                        label="Prompt Image"
                        :alwaysShowLabel="true"
                    />
                    <div
                        v-if="!passageImageUrl && !passageImageFile"
                        class="question-draft-form__passage-image-close-btn"
                        @mouseenter="passageDisplaySettings.showRemoveImageTooltip = true"
                        @mouseleave="passageDisplaySettings.showRemoveImageTooltip = false"
                        @click="passageDisplaySettings.showRemoveImageTooltip = false;
                                passageDisplaySettings.showImage = false"
                    >
                        <Tooltip v-if="passageDisplaySettings.showRemoveImageTooltip">
                            Remove Prompt Image
                        </Tooltip>
                        <Icon type="close" class="question-draft-form__passage-close-icon"/>
                    </div>
                    <div v-if="passageImageFile || passageImageUrl">
                        <div class="question-draft-form__editor-field-label">
                            Prompt Image Alt Text (aim for less than 125 characters)
                        </div>
                        <EditorField
                            v-model="passageImageAltText"
                            :is-read-only="isReadOnly"
                            :style="{ height: '78px' }"
                        />
                        <div>
                            <div class="question-draft-form__editor-field-label">
                                Prompt Image Long Alt Text
                            </div>
                            <EditorField
                                v-model="passageImageLongAltText"
                                size="large"
                                :editor-refresh="lastRefreshTime"
                                :editor-context="examDraftId"
                                :is-read-only="isReadOnly"
                                @editorCreated="storeEditor"
                                :style="{ minHeight: '107px' }"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-else-if="!passageDisplaySettings.showImage && !passageImageFile && !passageImageUrl
                        && (passageDisplaySettings.showPassage || passage)"
                    @click="passageDisplaySettings.showImage = true"
                    class="question-draft-form__add-image-btn"
                >
                    Add Image
                </div>
            </div>
            <Errors
                v-if="duplicateAnswersAndDistractors.length"
                :errors="duplicateAnswersAndDistractors.length ? ['All answers and distractors must be unique. '] : []"
                class="question-draft-form__answer-distractor-errors"
            />
            <div class="question-draft-form__answers-and-explanation">
                <div class="question-draft-form__question-answers">
                    <template v-if="questionDraftType === 'True/False'">
                        <SelectV2
                            label="Answer"
                            v-model="answers[0]"
                            class="question-draft-form__answer"
                            :class="{ 'question-draft-form__answer--error': isFieldInErrorState('answer') }"
                            :style="{ marginTop: '18px' }"
                            :data="[{ label: 'True', value: '<p>True</p>' }, { label: 'False', value: '<p>False</p>' }]"
                            :disabled="isReadOnly"
                            @update:modelValue="trueFalseChanged"
                        />
                    </template>
                    <template v-else>
                        <div
                            v-for="(answer, n) in answers"
                            :key="`answer-${n}`"
                            :style="{ position: 'relative' }"
                        >
                            <div
                                class="question-draft-form__editor-field-label"
                                :class="{
                                    'question-draft-form__editor-field-label--error': isFieldInErrorState('answer')
                                        || (duplicateAnswersAndDistractors.includes(answers[n]) && !!answers[n])
                                }"
                            >
                                {{ `Answer${
                                    questionDraftType === 'Multiple Correct Response'
                                        ? ` ${n + 1}`
                                        : ''
                                }`}}
                            </div>
                            <template
                                v-if="!isReadOnly
                                    && questionDraftType === 'Multiple Correct Response'"
                            >
                                <div
                                    class="question-draft-form__remove-answer"
                                    :class="{'question-draft-form__remove-answer--disabled': answers.length <= 1}"
                                    @mouseenter="answerHoverIndex = n"
                                    @mouseleave="answerHoverIndex = null">
                                    <Tooltip v-if="answerHoverIndex === n">
                                        Remove Answer
                                    </Tooltip>
                                    <Icon
                                        type="close"
                                        @click.prevent="removeAnswer(n)"
                                    />
                                </div>
                            </template>
                            <div
                                v-if="!isReadOnly
                                    && questionDraftType === 'Multiple Correct Response'
                                    && (answers.length + distractors.length < maxMCRChoices)
                                    && n === answers.length - 1
                                "
                                class="question-draft-form__add-answer"
                                @mouseenter="showAddAnswerTooltip = true"
                                @mouseleave="showAddAnswerTooltip = false"
                            >
                                <Tooltip v-if="showAddAnswerTooltip">Add Answer</Tooltip>
                                <Icon type="addCircle" @click="addAnswer" />
                            </div>
                            <EditorField
                                v-model="answers[n]"
                                class="question-draft-form__answer"
                                :class="{
                                    'question-draft-form__answer--error': isFieldInErrorState('answer')
                                        || (duplicateAnswersAndDistractors.includes(answers[n]) && !!answers[n]),
                                    'question-draft-form__answer--has-stats': getAnswerStatByIndex(n)
                                }"
                                size="large"
                                :editor-refresh="lastRefreshTime"
                                :editor-context="examDraftId"
                                :is-read-only="isReadOnly"
                                @editorCreated="storeEditor"
                            />
                            <div
                                v-if="totalChoices
                                    && job?.jobFunction !== 'Create Questions'
                                    && getAnswerStatByIndex(n)"
                                class="question-draft-form__choices-percent"
                            >
                                {{ getAnswerStatByIndex(n) }}
                                <span class="question-draft-form__choices-percent-symbol">%</span>
                            </div>
                            <div
                                v-if="totalChoices
                                    && job?.jobFunction !== 'Create Questions'
                                    && getAnswerStatByIndex(n)"
                                class="question-draft-form__choices-progress-bar"
                            >
                                <div
                                    class="question-draft-form__choices-progress-bar-filled"
                                    :class="{
                                        'question-draft-form__choices-progress-bar-filled--correct':
                                            questionDraftType !=='Multiple Correct Response' }"
                                    :style="{ width: `${getAnswerStatByIndex(n)}%`}"
                                />
                            </div>
                        </div>
                        <div
                            v-for="(distractor, n) in distractors"
                            :key="`distractor-${n}`"
                            :style="{ position: 'relative' }"
                        >
                            <template
                                v-if="!isReadOnly"
                            >
                                <div
                                    class="question-draft-form__remove-distractor"
                                    :class="{ 'question-draft-form__remove-distractor--disabled': !(
                                        (questionDraftType === 'Multiple Choice'
                                            && distractors.length > minMCQDistractors)
                                        || (questionDraftType === 'Multiple Correct Response'
                                            && distractors.length > 1)
                                    )}"
                                    @mouseenter="distractorHoverIndex = n"
                                    @mouseleave="distractorHoverIndex = null"
                                >
                                    <Tooltip v-if="distractorHoverIndex === n">
                                        Remove Distractor
                                    </Tooltip>
                                    <Icon
                                        type="close"
                                        @click.prevent="removeDistractor(n)"
                                    />
                                </div>
                                <div
                                    class="question-draft-form__add-distractor"
                                    v-if="!isReadOnly
                                        && ((
                                            questionDraftType === 'Multiple Correct Response'
                                            && answers.length + distractors.length < maxMCRChoices
                                        ) || (
                                            questionDraftType === 'Multiple Choice'
                                            && distractors.length < defaultMCQDistractors
                                        )
                                        ) && n === distractors.length - 1"
                                    @mouseenter="showAddDistractorTooltip = true"
                                    @mouseleave="showAddDistractorTooltip = false"
                                >
                                    <Tooltip v-if="showAddDistractorTooltip">Add Distractor</Tooltip>
                                    <Icon type="addCircle" @click="addDistractor" />
                                </div>
                            </template>
                            <div
                                class="question-draft-form__editor-field-label"
                                :class="{
                                    'question-draft-form__editor-field-label--error': isFieldInErrorState('distractor')
                                        || (duplicateAnswersAndDistractors.includes(distractors[n]) && !!distractors[n])
                                }"
                            >
                                {{ `Distractor ${n+1}` }}
                            </div>
                            <EditorField
                                class="question-draft-form__distractor"
                                :class="{ 'question-draft-form__distractor--error': isFieldInErrorState('distractor')
                                              || (duplicateAnswersAndDistractors.includes(distractors[n])
                                                  && !!distractors[n]),
                                          'question-draft-form__distractor--has-stats': getDistractorStatByIndex(n)
                                }"
                                v-model="distractors[n]"
                                size="large"
                                :editor-refresh="lastRefreshTime"
                                :editor-context="examDraftId"
                                :is-read-only="isReadOnly"
                                @editorCreated="storeEditor"
                            />
                            <div
                                v-if="totalChoices
                                    && job?.jobFunction !== 'Create Questions'
                                    && getDistractorStatByIndex(n)"
                                class="question-draft-form__choices-percent"
                            >
                                {{ getDistractorStatByIndex(n) }}
                                <span class="question-draft-form__choices-percent-symbol">%</span>
                            </div>
                            <div
                                v-if="totalChoices
                                    && job?.jobFunction !== 'Create Questions'
                                    && getDistractorStatByIndex(n)"
                                class="question-draft-form__choices-progress-bar"
                            >
                                <div
                                    class="question-draft-form__choices-progress-bar-filled"
                                    :style="{ width: `${getDistractorStatByIndex(n)}%`}"
                                />
                            </div>
                        </div>
                        <div
                            v-if="choiceStats && Object.keys(choiceStats).length
                                && job?.jobFunction !== 'Create Questions'"
                            class="question-draft-form__total-stats-container"
                        >
                            <div class="question-draft-form__total-stats-block">
                                <div class="question-draft-form__total-stats-number">{{ totalChoices }}</div>
                                <div class="question-draft-form__total-stats-text">Answers</div>
                            </div>
                            <div class="question-draft-form__total-stats-block">
                                <div class="question-draft-form__total-stats-number">
                                    {{ Math.round(percentCorrect) }}%
                                </div>
                                <div class="question-draft-form__total-stats-text">Correct</div>
                            </div>
                        </div>
                    </template>
                    <div class="question-draft-form__references-section">
                        <div class="question-draft-form__section-header">
                            <Highlight />
                            References
                        </div>
                        <div class="question-draft-form__editor-field-label">References</div>
                        <div
                            v-for="(reference, index) in references"
                            :key="reference.id"
                            class="question-draft-form__form-reference"
                        >
                            <EditorField
                                v-model="reference.text"
                                size="large"
                                :editor-refresh="lastRefreshTime"
                                :editor-context="examDraftId"
                                :is-read-only="isReadOnly"
                                @editorCreated="storeEditor"
                            />
                            <div
                                class="question-draft-form__remove-reference"
                                v-if="!isReadOnly"
                                @mouseenter="referenceHoverIndex = index"
                                @mouseleave="referenceHoverIndex = null">
                                <Tooltip v-if="referenceHoverIndex === index">
                                    Remove Reference
                                </Tooltip>
                                <Icon
                                    type="close"
                                    @click.prevent="removeReference(index)"
                                />
                            </div>
                        </div>
                        <SelectV2
                            :modelValue="selectedReference"
                            :stringData="jobReferences"
                            :disabled="isReadOnly"
                            :placeholder="references.length ? 'Select Another Reference' : 'Select Reference'"
                            @update:modelValue="addReference"
                        />
                    </div>
                </div>
                <div class="question-draft-form__question-explanation">
                    <div
                        class="question-draft-form__editor-field-label"
                        :class="{
                            'question-draft-form__editor-field-label--error': isFieldInErrorState('Explanation')
                        }"
                    >
                        Explanation
                    </div>
                    <EditorField
                        class="question-draft-form__explanation-editor"
                        :class="{
                            'question-draft-form__explanation-editor--error': isFieldInErrorState('Explanation')
                        }"
                        v-model="explanation"
                        size="large"
                        :editor-refresh="lastRefreshTime"
                        :editor-context="examDraftId"
                        :is-read-only="isReadOnly"
                        @editorCreated="storeEditor"
                    />
                    <div>
                        <div>
                            <ImageField
                                v-model="explanationImageFile"
                                class="question-draft-form__explanation-image-field"
                                :image="explanationImageUrl"
                                :disabled="isReadOnly"
                                :compact="true"
                                buttonName="Add Explanation Image"
                                @remove="clearExplanationImage"
                                tooltip="Remove Explanation Image"
                                label="Explanation Image"
                            />
                        </div>
                        <div
                            v-if="explanationImageFile || explanationImageUrl"
                            class="question-draft-form__explanation-image-text-section"
                        >
                            <div class="question-draft-form__editor-field-label">
                                Explanation Image Alt Text (125 characters or less)
                            </div>
                            <EditorField
                                v-model="explanationImageAltText"
                                :is-read-only="isReadOnly"
                                :style="{ minHeight: '78px' }"
                            />
                            <div class="question-draft-form__editor-field-label">
                                Explanation Image Long Alt Text
                            </div>
                            <EditorField
                                v-model="explanationImageLongAltText"
                                size="large"
                                :editor-refresh="lastRefreshTime"
                                :editor-context="examDraftId"
                                :is-read-only="isReadOnly"
                                @editorCreated="storeEditor"
                                :style="{ minHeight: '107px' }"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <QuestionDraftPreview 
            v-if="isQuestionModalOpen"
            class="question-draft-form__question-preview" 
            @close="isQuestionModalOpen = false" 
        />
        <ButtonFooter v-if="!isLoading && currentUser">
            <template
                #leftSide 
            >
                <div v-if="!isLoading" class="question-draft-form__left-side-buttons">
                    <PocketButton
                        v-if="
                            (currentUser.role === 'Admin' && $route.params.questionDraftId)
                                || (originalJobStatus === 'Writer' && !examDataId && currentUser.role !== 'Editor')
                        "
                        type="primary-red"
                        :disabled="!$route.params.questionDraftId"
                        @click="deleteQuestion"
                    >
                        Delete Draft
                    </PocketButton>
                </div>
            </template>
            <template 
                #center 
            >
                <div v-if="!isLoading" class="question-draft-form__center-buttons">
                    <PocketButton
                        type="secondary"
                        title="Preview Question"
                        class="question-draft-form__preview-button"
                        @click="toggleQuestionModal"
                    >
                        <Icon type="preview" />
                        <div>Preview</div>
                    </PocketButton>
                    <ChatButton
                        v-if="!isLoading && job && jobId"
                        class="question-draft-form__chat-button"
                        :job-id="jobId"
                    />
                </div>
            </template>
            <template
                #rightSide 
            >
                <div v-if="!isLoading" class="question-draft-form__right-side-buttons">
                    <PocketButton
                        class="question-draft-form__right-button"
                        :class="{'question-draft-form__right-button--editor-flagged': 
                            currentUser.role === 'Editor'
                        }"
                        type="secondary"
                        @click="backToJobClicked"
                    >
                        Close
                    </PocketButton>
                    <PocketButton
                        v-if="!isReadOnly"
                        type="primary"
                        class="question-draft-form__save-changes"
                        :disabled="!isDraftEdited"
                        @click="saveQuestion()"
                    >
                        Save
                    </PocketButton>
                    <span
                        v-if="jobName &&
                            ((currentUser.role === 'Admin' || currentUser.role === 'Editor') 
                                && draftStatus === 'active') ||
                            (currentUser.role === 'Writer' && jobStatus !== 'Completed')"
                        class="question-draft-form__line-separator" 
                    />
                    <PocketButton
                        v-if="
                            (currentUser.role === 'Writer' && (jobStatus === 'Editor' || jobStatus === 'Completed'))
                                || (currentUser.role === 'Editor' && jobStatus === 'Completed')
                        "
                        @click="recallQuestion"
                    >
                        Recall Question
                    </PocketButton>
                    <PocketButton
                        v-if="(!isFlagged || originalJobStatus === 'Editor')
                            && (
                                (currentUser.role === 'Editor'
                                    && originalJobStatus === 'Editor'
                                    && jobStatus !== 'Completed')
                                || (currentUser.role === 'Admin' && isFlagged && originalJobStatus === 'Writer')
                                || (currentUser.role === 'Admin' && originalJobStatus === 'Editor')
                                || (currentUser.role === 'Admin' && originalJobStatus === 'Completed')
                            )
                            && $route.params.questionDraftId
                            && draftStatus === 'active'
                            && job"
                        class="question-draft-form__right-button"
                        type="primary"
                        @click="flagQuestion"
                    >
                        {{ currentUser.role === 'Editor' ? 'Flag Question' : 'To Writer' }}
                    </PocketButton>
                    <PocketButton
                        v-if="!isFlagged && (currentUser.role === 'Admin' && originalJobStatus === 'Writer')
                            && $route.params.questionDraftId
                            && draftStatus === 'active'
                            && job"
                        class="question-draft-form__right-button"
                        type="primary"
                        @click="flagQuestion"
                    >
                        Flag
                    </PocketButton>
                    <PocketButton
                        v-if="isFlagged && (currentUser.role === 'Admin' && originalJobStatus === 'Writer')
                            && $route.params.questionDraftId
                            && draftStatus === 'active'
                            && job"
                        class="question-draft-form__right-button"
                        type="primary"
                        @click="unflagQuestion"
                    >
                        Unflag
                    </PocketButton>
                    <PocketButton
                        v-if="!isReadOnly
                            && ((currentUser.role === 'Writer' && originalJobStatus === 'Writer')
                                || (currentUser.role === 'Admin' && originalJobStatus !== 'Editor'))
                            && jobId
                            && job
                            && !job.isCompleted"
                        type="primary"
                        class="question-draft-form__right-button"
                        :disabled="!prompt || !explanation || !answers[0] || 
                            (questionDraftType === 'Multiple Choice' && !distractors[0]) ||
                            (questionDraftType === 'Multiple Correct Response' && !distractors[0])"
                        @click="submitToEditor"
                    >
                        {{
                            currentUser.role === 'Admin'
                                ? 'To Editor'
                                : 'Submit to Editor'
                        }}
                    </PocketButton>
                    <PocketButton
                        v-if="isFlagged
                            && currentUser.role === 'Editor'
                            && originalJobStatus !== 'Editor'
                            && $route.params.questionDraftId
                            && draftStatus === 'active'"
                        class="question-draft-form__right-button"
                        @click="unflagQuestion"
                    >
                        Unflag Question
                    </PocketButton>
                    <PocketButton
                        v-if="!isReadOnly
                            && currentUser.role !== 'Writer'
                            && jobId
                            && (
                                (originalJobStatus === 'Editor' && jobStatus !== 'Completed')
                                || (currentUser.role === 'Admin' && originalJobStatus !== 'Completed')
                            )"
                        type="primary"
                        @click="approveQuestion"
                    >
                        Approve
                    </PocketButton>
                </div>
            </template>
        </ButtonFooter>
    </div>
</template>

<script lang="ts" src="./QuestionDraftForm.ts"></script>

<style lang="scss" scoped>

:deep(.uikit-modal) {
        top: 30%;
    }

.question-draft-form {
    padding: $base;
    padding-top: 32px;
    width: 1112px;
    margin: auto;
    position: relative;
    margin-top: 52px;
    padding-bottom: 200px;

    &__errors {
        margin: 36px 0;
        width: 922px;

        :deep(.uikit-errors__error):first-of-type {
            margin-left: 0;

            &::before {
                content: '';
            }
        }
    }

    &__subject-subtopic-errors, &__answer-distractor-errors {
        width: 922px;
        margin: 36px 0;
    }

    &__answer-distractor-errors {
        margin-bottom: 6px;
    }

    &__archive-status-banner {
        width: 922px;
        background-color: $gray-background;
        border: 1px solid rgba($pewter, 0.85);
        border-radius: 6px;
        padding: 8px 24px 8px 12px;
        font-size: 15px;
        line-height: 20px;
    }

    &__modal-error {
        width: 374px;
        padding-top: 36px;
        padding-bottom: 24px;
        height: auto;
    }

    :deep(.uikit-errors__error) {
        margin-left: 10px;
    }

    &__metadata-container {
        display: flex;
        flex-direction: column;
        width: 244px;
        padding: 12px 0 16px 12px;
        margin-top: 18px;
    }

    &__metadata-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
    }

    &__metadata-name {
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
        color: $ash;
    }

    &__metadata {
        font-size: 14px;
        line-height: 19px;
        color: $brand-black;
        text-align: right;
    }

    &__modal-close-btn {
        height: 34px;
        width: 34px;
        color: $brand-blue;
        position: absolute;
        left: 328px;
        top: 12px;
        cursor: pointer;
        z-index: 10000;

        &:hover {
            color: $brand-black;
        }
    }

    &__modal-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.3px;
        width: 294px;
        text-align: center;
        margin: auto;
        margin-bottom: 12px;
    }

    &__modal-subtext {
        width: 294px;
        font-size: 15px;
        line-height: 20px;
        color: $ash;
        margin: auto;
        text-align: center;
    }

    &__modal-buttons {
        margin-top: 32px;
        text-align: center;
    }

    &__progress {
        display: flex;
        justify-content: space-between;
    }

    &__clarifying-text {
        display: flex;
        align-items: center;
        margin-top: 6px;

        span {
            height: 20px;
        }
    }

    &__toast {
        position: fixed;
        bottom: 100px;
    }

    &__toast-icon {
        &--arrow {
            color: $baby-blue;
            transform: rotate(180deg);
        }
        &--filled-flag {
            color: $butterscotch;
        }
        &--unfilled-flag {
            color: $steel;
        }
        &--delete {
            color: $pepper;
        }
    }

    &__back {
        margin-right: 6px;
        padding: 4px;
    }

    &__job-status {
        color: $slate;
        font-size: 16px;
    }

    &__job-status-separator {
        border: 1px solid $cloudy;
        border-radius: 3px;
        margin-right: 9px;
    }

    &__title-text {
        margin-top: 12px;
        margin-bottom: 34px;

        &--edit {
            margin-top: 62px;
        }
    }

    &__section-header {
        font-size: 20px;
        font-weight: 600;
        line-height: 27px;
        position: relative;
        width: fit-content;
        margin-bottom: 10px;

        svg {
            position: absolute;
            left: -8px;
            top: 15px;
            width: calc(100% + 20px);
            height: 27px;
        }
    }

    &__progress-bar {
        width: 40%;
    }

    &__references-section {
        display: flex;
        flex-direction: column;
        width: 396px;
        margin-top: 64px;
    }

    &__question-metadata {
        display: flex;
        margin-bottom: 64px;
        width: 922px;
        justify-content: space-between;
    }

    &__question-metadata-left, &__question-metadata-right {
        width: 396px;
    }

    &__metadata-select {
        margin-top: 18px;

        &--error {

            :deep(.select-field__value) {
                border-color: $pepper;
            }

            :deep(.select-field__label) {
                color: $pepper;
            }
        }
    }

    &__bloom-select {
        position: relative;
    }

    &__ai-assist-btn {
        display: flex;
        align-items: center;
        position: absolute;
        left: 311px;
        width: 73px;
        line-height: 18px;
        font-size: 14px;
        color: $brand-blue;
        cursor: pointer;
        z-index: 1;

        &:hover {
            color: $brand-black;
        }

        &--suggestion {
            position: absolute;
            text-align: right;
            right: 0px;
            top: 2px;
            padding-bottom: 2px;
            color: $ash;
            min-width: 150px;
            font-size: 13px;
        }

        &--disabled {
            color: rgba($brand-blue, 0.4);

            &:hover {
                color: rgba($brand-blue, 0.4);
            }
        }
    }

    &__ai-assist-icon {
        position: relative;
        bottom: 1px;
        margin-left: 4px;
        width: 14px;
        height: 14px;
    }

    &__ai-assist-loading-icon {
        position: absolute;
        right: 3px;
        margin-left: 4px;
        width: 14px;
        height: 14px;
    }

    &__ai-assist-suggestion {
        position: absolute;
        left: 320px;
        bottom: 11px;
        font-size: 14px;
    }

    &.question-nojob-create {
        padding-top: 0;
    }

    &__form-options {
        button {
            margin-top: 5px;
            margin-right: 25px;
            margin-bottom: 25px;

            &.question-delete,
            &.question-archive {
                margin-right: 0;
            }
        }
    }

    &__question-type-dropdown-item {
        display: flex;
    }

    &__question-type-dropdown-item-label {
        font-weight: 600;
        margin-right: 4px;
    }

    &__editor-field-label {
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        margin: 18px 0 4px 12px;
        color: $pickled-bluewood;

        &--error {
            color: $pepper;
        }
    }

    &__prompt-editor {
        min-height: 129px;

        &--error {
            border-color: $pepper;
        }
    }

    &__prompt-passage {
        position: relative;
    }

    &__prompt-passage-editor {
        min-height: 78px;
    }

    &__explanation-editor {
        min-height: 264px;

        &--error {
            border-color: $pepper;
        }
    }

    &__passage-close-btn, &__passage-image-close-btn {
        position: absolute;
        width: 20px;
        height: 20px;

        .uikit-tooltip {
            position: absolute;
            top: -37px;
            left: 10px;
        }

        &:hover { 
            cursor: pointer;
            
            svg {
                color: $brand-black;
            }
        }
    }

    &__passage-close-btn {
        left: 420px;
        bottom: 32px;
    }

    &__passage-image-close-btn {
        position: relative;
        left: 420px;
        bottom: 10px;
    }

    &__passage-close-icon {
        color: $brand-blue;
    }

    &__prompt-image-passage {
        width: 396px;
        margin-bottom: 32px;
    }

    &__passage-image-field {
        position: relative;
        margin-top: 20px;
        white-space: nowrap;

        :deep(.image-field__file-name) {
            margin-bottom: 28px;
        }
    }

    &__add-passage-image-btn,
    &__add-explanation-image-btn,
    &__add-passage-btn,
    &__add-image-btn {
        width: fit-content;
        padding: 10px 18px 9px 18px;
        border: 1px solid $brand-blue;
        border-radius: 4px;
        color: $brand-blue;
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        box-sizing: border-box;
        cursor: pointer;
        margin-top: 20px;

        &:hover {
            background-color: $brand-blue;
            color: $white;
        }
    }

    &__explanation-image-text-section {
        margin-top: 26px;
    }

    &__question-section {
        display: flex;
        flex-direction: column;
    }

    &__answers-and-explanation {
        display: flex;
        justify-content: space-between;
        width: 922px;
        margin-bottom: 38px;
    }

    &__question-answers {
        width: 396px;
    }

    &__answer {
        position: relative;

        &--error {
            border-color: $pepper;
        }

        &--has-stats {

            :deep(.ck-content) {
                padding-right: 54px!important;
            }
        }
    }

    &__total-stats-container {
        display: flex;
        justify-content: space-between;
        margin-top: 36px;
    }

    &__total-stats-block {
        width: 194px;
        height: 66px;
        background-color: $white;
        padding: 9px 28px 10px 28px;
        text-align: center;
        border: 1px solid rgba($pewter, 0.85);
        border-radius: 3px;
    }

    &__total-stats-number {
        font-size: 24px;
        line-height: 30px;
        font-weight: 600;
        letter-spacing: 0.24px;
    }

    &__total-stats-text {
        font-size: 13px;
        line-height: 17px;
        color: $ash;
    }

    &__question-explanation {
        width: 396px;
    }

    &__explanation-image-field {
        margin-top: 20px;
    }

    &__form-reference {
        position: relative;
        margin-bottom: 6px;
    }

    :deep(.question-draft-form__answer) {
        min-height: 36px;

        p {
            margin: 0;
            font-size: 15px;
            line-height: 20px;
            margin-top: 8px;
            margin-bottom: 6px;
        }
    }

    :deep(.question-draft-form__distractor) {
        min-height: 36px;

        p {
            margin: 0;
            line-height: 20px;
            font-size: 15px;
            margin-top: 8px;
            margin-bottom: 6px;
        }
    }

    &__distractor {

        &--error {
            border-color: $pepper;
        }

        &--has-stats {

            :deep(.editor) {
                padding-right: 54px!important;
            }
        }
    }

    &__choices-percent {
        position: absolute;
        top: 33px;
        left: 344px;
        font-weight: 600;
        color: $ash;
        width: 46px;
        text-align: right;
    }

    &__choices-percent-symbol {
        margin-left: -2px;
        font-weight: 500;
    }

    &__choices-progress-bar {
        margin-top: 3px;
        height: 11px;
        background-color: rgba($brand-black, 0.15);
        border-radius: 2px;
    }

    &__choices-progress-bar-filled {
        height: 11px;
        background-color: $slate;
        border-radius: 2px;

        &--correct {
            background-color: $cadaverous;
        }
    }

    &__add-answer {
        :deep(.uikit-btn--secondary) {
            border-radius: 3px;
            border: solid 1px $brand-blue;
        }
    }

    &__remove-distractor, &__remove-answer, &__remove-reference {
        position: absolute;
        left: 416px;
        top: 29px;
        color: $brand-blue;
        cursor: pointer;

        .uikit-tooltip {
            position: absolute;
            top: -37px;
            left: 10px;
        }

        &--disabled {
            color: rgba($brand-blue, 0.40)
        }
    }

    &__remove-reference {
        top: 16px;
    }

    &__add-distractor, &__add-answer {
        position: absolute;
        left: 450px;
        top: 29px;
        color: $brand-blue;
        cursor: pointer;

        .uikit-tooltip {
            position: absolute;
            top: -37px;
            left: 10px;
        }
    }

    &__free-question-setting {
        margin: 6px 0;
        font-size: 14px;

        input {
            height: 13px;
            margin-right: 5px;
            margin-left: 8px;
        }
    }

    &__mock-exam-setting {
        margin: 6px 0;
        font-size: 14px;

        input {
            height: 13px;
            margin-right: 5px;
            margin-left: 8px;
        }
    }

    .submit-toggle {
        height: 20px;
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-left: 30px;
        margin-bottom: 20px;

        input {
            height: 20px;
            margin-right: 5px;
        }
    }

    &__question-preview {
        :deep(.uikit-modal-container) {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }

    &__center-buttons {
        display: flex;
        position: absolute;
        left: calc(50% - 115px);
    }

    &__right-side-buttons {
        position: absolute;
        right: 0;
        margin-right: 18px;
    }

    &__left-side-buttons {
        position: absolute;
        margin-left: 18px;
    }

    &__preview-button {
        font-size: 16px;
        line-height: 17px;
        font-weight: 600;

        svg {
            margin-top: -3px;
            margin-right: 7px;
        }

        :deep(.uikit-btn) {
            &:hover {
                background-color: transparent;
            }
        }

        :deep(.uikit-btn__content) {
            display: flex;
            align-items: center;
        }
    }

    &__line-separator {
        border: 1px solid $cloudy;
        border-radius: 3px;
        margin-right: 18px;
        margin-left: 18px;
    }

    &__right-button {
        margin-right: 10px;

        &--editor-flagged {
            margin-right: 0;
        }
    }

    :deep(.ck-content) {
        padding-left: 11px;
        padding-right: 11px;
        font-size: 15px;
    }
}
</style>