<template>
    <div v-if="knowledgeAreaDrafts.length" class="question-templates">
        <div class="question-templates__head">
            <div class="question-templates__head-text">
                {{ `${totalCount.totalCount - totalCount.completedCount}
                Question${(totalCount.totalCount - totalCount.completedCount) > 1 ? 's' : ''} to Create` }}
            </div>
            <PocketButton
                v-if="currentUserRole !== 'Editor'"
                type="primary"
                @click="$router.push({ 
                    name: 'question-draft-create', 
                    query: { job: jobId }, 
                })">
                Create Question
            </PocketButton>
        </div>
        <Table
            :columns="tableColumns"
            :rows="tableRows"
            :show-header="false"
            :table-column-labels-styles="{ 
                backgroundColor: brandColors.grayBackground,
                color: brandColors.brandBlack,
                paddingRight: 0,
            }"
            :table-row-styles="{
                cursor: currentUserRole !== 'Editor' && rowHoverId !== 'total' && isHoveringExpandableCell 
                    ? 'pointer' 
                    : 'default',
                padding: '10px 0',
                paddingLeft: '28px'
            }"
            :table-subrows-styles="{ padding: '10px 0 10px 11px', fontSize: '14px' }"
            @rowClicked="rowClicked"
            @rowEnter="rowEnter"
            @rowLeave="rowLeave"
        >
            <template #tableCellValue="{ row, column }">
                <template v-if="row.subrows && row.subrows.length && column.propName === 'subject'">
                    <div class="question-templates__prefix-number">{{ row.prefixNumber }}</div>
                    <div class="question-templates__overflow-tooltip-parent-container">
                        <div
                            class="question-templates__overflow-tooltip-container"
                            @mouseenter="isHoveringExpandableCell = true"
                            @mouseleave="isHoveringExpandableCell = false"
                            @click.stop="toggleSubrows(row, column)"
                            :style="{
                                color: rowHoverId === row.id && isHoveringExpandableCell 
                                    ? brandColors.brandBlue 
                                    : brandColors.brandBlack,
                            }"> 
                            <div
                                class="question-templates__subrow-toggle-cell"
                                :style="{ 
                                    maxWidth: '400px',
                                }">
                                <OverflowTooltip>
                                    {{ row.subject }}
                                </OverflowTooltip>
                            </div>
                            <Icon
                                class="question-templates__arrow-icon" 
                                type="accordionArrow"
                                :class="{'question-templates__arrow-icon--expanded': row.displaySubrows}"
                            />

                        </div>
                    </div>
                </template>
                <template v-else-if="column.propName === 'subject'">
                    <div class="question-templates__prefix-number">{{ row.prefixNumber }}</div>
                    <OverflowTooltip>{{ row.subject }}</OverflowTooltip>
                </template>
                <template v-if="column.propName === 'count'">
                    <div :class="{'question-templates__count-cell': row.subrows && row.subrows.length}">
                        <OverflowTooltip>
                            {{ row.count }}
                        </OverflowTooltip>
                    </div>
                    <div
                        :style="{ position: 'relative'}"
                        v-if="isRowCompleted(row)"
                    >
                        <Icon class="question-templates__check-icon" type="check"></Icon>
                    </div>
                </template>
            </template>
            <template #tableSubrowCellValue="{ subrow, column }">
                <template v-if="column.propName === 'count' && isRowCompleted(subrow)">
                    <div :style="{ position: 'relative'}">
                        <OverflowTooltip>
                            {{ subrow.count }}
                        </OverflowTooltip>
                        <Icon class="question-templates__check-icon-subtopic" type="check"></Icon>
                    </div>
                </template>
            </template>
        </Table>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import type { IQuestionTemplate } from '@/store/jobs/types'
import type { IParseQuestionDraft } from '@/store/questionDrafts/types'
import type { CMS } from '@pocketprep/types'
import kaDraftsModule from '@/store/knowledgeAreaDrafts/module'
import UIKit, { type ITableColumn, type TTableRow, type TTableSubrow } from '@pocketprep/ui-kit'
import BrandColors from '@pocketprep/ui-kit/pocketprep-export.module.scss'

@Component({
    components: {
        PocketButton: UIKit.Button,
        Table: UIKit.Table,
        Icon: UIKit.Icon,
        OverflowTooltip: UIKit.OverflowTooltip,
    },
})
export default class QuestionTemplatesList extends Vue {
    @Prop() readonly questionTemplates!: IQuestionTemplate[]
    @Prop() readonly questionDrafts?: IParseQuestionDraft[]
    @Prop() readonly jobId!: string
    @Prop({ default: false }) readonly hideCreateButton!: boolean
    @Prop() readonly currentUserRole!: string

    knowledgeAreaDrafts: (CMS.Class.KnowledgeAreaDraftJSON | undefined)[] = []
    tableColumns: ITableColumn [] = 
        [{
            name: 'Subject',
            propName: 'subject',
            styles: {
                width: '420px',
            },
            isSortDisabled: true,
        }, {
            name: 'Count',
            propName: 'count',
            styles: {
                textAlign: 'right',
                paddingRight: '47px',
            },
            labelStyles: {
                paddingRight: '47px',
            },
            isSortDisabled: true,
        }]
    brandColors = BrandColors
    rowHoverId: string | null = null
    expandedRowIdSet: Set<string | number> = new Set()
    isHoveringExpandableCell = false

    get totalCount () {
        return this.questionTemplates.reduce((acc: { completedCount: number; totalCount: number}, curr) => {
            acc.completedCount += this.questionsCompletedCount(curr.knowledgeAreaDraftId)
            acc.totalCount += curr.count
            return acc
        }, { completedCount: 0, totalCount: 0 })
    }

    get tableRows () {
        const rows: TTableRow [] = this.questionTemplates.map((item, index) => {
            const prefixNumber = index + 1
            const styles: { [x: string]: string } = {}
            if (item.knowledgeAreaDraftId === this.rowHoverId
            && this.currentUserRole !== 'Editor' && !this.isHoveringExpandableCell) {
                styles.backgroundColor = this.brandColors.grayBackground
            }
            const kadSubtopics = this.knowledgeAreaDrafts.find(kad => 
                kad?.objectId === item.knowledgeAreaDraftId)?.subtopics
            if (item.subtopics?.some(st => st.count)) {
                styles.padding = '0'
            }
            return {
                prefixNumber,
                id: item.knowledgeAreaDraftId,
                subject: this.knowledgeAreaName(item.knowledgeAreaDraftId),
                displaySubrows: this.expandedRowIdSet.has(item.knowledgeAreaDraftId),
                subrows: item.subtopics?.filter(st => st.count !== 0).map(subtopic => {
                    const subrowStyles: { [x: string]: string } = {
                        padding: '7px 0 6px 30px',
                        cursor: 'default',
                        color: this.brandColors.pickledBluewood,
                    }
                    if (subtopic.id === this.rowHoverId && this.currentUserRole !== 'Editor') {
                        subrowStyles.backgroundColor = this.brandColors.grayBackground
                    }
                    //TODO: When when all the old CMS jobs are completed we can switch to using only subtopic.id
                    const fetchedSubtopicId = kadSubtopics?.find(kadSubtopic => kadSubtopic.name === subtopic.name)?.id
                    return {
                        id: fetchedSubtopicId || subtopic.id,
                        subjectId: item.knowledgeAreaDraftId,
                        subject: subtopic.name,
                        count: `${this.questionsInSubtopicCompletedCount(fetchedSubtopicId
                            || subtopic.id)}/${subtopic.count}`,
                        isSubtopicRow: true,
                        styles: subrowStyles,
                    }
                }),
                count: `${this.questionsCompletedCount(item.knowledgeAreaDraftId)}/${item.count}`,
                styles,
            }
        })
        rows.push({
            id: 'total',
            subject: 'Total',
            count: `${this.totalCount.completedCount}/${this.totalCount.totalCount}`,
            styles: {
                background: this.brandColors.creamPuff,
                fontWeight: '600',
            },
        })
        return rows
    }

    get sortedQuestionTemplatesWithNames () {
        return this.questionTemplates?.map(questionTemplate => ({
            ...questionTemplate,
            subjectName: this.knowledgeAreaName(questionTemplate.knowledgeAreaDraftId),
        }))?.sort((a, b) =>
            a.subjectName.toLowerCase().localeCompare(b.subjectName.toLowerCase(), undefined, { numeric: true })
        ) || []
    }

    toggleSubrows (row: TTableRow, column: ITableColumn) {
        if (column.propName === 'subject') {
            row.displaySubrows = !row.displaySubrows
            if (row.displaySubrows) {
                this.expandedRowIdSet.add(row.id)
            } else {
                this.expandedRowIdSet.delete(row.id)
            }
        }
    }

    rowClicked (row: TTableRow) {
        if (this.currentUserRole === 'Editor' || row.id === 'total' || this.isRowCompleted(row)) {
            return
        } 
        if (row.isSubtopicRow) {
            this.$router.push({ 
                name: 'question-draft-create', 
                query: { ka: row.subjectId as string, job: this.jobId, subtopic: row.id }, 
            })
        } else {
            this.$router.push({ 
                name: 'question-draft-create', 
                query: { ka: row.id as string, job: this.jobId }, 
            })
        }
    }

    rowEnter (row: TTableRow) {
        this.rowHoverId = String(row.id)
    }

    rowLeave (row: TTableRow) {
        if (this.rowHoverId === String(row.id)) {
            this.rowHoverId = null
        }
    }

    knowledgeAreaName (kaId: string) {
        const knowledgeAreaDraft = this.knowledgeAreaDrafts.length 
            && this.knowledgeAreaDrafts.find(ka => !!(ka) && ka.objectId === kaId)
        return knowledgeAreaDraft
            ? knowledgeAreaDraft.name
            : ''
    }

    questionsCompletedCount (ka: string) {
        return (this.questionDrafts && this.questionDrafts.length)
            ? this.questionDrafts
                .filter(questionDraft => 
                    questionDraft.knowledgeAreaDraft &&
                    questionDraft.knowledgeAreaDraft.objectId === ka &&
                    !questionDraft.examDataId
                ).length
            : 0
    }

    questionsInSubtopicCompletedCount (subtopic: string | undefined) {
        if (!subtopic) {
            return 0
        }
        return (this.questionDrafts && this.questionDrafts.length)
            ? this.questionDrafts
                .filter(questionDraft => 
                    questionDraft.subtopicId &&
                    questionDraft.subtopicId === subtopic &&
                    !questionDraft.examDataId
                ).length
            : 0
    }

    isRowCompleted (row: TTableRow | TTableSubrow) {
        const rowCount = row.count as string
        const countNumbers = rowCount.split('/')
        return parseInt(countNumbers[0]) >= parseInt(countNumbers[1])
    }

    async mounted () {
        if (this.questionTemplates && this.questionTemplates.length) {
            const knowledgeAreaPromises = this.questionTemplates.map(
                async template => 
                    kaDraftsModule.actions.fetchKADraft(template.knowledgeAreaDraftId)
            )

            this.knowledgeAreaDrafts = await Promise.all(knowledgeAreaPromises)

            // Expand all rows with subrows
            this.tableRows.forEach(tableRow => {
                if (tableRow.subrows) {
                    this.expandedRowIdSet.add(tableRow.id)
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.question-templates {
    margin-bottom: 50px;
    width: 570px;

    :deep(table.uikit-table__content) {
        border: 1px solid rgba($pewter, 0.85);
        border-radius: 4px;
        overflow: hidden;
    }

    :deep(.uikit-table__row:last-child) {
        border-bottom: none;
    }

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
    }

    &__head-text {
        font-weight: 600;
        font-size: 20px;
        line-height: 36px;
        letter-spacing: 0.2px;
    }

    :deep(.uikit-table__row .uikit-overflow-tooltip) {
        max-width: 437px;
    }

    :deep(.uikit-table__subrow .uikit-table__cell:first-of-type .uikit-overflow-tooltip) {
        width: 400px;
    }

    &__prefix-number {
        position: absolute;
        left: 10px;
        top: 10px;
        font-weight: 600;
        line-height: 20px;
        text-align: right;
        color: $slate;
    }

    &__subrow-toggle-cell {
        width: fit-content;
        position: relative;
    }

    &__count-cell {
        padding-top: 10px;
    }

    &__overflow-tooltip-parent-container {
        display: flex;
    }

    &__overflow-tooltip-container {
        display: flex;
        align-items: center;
        padding: 10px 0;
    }

    &__arrow-icon {
        margin-left: 6px;
        
        &--expanded {
            transform: rotate(180deg);
        }
    }

    &__check-icon {
        color: $cadaverous;
        position: absolute;
        height: 13px;
        width: 16px;
        bottom: 13px;
        bottom: 4px;
        left: 74px;
    }

    &__check-icon-subtopic {
        color: $cadaverous;
        position: absolute;
        height: 13px;
        width: 16px;
        bottom: 4px;
        left: 74px;
    }
}
</style>
