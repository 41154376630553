<template>
    <nav
        class="nav"
        :class="{
            'open': menuVisible,
            'nav--prod-env': currentEnvironment === 'production'
        }"
    >
        <div class="nav__title">
            <img
                class="nav__logo"
                src="@/assets/img/pocket-prep-logo.svg"
                alt="Pocket Prep"
            >
            <div class="nav__app-name">
                CMS
            </div>
            <div v-if="currentEnvironment !== 'production'" class="nav__env-tag">
                {{ currentEnvironment }}
            </div>
        </div>
        <div
            v-if="isAdmin"
            class="nav__links"
        >
            <RouterLink
                :to="{ name: 'job-list' }"
                exact
            >
                <span>Jobs</span>
            </RouterLink>
            <RouterLink
                :to="{ name: 'user-list' }"
            >
                <span>Users</span>
            </RouterLink>
            <RouterLink
                :to="{ name: 'exam-list' }"
            >
                <span>Exams</span>
            </RouterLink>
            <RouterLink
                :to="{ name: 'exam-draft-list' }"
            >
                <span>Exam Drafts</span>
            </RouterLink>
            <RouterLink
                :to="{ name: 'question-list' }"
            >
                <span>Questions</span>
            </RouterLink>
            <RouterLink
                :to="{ name: 'question-draft-list' }"
            >
                <span>Question Drafts</span>
            </RouterLink>
            <RouterLink
                :to="{ name: 'bundle-list' }"
            >
                <span>Bundles</span>
            </RouterLink>
        </div>
        <RouterLink
            v-else
            class="nav__home-btn"
            :to="{
                name: 'job-list'
            }"
        >
            <span>Jobs</span>
        </RouterLink>
        <UserDropdown />
    </nav>
</template>

<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faHome, faBars, faUserFriends } from '@fortawesome/free-solid-svg-icons'

import UserDropdown from '@/components/Users/UserDropdown.vue'
import { Component, Vue } from 'vue-facing-decorator'
import navigationModule from '@/store/navigation/module'
import { usersModule } from '@/store/users/module'
import { environment } from '@/utils'

@Component({
    components: {
        UserDropdown,
        VIcon: FontAwesomeIcon,
    },
})
export default class Nav extends Vue {
    iconHome = faHome
    iconBars = faBars
    iconUserFriends = faUserFriends 

    get currentEnvironment () {
        return environment
    }

    get isAdmin () {
        return usersModule.getters.getIsAdmin()
    }

    get menuVisible () {
        return navigationModule.state.menuVisible
    }

    toggleMenu () {
        navigationModule.actions.updateMenuVisible(!navigationModule.state.menuVisible)
    }
}
</script>

<style lang="scss" scoped>

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    background-color: $brand-blue;
    color: white;
    min-height: 52px;
    position: fixed;
    width: 100%;
    z-index: 100;

    &--prod-env {
        background-color: $charcoal;
    }

    @include breakpoint('mobile') {
        height: 52px;
        overflow: hidden;

        &.open {
            height: auto;

            ul {
                margin-bottom: 10px;
            }
        }
    }

    &__title {
        display: flex;
        align-items: center;
        margin-left: 44px;
    }

    &__logo {
        width: 30px;
        height: 30px;
    }

    &__app-name {
        margin-left: 12px;
        margin-right: 8px;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
    }

    &__env-tag {
        text-transform: capitalize;
        height: 16px;
        font-size: 12px;
        line-height: 16px;
        background: $buttermilk;
        padding: 0 6px;
        border: 1px solid $banana-bread;
        border-radius: 3px;
        margin-left: 6px;
        color: $brand-black;
        font-weight: 500;
    }

    &__links {
        display: flex;
        justify-content: center;

        a {
            padding: 0 8px;
            margin: 0 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            height: 42px;
            color: $white;
            font-weight: 600;
            text-decoration: none;
            letter-spacing: 0.15px;
            opacity: 0.8;
            outline: none;
            border: none;

            &:hover {
                opacity: 1;
            }

            &:focus {
                border-color: $sky-blue;
            }

            &.router-link-active {
                opacity: 1;
                color: $buttermilk;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: -6px;
                    left: 0;
                    width: 100%;
                    height: 4px;
                    background: $banana-bread;
                }
            }
        }
    }

    &__home-btn {
        cursor: pointer;
        padding: 0 8px;
        margin: 0 6px;
        display: flex;
        align-items: center;
        position: relative;
        height: 42px;
        font-weight: 600;
        text-decoration: none;
        letter-spacing: 0.15px;
        opacity: 1;
        color: $buttermilk;

        &.router-link-active {
            &::after {
                content: '';
                position: absolute;
                bottom: -6px;
                left: 0;
                width: 100%;
                height: 4px;
                background: $banana-bread;
            }
        }
    }
}
</style>