import type { TGetExamDraftMockExamDrafts } from './types'
import mockExamDraftsModule from '@/store/mockExamDrafts/module'

/**
 * Return mock exam drafts associated with specified exam draft
 */
const getMockExamDraftsByExamDraftId = (
    id: Parameters<TGetExamDraftMockExamDrafts>[0]
): ReturnType<TGetExamDraftMockExamDrafts> => {
    return mockExamDraftsModule.state.mockExamDrafts[id]
}

export default {
    getMockExamDraftsByExamDraftId,
}
