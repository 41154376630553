<template>
    <div class="users">
        <FormValidation :messages="validationMessages" />
        <Loading v-if="isLoading" />
        <div class="users__options">
            <TitleText>
                Users
            </TitleText>
            <PocketButton
                class="users__create-user-btn"
                @click="createUserClicked"
            >
                Add User
            </PocketButton>
        </div>
        <List
            v-if="listOptions"
            store-name="usersList"
            :list-options="listOptions"
            :force-show-controls="true"
            @itemClicked="userClicked"
            @select="selectedUsers = $event"
        />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { List, type IListOptions } from '@/components/Lists'
import Loading from '@/components/Loading.vue'
import { FormValidation } from '@/components/Forms'
import type { IUser, TRole } from '@/store/users/types'
import { usersModule } from '@/store/users/module'
import TitleText from '@/components/TitleText.vue'
import UIKit from '@pocketprep/ui-kit'

interface IMappedUser {
    objectId: string
    name: string
    email: string
    role: TRole
    isDisabled?: string
}

@Component({
    components: {
        List,
        Loading,
        FormValidation,
        TitleText,
        PocketButton: UIKit.Button,
    },
})
export default class UserList extends Vue {
    isLoading = true
    selectedUsers: IUser[] = []
    validationMessages: string[] = []

    get currentUser () {
        return usersModule.getters.getCurrentUser()
    }

    get users () {
        return usersModule.state.users
    }

    async bulkEditCheckedUsers (action: string) {
        this.isLoading = true
        this.validationMessages = []

        let userPromises
        if (action === 'disable') {
            if (this.selectedUsers.find(user => user.objectId === this.currentUser?.objectId)) {
                alert('You cannot disable yourself.')
                return
            }

            userPromises = this.selectedUsers.map(async user => await usersModule.actions.disableUser(user.objectId))
        } else if (action === 'enable') {
            userPromises = this.selectedUsers.map(async user => await usersModule.actions.enableUser(user.objectId))
        }

        userPromises && userPromises.length &&
            await Promise.all(userPromises)

        this.selectedUsers = []
        this.validationMessages.push('success/User(s) successfully updated.')
        this.isLoading = false
    }

    get listOptions (): IListOptions<IMappedUser> {
        return {
            listData: this.users && this.users.map(({ objectId, username, role, isDisabled }) => ({
                objectId,
                name: usersModule.getters.getDisplayName(objectId) || 'Unknown',
                email: username,
                role,
                isDisabled: isDisabled ? 'Yes' : 'No',
            })),
            listSchema: [
                {
                    propName: 'name',
                    label: 'Name',
                    type: 'text',
                    options: {
                        style: 'title',
                        width: 200,
                        group: 0,
                    },
                },
                {
                    propName: 'email',
                    label: 'Email',
                    type: 'text',
                    options: {
                        width: 250,
                        group: 1,
                    },
                },
                {
                    propName: 'role',
                    label: 'Role',
                    type: 'text',
                    options: {
                        width: 150,
                        group: 1,
                    },
                    data: [ 'Writer', 'Editor', 'Admin' ],
                },
                {
                    propName: 'isDisabled',
                    label: 'Disabled',
                    type: 'text',
                    options: {
                        group: 2,
                        isHidden: true,
                    },
                    data: [ 'Yes', 'No' ],
                },
                {
                    type: 'whitespace',
                },
            ],
            listDataModifiers: [
                data => data.isDisabled === 'Yes' && { opacity: '0.5' },
            ],
        }
    }

    userClicked (user: IMappedUser) {
        this.$router.push({
            name: 'user-view',
            params: {
                userId: user.objectId,
            },
        })
    }

    createUserClicked () {
        this.$router.push({
            name: 'user-create',
        })
    }

    async mounted () {
        this.isLoading = true
        await usersModule.actions.fetchUsers()
        this.isLoading = false
    }
}
</script>

<style lang="scss" scoped>

.users {
    flex-grow: 1;
    padding: $base;
    padding-bottom: 0;
    margin-top: 52px;

    &__options {
        display: flex;
        align-items: center;
        padding-left: 10px;
        margin-top: 46px;
        margin-bottom: 34px;
    }

    &__create-user-btn {
        margin-left: auto;
        margin-right: 10px;
        margin-bottom: 4px;
    }
}
</style>