import * as Sentry from '@sentry/vue'
import type { App } from 'vue'
import { environment } from './utils'

export const SentryInit = (app: App) => {
    /* istanbul ignore next */
    if (import.meta.env.VUE_APP_SENTRY_DSN) {
        Sentry.init({
            app,
            dsn: import.meta.env.VUE_APP_SENTRY_DSN,
            release: `cms@${import.meta.env.VUE_APP_VERSION}`,
            environment,
            beforeSend: (event, hint) => {
                if (
                    hint?.originalException
                    && (
                        hint.originalException.toString().includes('Invalid session token')
                        || hint.originalException.toString().includes('Unable to connect to the Parse API')
                        || hint.originalException.toString().includes('window.webkit.messageHandlers')
                        || hint.originalException.toString().includes('ceCurrentVideo.currentTime')
                        || hint.originalException.toString().includes('instantSearchSDKJSBridgeClearHighlight')
                    )
                ) {
                    return null
                }
            
                return event
            },
        })
    }
}
