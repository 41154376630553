<template>
    <div
        class="form-group"
        :class="{
            [`form-group--${(groupOpen ? 'open' : 'closed')}`]: true,
            'form-group--toggleable': toggleable
        }"
    >
        <label
            v-if="label"
            class="form-group__label"
            :class="{
                'form-group__label--toggleable': toggleable,
                [`form-group__label--${(groupOpen ? 'open' : 'closed')}`]: true,
            }"
            @click="toggleGroup"
        >
            {{ label }}
            <slot name="labelButton" />
        </label>
        <Transition
            name="slide"
            @enter="setElementHeight"
            @before-leave="setElementHeight"
            @after-leave="removeElementHeight"
            @after-enter="removeElementHeight"
        >
            <div
                v-show="groupOpen"
                class="form-group__contents"
            >
                <slot />
                <div
                    v-if="percent"
                    class="form-group__progress"
                >
                    <div class="form-group__progress-value" :style="{width:`${percent}%`}">
                        {{ percent }}%
                    </div>
                </div>
                <div
                    v-if="subtext"
                    class="form-group__subtext"
                    v-html="subtext"
                />
            </div>
        </Transition>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator'

@Component
export default class FormGroup extends Vue {
    @Prop() readonly label?: string
    @Prop({ default: false }) readonly toggleable?: boolean
    @Prop({ default: false }) readonly groupDefaultOpen!: boolean
    @Prop({ default: '' }) readonly subtext?: string
    @Prop({ default: 0 }) readonly percent?: number

    groupOpen = true

    toggleGroup () {
        this.groupOpen = this.toggleable
            ? !this.groupOpen
            : true
    }

    setElementHeight (el: Element) {
        (el as HTMLElement).style.height = el.scrollHeight + 'px'
    }

    removeElementHeight (el: Element) {
        (el as HTMLElement).style.height = ''
    }

    created () {
        this.groupOpen = this.toggleable
            ? this.groupDefaultOpen
            : true
    }
}
</script>

<style lang="scss" scoped>
.form-group {
    margin: 16px 0;

    &__label {
        display: block;
        font-size: 14px;
        line-height: 22px;
        position: relative;
        margin-left: 8px;

        &--toggleable {
            cursor: pointer;
            user-select: none;

            &::after {
                display: inline-block;
                margin-left: 5px;
                content: '\25BE';
                font-size: 14px;
                font-family: Arial, Helvetica, sans-serif;
                transition: all 0.3s ease;
                transform: rotate(180deg);
            }
        }

        &--closed::after {
            transform: rotate(0deg);
        }
    }

    &__subtext {
        font-size: 12px;
        max-width: 300px;
    }

    &--toggleable {
        overflow: hidden;
    }

    &__progress {
        background-color: #ddd;

        &-value {
            background-color: #aaa;
            color: #fff;
            height: 20px;
            font-size: 14px;
            text-indent: 5px;
            line-height: 20px;
        }
    }

    &__contents {
        margin-right: 10px;
    }
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.3s ease;
}

.slide-enter {
    height: 0;
}

.slide-leave-to {
    height: 0 !important;
}
</style>
