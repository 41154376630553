import type { 
    TCreateKADraft,
    TFetchKADraftsByExamDraftId,
    TCloneKAsFromExamDraftId,
    TFetchKADrafts,
    TFetchKADraft,
    TFetchKADraftByExamDraftIdAndName,
    TUpdateKADraft,
    TUpsertKADrafts,
    TDeleteKADraft,
} from './types'
import { Parse, sessionToken, objPointer } from '@/store/ParseUtils'
import type { CMS } from '@pocketprep/types'
import examDraftsModule from '@/store/examDrafts/module'
import examsModule from '@/store/exams/module'

/**
 * Clone knowledge areas to knowledge area drafts by exam ID
 */
const cloneKnowledgeAreasFromExamDraftId = async (
    examDraftId: Parameters<TCloneKAsFromExamDraftId>[0]
): ReturnType<TCloneKAsFromExamDraftId> => {
    // fetch exam draft
    const examDraft = await examDraftsModule.actions.fetchExamDraft(examDraftId)

    // fail if no exam draft
    if (!examDraft) {
        throw new Error('Unable to find exam draft.')
    }

    // check for existing knowledge area drafts and just return them if found
    const knowledgeAreaDrafts = await fetchKADraftsByExamDraftId(examDraft.objectId)
    if (knowledgeAreaDrafts.length) {
        return knowledgeAreaDrafts
    }

    // If no knowledge area drafts and the exam hasn't been exported before, then we have no subjects yet
    if (!examDraft.examMetadataId) {
        return []
    }

    // if exam has been exported before, fetch Subjects and save them as drafts
    const knowledgeAreas = await examsModule.actions.fetchSubjects({ examMetadataId: examDraft.examMetadataId })
    const knowledgeAreaPromises = await Promise.all(knowledgeAreas.map(async ka => {
        return createKADraft({
            name: ka.name,
            examDraftId: examDraft.objectId,
            isArchived: ka.isArchived,
            subjectId: ka.objectId,
            subtopics: ka.subtopics,
        })
    }))

    return knowledgeAreaPromises
}

/**
 *  Fetch all knowledge areas that are currently in cms server 
 */
const fetchKADrafts = async (): ReturnType<TFetchKADrafts> => {
    const parseKnowledgeAreas = await new Parse.Query<CMS.Class.KnowledgeAreaDraft>('KnowledgeAreaDraft')
        .findAll({
            ...sessionToken(),
            batchSize: 2000,
        })

    return parseKnowledgeAreas.map(ka => ka.toJSON())
}

/**
 * Fetch knowledge area by ID
 * 
 * @param {string} knowledgeAreaID - ID of knowledge area to fetch
 * 
 * @returns {Promise<IKnowledgeAreaDraft>}
 */
const fetchKADraft = async (kaId: Parameters<TFetchKADraft>[0]): ReturnType<TFetchKADraft> => {
    const parseKnowledgeArea = 
        await new Parse.Query<CMS.Class.KnowledgeAreaDraft>('KnowledgeAreaDraft')
            .equalTo('objectId', kaId)
            .first()

    return (parseKnowledgeArea && parseKnowledgeArea.toJSON()) || undefined
}

/**
 * Fetch knowledgeAreaDrafts by exam draft ID
 * 
 * @param {string} examId - ID of exam to fetch knowledge areas for
 *
 * @returns {Promise} resolves with IKnowledgeArea[]
 */
const fetchKADraftsByExamDraftId = async (
    examDraftId: Parameters<TFetchKADraftsByExamDraftId>[0]
): ReturnType<TFetchKADraftsByExamDraftId> => {
    const kaDrafts = await new Parse.Query<CMS.Class.KnowledgeAreaDraft>('KnowledgeAreaDraft')
        .equalTo('examDraft', objPointer(examDraftId)('ExamDraft'))
        .find(sessionToken())

    return kaDrafts.map(ka => ka.toJSON())
}

/**
 * Fetch knowledgeAreaDrafts by exam draft ID and name of KA
 * 
 * @param {string} examId - ID of exam to fetch knowledge areas for
 * @param {string} name - name of knowledge area to find
 *
 * @returns {Promise} resolves with IKnowledgeArea
 */
const fetchKADraftByExamDraftIdAndName = async (
    { examDraftId, name }: Parameters<TFetchKADraftByExamDraftIdAndName>[0]
): ReturnType<TFetchKADraftByExamDraftIdAndName> => {
    const knowledgeAreaDraft = (await new Parse.Query<CMS.Class.KnowledgeAreaDraft>('KnowledgeAreaDraft')
        .equalTo('examDraft', objPointer(examDraftId)('ExamDraft'))
        .equalTo('name', name)
        .first(sessionToken()))

    return knowledgeAreaDraft && knowledgeAreaDraft.toJSON()
}

/**
 * Create new knowledgeAreaDraft
 *
 * @param {IKnowledgeAreaDraft} params - knowledge area object
 *
 * @returns {Promise} resolves to IKnowledgeAreaDraft of new knowledgeAreaDraft object
 */
const createKADraft = async (
    {
        name,
        examDraftId,
        isArchived = false,
        subjectId,
        subtopics,
    }: Parameters<TCreateKADraft>[0]): ReturnType<TCreateKADraft> => {
    const newKnowledgeArea = new Parse.Object('KnowledgeAreaDraft',
        {
            examDraft: objPointer(examDraftId)('ExamDraft'),
            name,
            isArchived,
            subjectId,
            subtopics,
        }
    )

    const savedKnowledgeArea = await newKnowledgeArea.save(null, sessionToken())

    return savedKnowledgeArea.toJSON()
}

/**
 * Update knowledgeAreaDraft
 *
 * @param {string} knowledgeAreaDraftId - ID of knowledgeAreaDraft to update
 * @param {object} params - Key/value pairs to update Parse object
 *
 * @returns {Promise} resolves to IKnowledgeAreaDraft of updated knowledgeAreaDraft
 */
const updateKADraft = async (
    { knowledgeAreaDraftId, params }: Parameters<TUpdateKADraft>[0]
): ReturnType<TUpdateKADraft> => {
    const knowledgeAreaDraft = new Parse.Object('KnowledgeAreaDraft',
        { objectId: knowledgeAreaDraftId, ...params }
    )

    const updatedKnowledgeArea = await knowledgeAreaDraft.save(null, sessionToken())

    return updatedKnowledgeArea.toJSON()
}

/**
 * Upsert question drafts
 *
 * @param {object[]} knowledgeAreaDrafts - array of knowledge area draft objects:
 *      @param {string} [objectId] - id of knowledge area to update
 *      @param {string} [name] - name of knowledge area
 *      @param {boolean} [isArchived] - true/false whether knowledge area is archived
 *      @param {object} [examDraft] - pointer type object
 * 
 * @returns {Promise} resolves to Parse.Object[] of new question
 */
const upsertKADrafts = async (
    knowledgeAreaDrafts: Parameters<TUpsertKADrafts>[0]
): ReturnType<TUpsertKADrafts> => {
    const mappedknowledgeAreaDrafts = (knowledgeAreaDrafts || []).map(
        knowledgeAreaDraft => {
            const newKnowledgeArea = new Parse.Object('KnowledgeAreaDraft', {
                ...knowledgeAreaDraft,
            })

            return newKnowledgeArea
        }
    )

    const upsertedKADrafts = await Parse.Object.saveAll(mappedknowledgeAreaDrafts)

    return upsertedKADrafts.map(item => item.toJSON())
}

/**
 * Delete knowledgeAreaDraft
 *
 * @param {string} knowledgeAreaDraftId - ID of knowledgeAreaDraft to delete
 *
 * @returns {Promise} resolves to true if successful delete or false if failed
 */
const deleteKADraft = async (
    knowledgeAreaDraftId: Parameters<TDeleteKADraft>[0]): ReturnType<TDeleteKADraft> => {
    const knowledgeAreaDraft = await new Parse.Query('KnowledgeAreaDraft')
        .equalTo('objectId', knowledgeAreaDraftId)
        .first()

    try {
        knowledgeAreaDraft
            && await knowledgeAreaDraft.destroy(sessionToken())

        return true
    } catch (e) {
        return false
    }
}

export default {
    cloneKnowledgeAreasFromExamDraftId,
    fetchKADrafts,
    fetchKADraft,
    fetchKADraftsByExamDraftId,
    fetchKADraftByExamDraftIdAndName,
    createKADraft,
    updateKADraft,
    upsertKADrafts,
    deleteKADraft,
}
