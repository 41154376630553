import type { 
    TCloneExamDraftFromExamId,
    TFetchExamDraftByMetadataId,
    TFetchExamDrafts,
    TFetchUnexportedExamDrafts,
    TFetchExamDraft,
    TFetchExamDraftKADrafts,
    TCreateExamDraft,
    TUpdateExamDraft,
    TDeleteExamDraft,
    TFetchExamDraftStats,
} from './types'
import { objPointer, Parse, sessionToken } from '@/store/ParseUtils'
import type { CMS } from '@pocketprep/types'
import examDraftsModule from '@/store/examDrafts/module'
import kaDraftsModule from '@/store/knowledgeAreaDrafts/module'
import examsModule from '@/store/exams/module'
import mockExamsModule from '@/store/mockExams/module'
import mockExamDraftsModule from '@/store/mockExamDrafts/module'
import questionsModule from '@/store/questions/module'

const updateStoreExamDraft = (examDraftUpdate: CMS.Class.ExamDraftJSON) => {
    const examDraftsState = examDraftsModule.state
    const examDraftIndex = examDraftsState.examDrafts
        .findIndex(examDraft => examDraft.objectId === examDraftUpdate.objectId)

    if (examDraftIndex !== -1) {
        examDraftsState.examDrafts[examDraftIndex] = examDraftUpdate
    } else {
        examDraftsState.examDrafts.push(examDraftUpdate)
    }
}

/**
 * Fetch all examDrafts and commit to store
 *
 * @returns {Promise} resolves with IExamDraft[]
 */
const fetchExamDrafts = async (): ReturnType<TFetchExamDrafts> => {
    const examQuery = new Parse.Query<CMS.Class.ExamDraft>('ExamDraft')
    const examDrafts = await examQuery.findAll({
        ...sessionToken(),
        batchSize: 2000,
    })
    const examDraftsMapped = examDrafts.map(examDraft => {
        // releaseInfo and description are now  non-optional properties
        //
        // Unfortunately, currently created ExamDraft objects do not have this releaseInfo
        // and may not have description either.  This updated mapping utility ensures both
        // will be valid in our state when we read them in
        const emptyInfo: CMS.Class.ExamDraftJSON['releaseInfo'] = { name: '', description: '', message: '' }
        return {
            releaseInfo : emptyInfo,
            description: '',
            ...(examDraft.toJSON() as Partial<CMS.Class.ExamDraftJSON>),
        } as CMS.Class.ExamDraftJSON
    })

    examDraftsModule.state.examDrafts = examDraftsMapped

    return examDraftsMapped
}

/**
 * Fetch all unexported examDrafts (those without examMetadataId)
 *
 * @returns {Promise} resolves with IExamDraft[]
 */
const fetchUnexportedExamDrafts = async (): ReturnType<TFetchUnexportedExamDrafts> => {
    const examQuery = new Parse.Query<CMS.Class.ExamDraft>('ExamDraft')
        .doesNotExist('examMetadataId')
    const examDrafts = await examQuery.findAll({
        ...sessionToken(),
        batchSize: 2000,
    })
    const examDraftsMapped = examDrafts.map(examDraft => examDraft.toJSON())

    return examDraftsMapped
}

/**
 * Fetch examDraft by ID and commit to store
 *
 * @returns {Promise} resolves with IExamDraft[]
 */
const fetchExamDraft = async (examDraftId: Parameters<TFetchExamDraft>[0]): ReturnType<TFetchExamDraft> => {
    // fetch questions
    const examQuery = new Parse.Query<CMS.Class.ExamDraft>('ExamDraft').equalTo('objectId', examDraftId)
    const examDraft = await examQuery.first(sessionToken()),
        examDraftMapped = examDraft && examDraft.toJSON()
    
    if (examDraftMapped) {
        updateStoreExamDraft(examDraftMapped)
    }

    return examDraftMapped
}

/**
 * Fetch examDraft by examMetadataID and commit to store
 *
 * @returns {Promise} resolves with IExamDraft[]
 */
const fetchExamDraftByMetadataId = async (
    examMetadataId: Parameters<TFetchExamDraftByMetadataId>[0]
): ReturnType<TFetchExamDraftByMetadataId> => {
    // fetch questions
    const examQuery = new Parse.Query<CMS.Class.ExamDraft>('ExamDraft')
        .equalTo('examMetadataId', examMetadataId)
    const examDraft = await examQuery.first(sessionToken()),
        examDraftMapped = examDraft && examDraft.toJSON()
    
    if (examDraftMapped) {
        updateStoreExamDraft(examDraftMapped)
    }

    return examDraftMapped
}

/**
 * Fetch exam draft knowledge areas
 * 
 * @param {string} examDraftId - ID of exam to fetch knowledge areas for
 *
 * @returns {Promise} resolves with IKnowledgeAreaDraft[] when query completes
 */
const fetchExamDraftKADrafts = async (
    examDraftId: Parameters<TFetchExamDraftKADrafts>[0]
): ReturnType<TFetchExamDraftKADrafts> => {
    return kaDraftsModule.actions.fetchKADraftsByExamDraftId(examDraftId)
}

/**
 * Create new examDraft from examId OR return the existing exam draft if it already exists
 *
 * @param {IExamDraft} params - exam draft object
 *
 * @returns {Promise} resolves to IExamDraft of new examDraft object
 */
const cloneExamDraftFromExamId = async (
    examId: Parameters<TCloneExamDraftFromExamId>[0]
): ReturnType<TCloneExamDraftFromExamId> => {
    // get exam by exam id
    const exam = examsModule.getters.getExam(examId)
        || await examsModule.actions.fetchExam(examId)

    if (!exam) {
        throw new Error('cloneExamDraftFromExamId: Unable to find exam by ID')
    }

    // check to see if exam draft exists or return exam draft if it exists
    let examDraft = examDraftsModule.getters.getExamDraftByMetadataId(examId) ||
        await examDraftsModule.actions.fetchExamDraftByMetadataId(examId)
    
    // if no exam draft exists, make it
    if (!examDraft) {
        // create exam draft
        examDraft = await examDraftsModule.actions.createExamDraft({
            examMetadataId: exam.objectId,
            nativeAppName: exam.nativeAppName,
            releaseInfo: exam.releaseInfo,
            descriptiveName: exam.descriptiveName || '',
            hideReferences: exam.hideReferences,
            isFree: exam.isFree,
            compositeKey: exam.compositeKey,
            description: exam.description,
            appId: exam.appId,
            appName: exam.nativeAppName,
        })

        // get mock exams
        const mockExams = await mockExamsModule.actions.fetchMockExams(exam.objectId)

        // save mock exams
        const mockExamPayloads = mockExams.map(mockExam => ({
            name: mockExam.name,
            description: mockExam.description,
            durationSeconds: mockExam.durationSeconds,
            questionSerials: mockExam.questionSerials,
            enabled: mockExam.enabled,
            mockExamId: mockExam.objectId,
        }))
        await mockExamDraftsModule.actions.createMockExamDrafts({
            examDraftId: examDraft.objectId,
            payload: mockExamPayloads,
        })

        // fetch all mock exam drafts to put in store
        await mockExamDraftsModule.actions.fetchMockExamDrafts({ examDraftId: examDraft.objectId })
    }

    return examDraft
}

/**
 * Create new examDraft
 *
 * @param {IExamDraft} params - exam draft object
 *
 * @returns {Promise} resolves to IExamDraft of new examDraft object
 */
const createExamDraft = async ({
    examMetadataId,
    nativeAppName,
    releaseInfo,
    descriptiveName,
    appName,
    hideReferences,
    isFree,
    compositeKey,
    appId,
    description,
    mockExamDrafts,
}: Parameters<TCreateExamDraft>[0]): ReturnType<TCreateExamDraft> => {
    // map mockExamDraft array to pointers in case Parse magically convered them to parse objects
    const mappedMockExamDrafts = mockExamDrafts?.map(
        me => objPointer('id' in me ? me.id : me.objectId)('MockExamDraft')
    )
    const newExamDraft = new Parse.Object('ExamDraft',
        {
            examMetadataId,
            nativeAppName,
            releaseInfo,
            descriptiveName,
            appName,
            hideReferences,
            isFree,
            compositeKey,
            appId,
            description,
            mockExamDrafts: mappedMockExamDrafts,
        })

    const savedExamDraft = await newExamDraft.save(null, sessionToken())

    examDraftsModule.state.examDrafts = [
        savedExamDraft.toJSON(),
        ...examDraftsModule.state.examDrafts,
    ]

    return savedExamDraft.toJSON()
}

/**
 * Update examDraft and commit updated examDraft to store
 *
 * @param {string} examDraftId - ID of examDraft to update
 * @param {object} params - Key/value pairs to update Parse object
 *
 * @returns {Promise} resolves to IExamDraft of updated examDraft or 
 *  undefined if examDraft id does not match an examDraft in the store
 */
const updateExamDraft = async ({
    examDraftId,
    params,
}: Parameters<TUpdateExamDraft>[0]): ReturnType<TUpdateExamDraft> => {
    const examDraft = new Parse.Object('ExamDraft', { objectId: examDraftId, ...params } as CMS.Class.ExamDraftPayload)

    const updatedExamDraft = await examDraft.save(null, sessionToken())

    updateStoreExamDraft(updatedExamDraft.toJSON())

    return updatedExamDraft.toJSON()
}

/**
 * Delete examDraft and remove from store
 *
 * @param {string} examDraftId - ID of examDraft to update
 *
 * @returns {Promise} resolves to true if successful delete or false if failed
 */
const deleteExamDraft = async (examDraftId: Parameters<TDeleteExamDraft>[0]): ReturnType<TDeleteExamDraft> => {
    const examDraft = await new Parse.Query<CMS.Class.ExamDraft>('ExamDraft')
        .equalTo('objectId', examDraftId)
        .first()

    try {
        if (examDraft) {
            // delete exam draft
            await examDraft.destroy(sessionToken())
            
            // find all exam knowledge areas
            const knowledgeAreaDrafts = await new Parse.Query('KnowledgeAreaDraft')
                .equalTo('examDraft', { __type: 'Pointer', className: 'ExamDraft', objectId: examDraftId })
                .findAll({ batchSize: 2000 })

            // find all mock exam drafts
            const mockExamDrafts = await new Parse.Query<CMS.Class.MockExamDraft>('MockExamDraft')
                .containedIn(
                    'objectId', 
                    examDraft.get('mockExamDrafts')?.map(me => 'id' in me ? me.id : me.objectId) || []
                )
                .findAll()

            // delete mock exam drafts and knowlkege area drafts
            await Promise.all([
                Parse.Object.destroyAll(knowledgeAreaDrafts),
                Parse.Object.destroyAll(mockExamDrafts),
            ])

            return true
        }
    } catch (e) {
        return false
    }

    return false
}

/**
 * Fetch an exam draft's statistical info, primarily for categorizing its question drafts
 */
const fetchExamDraftStats = async (
    params: Parameters<TFetchExamDraftStats>[0]
): ReturnType<TFetchExamDraftStats> => {
    const questionDrafts = await new Parse.Query<CMS.Class.QuestionDraft>('QuestionDraft')
        .equalTo('examDraft', { __type: 'Pointer', className: 'ExamDraft', objectId: params.examDraftId })
        .select('serial', 'isSpecial', 'isArchived', 'examDataId')
        .findAll({ batchSize: 10000 })

    const questions = params.examMetadataId 
        ? await questionsModule.actions.fetchQuestionsByExam({
            examMetadataId: params.examMetadataId,
            searchConfig: { equalTo: { isArchived: false } },
        }) : []

    interface IReducedQuestions { 
        liveQuestionLib: { 
            [key: string]: { isSpecial: boolean; isMock: boolean; objectId: string }
        }
        originalSpecialCount: number
        originalMockCount: number
    }
    const {
        liveQuestionLib,
        originalSpecialCount,
        originalMockCount,
    } = questions.reduce<IReducedQuestions>((acc, q) => { 
        const newAcc = acc

        if (q.isFree) {
            newAcc.originalSpecialCount++
        }
        if (q.isMockQuestion) {
            newAcc.originalMockCount++
        }
        newAcc.liveQuestionLib[q.serial] = {
            isSpecial: !!q.isFree,
            isMock: !!q.isMockQuestion,
            objectId: q.objectId,
        }

        return newAcc
    }, { liveQuestionLib: {}, originalSpecialCount: 0, originalMockCount: 0 })

    const {
        newTotalCount,
        newSpecialCount,
        newMockCount,
    } = questionDrafts.reduce((acc, qd) => {
        const serial = qd.get('serial')
        if (!serial) {
            return acc
        }

        const oldQ = liveQuestionLib[serial]

        // if it's a new non-archived question or the old Q was archived, increment counts
        if (!oldQ && !qd.get('isArchived')) {
            acc.newTotalCount++

            if (qd.get('isSpecial')) {
                acc.newSpecialCount++
            }

            if (qd.get('isMockQuestion')) {
                acc.newMockCount++
            }

        // if old question that was not archived and is still not archived
        } else if (oldQ && !qd.get('isArchived')) {
            // old question was special, new question is not
            if (oldQ.isSpecial && !qd.get('isSpecial')) {
                acc.newSpecialCount--
            // old question wasn't special, new question is
            } else if (!oldQ.isSpecial && qd.get('isSpecial')) {
                acc.newSpecialCount++
            }
            // if old question was mock, new question is not
            if (oldQ.isMock && !qd.get('isMockQuestion')) {
                acc.newMockCount--
            } else if (!oldQ.isMock && qd.get('isMockQuestion')) {
                acc.newMockCount++
            }
        // if old question that was not archived but now is, decrement counts
        } else if (oldQ && qd.get('isArchived')) {
            acc.newTotalCount--
            if (oldQ.isSpecial) {
                acc.newSpecialCount--
            }
            if (oldQ.isMock) {
                acc.newMockCount--
            }
        }

        return acc
    }, { 
        newTotalCount: questions.length,
        newSpecialCount: originalSpecialCount,
        newMockCount: originalMockCount,
    })

    return {
        originalSpecialCount,
        originalMockCount,
        originalTotalCount: questions.length,
        newTotalCount,
        newSpecialCount,
        newMockCount,
    }
}

export default {
    fetchExamDrafts,
    fetchUnexportedExamDrafts,
    fetchExamDraft,
    fetchExamDraftByMetadataId,
    fetchExamDraftKADrafts,
    cloneExamDraftFromExamId,
    createExamDraft,
    updateExamDraft,
    updateStoreExamDraft,
    deleteExamDraft,
    fetchExamDraftStats,
}
