<template>
    <div v-if="isVisible" class="chat">
        <div ref="messages" class="chat__messages">
            <Message
                v-for="(message, index) in messages"
                :key="index"
                :content="message.content"
                :sender="getUser(message.userId)"
                :date="message.createdAt"
            />

            <div v-if="!messages.length" class="chat__messages-empty">
                There are no messages.
            </div>
        </div>
        <div class="chat__form">
            <PocketInput
                class="chat__text-input"
                placeholder="Input Text"
                v-model="message"
                :disabled="chatDisabled"
                :error="error"
                @keydown.enter="submitMessage"
            />
            <PocketButton
                class="chat__submit-message-btn"
                :disabled="chatDisabled"
                @click.prevent="submitMessage"
            >
                Send
            </PocketButton>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit, Watch } from 'vue-facing-decorator'
import type { TMappedMessage } from '@/store/chat/types'
import Message from './Message.vue'
import { chatModule } from '@/store/chat/module'
import { usersModule } from '@/store/users/module'
import UIKit from '@pocketprep/ui-kit'

@Component({
    components: {
        Message,
        PocketInput: UIKit.Input,
        PocketButton: UIKit.Button,
    },
    options: {
        sockets: {},
    },
})
export default class Chat extends Vue {
    @Prop() readonly jobId?: string
    @Prop() readonly isVisible!: boolean

    message = ''
    messages: TMappedMessage[] = []
    error = false
    chatDisabled = false

    get currentUser () {
        return usersModule.getters.getCurrentUser()
    }

    async mounted () {
        this.joinJobChatRoom()

        await this.fetchMessages()
        this.scrollChatBottom()

        const currentUser = this.currentUser

        // check whether there are unread messages
        if (currentUser && this.messages.length && 
            this.messages.find(message => 
                !!message.readUsers && 
                !message.readUsers.includes(currentUser.objectId)
            )
        ) {
            this.newMessage()
        }
    }

    getUser (userId: string) {
        return usersModule.getters.getUser(userId)
    }

    keyMonitor (e: KeyboardEvent) {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            this.submitMessage()
        }
    }

    async submitMessage () {
        if (this.currentUser) {
            this.error = false
            this.chatDisabled = true

            if (this.message === '') {
                this.error = true
                this.chatDisabled = false
                return
            }

            if (!this.jobId) {
                throw new Error('Invalid job.')
            }

            const newMessage = await chatModule.actions.createMessage({
                content: this.message,
                userId: this.currentUser.objectId,
                jobId: this.jobId,
            })
    
            this.$socket?.emit('SEND_MESSAGE', {
                content: this.message,
                userId: this.currentUser.objectId,
                jobId: this.jobId,
                createdAt: newMessage.createdAt,
            })
    
            this.chatDisabled = false
            this.message = ''

            await this.fetchMessages()
            this.scrollChatBottom()
        }
    }

    async fetchMessages () {
        this.messages = this.jobId
            ? (await chatModule.actions.fetchMessagesByJobId(this.jobId)).sort((a, b) => {
                if (a.createdAt && b.createdAt) {
                    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
                }

                return 0
            })
            : []
    }

    joinJobChatRoom () {
        if (this.jobId) {
            this.$socket?.emit('JOIN_ROOM', this.jobId)
            this.sockets?.subscribe('MESSAGE', (message: TMappedMessage) => {
                this.messages.push(message)
                this.newMessage()
                this.$nextTick(() => this.scrollChatBottom())
            })
        }
    }

    scrollChatBottom () {
        const messageEl = this.$refs.messages as HTMLElement
        if (messageEl) {
            messageEl.scrollTop = messageEl.scrollHeight
        }
    }

    @Watch('isVisible')
    isVisibleChange (val: boolean) {
        if (val) {
            this.$nextTick(() => {
                this.scrollChatBottom()
            })
            // Mark messages as read after 1 second
            setTimeout(async () => {
                if (this.jobId && this.currentUser) {
                    chatModule.actions.readJobMessages({
                        jobId: this.jobId,
                        userId: this.currentUser.objectId,
                    })
                }
            }, 1000)
        }
    }

    @Emit('newMessage')
    newMessage () {
        // emit undefined
    }
}
</script>

<style lang="scss" scoped>

.chat {
    background: $white;
    height: 396px;
    width: 496px;
    max-width: 100%;
    overflow-x: hidden;
    position: relative;
    border-radius: 10px;
    box-shadow: 0px 1px 15px 0px rgba($black-feather, 0.10);

    &__messages {
        padding: 20px;
        overflow-y: scroll;
        max-height: calc(100% - 84px);

        @include breakpoint('mobile') {
            padding: 10px;
        }
    }

    &__messages-empty {
        font-style: italic;
        text-align: center;
        font-size: 14px;
    }

    &__form {
        border-top: 1px solid $medium-gray;
        position: absolute;
        display: flex;
        bottom: 0;
        left: 0;
        width: 100%;
        align-items: center;
        padding: 10px;
    }

    &__text-input {
        width: 478px;
        height: 36px;

        :deep(.uikit-input__input) {
            border-right: none;
        }
    }

    &__submit-message-btn {
        height: 36px;
        margin-left: -8px;
        width: 80px;

        :deep(.uikit-btn__content) {
            margin-bottom: 1px;
        }
    }
}
</style>
