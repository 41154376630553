import { Vue, Component, Watch } from 'vue-facing-decorator'
import {
    TextField,
    FormGroup,
    FormSection,
    FormValidation,
    SelectField,
    EditorField,
} from '@/components/Forms/'
import QuestionTemplates from './QuestionTemplates.vue'
import ReferencesList from './ReferencesList.vue'
import JobLoading from '@/components/JobLoading.vue'
import JobInfo from '@/components/Jobs/JobInfo.vue'
import type { IListOptions, TSchema, TListDataIconFunc } from '@/components/Lists'
import { List } from '@/components/Lists'
import { objPointer } from '@/store/ParseUtils'
import ImportField from '@/components/Jobs/ImportField.vue'
import type { CMS, Study } from '@pocketprep/types'
import type { TMappedImportQuestion } from '@/store/files/types'
import type { TQuestionType } from '@/store/types'
import type { IQuestionDraft, TQuestionDraftStatus } from '@/store/questionDrafts/types'
import type { IKnowledgeArea } from '@/store/knowledgeAreaDrafts/types'
import type { IJobPayload, IQuestionTemplate, IQuestionTemplatePayload } from '@/store/jobs/types'
import type { TEnhancedQuestion } from '@/store/questions/types'
import jobsModule from '@/store/jobs/module'
import questionDraftsModule from '@/store/questionDrafts/module'
import examsModule from '@/store/exams/module'
import examDraftsModule from '@/store/examDrafts/module'
import kaDraftsModule from '@/store/knowledgeAreaDrafts/module'
import { usersModule } from '@/store/users/module'
import questionsModule from '@/store/questions/module'
import mockExamDraftsModule from '@/store/mockExamDrafts/module'
import mockExamsModule from '@/store/mockExams/module'
import ButtonFooter from '@/components/ButtonFooter.vue'
import TitleText from '@/components/TitleText.vue'
import ChatButton from '@/components/Chat/ChatButton.vue'
import Highlight from '@/assets/img/jobForm/Highlight.vue'
import UIKit from '@pocketprep/ui-kit'
import { bloomTaxonomyLevels, generateId } from '@/utils'

interface IListQuestionDraft {
    objectId: string
    serial: string
    prompt: string
    type: 'MCQ' | 'MCR' | 'TF'
    explanation: string
    passage: string
    references: string
    knowledgeArea: string
    isArchived: string
    selected: string
    isDisabled: string
    isSpecial: 'Yes' | 'No'
    answeredCount: number
    percentCorrect: number
    explanationImage: 'Yes' | 'No'
    questionImage: 'Yes' | 'No'
    subtopic: string
    bloomTaxonomyLevel: Study.Class.BloomTaxonomyLevel
    copyleaksScore?: CMS.Class.ICopyleaksScore
}
interface IMappedQuestionDraft {
    objectId?: string
    serial?: string
    examDataId?: string
    prompt: string
    type: TQuestionType
    explanation?: string
    passage?: string
    references?: string[]
    isArchived: boolean
    isFlagged: boolean
    draftStatus: TQuestionDraftStatus
    isSpecial: boolean
    hasComments: boolean
    hasSuggestions: boolean
    knowledgeArea: string
    isDisabled: boolean
    answeredCount: number
    percentCorrect: number
    images: CMS.Class.QuestionDraftJSON['images']
    isMockQuestion?: boolean
    subtopic?: string
    bloomTaxonomyLevel?: Study.Class.BloomTaxonomyLevel
    copyleaksScore?: CMS.Class.ICopyleaksScore
}

interface IExamListItem {
    status: 'new' | 'live'
    value: string
    label: string
}
@Component({
    components: {
        TextField,
        FormGroup,
        QuestionTemplates,
        FormSection,
        FormValidation,
        ReferencesList,
        JobInfo,
        List,
        ImportField,
        SelectField,
        ButtonFooter,
        TitleText,
        JobLoading,
        Errors: UIKit.Errors,
        CheckboxOptions: UIKit.CheckboxOption,
        Checkbox: UIKit.Checkbox,
        PocketButton: UIKit.Button,
        Icon: UIKit.Icon,
        PocketSelect: UIKit.Select,
        Calendar: UIKit.Calendar,
        PocketTextarea: UIKit.Textarea,
        PocketRadio: UIKit.Radio,
        EditorField,
        Tooltip: UIKit.Tooltip,
        OverflowTooltip: UIKit.OverflowTooltip,
        Highlight,
        ChatButton,
    },
})
export default class JobForm extends Vue {
    isLoading = true
    jobLoadingProgress = 0
    isDeletingJob = false
    isCreatingJob = false
    jobOptions = [
        { value: 'createQuestions', label: 'Create Questions' },
        { value: 'reviewQuestions', label: 'Review Questions' },
        { value: 'importQuestions', label:'Import Questions' },
    ]
    selectedJobFunction: { value: string; label: string } | null = null
    resetQuestionMetrics = false
    checkJobPlagiarism = false
    jobQuestionDrafts: IQuestionDraft[] = []
    validationMessages: string[] = []
    name = ''
    dueDate: Date | null = null
    dueDateLoaded = false
    writerSearch = ''
    writer: { value?: string; label: string } | null = null
    editorSearch = ''
    editor: { value?: string; label: string } | null = null
    leadSearch = ''
    lead: { value?: string; label: string } | null = null
    examId = ''
    examDraft: CMS.Class.ExamDraftJSON | undefined | null = null
    defaultExam: { value?: string; label: string } | null = null
    selectedJobType: {value: string; label: string } | null = null
    keywordStyles = [
        { value: 'uppercase', label: 'UPPERCASE' },
        { value: 'uppercaseBold', label: 'UPPERCASE BOLD' },
        { value: 'bold', label: 'Bold' },
        { value: 'none', label: 'None' },
    ]
    keywordStyling: { value?: string; label: string } | null = null
    // eslint-disable-next-line max-len
    defaultNotesAndLinks = '<p><a target="_blank" rel="noopener noreferrer" href="https://www.dropbox.com/scl/fi/fgl65ci1axp7g2u4bhpjz/Writer-Guide.pdf?rlkey=4p0mlwll847gugsgttmjrurw8&amp;dl=0">Writer Guide</a></p><p><a target="_blank" rel="noopener noreferrer" href="https://www.dropbox.com/scl/fi/d1sjcnnv57g62281gzjz2/Editor-Guide.pdf?rlkey=7vndngzgflsvbpaq8kcthswxp&amp;dl=0">Editor Guide</a></p><p>&nbsp;</p>'
    notesAndLinks = this.defaultNotesAndLinks
    selectedQuestions: string[] = []
    exams: Study.Class.ExamMetadataJSON[] = []
    examDrafts: CMS.Class.ExamDraftJSON[] = []
    unexportedExams: CMS.Class.ExamDraftJSON[] = []
    allowedQuestionTypes: CMS.Class.IAllowedQuestionTypes = {
        mcq: {
            allowed: true,
            numDistractors: 3,
        },
        mcr: {
            allowed: false,
        },
        tf: {
            allowed: false,
        },
    }
    targetExam: { value: string; label: string } | null = null
    mockExams: (Study.Class.MockExamJSON | CMS.Class.MockExamDraftJSON)[] = []
    knowledgeAreas: IKnowledgeArea[] = []
    knowledgeAreaDrafts: CMS.Class.KnowledgeAreaDraftJSON[] = []
    questionTemplates: IQuestionTemplatePayload[] = []
    selectedExam: Study.Class.ExamMetadataJSON | CMS.Class.ExamDraftJSON | null = null
    examQuestions: TEnhancedQuestion[] = [] // questions fetched for exam for new jobs
    importedQuestions: TMappedImportQuestion[] = []
    references: {
        reference: string
        id: string
        showRemoveTooltip?: boolean
        showAddTooltip?: boolean
    }[] = [{ reference: '', id: generateId(), showRemoveTooltip: false, showAddTooltip: false }]
    jobTypes = [
        { value: 'newExam', label: 'New Exam' }, 
        { value: 'newMockExam', label: 'New Mock Exam' },
        { value: 'newQuestions', label: 'New Questions' }, 
        { value: 'communityScoreReview', label: 'Community Score Review' },
        { value: 'qualityReview', label: 'Quality Review' }, 
        { value: 'examOutlineUpdates', label: 'Exam Outline Updates' },
        { value: 'memberConcern', label: 'Member Concern' },
        { value: 'referenceUpdate', label: 'References' }, 
        { value: 'other', label: 'Other' },
    ]
    subtopicCountErrors: { [subjectName: string]: string [] } = {}
    
    get loadingMessage () {
        if (this.isCreatingJob) {
            return 'Creating Job'
        } else if (this.isDeletingJob) {
            return 'Deleting Job'
        } else {
            return 'Loading Job'
        }
    }

    get targetExams () {
        const mockExams = this.mockExams.map(me => ({ value: me.objectId, label: me.name }))
        if (this.selectedJobType?.value !== 'newMockExam') {
            return [
                {
                    value: 'standard',
                    label: 'Standard Question Bank',
                },
                ...mockExams,
            ]
        } else {
            return [ ...mockExams ]
        }
    }

    get users () {
        return usersModule.state.users
    }

    get jobs () {
        return jobsModule.state.jobs
    }

    get isCreateQuestions () {
        return this.selectedJobFunction?.value === 'createQuestions'
    }
    get isReviewQuestions () {
        return this.selectedJobFunction?.value === 'reviewQuestions'
    }
    get isImportQuestions () {
        return this.selectedJobFunction?.value === 'importQuestions'
    }

    get job () {
        const jobId = this.$route.params.jobId,
            job = this.jobs.find(item => item.objectId === jobId)

        return job || undefined
    }

    get isNotesEdited () {
        return this.notesAndLinks !== this.defaultNotesAndLinks
    }

    get mappedKnowledgeAreas ()  {
        const jobKnowledgeAreas: (IKnowledgeArea|CMS.Class.KnowledgeAreaDraftJSON)[] =
            this.knowledgeAreas.length ? this.knowledgeAreas : this.knowledgeAreaDrafts
        return jobKnowledgeAreas.length
            ? jobKnowledgeAreas
                .filter(ka => !ka.isArchived)
                .sort((a, b) => a.name < b.name ? -1 : a.name === b.name ? 0 : 1)
                .map(knowledgeArea => ({
                    name: knowledgeArea.name,
                    subtopics: knowledgeArea.subtopics,
                }))
            : []
    }

    get examDraftNamesByCompositeKey () {
        type TDraftNames = {
            [key: string]: string
        }
        const examDraftNames: TDraftNames = {}
        this.examDrafts.forEach(ed => {
            examDraftNames[ed.compositeKey] = ed.nativeAppName
        })

        return examDraftNames
    }

    get formattedExamList () {
        return [
            ...this.exams
                .sort((a, b) => -a.compositeKey.localeCompare(b.compositeKey, undefined, { numeric: true }))
                .filter((exam, index, self) =>
                    // Only keep the first exam of each directory (which will be the most recent due to previous sort)
                    index === self.findIndex(selfExam =>
                        selfExam.compositeKey.split('/')[0] === exam.compositeKey.split('/')[0]
                    )
                )
                .map(exam => ({
                    status: 'live' as const,
                    compositeKey: exam.compositeKey,
                    value: exam.objectId,
                    // if exam draft available use that name, otherwise use exported exam name
                    label: this.examDraftNamesByCompositeKey[exam.compositeKey] || exam.nativeAppName,
                }))
                .sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true })),
            ...this.unexportedExams.map(exam => ({
                status: 'new' as const,
                compositeKey: exam.compositeKey,
                value: exam.objectId,
                label: exam.nativeAppName,
            })),
        ]
    }

    get canSubmitForm (): boolean {
        const hasSubtopicCountErrors = Object.values(this.subtopicCountErrors).some(subj => subj.length)
        if (this.$route.params.jobId && !hasSubtopicCountErrors) {
            return true
        }
        if (this.importedQuestions.length) {
            return true
        }
        if (this.isReviewQuestions && this.selectedQuestions.length) {
            return true
        }
        if (this.questionTemplates.filter(qt => qt.count > 0).length && !hasSubtopicCountErrors) {
            return true
        }
        return false
    }

    get questions (): IMappedQuestionDraft[] {
        // if there are no job question drafts and no exam questions, there are no questions
        if (!this.jobQuestionDrafts.length && !this.examQuestions.length) {
            return []
        }

        // get job questions if jobId set, otherwise use exam questions
        const questions = this.$route.params.jobId
            ? this.jobQuestionDrafts.map(item => {
                const knowledgeAreaObj = this.knowledgeAreaDrafts.find(ka => ka.objectId === item.knowledgeAreaDraftId)

                return {
                    ...item,
                    prompt: item.prompt || '',
                    isDisabled: false,
                    knowledgeArea: (knowledgeAreaObj && knowledgeAreaObj.name) || '',
                    answeredCount: (item.answeredCorrectlyCount || 0) + (item.answeredIncorrectlyCount || 0),
                    percentCorrect: item.percentCorrect || 0,
                    images: item.images || {},
                }
            })
            : this.examQuestions.map(item => {
                const isDisabled = questionDraftsModule.state.questionDraftExamIds.includes(item.objectId || '')
                const draftImageObject: CMS.Class.QuestionDraftJSON['images'] = {}
                if (item.explanationImage) {
                    draftImageObject.explanation = item.explanationImage
                }
                if (item.passageImage) {
                    draftImageObject.passage = item.passageImage
                }
                const subtopic = item.subtopicId && (item.subject as Study.Class.SubjectJSON).subtopics?.find(sub =>
                    sub.id === item.subtopicId
                )?.name

                return {
                    objectId: item.objectId,
                    serial: item.serial,
                    isDisabled,
                    examDataId: item.objectId,
                    prompt: item.prompt,
                    type: item.type,
                    explanation: item.explanation,
                    passage: item.passage,
                    references: item.references,
                    isArchived: item.isArchived,
                    isFlagged: false,
                    draftStatus: 'inactive' as const,
                    isSpecial: item.isFree,
                    hasComments: false,
                    hasSuggestions: false,
                    knowledgeArea: (item.subject as Study.Class.SubjectJSON).name,
                    answeredCount: item.answeredCorrectlyCount || 0 + item.answeredIncorrectlyCount || 0,
                    percentCorrect: item.percentCorrect || 0,
                    images: draftImageObject,
                    isMockQuestion: item.isMockQuestion,
                    subtopic,
                    bloomTaxonomyLevel: item.bloomTaxonomyLevel,
                }
            })

        return questions
    }

    get selectedListData () {
        return typeof this.listOptions.listData !== 'function'
             && this.listOptions.listData.filter(data => data.selected === 'Yes')
    }

    get subtopicData () {
        return this.mappedKnowledgeAreas?.flatMap(ka =>
            ka.subtopics?.map((sub, index) => ({
                label: sub.name,
                value: sub.name,
                sectionTitle: index === 0 ? ka.name : '',   // Add the section title to first of each section
            })) || [])
    } 

    get listOptions (): IListOptions<IListQuestionDraft> {
        const listSchema: TSchema<IListQuestionDraft> = [
            {
                propName: 'serial',
                label: 'Serial',
                type: 'text',
                options: {
                    width: 155,
                    group: 0,
                    labelStyles: !this.job ? { paddingLeft: '50px' } : {},
                },
            },
            {
                propName: 'knowledgeArea',
                label: 'Subject',
                type: 'text',
                options: {
                    style: 'overflow-ellipsis',
                    width: this.subtopicData.length ? 180 : 254,
                    group: 0,
                    typeahead: true,
                },
                data: this.mappedKnowledgeAreas.map(ka => ka.name),
            },
            {
                propName: 'bloomTaxonomyLevel',
                label: 'Bloom\'s',
                type: 'text',
                data: bloomTaxonomyLevels,
                options: {
                    group: 0,
                    width: 104,
                },
            },
            {
                propName: 'type',
                label: 'Question type',
                type: 'text',
                options: {
                    group: 0,
                    width: 60,
                },
                data: [ 'MCQ', 'MCR', 'TF' ],
            },
            {
                propName: 'prompt',
                label: 'Question Prompt',
                type: 'text',
                options: {
                    style: 'overflow-ellipsis',
                    group: 2,
                    width: this.subtopicData.length ? 420 : 520,
                },
            },
            {
                propName: 'answeredCount',
                label: 'Answered',
                type: 'text',
                options: {
                    filter: false,
                    group: 1,
                    width: 75,
                    fieldStyles: { paddingLeft:'0', paddingRight:'15px', textAlign: 'right' },
                    labelStyles: { paddingRight:'0', paddingLeft:'0' },
                },
            },
            {
                propName: 'percentCorrect',
                label: '% Correct',
                type: 'percent',
                options: {
                    width: 60,
                    group: 1,
                    fieldStyles: { padding: '10px 17px 10px 0', textAlign: 'right' },
                    labelStyles: { paddingRight: '0', paddingLeft:'0', textAlign: 'left', marginLeft: '0' },
                },
            },
            {
                propName: 'passage',
                label: 'Passage',
                type: 'text',
                options: {
                    isHidden: true,
                },
            },
            {
                propName: 'explanation',
                label: 'Explanation',
                type: 'text',
                options: {
                    isHidden: true,
                },
            },
            {
                propName: 'references',
                label: 'References',
                type: 'text',
                options: {
                    isHidden: true,
                },
            },
            {
                propName: 'selected',
                label: 'Selected',
                type: 'text',
                options: {
                    filter: this.selectedQuestions.length === 1 ? 'Yes' : '',
                    isHidden: true,
                    filterValueWhenActive: 'Yes',
                },
                data: [ 'Yes', 'No' ],
            },
            {
                propName: 'isSpecial',
                label: 'Free question',
                type: 'text',
                options: {
                    isHidden: true,
                    filterValueWhenActive: 'Yes',
                },
                data: [ 'Yes', 'No' ],
            },
            {
                propName: 'isDisabled',
                label: 'Disabled (in another job)',
                type: 'text',
                options: {
                    isHidden: true,
                    filter: 'No',
                    filterValueWhenActive: 'Yes',
                },
                data: [ 'Yes', 'No' ],
            },
            {
                propName: 'isArchived',
                label: 'Archived question',
                type: 'text',
                options: {
                    isHidden: true,
                    filter: 'Active',
                    filterValueWhenActive: 'Archived',
                },
                data: [ 'Active', 'Archived' ],
            },
            {
                propName: 'explanationImage',
                label: 'Has explanation image',
                type: 'text',
                options: {
                    isHidden: true,
                    filterValueWhenActive: 'Yes',
                },
                data: [ 'Yes', 'No' ],
            },
            {
                propName: 'questionImage',
                label: 'Has question image',
                type: 'text',
                options: {
                    isHidden: true,
                    filterValueWhenActive: 'Yes',
                },
                data: [ 'Yes', 'No' ],
            },
        ]

        if (this.subtopicData.length) {
            listSchema.splice(2, 0, 
                {
                    propName: 'subtopic',
                    label: 'Subtopic',
                    type: 'text',
                    data: this.subtopicData,
                    options: {
                        width: 174,
                        group: 0,
                        typeahead: true,
                    },
                }
            )
        }

        const listDataModifiers = [
            (data: IListQuestionDraft) =>
                data.isDisabled === 'Yes' && { opacity: '0.5' },
        ]

        const listDataIcons: TListDataIconFunc<IListQuestionDraft>[] = [
            data =>
                data.isDisabled === 'Yes'
                    ? {
                        iconName: 'exclamationTriangle',
                        label: 'Question in active job',
                        styles: { color: '#fff', backgroundColor: '#000' },
                    }
                    : false,
            data =>
                data.isArchived === 'Archived'
                    ? {
                        iconName: 'archive',
                        label: 'Archived',
                        styles: { color: '#fff', backgroundColor: '#475964' },
                    }
                    : false,
        ]

        return {
            listData: this.formattedListData,
            listSchema,
            listDataModifiers,
            listDataIcons,
            defaultSort: {
                propName: 'knowledgeArea',
                sortDir: 'ASC',
            },
        }
    }

    get formattedListData (): IListQuestionDraft[] {
        const mockQuestionSerialsSet = 
            new Set(this.mockExams.find(me => me.objectId === this.targetExam?.value)?.questionSerials)
        const filteredQuestions = this.targetExam?.value === 'standard'
            ? this.questions.filter(q => !q.isMockQuestion)
            : this.questions.filter(q => mockQuestionSerialsSet.has(q.serial || ''))
        return filteredQuestions.map(item => {
            const questionId = item.objectId || item.examDataId || ''

            return {
                objectId: questionId || '',
                serial: item.serial || '',
                type: item.type === 'Multiple Choice' 
                    ? 'MCQ' : 
                    item.type === 'Multiple Correct Response' 
                        ? 'MCR' 
                        : 'TF',
                knowledgeArea: item.knowledgeArea || '',
                prompt: item.prompt,
                explanation: item.explanation || '',
                passage: item.passage || '',
                references: item.references?.join(', ') || '',
                isArchived: (item.isArchived && 'Archived') || 'Active',
                selected: this.selectedQuestions.find(selectedQuestionId => selectedQuestionId === questionId)
                    ? 'Yes'
                    : 'No',
                isDisabled: item.isDisabled ? 'Yes' : 'No',
                isSpecial: item.isSpecial ? 'Yes' : 'No',
                answeredCount: item.answeredCount,
                percentCorrect: item.percentCorrect,
                explanationImage: item.images?.explanation ? 'Yes' : 'No',
                questionImage: item.images?.passage ? 'Yes' : 'No',
                subtopic: item.subtopic || '',
                bloomTaxonomyLevel: item.bloomTaxonomyLevel || 'None',
            }
        })
    }

    get isAllowedQuestionTypesValid () {
        const aqt = this.allowedQuestionTypes
        return aqt.mcq.allowed || aqt.mcr.allowed || aqt.tf.allowed
    }

    get currentUser () {
        return usersModule.state.user
    }

    async mounted () {
        // fetch exams
        this.isLoading = true
        this.exams = await examsModule.actions.fetchExams()
        this.examDrafts = await examDraftsModule.actions.fetchExamDrafts()
        this.jobLoadingProgress = 30

        // fetch unexported exams
        this.unexportedExams = await examDraftsModule.actions.fetchUnexportedExamDrafts()
        this.jobLoadingProgress = 60

        // check if job is in store or fetch job
        if (!this.job && typeof this.$route.params.jobId === 'string') {
            await jobsModule.actions.fetchJob(this.$route.params.jobId)
        }

        // if editing a job
        if (this.job) {
            this.examDraft = await this.fetchOrGetExamDraft(this.job.examDraftId)

            if (!this.examDraft) {
                throw new Error('Unable to find exam draft.')
            }

            // fetch exam knowledge areas
            this.knowledgeAreaDrafts = await kaDraftsModule.actions.fetchKADraftsByExamDraftId(this.examDraft.objectId)

            // CMS-476 - Check that we have fetched subtopics
            // This check can be removed once all jobs created before this fix are complete
            if (this.examDraft?.examMetadataId && !this.subtopicData.length) {
                const subjects = (await examsModule.actions.fetchSubjects({
                    examMetadataId: this.examDraft.examMetadataId,
                }))
                // For each subject, if there are live subtopics but no draft subtopics, update the draft subtopics
                const kaDraftUpdates = subjects.map(subject => {
                    const subjectKADraft = this.knowledgeAreaDrafts
                        .find(kaDraft => kaDraft.subjectId === subject.objectId)
                    if (subjectKADraft
                        && !subjectKADraft?.subtopics
                        && subject.subtopics
                    ) {
                        return kaDraftsModule.actions.updateKADraft({
                            knowledgeAreaDraftId: subjectKADraft.objectId,
                            params: {
                                examDraft: objPointer(subjectKADraft.examDraft.objectId)('ExamDraft'),
                                isArchived: subjectKADraft.isArchived,
                                name: subjectKADraft.name,
                                subjectId: subjectKADraft.subjectId,
                                subtopics: subject.subtopics,
                            },
                        })
                    } else {
                        return null
                    }
                })
                // Wait for any promises to resolve
                await Promise.all(kaDraftUpdates)
                // If any subtopic fixes were required, refetch the knowledge area drafts
                const didUpdateKADrafts = kaDraftUpdates.some(kaDraftUpdate => kaDraftUpdate !== null)
                if (didUpdateKADrafts) {
                    this.knowledgeAreaDrafts = await kaDraftsModule.actions.fetchKADraftsByExamDraftId(
                        this.examDraft.objectId
                    )
                }
            }
            // End of CMS-476 check

            // fetch job question drafts
            const jobId = this.job.objectId
            this.jobQuestionDrafts = jobId
                ? await Promise.all((await questionDraftsModule.actions.fetchQuestionDrafts(
                    {
                        equalTo: {
                            job: objPointer(jobId)('Job'),
                        },
                    }
                )).results.map(item => questionDraftsModule.actions.convertPQDraftToQDraft(item)))
                : []

            this.jobLoadingProgress = 80

            // update local state with job info
            this.name = this.job.name
            if (this.job.mockExamDraftId) {
                const mockExamDrafts = await mockExamDraftsModule.actions.fetchMockExamDrafts({
                    examDraftId: this.job.examDraftId,
                })
                const targetMockExam = mockExamDrafts.find(med => med.objectId === this.job?.mockExamDraftId)
                this.targetExam = { value: targetMockExam?.objectId || '', label: targetMockExam?.name || '' } || null
            } else {
                this.targetExam = this.targetExams.find(te => te.value === 'standard') || null
            }
            this.selectedJobFunction = this.jobOptions.find(jo => jo.label === this.job?.jobFunction) || null
            this.defaultExam = this.formattedExamList.find(exam => exam.label === this.job?.name) || null
            this.dueDate = this.job.dueDate ? new Date(this.job.dueDate) : null
            this.writer = this.formattedUserList('Writer').find(user => user.value === this.job?.writerId) || null
            this.editor = this.formattedUserList('Editor').find(user => user.value === this.job?.editorId) || null
            this.lead = this.formattedUserList('Admin').find(user => user.value === this.job?.leadId) || null
            this.keywordStyling = this.keywordStyles.find(style => style.label === this.job?.keywordStyling) || null
            this.notesAndLinks = this.job.notesAndLinks || this.defaultNotesAndLinks
            this.checkJobPlagiarism = this.job.runPlagiarismCheck
            if (this.job.references?.length) {
                this.references = this.job.references?.map(reference => ({
                    reference,
                    id: generateId(),
                    showRemoveTooltip: false,
                    showAddTooltip: false,
                }))
            }
            this.allowedQuestionTypes = this.job.allowedQuestionTypes
            this.questionTemplates = this.job.questionTemplates && this.job.questionTemplates.map(qt => {
                const ka = this.knowledgeAreaDrafts.find(item => item.objectId === qt.knowledgeAreaDraftId)
                return {
                    name: (ka && ka.name) || '',
                    count: qt.count,
                    subtopics: qt.subtopics,
                }
            })
                || []
        } else {
            // if not editing job, fetch question draft IDs
            await questionDraftsModule.actions.fetchQuestionDraftExamIds()
        }

        // if creating a job from a single question ID
        if (typeof this.$route.query.question === 'string') {
            const questionId = this.$route.query.question
            const question = await questionsModule.actions.fetchQuestion(questionId)
            const exam = this.exams.find(item => item.compositeKey === question.compositeKey)

            if (!exam) {
                throw new Error('Unable to find question exam.')
            }

            await this.examSelected({ status: 'live', value: exam.objectId, label: '' })
            this.defaultExam =  this.formattedExamList.find(fe => fe.compositeKey === exam.compositeKey) || null
            const reviewQuestions = this.jobOptions.find(jobOption => jobOption.value === 'reviewQuestions')
            if (reviewQuestions) {
                this.selectedJobFunction = reviewQuestions
            }
            if (question.isMockQuestion) {
                const mockExamForQuestion = this.mockExams.find(me => me.questionSerials.includes(question.serial))
                this.targetExam = this.targetExams.find(te => te.value === mockExamForQuestion?.objectId) || null
            } else {
                this.targetExam = this.targetExams.find(te => te.value === 'standard') || null
            }

            const newQuestion = this.questions.find(item => item.examDataId === question.objectId)

            this.selectedQuestions = [ (newQuestion && newQuestion.examDataId) || '' ]
        }

        // if examId passed, load that exam
        if (this.$route.query.examId && typeof this.$route.query.examId === 'string') {
            const exam = await this.fetchOrGetExam(this.$route.query.examId)
            if (exam) {
                await this.examSelected({ status: 'live', value: this.$route.query.examId, label: '' })
                this.defaultExam = this.formattedExamList.find(fe => fe.compositeKey === exam.compositeKey) || null
            }
        }

        // if examDraftId passed, load that exam
        if (this.$route.query.examDraftId && typeof this.$route.query.examDraftId === 'string') {
            await this.examSelected({ status: 'new', value: this.$route.query.examDraftId, label: '' })
            this.defaultExam = this.formattedExamList.find(fe => 
                fe.compositeKey === this.examDraft?.compositeKey) || null
        }

        this.jobLoadingProgress = 100
        if (this.isLoading) {
            this.isLoading = false
        }
        this.jobLoadingProgress = 0
    }

    reviewQuestionClicked (row: { objectId: string }) {
        const clickedQuestion = this.questions.find(q => q.objectId === row.objectId)
        if (this.selectedQuestions.includes(row.objectId)) {
            this.selectedQuestions = this.selectedQuestions.filter(id => id !== row.objectId)
        } else if (clickedQuestion && !clickedQuestion.isDisabled) {
            this.selectedQuestions.push(row.objectId)
        }
    }

    @Watch('selectedJobType')
    updateRadioChoice () {
        if ([ 'newExam', 'newMockExam', 'newQuestions' ].includes(this.selectedJobType?.value as string)) {
            const createQuestions = this.jobOptions.find(jobOption => jobOption.value === 'createQuestions')
            if (createQuestions) {
                this.selectedJobFunction = createQuestions
            }
        } else if (this.selectedJobType?.value === 'memberConcern'
        || this.selectedJobType?.value === 'communityScoreReview'
        || this.selectedJobType?.value === 'qualityReview'
        ) {
            const reviewQuestions = this.jobOptions.find(jobOption => jobOption.value === 'reviewQuestions')
            if (reviewQuestions) {
                this.selectedJobFunction = reviewQuestions
                this.resetQuestionMetrics = true
            }
        } else {
            const reviewQuestions = this.jobOptions.find(jobOption => jobOption.value === 'reviewQuestions')
            if (reviewQuestions) {
                this.selectedJobFunction = reviewQuestions
            }
        }
    }

    @Watch('selectedJobType')
    updateTargetExam () {
        if (this.selectedJobType?.value === 'newMockExam') {
            this.targetExam = null
        }
    }

    @Watch('targetExam')
    @Watch('reviewQuestions')
    targetExamChanged () {
        this.isLoading = true
        this.selectedQuestions = (typeof this.listOptions.listData !== 'function'
            && this.listOptions.listData
                .filter(item => 
                    item.isDisabled !== 'Yes' 
                    && item.isArchived !== 'Archived' 
                    && (
                        (typeof this.$route.query.question === 'string' && this.$route.query.question === item.objectId)
                        || !this.$route.query.question
                    ))
                .map(data => data.objectId || ''))
            || []
        this.isLoading = false
    }

    @Watch('selectedJobFunction')
    clearImportedQuestions () {
        if (this.selectedJobFunction?.value !== 'importQuestions') {
            this.importedQuestions = []
        }
    }

    async fetchOrGetExamDraft (examDraftId: string) {
        return examDraftsModule.getters.getExamDraft(examDraftId)
            || await examDraftsModule.actions.fetchExamDraft(examDraftId)
    }

    formattedUserList (role: string):
    {
        value: string
        label: string
    }[] {
        return this.users
            .filter(user => !user.isDisabled)
            .filter(user => [ 'Admin', role ].includes(user.role)).map((user) => ({
                value: user.objectId,
                label: `${user.firstName} ${user.lastName}`,
                subtext: user.username,
            }))
            .sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)
    }

    async fetchOrGetExam (examId: string) {
        return examsModule.getters.getExam(examId)
            || await examsModule.actions.fetchExam(examId)
    }

    async examSelected (result?: IExamListItem) {
        if (result?.value === this.selectedExam?.objectId) {
            return
        }
        if (!result || !result.value) {
            this.examDraft = null
            this.knowledgeAreaDrafts = []
            this.knowledgeAreas = []
            this.examId = ''
            this.examQuestions = []
            return
        }

        this.selectedExam = result.status === 'live'
            ? await this.fetchOrGetExam(result.value || '') || null
            : await examDraftsModule.actions.fetchExamDraft(result.value) || null

        if (!this.selectedExam) {
            throw new Error('Unable to find exam.')
        }

        this.isLoading = true

        this.knowledgeAreaDrafts = []
        this.knowledgeAreas = []

        // set default name
        this.name = this.selectedExam.nativeAppName

        // if exam is live
        if (result.status === 'live') {
            this.examDraft = await examDraftsModule.actions.fetchExamDraftByMetadataId(result.value)
            this.examId = this.selectedExam.objectId
        }
        // if exam is draft
        if (result.status === 'new' && 'appName' in this.selectedExam) {
            this.examDraft = this.selectedExam
        }

        // if exam draft exists, fetch draft knowledge areas and mock exam drafts
        if (this.examDraft) {
            this.knowledgeAreaDrafts = await kaDraftsModule.actions.fetchKADraftsByExamDraftId(this.examDraft.objectId)

            // fetch mock exams
            this.mockExams = await mockExamDraftsModule.actions.fetchMockExamDrafts({
                examDraftId: this.examDraft.objectId,
                forceFetch: true,
            })
                || []
        } else {
            // otherwise, get selected exam's live knowledge areas and mock exams
            this.knowledgeAreas = (await examsModule.actions.fetchSubjects({ examMetadataId: this.examId }))
                .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                .map(s => ({
                    name: s.name,
                    isArchived: s.isArchived,
                    examMetadataId: s.examMetadata.objectId,
                    subjectId: s.objectId,
                    subtopics: s.subtopics,
                }))

            // fetch mock exams
            this.mockExams = this.examId && await mockExamsModule.actions.fetchMockExams(this.examId) || []
        }

        // fetch exam's questions
        if (this.examId) {
            this.examQuestions = await questionsModule.actions.fetchQuestionsByExam({
                examMetadataId: this.examId,
            })
            // select all questions
            this.targetExamChanged()
        }

        // if starting job from specific question and question is mock, select correct mock exam
        if (typeof this.$route.query.question === 'string') {
            const question = this.examQuestions.find(item => item.objectId === this.$route.query.question)
            if (question && question.isMockQuestion) {
                const mockObj = this.mockExams.find(me => me.questionSerials.includes(question.serial))
                mockObj 
                    ? this.targetExam = { 
                        value: mockObj.objectId,
                        label: mockObj.name,
                    }
                    : this.targetExams[0]
            }
        }

        this.targetExam = null
        this.isLoading = false
    }

    updatedQuestionTemplates (questionTemplates: IQuestionTemplatePayload[]) {
        this.questionTemplates = questionTemplates
    }

    updatedSubtopicCountErrors (subtopicCountErrors: { [subjectName: string]: string[] }) {
        this.subtopicCountErrors = subtopicCountErrors
    }

    removeReference (index: number) {
        if (this.references.length <= 1) {
            return
        }
        this.references.splice(index, 1)
        this.references[index - 1].showAddTooltip = false
    }

    addReference () {
        this.references.push({ reference:'', id: generateId() })
    }

    async submitCreateJobForm () {
        this.isLoading = true
        this.isCreatingJob = true
        this.validationMessages = []

        if (!this.isAllowedQuestionTypesValid) {
            this.validationMessages.push('At least 1 question type must be selected to create a job.')
        }

        if (this.validationMessages.length) {
            this.isLoading = false
            return
        }

        // get or clone exam draft
        const examDraft = this.examDraft
            || await examDraftsModule.actions.cloneExamDraftFromExamId(this.examId)

        if (!examDraft) {
            throw new Error('Unable to fetch exam draft')
        }

        this.jobLoadingProgress = 20

        // fetch mock exam drafts so we have the right id for the job pointer
        if (this.targetExam?.value && this.targetExam?.value !== 'standard') {
            this.mockExams = await mockExamDraftsModule.actions.fetchMockExamDrafts({ examDraftId: examDraft.objectId })
            this.targetExam.value = (this.mockExams as CMS.Class.MockExamDraftJSON[])
                .find(me => 
                    (me.mockExamId && me.mockExamId === this.targetExam?.value) 
                    || me.objectId === this.targetExam?.value
                )?.objectId || 'standard'
        }

        this.jobLoadingProgress = 40

        // get or clone knowledge areas
        const knowledgeAreaDrafts = this.$route.params.jobId
            ? this.knowledgeAreaDrafts
            : await kaDraftsModule.actions.cloneKnowledgeAreasFromExamDraftId(examDraft.objectId)

        // import KAs and add question templates for imported questions
        if (this.importedQuestions.length && this.isImportQuestions) {
            knowledgeAreaDrafts.push(...(await this.createNewImportedKAs(examDraft, knowledgeAreaDrafts)))

            // create imported question question templates
            const initNewQTsObj: { [key: string]: IQuestionTemplatePayload } = {},
                existingQTsObj = this.questionTemplates.reduce((acc, qt) => {
                    acc[qt.name] = qt
                    return acc
                }, initNewQTsObj),
                newImportedQuestions = this.importedQuestions.filter(iq => !iq.objectId),
                combinedQTs = newImportedQuestions.reduce((acc, iq) => {
                    const subjName = iq.subject
                    if (subjName in acc) {
                        acc[subjName].count++
                    } else {
                        acc[subjName] = {
                            name: subjName,
                            count: 1,
                        }
                    }

                    return acc
                }, existingQTsObj)

            this.questionTemplates = Object.values(combinedQTs)
        }

        this.jobLoadingProgress = 50

        // map question templates to knowledge area Parse objects
        const questionTemplates: IQuestionTemplate[] = (knowledgeAreaDrafts.length && this.questionTemplates.length)
            ? this.questionTemplates.map<IQuestionTemplate | undefined>(qt => {
                const ka = knowledgeAreaDrafts.find(item => item.name === qt.name)

                return ka
                        && {
                            knowledgeAreaDraftId: ka.objectId || '',
                            count: qt.count,
                            subtopics: qt.subtopics,
                        }
            })
                .filter((qt): qt is IQuestionTemplate => qt !== undefined && !!(qt.count))
            : []

        // setup job object for saving
        const jobId = typeof this.$route.params.jobId === 'string' ? this.$route.params.jobId : undefined
        const jobSettings: IJobPayload = {
            objectId: jobId,
            name: this.name,
            type: this.selectedJobType?.label,
            jobFunction: this.selectedJobFunction?.label,
            dueDate: this.dueDate ? new Date(this.dueDate) : undefined,
            writerId: this.writer?.value,
            editorId: this.editor?.value,
            leadId: this.lead?.value,
            keywordStyling: this.keywordStyling?.label,
            notesAndLinks: this.notesAndLinks,
            references: this.references.map(ref => ref.reference).filter(ref => ref),
            allowedQuestionTypes: this.allowedQuestionTypes,
            questionTemplates,
            runPlagiarismCheck: this.checkJobPlagiarism,
            examDraft: { __type: 'Pointer', className: 'ExamDraft', objectId: examDraft.objectId },
            mockExamDraft: this.targetExam?.value === 'standard' || !this.targetExam?.value
                ? undefined
                : { __type: 'Pointer', className: 'MockExamDraft', objectId: this.targetExam?.value as string },
        }

        this.jobLoadingProgress = 60

        // if new job, save and fetch the exam draft and knowledge area drafts
        const job = this.$route.params.jobId
            ? await jobsModule.actions.updateJob(jobSettings)
            : await jobsModule.actions.createJob(jobSettings)

        // save questions if new job
        if (!this.$route.params.jobId && this.isReviewQuestions) {
            const newQuestions = this.examQuestions.filter(item => this.selectedQuestions.includes(item.objectId))
            const newQuestionDrafts = await Promise.all(newQuestions.map(async question => {
                const knowledgeAreaDraft = knowledgeAreaDrafts
                    .find(item => item.name === (question.subject as Study.Class.SubjectJSON).name)

                return {
                    ...(await questionDraftsModule.actions.convertPQToPQDraft({
                        question,
                        examDraft,
                        knowledgeAreaDraft,
                    })),
                    job: objPointer(job.objectId)('Job'),
                    jobStatus: 'Writer' as const,
                    draftStatus: 'active' as const,
                    willResetMetrics: this.isReviewQuestions && this.resetQuestionMetrics,
                }
            }))
            
            await questionDraftsModule.actions.upsertQuestionDrafts(newQuestionDrafts)
        }

        // save imported questions
        if (this.importedQuestions.length && this.isImportQuestions) {
            // import question drafts
            const newImportedQuestionDrafts = await Promise.all(this.importedQuestions.map(async question => {
                const knowledgeAreaDraft = knowledgeAreaDrafts
                    .find(item => item.name === question.subject)

                return {
                    ... (await questionDraftsModule.actions.convertPQToPQDraft({
                        // Forcing this conversion because Subject and ExamMetadata are different
                        // between these two types but not used in convertPQToPQDraft anyway
                        question: (question as unknown as TEnhancedQuestion),
                        examDraft,
                        knowledgeAreaDraft,
                    })),
                    job: objPointer(job.objectId)('Job'),
                    jobStatus: 'Writer' as const,
                    draftStatus: 'active' as const,
                    isMockQuestion: this.targetExam?.value !== 'standard',
                }
            }))

            await questionDraftsModule.actions.upsertQuestionDrafts(newImportedQuestionDrafts)
            
            // update MockExamDraft with imported questions
            if (this.targetExam?.value !== 'standard') {
                const mockExam = (this.mockExams as CMS.Class.MockExamDraftJSON[])
                    .find(me => (me.mockExamId && me.mockExamId === this.targetExam?.value) || 
                    me.objectId === this.targetExam?.value)
                const mockExamDraftSerials = new Set([
                    ...mockExam?.questionSerials || [],                 // current serials in MockExam
                    ...newImportedQuestionDrafts.map(qd => qd.serial),  // imported serials
                ] as string[])
                mockExam && await mockExamDraftsModule.actions.updateMockExamDraft({
                    ...mockExam,
                    questionSerials: Array.from(mockExamDraftSerials),
                })
            }
        }

        this.jobLoadingProgress = 80

        // refetch job to get updated metrics
        await jobsModule.actions.fetchJob(job.objectId)

        // kick off plagiarism scan
        if (this.checkJobPlagiarism) {
            jobsModule.actions.checkJobPlagiarism(job.objectId)
        }

        this.jobLoadingProgress = 100
        this.isCreatingJob = false
        this.jobLoadingProgress = 0

        window.scrollTo(0,0)

        this.$router.push({
            name: 'job-view',
            params: { jobId: job.objectId },
            query: { newJob: 'true' },
        })
    }

    cancelJobFormClicked () {
        this.$router.push({
            name: 'job-list',
        })
    }

    async submitDeleteJob () {
        if (!this.job) {
            throw 'Job not found. Unable to delete job.'
        }

        const confirmDialog = confirm(
            `Are you sure you want to delete ${this.job.name}? ` +
            'Any changes made to questions in this job will be lost. This action cannot be undone.'
        )
        if (confirmDialog) {
            this.isDeletingJob = true
            this.isLoading = true
            this.jobLoadingProgress = 20
            await jobsModule.actions.deleteJob(this.job.objectId)
            this.jobLoadingProgress = 100
            await this.$router.push({ name: 'job-list' })
            this.isDeletingJob = false
            this.isLoading = false
            this.jobLoadingProgress = 0
        }
    }

    // check if imported data has new knowledge areas and import them
    async createNewImportedKAs (
        examDraft: CMS.Class.ExamDraftJSON,
        knowledgeAreaDrafts: CMS.Class.KnowledgeAreaDraftJSON[]
    ) {
        // check if there are new knowledge areas
        const initExistingKAsObj: { [key: string]: CMS.Class.KnowledgeAreaDraftJSON } = {},
            existingKAs = knowledgeAreaDrafts.reduce((acc, ka) => {
                acc[ka.name] = ka
                return acc
            }, initExistingKAsObj),
            initImportedKAsObj: string[] = [],
            importedKAs = this.importedQuestions.reduce((acc, iq) => {
                const subjName = iq.subject
                if (!acc.includes(subjName)) {
                    acc.push(subjName)
                }
                return acc
            }, initImportedKAsObj),
            missingKAs = importedKAs.filter(ka => !(ka in existingKAs))

        let newKADrafts: CMS.Class.KnowledgeAreaDraftJSON[] = []
        if (missingKAs.length) {
            const newKADraftPromises = missingKAs.map(ka =>
                kaDraftsModule.actions.createKADraft({ examDraftId: examDraft.objectId, isArchived: false, name: ka })
            )

            newKADrafts = await Promise.all(newKADraftPromises)
        }

        return newKADrafts
    }

    selectQuestionDrafts (selectedData: IListQuestionDraft[]) {
        this.$nextTick(() => {
            this.selectedQuestions = selectedData
                .filter(data => data.isDisabled !== 'Yes' && data.isArchived !== 'Archived')
                .map(data => data.objectId || '')
        })
    }
}