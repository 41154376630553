import { createApp } from 'vue'
import { usersModule } from '@/store/users/module'
import '@/styles/global.scss'
import '@pocketprep/ui-kit/normalize'
import '@pocketprep/ui-kit/fonts'
import '@pocketprep/ui-kit/styles'
import App from '@/App.vue'
import router from '@/router'
import '@/elk'

const app = createApp(App)

import { SentryInit } from '@/sentry'
SentryInit(app)

app.use(router)

import VueSocketIO from 'vue-3-socket.io'
import SocketIO from 'socket.io-client'
app.use(new VueSocketIO({
    connection: SocketIO({
        reconnection: window.location.origin.includes(':8080') || window.location.origin.includes(':5300')
            ? false
            : true,
        transports: [ 'websocket' ],
    }),
}))

app.config.errorHandler = async (e) => {
    const error = e as Error | string
    if (error.toString().includes('Invalid session token')) {
        await usersModule.actions.logOut()
        router.push({
            name: 'sign-in',
            query: {
                error: 'Your session has expired. Please sign in again to continue studying.',
            },
        }).catch(() => {
            // noop
        })
    } else {
        throw error
    }
}

app.directive('click-outside', {
    beforeMount (el, binding) {
        const clickHandler = (e: Event) => {
            const target = e.target as HTMLSelectElement
            if (!el.contains(target) && el !== e.target) {
                binding.value(clickHandler)
            }
        }

        document.addEventListener('click', clickHandler)
    },
    
    unmounted (el, binding) {
        document.removeEventListener('click', binding.value)
    },
})

app.mount('#app')
