import { reactive } from 'vue'
import state from './state'
import actions from './actions'

const navigationModule = {
    state: reactive(state),
    actions,
}

export default navigationModule
