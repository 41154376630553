<template>
    <div class="bundles">
        <Loading v-if="isLoading" />
        <div
            class="bundles__title"
        >
            <TitleText>
                Bundles
            </TitleText>
            <PocketButton
                class="bundles__create-bundle-btn"
                @click="createBundleClicked"
            >
                Create Bundle
            </PocketButton>
        </div>
        <List 
            v-if="!isLoading"
            store-name="bundlesList"
            :list-options="listOptions"
            :pre-selected-items="selectedBundles"
            @select="selectedBundles = $event"
            @itemClicked="bundleClicked"
        />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import Loading from '@/components/Loading.vue'
import { type IListOptions, List } from '@/components/Lists'
import type { Study } from '@pocketprep/types'
import { bundlesModule } from '@/store/bundles/module'
import TitleText from '@/components/TitleText.vue'
import UIKit from '@pocketprep/ui-kit'

@Component({
    components: {
        List,
        Loading,
        TitleText,
        PocketButton: UIKit.Button,
    },
})
export default class BundleList extends Vue {
    isLoading = true
    bundles: Study.Class.BundleJSON[] = []
    selectedBundles: Study.Class.BundleJSON[] = []

    get listOptions (): IListOptions<Study.Class.BundleJSON> {
        return {
            listData: this.bundles,
            listSchema: [
                {
                    propName: 'name',
                    label: 'Name',
                    type: 'text',
                    options: {
                        width: 200,
                        group: 0,
                    },
                },
            ],
            listDataModifiers: [
                data => data.isDisabled && { opacity: '0.5' },
            ],
        }
    }

    async mounted () {
        this.isLoading = true

        this.bundles = await bundlesModule.actions.fetchBundles()

        this.isLoading = false
    }

    bundleClicked (bundle: Study.Class.BundleJSON) {
        this.$router.push({
            name: 'bundle-edit',
            params: {
                bundleId: String(bundle.objectId),
            },
        })
    }

    createBundleClicked () {
        this.$router.push({
            name: 'bundle-create',
        })
    }
}
</script>

<style lang="scss" scoped>

.bundles {
    flex-grow: 1;
    padding: $base;
    padding-bottom: 0;
    margin-top: 52px;

    &__title {
        display: flex;
        align-items: center;
        padding-left: 10px;
        margin-top: 46px;
        margin-bottom: 34px;
    }

    &__create-bundle-btn {
        margin-left: auto;
        margin-right: 10px;
        margin-bottom: 4px;
    }
}
</style>
