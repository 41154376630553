import type { INavigationModuleState } from './types'

export const getInitialNavigationState = (): INavigationModuleState => {
    const menuVisibleString = localStorage.getItem('menuVisible')
    const menuVisible: boolean = menuVisibleString && JSON.parse(menuVisibleString) || false

    return {
        menuVisible,
    }
}

const moduleState = getInitialNavigationState()

export default moduleState
