import { reactive } from 'vue'
import state from './state'
import getters from './getters'
import actions from './actions'

export const usersModule = {
    state: reactive(state),
    getters,
    actions,
}
