import type { TGetExam } from './types'
import examsModule from '@/store/exams/module'

/**
 * Return the exam object with the given objectId
 */
const getExam = (id: Parameters<TGetExam>[0]): ReturnType<TGetExam> => {
    return examsModule.state.exams.find(exam => exam.objectId === id)
}

/**
 * Return an exam nativeAppName with a given exam objectId
 */
const getExamName = (id: Parameters<TGetExam>[0]) => {
    return examsModule.state.exams.find(exam => exam.objectId === id)?.nativeAppName
}

export default {
    getExam,
    getExamName,
}
