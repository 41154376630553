import type { TGetListState } from './types'
import listsModule from '@/store/lists/module'


const getListState = (listName: Parameters<TGetListState>[0]): ReturnType<TGetListState> => {
    return listsModule.state.listStates.find(listState => listState.listName === listName)
}

export default {
    getListState,
}
