import Parse from 'parse'
if (import.meta.env.NODE_ENV === 'test') {
    Parse.User.enableUnsafeCurrentUser()
}
import * as R from 'ramda'
import type { ISearchParams } from '@/store/types'

const parseAppId = import.meta.env.VUE_APP_PARSE_APP_ID
const parseJSKey = import.meta.env.VUE_APP_PARSE_JAVASCRIPT_KEY
const parseUrl = import.meta.env.VUE_APP_PARSE_URL

if (parseAppId && parseUrl && parseJSKey) {
    Parse.initialize(parseAppId, parseJSKey)
    Parse.serverURL = parseUrl
}
export { Parse }

export const ROLES = {
    ADMIN: 'Admin',
    EDITOR: 'Editor',
    WRITER: 'Writer',
}

/*
 * Determine if the call included the required parameters
 *
 * @param {object} params - object with key/value pairs to check
 * @param {array} requiredKeys - array of keys expected in params
 *
 * @returns {promise} success / failure
 */
export const validateParams = <T extends Record<string, unknown>>(params: T, requiredKeys: (keyof T)[]) => {
    const providedKeys = R.keys(params),
        intersection = R.intersection(providedKeys, requiredKeys)

    return (intersection.length === requiredKeys.length)
        ? Promise.resolve()
        : Promise.reject('Unknown Function / Parameters')
}

/**
 * Create a Parse pointer object
 *
 * @param {string} className - value of the class
 * @param {string} objectId - object's Id value
 *
 * @returns {object} object that conforms to Parse Pointer interface
 */
export const objPointer = (objectId: string) => 
    (className: string): 
    { __type: 'Pointer'; className: string; objectId: string } =>
        ({
            __type: 'Pointer',
            className,
            objectId,
        })

/**
 * Get the session token for the current user or return undefined
 *
 * @returns {object|undefined} current session token string or undefined if not current user
 */
export const sessionToken = (): { sessionToken: string } | undefined => {
    const currentUser = Parse.User.current()
    return currentUser 
        ? { sessionToken: currentUser.getSessionToken() } 
        : undefined
}

// Additional Parse Types
export interface IParseDate {
    iso: string
}

/**
 * Add pagination, filter, and sort constraints to a Parse.Query
 * 
 * @return The result count prior to adding pagination
 */
export const applySearchParams = async <T extends { [key: string]: unknown }>(searchQuery: Parse.Query, {	
    perPage,
    page = 0,	
    orderBy,	
    order = 'ascending',
    equalTo,
    notEqualTo,
    contains,
    containsAll,
    containedIn,
    exists,
    doesNotExist,
    caseSensitive = false,
}: ISearchParams<T> = {	
    perPage: 20,
    page: 0,
}): Promise<number> => {
    const addPagination = (query: Parse.Query) => perPage && query.limit(perPage).skip(page * perPage)
    const addSorting = (query: Parse.Query) => {
        if (orderBy && typeof orderBy === 'string') {
            if (order === 'descending') {
                query.addDescending(orderBy)	
            } else {	
                query.addAscending(orderBy)	
            }
        }
    }
    const addEqualTo = (query: Parse.Query) => {
        if (equalTo) {
            const equalToProps = Object.keys(equalTo)
            if (equalToProps.length) {
                equalToProps.forEach(item => query.equalTo(item, equalTo[item]))
            }
        }
    }
    const addNotEqualTo = (query: Parse.Query) => {
        if (notEqualTo) {
            const notEqualToProps = Object.keys(notEqualTo)
            if (notEqualToProps.length) {
                notEqualToProps.forEach(item => query.notEqualTo(item, notEqualTo[item]))
            }
        }
    }
    const addContains = (query: Parse.Query) => {
        if (contains) {
            const containProps = Object.keys(contains)
            if (containProps.length) {
                containProps.forEach(item => query.matches(
                    item,
                    new RegExp(contains[item] || ''),
                    caseSensitive ? undefined : 'i'
                ))
            }
        }
    }
    const addContainsAll = (query: Parse.Query) => {
        if (containsAll) {
            const containsAllProps = Object.keys(containsAll)
            if (containsAllProps.length) {
                containsAllProps.forEach(item => query.containsAll(item, containsAll[item] || []))
            }
        }
    }

    const addContainedIn = (query: Parse.Query) => {
        if (containedIn) {
            const containedInProps = Object.keys(containedIn)
            if (containedInProps.length) {
                containedInProps.forEach(item => query.containedIn(item, containedIn[item] || []))
            }
        }
    }

    const addExists = (query: Parse.Query) => {
        if (exists instanceof Array && exists.length) {
            exists.forEach(item => query.exists(String(item)))
        }
    }

    const addDoesNotExist = (query: Parse.Query) => {
        if (doesNotExist instanceof Array && doesNotExist.length) {
            doesNotExist.forEach(item => query.doesNotExist(String(item)))
        }
    }

    addSorting(searchQuery)
    addEqualTo(searchQuery)
    addNotEqualTo(searchQuery)
    addContains(searchQuery)
    addContainsAll(searchQuery)
    addContainedIn(searchQuery)
    addExists(searchQuery)
    addDoesNotExist(searchQuery)
    const totalCount = await searchQuery.count()
    addPagination(searchQuery)

    return totalCount
}