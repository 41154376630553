<template>
    <!-- eslint-disable -->
<svg width="134" height="27" viewBox="0 0 134 27" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
<g style="mix-blend-mode:multiply">
<path fill-rule="evenodd" clip-rule="evenodd" d="M121.896 26.7761C78.6574 17.4701 39.0734 19.3182 11.3378 23.0625C5.86462 23.8014 0.828735 19.9635 0.0898539 14.4903C-0.649027 9.01707 3.1889 3.98118 8.66209 3.2423C37.9265 -0.708394 79.9561 -2.70836 126.104 7.22387C131.503 8.38592 134.938 13.7049 133.776 19.1041C132.614 24.5033 127.295 27.9382 121.896 26.7761Z" fill="#FFE4B6" fill-opacity="0.7"/>
</g>
</svg>
    <!-- eslint-enable -->
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'

@Component
export default class Highlight extends Vue {

}
</script>