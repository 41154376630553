import type { 
    TFetchCommentsByThread,
    TFetchSuggestionById,
    TDeleteAllSuggestions,
    TDeleteAllThreads,
    TSaveSuggestions,
    TSaveThreads,
} from './types'
import { Parse, sessionToken, objPointer } from '@/store/ParseUtils'
import type { CMS } from '@pocketprep/types'

/**
 * Get all comments for a specific thread
 *
 * @param {string} threadId - ID of thread to get comments for
 *
 * @returns {Promise} resolves to IThread[] of comments
 */
const fetchCommentsByThread = async (
    threadId: Parameters<TFetchCommentsByThread>[0]
): ReturnType<TFetchCommentsByThread> => {
    const commentQuery = new Parse.Query<CMS.Class.CKComment>('CK_Comment')
    commentQuery.equalTo('threadId', threadId)
    const comments = await commentQuery.find(sessionToken())
    
    return {
        threadId,
        comments: comments.map(comment => ({
            commentId: comment.get('commentId'),
            authorId: comment.get('author').id,
            content: comment.get('content'),
            createdAt: comment.get('commentDate') || comment.createdAt,
        })),
    }
}

/**
 * Get suggestion by ID
 *
 * @param {string} suggestionId - ID of suggestion to get
 *
 * @returns {Promise} resolves to ISuggestion
 */
const fetchSuggestionById = async (
    suggestionId: Parameters<TFetchSuggestionById>[0]
): ReturnType<TFetchSuggestionById> => {
    const suggestionQuery = new Parse.Query<CMS.Class.CKSuggestion>('CK_Suggestion')
    suggestionQuery.equalTo('suggestionId', suggestionId)
    const suggestion = await suggestionQuery.first(sessionToken())

    if (!suggestion) {
        throw 'No suggestion found.'
    }
    
    return {
        id: suggestion.get('suggestionId'),
        type: suggestion.get('type'),
        authorId: suggestion.get('author').id,
        data: suggestion.get('data'),
        hasComments: suggestion.get('hasComments'),
        createdAt: suggestion.createdAt,
    }
}

/**
 * Delete all suggestions
 *
 * @param {string} questionDraftId - ID of question to delete suggestions from
 *
 */
const deleteAllSuggestions = async (
    questionDraftId: Parameters<TDeleteAllSuggestions>[0]): ReturnType<TDeleteAllSuggestions> => {
    try {
        const suggestionQuery = new Parse.Query<CMS.Class.CKComment>('CK_Suggestion')
        suggestionQuery.equalTo('questionDraftId', questionDraftId)
        const suggestionsToDestroy = await suggestionQuery.find(sessionToken())
        await Parse.Object.destroyAll(suggestionsToDestroy)

        return true
    } catch (e) {
        return false
    }
}

/**
 * Delete all threads
 *
 * @param {string} questionDraftId - ID of question to delete threads from
 *
 */
const deleteAllThreads = async (
    questionDraftId: Parameters<TDeleteAllThreads>[0]): ReturnType<TDeleteAllThreads> => {
    try {
        const commentQuery = new Parse.Query<CMS.Class.CKComment>('CK_Comment')
        commentQuery.equalTo('questionDraftId', questionDraftId)
        const comments = await commentQuery.find(sessionToken())
        await Parse.Object.destroyAll(comments)

        return true
    } catch (e) {
        return false
    }
}

/**
 * Save suggestions
 *
 * @param {ISuggestion[]} suggestions - suggestions to save or update
 * @param {string} questionDraftId - ID of question suggestions belong to
 *
 * @returns {Promise} resolves to ISuggestion[] of suggestions
 */
const saveSuggestions = async (
    { suggestions, questionDraftId, examDraftId }: Parameters<TSaveSuggestions>[0]): ReturnType<TSaveSuggestions> => {
    // map all comments to prepare for Parse
    if (!suggestions || !suggestions.length) {
        return false
    }

    const mappedSuggestions = suggestions.map(suggestion => ({
            ...suggestion,
            questionDraftId,
            examDraftId,
        })),
        parseSuggestions = mappedSuggestions.map(suggestion => {
            const parseSuggestion = new Parse.Object('CK_Suggestion',
                {
                    questionDraftId: suggestion.questionDraftId,
                    examDraftId: suggestion.examDraftId,
                    suggestionId: suggestion.id,
                    type: `${suggestion.type}${suggestion.subType ? `:${suggestion.subType}` : ''}`,
                    author: objPointer(suggestion.author.id)('_User'),
                    hasComments: !!suggestion.commentThread?.comments?.length,
                    data: suggestion.data || undefined, // defaults to 'null' for some reason
                })

            return parseSuggestion
        })

    // save suggestions
    await Parse.Object.saveAll(parseSuggestions, sessionToken())
}

/**
 * Save threads
 *
 * @param {IThread[]} threads - threads to save or update
 * @param {string} questionDraftId - ID of question threads belong to
 *
 * @returns {Promise} resolves to IComment[] of comments
 */
const saveThreads = async (
    { threads, questionDraftId, examDraftId }: Parameters<TSaveThreads>[0]): ReturnType<TSaveThreads> => {
    if (!threads || !threads.length) {
        return false
    }

    // map all comments to prepare for Parse
    const mappedComments = threads.map(thread => {
        return thread.comments.map(comment => ({
            ...comment,
            threadId: thread.threadId,
            questionDraftId,
            examDraftId,
        }))
    }).reduce((acc, val) => acc.concat(val), [])

    // map all comments to Parse Objects for saving
    const parseComments = mappedComments.map(comment => {
        const parseComment = new Parse.Object('CK_Comment',
            {
                questionDraftId: comment.questionDraftId,
                examDraftId: comment.examDraftId,
                commentId: comment.commentId,
                content: comment.content,
                commentDate: comment.createdAt,
                threadId: comment.threadId,
                author: objPointer(comment.authorId)('_User'),
            })

        return parseComment
    })

    // save comments 
    await Parse.Object.saveAll(parseComments, sessionToken())
}

export default {
    fetchCommentsByThread,
    fetchSuggestionById,
    deleteAllSuggestions,
    deleteAllThreads,
    saveSuggestions,
    saveThreads,
}
